import axios from "axios";
import { clearAllCachevalue, localClearAll, localGetItem, localSetItem } from "../../Utility/Services/CacheProviderService";

// const BASE_DOMAIN = "https://devv4api.emedhub.in/" //DEV API URL
const BASE_DOMAIN = "https://uat.v4.api.emedhub.in/"; //UAT API URL
// const BASE_DOMAIN = "https://api.emedhub.in/"; //Production API URL

// const Token = localGetItem("AccessToken")

// const API_HEADERS = axios.create({
//   baseURL: BASE_DOMAIN,
//   headers: {
//     "Content-type": "application/json",
//     "Authorization": "Token " + Token
//   }
// });
const App_Version = 13.1
class RestAPIService {
  getAll = async(URL) => {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No connection available'));
    } else {
      const response = await axios.create({
        baseURL: BASE_DOMAIN,
        headers: {
          "Content-type": "application/json",
          "Authorization": "Token " + localGetItem("AccessToken")
        }
      }).get("/" + URL);
      const  {data} = response
      if(data?.version != App_Version && typeof(data) === "object"){
        localClearAll()
        clearAllCachevalue()
        setTimeout(() => {
          window.location.reload(true)
        }, 1000);
        return response
      }else{
        if(data?.show_sms_alert){
          localSetItem("show_sms_alert", true)
        }else{
          localSetItem("show_sms_alert", false)
        }
        return response
      }

    }
  }
  get(id, URL) {
    return axios.create({
      baseURL: BASE_DOMAIN,
      headers: {
        "Content-type": "application/json",
        "Authorization": "Token " + localGetItem("AccessToken")
      }
    }).get(`/${URL}/${id}`);
  }
  getListbyData(id, data, URL) {
    return axios.create({
      baseURL: BASE_DOMAIN,
      headers: {
        "Content-type": "application/json",
        "Authorization": "Token " + localGetItem("AccessToken")
      }
    }).get(`${URL}/${id}`, data);
  }
  create(data, URL) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      return axios.create({
        baseURL: BASE_DOMAIN,
        headers: {
          "Content-type": "application/json",
          "Authorization": "Token " + localGetItem("AccessToken")
        }
      }).post("/" + URL, data);
    }
  }
  update(id, data, URL) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      return axios.create({
        baseURL: BASE_DOMAIN,
        headers: {
          "Content-type": "application/json",
          "Authorization": "Token " + localGetItem("AccessToken")
        }
      }).put(`/${URL}/${id}`, data);
    }
  }
  delete(URL, Data) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      return axios.create({
        baseURL: BASE_DOMAIN,
        headers: {
          "Content-type": "application/json",
          "Authorization": "Token " + localGetItem("AccessToken")
        }
      }).delete("/" + URL, { data: Data });
    }
  }
  deleteAll(URL) {
    return axios.create({
      baseURL: BASE_DOMAIN,
      headers: {
        "Content-type": "application/json",
        "Authorization": "Token " + localGetItem("AccessToken")
      }
    }).delete(`/${URL}`);
  }
  findByTitle(title, URL) {
    return axios.create({
      baseURL: BASE_DOMAIN,
      headers: {
        "Content-type": "application/json",
        "Authorization": "Token " + localGetItem("AccessToken")
      }
    }).get(`/${URL}?title=${title}`);
  }
  updateWithOutId(data, URL) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      return axios.create({
        baseURL: BASE_DOMAIN,
        headers: {
          "Content-type": "application/json",
          "Authorization": "Token " + localGetItem("AccessToken")
        }
      }).put(`/${URL}`, data)
    }
  }
  patch(data, URL) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      return axios.create({
        baseURL: BASE_DOMAIN,
        headers: {
          "Content-type": "application/json",
          "Authorization": "Token " + localGetItem("AccessToken")
        }
      }).patch(`/${URL}`, data);
    }
  }
  excelGet(URL) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      return axios.create({
        baseURL: BASE_DOMAIN,
        headers: {
          "Content-type": "application/json",
          "Authorization": "Token " + localGetItem("AccessToken")
        },
        responseType: 'blob'
      }).get("/" + URL);
    }
  }
  PrintPDF(URL) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      return axios.create({
        baseURL: BASE_DOMAIN,
        headers: {
          "Content-type": "application/pdf",
          "Authorization": "Token " + localGetItem("AccessToken")
        },
        responseType: 'blob',
      }).get("/" + URL);
    }
  }
  LoginAuthGetModules = async(URL) => {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      const response = await axios.create({
        baseURL: BASE_DOMAIN,
        headers: {
          "Content-type": "application/json",
          "Authorization": "Token " + localGetItem("LoginAuthToken")
        }
      }).get("/" + URL);
      const  {data} = response
      if(data?.version != App_Version && typeof(data) === "object"){
        localClearAll()
        clearAllCachevalue()
        setTimeout(() => {
          window.location.reload(true)
        }, 1000);
        return response
      }else{
        return response
      }
    }
  }
  OPTICAL_CRM_POST(data) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      return axios.create({
        baseURL: "https://api.opticalcrm.com",
        headers: {
          "Content-type": "application/json",
          "Token": localGetItem("OPTICAL_CRM_API_TOKEN")
        }
      }).post("/" + "sales_details.php", data);
    }
  }

  OPTICAL_CRM_POST_WITH_URL(url, data) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      return axios.create({
        baseURL: "https://api.opticalcrm.com",
        headers: {
          "Content-type": "application/json",
          "Token": localGetItem("OPTICAL_CRM_API_TOKEN")
        }
      }).post("/" + url , data);
    }
  }
  RemidiPost(url, data) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      return axios.create({
        baseURL: "https://demo.remediware.com",
        headers: {
          "Content-type": "application/json",
          "Origin" : "https://devv4api.emedhub.in/"
        },
        credentials : "include"
      }).post("/" + url, data);
    }
  }

  OPTICAL_CRM_GET(URL) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      return axios.create({
        baseURL: "https://api.opticalcrm.com",
        headers: {
          "Content-type": "application/json",
          "Token": localGetItem("OPTICAL_CRM_API_TOKEN")
        }
      }).get("/" + URL);
    }
  }

  QR_REGISTRATION_POST_WITH_URL(url, data){
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('No internet connection available'));
    } else {
      return axios.create({
        baseURL: BASE_DOMAIN,
        headers: {
          "Content-type": "application/json",
        }
      }).post("/" + url , data);
    }
  }
}

export default new RestAPIService();