import React, { Component } from "react";
import '../Configuration.css';
import { Box, Paper, TextField, Button, Stack, Typography, IconButton, Tooltip, Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, FormControlLabel } from "@mui/material";
import { withTranslation } from 'react-i18next';
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import { Demo_Layout_Default_JSON } from '../../../Utility/Constants'
import { ImagePaths } from '../../../Utility/ImagePaths'
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import Loader from '../../../Components/Loader'

class DemographicsConfigHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isErrorMsg: false,
            isErrorMsgText: "",
            mandotory_count: '04',
            right_Fields_data: [],
            drag_label: null,
            layout_id: null,
        }
    }

    componentDidMount() {
        this.getDemoLayout()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getDemoLayout = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FODemographicsLayout)
                .then((response) => {
                    if (response.data.status === "success") {
                        var Data = response.data.data
                        var len = Data.length
                        this.setState({
                            layout_id: Data[len - 1] ? Data[len - 1].id : null,
                            right_Fields_data: Data[len - 1] ? Data[len - 1].field_order : Demo_Layout_Default_JSON,
                            mandotory_count: Data[len - 1] ? ( Data[len - 1].mandatory_field_count < 10 ? "0" + Data[len - 1].mandatory_field_count : Data[len - 1].mandatory_field_count) : '04',
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })

        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    CheckValidation = () => {

        let states = this.state
        let MandatoryCount = +(states.mandotory_count)
        let flag = false;

        for (let i = 0; i < MandatoryCount; i++) {
            if (!states?.right_Fields_data[i]?.is_active) {
                this.errorMessage(`Mandatory fields should be active!`)
                flag = false
                break
            } else {
                flag = true
            }
        }
        return flag
    }

    postDemoLayout = () => {
        try {
            var states = this.state

            var data = {
                mandatory_field_count: +(states.mandotory_count),
                field_order: states.right_Fields_data
            }

            // if (states.layout_id) {
            //     data['id'] = states.layout_id
            // }

            let validation = this.CheckValidation()
            if (validation) {
            RestAPIService.create(data, Serviceurls.FODemographicsLayout)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
            }
        } catch (e) {

        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    renderInput = (data, type) => {
        return (
            <Box sx={{ marginTop: '2.5vh', marginLeft: '1vw', width: (data.label == "Age" || data.label == "Title" || data.label == "Gender") ? '5.5vw' : '12vw' }}>
                <TextField
                    disabled
                    label={(type === 'mandatory' || data.is_default) ? (data.label + "*") : data.label}
                    variant="outlined"
                    size="small"
                    defaultValue=" "
                />
            </Box>
        )
    }

    renderDemoLayoutLeft = () => {
        try {
            const { t } = this.props
            var states = this.state
            var Mandatory_Array = []
            var Non_Mandatory_Array = []
            const SplitCount = +(states.mandotory_count)
            const Total_Len = (states.right_Fields_data).length

            var Active_Array = (states.right_Fields_data).filter((item) => {
                return item.is_active == true
            })

            var Mandatory_Array = (Active_Array).slice(0, SplitCount)
            var Non_Mandatory_Array = (Active_Array).slice(SplitCount, Total_Len)

            return (
                <Stack component={"div"}>
                    <Paper className='eMed_demolayout_left1'  sx={{height: states?.mandotory_count > 10 ? "39.3vh" : "24.8vh"}}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#FAF9F9' }}>
                            <Typography className='eMed_refdoc_title'>{t("Mandatory Demographics Fields")}</Typography>
                            <Typography className="emed_demo_left_title_right">{t('Preview')}</Typography>
                        </Box>
                        <Box className="eMed_demolayout_left_TextInput">
                            {Mandatory_Array.map((item, index) => (
                                this.renderInput(item, 'mandatory')
                            ))}
                        </Box>

                    </Paper>
                    <Paper className='eMed_demolayout_left2' sx={{height: states?.mandotory_count > 10 ? "35.5vh" : "50vh"}}>
                        <Typography className='eMed_refdoc_title'>{t("Non Mandatory Demographics Fields")}</Typography>
                        <Box className="eMed_demolayout_left_TextInput" sx={{height: states?.mandotory_count > 10 ? "27vh" : "41vh", overflowY:"scroll"}}>
                            {Non_Mandatory_Array.map((item, index) => (
                                this.renderInput(item, 'non-mandatory')
                            ))}
                        </Box>
                    </Paper>

                </Stack>
            )
        } catch (e) {
            this.errorMessage(e)
        }
    }

    OnChangeCount = (e) => {
        let DefaultCount = 0
        this.state.right_Fields_data.forEach((item)=>{
        if(item.is_default === true && item.id <= e.target.value){DefaultCount += 1}
        })
        if(DefaultCount === 4){   // Check all 4 default fields are within the count
        this.setState({
            mandotory_count: e.target.value
        })}
        else{
            this.errorMessage("Default Mandatory Fields are only Within Mandatory Counts")
        }
    }

    handleDrag = (e) => {
        this.setState({
            drag_label: e.currentTarget.id
        })
    }

    handleDrop = (e) => {
        var states = this.state
        const dragBox = (states.right_Fields_data).find((field) => field.label === states.drag_label);
        const dropBox = (states.right_Fields_data).find((field) => field.label === e.currentTarget.id);

        const dragBoxID = dragBox.id;
        const dropBoxID = dropBox.id;

        const dragBoxField = dragBox.field_name;
        const dropBoxField = dropBox.field_name;

        const dragBoxCheck = dragBox.is_active;
        const dropBoxCheck = dropBox.is_active;

        // To make the default field also draggable.
        const dragBoxDefault = dragBox.is_default;
        const dropBoxDefault = dropBox.is_default;

        const newBoxState = (states.right_Fields_data).map((field) => {
            if ((!dragBoxDefault && !dropBoxDefault) || (dragBoxDefault && dropBoxDefault)) {
                if (field.label === states.drag_label) {
                    field.id = dropBoxID;
                    field.field_name = dragBoxField;
                    field.is_active = dragBoxCheck;
                    field.is_default = dragBoxDefault;
                }
                if (field.label === e.currentTarget.id) {
                    field.id = dragBoxID;
                    field.field_name = dropBoxField;
                    field.is_active = dropBoxCheck;
                    field.is_default = dropBoxDefault;
                }
            } else if (dropBoxID <= +this.state.mandotory_count && dragBoxID <= +this.state.mandotory_count) {
                if (field.label === states.drag_label) {
                    field.id = dropBoxID;
                    field.field_name = dragBoxField;
                    field.is_active = dragBoxCheck;
                    field.is_default = dragBoxDefault;
                }
                if (field.label === e.currentTarget.id) {
                    field.id = dragBoxID;
                    field.field_name = dropBoxField;
                    field.is_active = dropBoxCheck;
                    field.is_default = dropBoxDefault;
                }
            } else if (!(dropBoxID <= +this.state.mandotory_count && dragBoxID <= +this.state.mandotory_count)){
                this.errorMessage("Default Mandatory Fields are only dragable Within Mandatory Counts")
            }

            return field;
        });

        const SortData = newBoxState.sort((a, b) => { return a.id - b.id })

        this.setState({
            right_Fields_data: SortData
        })
    }

    handleCheckClick = (e) => {
        var { right_Fields_data } = this.state

        const ID = e.target.id
        const Index = right_Fields_data.findIndex((data) => {
            return data.id == ID
        })

        if (right_Fields_data[Index].is_default) {

        } else {
            right_Fields_data[Index].is_active = e.target.checked
        }

        this.setState({
            right_Fields_data
        })
    }

    renderRightField = (data) => {
        return (
            <div
                // To make the default field also draggable.
                // draggable={data.is_default ? false : true}
                draggable={true}
                onDragOver={(e) => e.preventDefault()}
                onDragStart={this.handleDrag}
                onDrop={this.handleDrop}
                id={data.label}
                className="eMed_demolayout_right2_field">
                <img className="eMed_demolayout_right2_field_img" src={ImagePaths.DragIndicator.default} alt="" />
                <FormControlLabel control={<Checkbox id={data.id} onChange={this.handleCheckClick} checked={data.is_active} />} label={data.is_default ? (data.label + "*") : data.label} />
            </div>
        )
    }

    renderDemoLayoutRight = () => {
        try {
            const { t } = this.props
            var states = this.state
            const count_data = ['04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15']
            return (
                <Stack component={"div"}>
                    <Paper className='eMed_demolayout_right1' elevation={2} >
                        <Typography className='eMed_refdoc_title'>{t("Demographics")}</Typography>
                        <Box className='emed_demolayout_right1_box'>
                            <Typography className='eMed_refdoc_title'>{t("Mandatory Fields")}</Typography>
                            <FormControl>
                                <Select
                                    size="small"
                                    value={states.mandotory_count}
                                    autoWidth
                                    onChange={this.OnChangeCount}
                                    className="emed_demolayout_right1_select"
                                    MenuProps={{
                                        style: { maxHeight: 350 }
                                    }}
                                >
                                    {count_data.map((value, index) => (
                                        <MenuItem value={value} key={index}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography fontSize={'60%'} color={'#888888'} alignSelf='center'>{('(') + t('System will take the first ') + (+states.mandotory_count) + t(' fields as a mandatory fields') + (')')}</Typography>
                        </Box>
                    </Paper>
                    <Paper className='eMed_demolayout_right2' elevation={2} >
                        {(states.right_Fields_data)
                            // .sort((a, b) => a.id - b.id)
                            .map((item, index) => {
                                return (
                                    this.renderRightField(item)
                                )
                            })}
                    </Paper>
                    <Paper className='eMed_demolayout_right3' elevation={2}>
                        <Button variant='outlined' size="small" id="eMed_Config_btn" sx={{ mr: '0.5vw' }} onClick={() => {
                            this.setState({
                                layout_id: null,
                                right_Fields_data: Demo_Layout_Default_JSON,
                                mandotory_count: '04',
                            })
                         }}>{t("RESET")}</Button>
                        <Button disabled={!CheckAccessFunc("front_office", "Settings", "Layout Configuration", "Demographics Configuration", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" sx={{ ml: '0.5vw' }} onClick={() => { this.postDemoLayout() }}>{t("SAVE")}</Button>
                    </Paper>

                </Stack>
            )
        } catch (e) {
            this.errorMessage(e)
        }
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        try {
            return (
                <Box component={"div"} className='eMed_refdoc_container'>
                    <Grid container spacing={2} className='eMed_refdoc_content' >
                        <Grid item xs={8}>
                            {this.renderDemoLayoutLeft()}
                        </Grid>
                        <Grid item xs={4}>
                            {this.renderDemoLayoutRight()}
                        </Grid>
                    </Grid>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    <Loader loaderOpen={this.state.isLoader} />
                </Box>
            )
        } catch (error) {
            this.errorMessage(error)
        }
    }
}
export default withTranslation()(DemographicsConfigHome);