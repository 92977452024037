import React, { Component } from 'react';
import { Autocomplete, Box, Button, Stack, Tooltip, Typography, TextField, Drawer, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import Loader from '../../../Components/Loader';
import { DateTime } from 'luxon';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';

class CultureTestReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            CultureReportData: [],
            groupData: [],
            testNameData: [],
            groupName: null,
            testName: null,
            FilterOpen: false,
            total_negative: 0,
            total_positive: 0,
            total_others: 0,
            TotalIsolates: 0,
            disableBtn: false,
            patientName: '',
            moduleType: '',
        }
    }

    componentDidMount() {
        this.GetCultureTestReport()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    removeDuplicate(data) {
        let nameListJsonObject = data.map(JSON.stringify);
        let nameListUniqueSet = new Set(nameListJsonObject);
        let nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }


    GetCultureTestReport = () => {
        try {
            let { moduleType } = this.state;
            this.LoaderFunction(true);
            this.setState({ disableBtn: true })
            RestAPIService.getAll(`${Serviceurls.CULTURE_TEST_REPORT}?module=${moduleType}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        let Data = response?.data?.data ? response?.data?.data : [];
                        this.LoaderFunction(false);
                        this.setState({
                            CultureReportData: Data,
                            TotalIsolates : response?.data?.total_isolation ? response?.data?.total_isolation : 0
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }


    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    closeFilter = () => {
        this.setState({
            FilterOpen: false
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <FormControl size='small' sx={{ width: '20vw' }}>
                            <InputLabel id="demo-simple-select-label">Select Module</InputLabel>
                            <Select
                                value={this.state.moduleType}
                                label="Select Result Type"
                                onChange={(e) => {
                                    this.setState({
                                        moduleType: e.target.value
                                    })
                                }}
                            >
                                <MenuItem value={""}>{"All"}</MenuItem>
                                <MenuItem value={"op"}>{"OP"}</MenuItem>
                                <MenuItem value={"ip"}>{"IP"}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                groupName: null,
                                testName: null,
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                patientName: '',
                                moduleType: '',
                            },
                                () => this.GetCultureTestReport())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid='Census_Report_FilterSearch_btn'
                        onClick={() => {
                            this.GetCultureTestReport()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    CultureReportDataPrint = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.CULTURE_TEST_REPORT}?module=${this.state.moduleType}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&export_type=pdf`)
                .then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        this.state.CultureReportData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "bacteria", headerName: t("Organism"), flex: 0.15, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.bacteria ? params?.row?.bacteria : '-'}</Box>)
            },
            {
                field: "isolation", headerName: t("No Of Isolates"), flex: 0.15, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.isolation ? params?.row?.isolation : '-'}</Box>)
            },
            {
                field: "percentage", headerName: t("% Of Isolates"), flex: 0.15, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.percentage ? params?.row?.percentage : '-'}</Box>)
            },
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard("Total Isolates", this.state.TotalIsolates, true)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' onClick={() => this.CultureReportDataPrint()}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} sx={{ height: '66vh', width: '92vw', margin: 'auto', backgroundColor: 'white' }}>
                    <DataGrid
                        rows={this.state.CultureReportData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(CultureTestReport)

