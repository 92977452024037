import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import DescriptionIcon from '@mui/icons-material/Description';
import ClearIcon from '@mui/icons-material/Clear';
import { AdmissionTypeList } from './RemedinetConstants';

class ClaimSubmissionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      CheckValidation: false,
      FormsDatas: this.props?.FormsDatas,
      ClaimDatas: {},
    }
    this.uploadAttachment = React.createRef();
  }
  // componentDidUpdate(prevProps) {
  //     if (prevProps !== this.props) {
  //         this.setState({
  //             ClaimDatas: this.props?.ClaimDatas
  //         })
  //     }
  // }
  handleChangeTextBox = (e, key) => {
    let FormData = this.state?.ClaimDatas
    switch (key) {
      case 'ip_number':
        FormData["ip_number"] = e.target.value
        break
      default:
        FormData[key] = e.target.value
        break;
    }
    this.setState({
      ClaimDatas: FormData,
      CheckValidation: false
    })
  }


  FormTextBox = (label, key, WidthinVW = 18, isMandatory = false) => {
    try {
      return (
        <Box className='eMed_Form_textBoxes' sx={{ width: `${WidthinVW}vw` }}>
          <TextField
            label={`${label}  ${isMandatory ? "*" : ""}`}
            variant="standard"
            sx={{ width: `${WidthinVW - 2}vw` }}
            error={isMandatory && this.state?.CheckValidation && !this.state?.ClaimDatas[key]}
            value={this.state?.ClaimDatas[key] ? this.state?.ClaimDatas[key] : ""}
            onChange={(e) => { this.handleChangeTextBox(e, key) }} />
        </Box>
      )
    }
    catch (e) {

    }
  }

  FormDateSelect = (label, key, WidthinVW = 18, isMandatory = false) => {
    try {
      return (
        <Box className='eMed_Form_textBoxes' sx={{ width: `${WidthinVW}vw` }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={this.state?.ClaimDatas[key] ? this.state?.ClaimDatas[key] : null}
              name={"datePicker"}
              inputFormat='DD-MM-YYYY'
              views={["year", "month", "day"]}
              onChange={
                (newDate) => {
                  let FormData = this.state?.ClaimDatas
                  FormData[key] = newDate
                  this.setState({
                    ClaimDatas: FormData
                  })
                }}
              renderInput={(params) => <TextField
                onKeyDown={(e) => e.preventDefault()}
                {...params}
                size='small'
                label={`${label}  ${isMandatory ? "*" : ""}`}
                variant="standard"
                sx={{ width: `${WidthinVW - 2}vw` }}
                error={isMandatory && this.state?.CheckValidation && !this.state?.ClaimDatas[key]} />}
            />
          </LocalizationProvider>
        </Box>
      )
    }
    catch (e) {

    }
  }
  FormTimeSelect = (label, key, WidthinVW = 18, isMandatory = false) => {
    try {
      return (
        <Box className='eMed_Form_textBoxes' sx={{ width: `${WidthinVW}vw` }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              format={'HH:mm'}
              value={this.state?.ClaimDatas[key] ? this.state?.ClaimDatas[key] : null}
              onChange={
                (newDate) => {
                  let FormData = this.state?.ClaimDatas
                  FormData[key] = newDate
                  this.setState({
                    ClaimDatas: FormData
                  })
                }}
              renderInput={(params) => <TextField
                onKeyDown={(e) => e.preventDefault()}
                {...params}
                size='small'
                label={`${label}  ${isMandatory ? "*" : ""}`}
                variant="standard"
                sx={{ width: `${WidthinVW - 2}vw` }}
                error={isMandatory && this.state?.CheckValidation && !this.state?.ClaimDatas[key]} />}
            />
          </LocalizationProvider>
        </Box>
      )
    }
    catch (e) {

    }
  }

  FormCheckBox = (label, key, WidthinVW = null, isMandatory = false) => {
    try {
      return (
        <Box className='eMed_Form_textBoxes' sx={{ width: WidthinVW ? `${WidthinVW}vw` : 'auto' }}>
          <FormControlLabel
            control={<Checkbox
              emed_tid="upi_checkbox"
              checked={this.state?.ClaimDatas[key] ? this.state?.ClaimDatas[key] : false}
              onChange={(e) => {
                let FormData = this.state?.ClaimDatas
                FormData[key] = e.target.checked
                this.setState({
                  ClaimDatas: FormData
                })
              }}
              size='small' />}
            label={`${label}  ${isMandatory ? "*" : ""}`} />
        </Box>
      )
    }
    catch (e) {

    }
  }

  FormAutoComplete = (label, List, labelKey, Valuekey, key, WidthinVW = 18, isMandatory = false) => {
    try {
      let States = this.state?.ClaimDatas
      return (
        <Box className='eMed_Form_textBoxes' sx={{ width: `${WidthinVW}vw` }}>
          <Autocomplete
            options={List}
            getOptionLabel={(option) => option[labelKey]}
            value={(List?.length > 0 && States[key]) ? List.find((item) => (item[Valuekey] === States[key])) : null}
            onChange={(e, value) => {
              let FormData = this.state?.ClaimDatas
              FormData[key] = value[Valuekey]
              this.setState({
                ClaimDatas: FormData
              })
            }}
            renderInput={(params) => <TextField
              {...params}
              size='small'
              label={`${label}  ${isMandatory ? "*" : ""}`}
              variant="standard"
              sx={{ width: `${WidthinVW - 2}vw` }}
              error={isMandatory && this.state?.CheckValidation && !this.state?.ClaimDatas[key]}
            // value={this.state?.ClaimDatas[key] ? this.state?.ClaimDatas[key] : ""}
            // onChange={(e) => { this.handleChangeTextBox(e, key) }} 
            />}
          />
        </Box>
      )
    }
    catch (e) {

    }
  }

  FormTwoRows = (label, key) => {
    let DurationList = ["Days", "Month", "Years"]
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '90vw' }}>
        <Typography width={"10vw"}>{label}</Typography>
        <FormControl sx={{ marginLeft: "1vw" }}>
          <RadioGroup
            value={this.state?.ClaimDatas[key]?.radio_value ? this.state?.ClaimDatas[key]?.radio_value : ""}
            sx={{ display: 'flex', flexDirection: 'row' }}
            onChange={(e) => {
              let FormData = this.state?.ClaimDatas
              let Obj = FormData[key] ? FormData[key] : {}
              Obj.radio_value = e.target.value
              FormData[key] = Obj
              this.setState({
                ClaimDatas: FormData
              })
            }}
          >
            <FormControlLabel value="Yes" control={<Radio size='small' />} label="Yes" />
            <FormControlLabel value="No" control={<Radio size='small' />} label="No" />
            <FormControlLabel value="Not Metioned" control={<Radio size='small' />} label="Not Metioned" />
          </RadioGroup>
        </FormControl>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box sx={{ width: `7vw`, marginRight: '1vw' }}>
            <TextField
              label={"Duration"}
              variant="standard"
              // error={isMandatory && this.state?.CheckValidation && !this.state?.ClaimDatas[key]}
              value={this.state?.ClaimDatas[key]?.duration_count ? this.state?.ClaimDatas[key]?.duration_count : ""}
              onChange={(e) => {
                let FormData = this.state?.ClaimDatas
                let Obj = FormData[key] ? FormData[key] : {}
                Obj.duration_count = e.target.value
                FormData[key] = Obj
                this.setState({
                  ClaimDatas: FormData
                })
              }} />
          </Box>
          <Select
            size='small'
            sx={{ width: '7vw', paddingTop: '1vw', paddingLeft: '0.5vw' }}
            variant='standard'
            value={this.state?.ClaimDatas[key]?.duration_type ? this.state?.ClaimDatas[key]?.duration_type : ""}
            label={"Duration"}
            onChange={(e) => {
              let FormData = this.state?.ClaimDatas
              let Obj = FormData[key] ? FormData[key] : {}
              Obj.duration_type = e.target.value
              FormData[key] = Obj
              this.setState({
                ClaimDatas: FormData
              })
            }}
          >
            {
              DurationList.map((list, index) => (
                <MenuItem key={index} value={list}>{list}</MenuItem>
              ))
            }
          </Select>
        </Box>
        <TextField
          variant="standard"
          sx={{ width: `30vw` }}
          label={"Remarks"}
          // error={isMandatory && this.state?.CheckValidation && !this.state?.ClaimDatas[key]}
          value={this.state?.ClaimDatas[key]?.remarks ? this.state?.ClaimDatas[key]?.remarks : ""}
          onChange={(e) => {
            let FormData = this.state?.ClaimDatas
            let Obj = FormData[key] ? FormData[key] : {}
            Obj.remarks = e.target.value
            FormData[key] = Obj
            this.setState({
              ClaimDatas: FormData
            })
          }} />
      </Box>
    )
  }

  render() {
    console.log(this.state.ClaimDatas);
    return (
      <Box>
        <Box className="eMEd_Form_body_div" height={"73vh"}>
          <Box className='eMed_Form_Div_body'>
            <Typography className='eMed_Form_Div_Title'>Claim Submission Fields</Typography>
            <Box className='eMed_Form_body_inputs_div'>
              {this.FormAutoComplete("Claim Status", AdmissionTypeList, "label", "id", "claim_status", 18, true)}
              {this.FormTextBox("Present Aliment", "present_aliment", 18, true)}
              {this.FormTextBox("Past Aliment", "past_aliment", 18, true)}
              {this.FormTextBox("Claim Discharge Type", "claim_discharge_type", 18, true)}
              {this.FormDateSelect("Claim Bill Date", "claim_bill_date", 18, true)}
              {this.FormTextBox("CGHS Procedure Count", "cghs_procedure_count", 18, true)}
              {this.FormTextBox("CGHS Allow Postprocedure", "cghs_allow_postprocedure", 18, true)}
              {this.FormTextBox("Is Nature Calamity", "is_nature_calamity", 18, true)}
              {this.FormTextBox("Accident Type", "accident_type", 18, true)}
            </Box>
          </Box>

          <Box className='eMed_Form_Div_body'>
            <Typography className='eMed_Form_Div_Title'>Legal Details</Typography>
            <Box className='eMed_Form_body_inputs_div'>
              {this.FormTextBox("FIR Number", "fir_number", 18, true)}
              {this.FormTextBox("Is It RTA", "is_it_rta", 18, true)}
              {this.FormTextBox("Is Injury Claim", "is_injury_claim", 18, true)}
              {this.FormTextBox("Is Injury Alcohol Claim", "is_injury_alcohol_claim", 18, true)}
              {this.FormTextBox("No FIR Reason Claim", "no_fir_reason_claim", 18, true)}
              {this.FormTextBox("Alcohol Report File Name", "alcohol_report_file_name", 18, true)}
              {this.FormTextBox("FIR Report File Name", "fir_report_file_name", 18, true)}
              {this.FormTextBox("Final Treatment Type", "final_treatment_type", 18, true)}
              {this.FormTextBox("Treatment Subtype", "treatment_subtype", 18, true)}
              {this.FormTextBox("Previous His Of PI", "previous_his_of_pi", 18, true)}
            </Box>
          </Box>
          <Box className='eMed_Form_Div_body'>
            <Typography className='eMed_Form_Div_Title'>Section</Typography>
            <Box className='eMed_Form_body_inputs_div'>
              {this.FormTextBox("Intensive Care", "intensive_care", 18, true)}
              {this.FormTextBox("Physican Reg No", "physican_reg_no", 18, true)}
              {this.FormTextBox("Final ICD Code", "final_icd_code", 18, true)}
            </Box>
          </Box>
          <Box className='eMed_Form_Div_body'>
            <Typography className='eMed_Form_Div_Title'>Previous Details</Typography>
            <Box className='eMed_Form_body_inputs_div'>
              {this.FormTextBox("Previous Claim No", "previous_claim_no", 18, true)}
              {this.FormTextBox("Previous Procedure Code", "previous_procedure_code", 18, true)}
              {this.FormTextBox("Previous Procedure Name", "previous_procedure_name", 18, true)}
              {this.FormTextBox("Previous Disease Code", "previous_disease_code", 18, true)}
              {this.FormTextBox("Previous Disease Name", "previous_disease_name", 18, true)}
              {this.FormTextBox("Previous DOD", "previous_dod", 18, true)}
            </Box>
          </Box>
          <Box className='eMed_Form_Div_body'>
            <Typography className='eMed_Form_Div_Title'>Insurance Details</Typography>
            <Box className='eMed_Form_body_inputs_div'>
              {this.FormTextBox("Insured First Name", "insured_first_name", 18, true)}
              {this.FormTextBox("Insured Middle Name", "insured_middle_name", 18, true)}
              {this.FormTextBox("Insured Last Name", "insured_last_name", 18, true)}
              {this.FormTextBox("Final Diagnosis", "final_diagnosis", 36, true)}
            </Box>
          </Box>
          <Box className='eMed_Form_Div_body'>
            <Typography className='eMed_Form_Div_Title'>Payment Details</Typography>
            <Box className='eMed_Form_body_inputs_div'>
              {this.FormTextBox("Patient Paid Amount", "patient_paid_amount", 18, true)}
              {this.FormTextBox("Hospital Claimed Amount", "hospital_claimed_Amount", 18, true)}
            </Box>
          </Box>

          <Box className='eMed_Form_Div_body'>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography className='eMed_Form_Div_Title'>Attachments</Typography>
              <input
                onChange={(e) => {
                  let states = this.state?.ClaimDatas
                  let Array = states.attachments ? states.attachments : []
                  Array.push({ filecontent: e.target.files[0], filename: e.target.files[0]?.name, remarks: "" })
                  states.attachments = Array
                  this.setState({ states })
                  e.target.value = ''
                }}
                hidden
                accept="application/pdf, image/jpg, image/jpeg, image/png"
                type="file"
                ref={this.uploadAttachment} />
              <Button size='small' variant='contained' sx={{ marginLeft: '2vw' }} onClick={() => this.uploadAttachment.current.click()}>
                Add Attachment
              </Button>
            </Box>
            <Box>
              {
                this.state.ClaimDatas?.attachments?.length > 0 ?
                  this.state.ClaimDatas?.attachments.map((list, index) => (
                    <Box className='eMed_document_div'>
                      <DescriptionIcon color='primary' />
                      <Typography width={"28vw"} paddingX={"1vw"}>{list?.filename?.length > 40 ? <Tooltip placement="top" title={list?.filename} arrow><div>{list?.filename.slice(0, 40) + "..."}</div></Tooltip> : list?.filename ? list?.filename : "-"}</Typography>
                      <TextField
                        label={`Attachment Remarks`}
                        variant="standard"
                        sx={{ width: `35vw` }}
                        value={list?.remarks ? list?.remarks : ""}
                        onChange={(e) => {
                          let states = this.state.ClaimDatas.attachments
                          states[index].remarks = e.target.value
                          this.setState({ states })
                        }} />
                      <IconButton onClick={() => {
                        let states = this.state.ClaimDatas.attachments
                        states.splice(index, 1)
                        this.setState({ states })
                      }}>
                        <ClearIcon color='error' />
                      </IconButton>
                    </Box>
                  ))
                  : null
              }
            </Box>
          </Box>

        </Box>
        <Box className="eMEd_Form_Foot_Btn_div">
          <Button size='small' sx={{ textTransform: 'capitalize' }} variant='outlined'>Save Draft</Button>
          <Box>
            <Button size='small' sx={{ textTransform: 'capitalize' }} variant='outlined' onClick={() => {
              // this.props.MovetoNext(this.state.FormsDatas, 1)
            }}>Cancel
            </Button>
            <Button size='small' sx={{ textTransform: 'capitalize', marginLeft: '1vw' }} variant='contained' onClick={() => {
              // this.props.MovetoPayerTab(this.state.ClaimDatas)
            }}>Next
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }
}
export default withTranslation()(ClaimSubmissionForm)
