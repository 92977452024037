import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, Modal, Paper, Radio, RadioGroup, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Colors } from '../../../../Styles/Colors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import './DoctorProfile.css'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import Loader from '../../../../Components/Loader';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import CloseIcon from '@mui/icons-material/Close';

export default class DocSessionConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            SessionsFullList: {
                "Monday": [],
                "Tuesday": [],
                "Wednesday": [],
                "Thursday": [],
                "Friday": [],
                "Saturday": [],
                "Sunday": [],
            },
            DeletedSessionsList: {
                "Monday": [],
                "Tuesday": [],
                "Wednesday": [],
                "Thursday": [],
                "Friday": [],
                "Saturday": [],
                "Sunday": [],
            },
            WeekArray: [],
            SessionStartTime: null,
            SessionEndTime: null,
            EditSessionIndex: null,
            IntervalValue: "",

            MondayCopy: false,
            TuesdayCopy: false,
            WednesdayCopy: false,
            ThursdayCopy: false,
            FridayCopy: false,
            SaturdayCopy: false,
            SundayCopy: false,

            SessionType: "all_week",
            DoctorRoomNo: "",
            SessionRemainder: 0,
            SessionConfigType: this.props?.DocSlotType ? this.props?.DocSlotType : (JSON.parse(localGetItem("loggedInUserInfo"))?.slot_based_on ? JSON.parse(localGetItem("loggedInUserInfo"))?.slot_based_on : "INTERVAL"),
            SavePopup: false,
        }
    }

    componentDidMount() {
        this.GetDocRoomNO()
        this.GetSessionDatas()
        // const speech = new SpeechSynthesisUtterance("Token Number 12");
        // window.speechSynthesis.speak(speech);
    }

    GetDocRoomNO() {
        try {
            this.LoaderFunction(true)
            let API_URL = ""
            if (this.props?.FromFO) {
                API_URL = Serviceurls.DOC_ROOMNO_CONFIG + '?doctor_id=' + (this.props?.SelectedDoctorDetails?.doctor_id ? this.props?.SelectedDoctorDetails?.doctor_id : "")
            } else {
                API_URL = Serviceurls.DOC_ROOMNO_CONFIG + '?clinic_id=' + (localGetItem('SelectedDoctorClinicId') ? +localGetItem('SelectedDoctorClinicId') : null)
            }
            RestAPIService.getAll(API_URL).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            DoctorRoomNo: response.data.data?.room_no ? response.data.data?.room_no : ""
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    GetSessionDatas() {
        try {
            this.LoaderFunction(true)
            let API_URL = ""
            if (this.props?.FromFO) {
                API_URL = Serviceurls.DOC_SESSION_CONFIG + '?doctor_id=' + (this.props?.SelectedDoctorDetails?.doctor_id ? this.props?.SelectedDoctorDetails?.doctor_id : "")
            } else {
                API_URL = Serviceurls.DOC_SESSION_CONFIG + '?clinic_id=' + (localGetItem('SelectedDoctorClinicId') ? +localGetItem('SelectedDoctorClinicId') : null)
            }
            RestAPIService.getAll(API_URL).
                then((response) => {
                    if (response.data.status === "success") {
                        let Response = response.data.data ? response.data.data : []
                        let SessionList = {
                            "Monday": Response[0]?.sessions ? Response[0]?.sessions.map((session, index) => ({...session, from_api: true, api_index : String(index) })) : [],
                            "Tuesday": Response[1]?.sessions ? Response[1]?.sessions.map((session, index) => ({...session, from_api: true , api_index : String(index) })) : [],
                            "Wednesday": Response[2]?.sessions ? Response[2]?.sessions.map((session, index) => ({...session, from_api: true, api_index : String(index)  })) : [],
                            "Thursday": Response[3]?.sessions ? Response[3]?.sessions.map((session, index) => ({...session, from_api: true, api_index : String(index)  })) : [],
                            "Friday": Response[4]?.sessions ? Response[4]?.sessions.map((session, index) => ({...session, from_api: true, api_index : String(index)  })) : [],
                            "Saturday": Response[5]?.sessions ? Response[5]?.sessions.map((session, index) => ({...session, from_api: true, api_index : String(index)  })) : [],
                            "Sunday": Response[6]?.sessions ? Response[6]?.sessions.map((session, index) => ({...session, from_api: true, api_index : String(index)  })) : [],
                        }
                        let IdList = {
                            "Monday": Response[0]?.id ? Response[0]?.id : [],
                            "Tuesday": Response[1]?.id ? Response[1]?.id : [],
                            "Wednesday": Response[2]?.id ? Response[2]?.id : [],
                            "Thursday": Response[3]?.id ? Response[3]?.id : [],
                            "Friday": Response[4]?.id ? Response[4]?.id : [],
                            "Saturday": Response[5]?.id ? Response[5]?.id : [],
                            "Sunday": Response[6]?.id ? Response[6]?.id : [],
                        }

                        this.setState({
                            SessionsFullList: SessionList,
                            IdList: IdList,
                            DuplicateSessList : JSON.parse(JSON.stringify((SessionList)))
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            DisableBtn: false
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            DisableBtn: false
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    timeStringToMinutes(time) {
        let [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    }

    isTimeSlotAvailable(sessions, newSession, EditSessionIndex = null) {
        let newStart = this.timeStringToMinutes(newSession.start_time);
        let newEnd = this.timeStringToMinutes(newSession.end_time);

        for (let [index, session] of sessions.entries()) {
            if (EditSessionIndex !== index) {
                let sessionStart = this.timeStringToMinutes(session.start_time);
                let sessionEnd = this.timeStringToMinutes(session.end_time);

                if ((newStart >= sessionStart && newStart < sessionEnd) ||
                    (newEnd > sessionStart && newEnd <= sessionEnd) ||
                    (newStart <= sessionStart && newEnd >= sessionEnd)) {
                    return false; // Overlapping found
                }
            }
        }
        return true; // No overlap found
    }

    Timeto24Hr(dateTimeString) {
        const date = new Date(dateTimeString);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const formattedTime = `${hours}:${minutes}`;
        return formattedTime
    }

    convertTo12Hour(time) {
        const [hour, minute] = time.split(':').map(Number);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12; // Converts "0" hour to "12"
        return `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
    }

    convertToJSDate(time) {
        const [hour, minute] = time.split(':').map(Number);
        const now = new Date();
        now.setHours(hour, minute, 0, 0); // Set the time to the specified hour and minute, with seconds and milliseconds set to 0
        return now;
    }

    handleCheckBox(e, key, type,) {
        if (type === "copy") {
            let newSession = {
                "start_time": this.Timeto24Hr(this.state.SessionStartTime),
                "end_time": this.Timeto24Hr(this.state.SessionEndTime),
                "type": this.state.SessionConfigType === "INTERVAL" ? "Time" : "Count",
                "interval": this.state.IntervalValue,
                "is_special_session": this.state.SessionType === "special_session" ? true : false,
                "special_sessions": this.state.WeekArray ? this.state.WeekArray?.join() : ""
            }
            let states = this.state
            if (states[`${key}Copy`]) {
                states[`${key}Copy`] = false
                this.setState({ states })
            } else if (this.isTimeSlotAvailable(this.state.SessionsFullList[key] ? this.state.SessionsFullList[key] : [], newSession)) {
                states[`${key}Copy`] = states[`${key}Copy`] ? false : true
                this.setState({ states })
            } else {
                this.setState({
                    ConFLictDay: key
                })
            }
        } else {
            let List = this.state.WeekArray
            if (e.target.checked) {
                List.push(key)
                this.setState({
                    WeekArray: List
                })
            } else {
                this.setState({
                    WeekArray: List.filter((item) => (item !== key))
                })
            }
        }
    }

    CalculateValidInterval() {
        try {
            if (!(this.state.SessionStartTime === null || isNaN(Date.parse(this.state.SessionStartTime)) || this.state.SessionEndTime === null || isNaN(Date.parse(this.state.SessionEndTime)) || this.state.IntervalValue === "")) {
                let newStart = this.timeStringToMinutes(this.Timeto24Hr(this.state.SessionStartTime));
                let newEnd = this.timeStringToMinutes(this.Timeto24Hr(this.state.SessionEndTime));
                let Remainder = (newEnd - newStart) % +this.state.IntervalValue
                this.setState({
                    SessionRemainder: Remainder ? Remainder : 0
                })
            } else {
                this.setState({
                    SessionRemainder: 0
                })
            }
        } catch (e) {

        }
    }

    renderSessionDialog = () => {
        return (
            <Dialog
                open={this.state.OpenSessionPopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0.5vw', marginBottom: '0vw' }}>
                </div>
                <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginX: "0.5vw" }}>
                    <Typography variant="h6" fontWeight={600}> {`Session ${this.state.SelectedSessionDatas?.session_count} - ${this.state.SelectedSessionDatas?.name}`}</Typography>
                    <IconButton emed_tid='renderSession_popup_close_btn' onClick={() => { this.ClearAlldatas() }}><CloseIcon /></IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography>Add Session Timing</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: "1vw" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label='Start Time *'
                                value={this.state.SessionStartTime ? this.state.SessionStartTime : null}
                                onChange={(newStartTime) => this.setState({
                                    SessionStartTime: newStartTime?.$d ? newStartTime?.$d : null,
                                    SessionRemainder: 0,
                                    MondayCopy: false,
                                    TuesdayCopy: false,
                                    WednesdayCopy: false,
                                    ThursdayCopy: false,
                                    FridayCopy: false,
                                    SaturdayCopy: false,
                                    SundayCopy: false,
                                }, () => { this.CalculateValidInterval() })}
                                renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "HH:MM (AM|PM)", "emed_tid": "doctor_schedule_startTime" }} style={{ width: "90%", marginRight: "1vw" }} size="small" />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label='End Time *'
                                value={this.state.SessionEndTime ? this.state.SessionEndTime : null}
                                onChange={(newStartTime) => this.setState({
                                    SessionEndTime: newStartTime?.$d ? newStartTime?.$d : null,
                                    SessionRemainder: 0,
                                    MondayCopy: false,
                                    TuesdayCopy: false,
                                    WednesdayCopy: false,
                                    ThursdayCopy: false,
                                    FridayCopy: false,
                                    SaturdayCopy: false,
                                    SundayCopy: false,
                                }, () => { this.CalculateValidInterval() })}
                                renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "HH:MM (AM|PM)", "emed_tid": "doctor_schedule_endTime" }} style={{ width: "98%" }} size="small" />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <TextField
                        size='small'
                        value={this.state.IntervalValue}
                        inputProps={{ maxLength: 3, emed_tid: 'renderSession_popup_intervalInput' }}
                        label={`${this.state.SessionConfigType === "INTERVAL" ? "Interval Minutes *" : "Patient Count *"}`}
                        sx={{ fontSize: '0.8vw', marginTop: "1vw", width: "15.3vw" }}
                        onChange={(e) => {
                            let numOnly = CommonValidation.numberOnly(e.target.value);
                            if (numOnly || e.target.value === '') {
                                this.setState({
                                    IntervalValue: e.target.value
                                }, () => {
                                    if (this.state.SessionConfigType === "INTERVAL") {
                                        this.CalculateValidInterval()
                                    }
                                })
                            }
                        }}
                    />

                    <Box marginTop={'1vw'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600}>Copy this Session to another day</Typography>
                        <Box className="eMed_CheckBox_Group" sx={{ width: "30vw" }}>
                            {this.state.SelectedSesDay === "Monday" ? null : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.MondayCopy} onChange={(e) => { this.handleCheckBox(e, "Monday", "copy") }} size='small' emed_tid='renderSession_popup_Monday_checkBox' />} label={"Monday"} />}
                            {this.state.SelectedSesDay === "Tuesday" ? null : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.TuesdayCopy} onChange={(e) => { this.handleCheckBox(e, "Tuesday", "copy") }} size='small' emed_tid='renderSession_popup_Tuesday_checkBox' />} label={"Tuesday"} />}
                            {this.state.SelectedSesDay === "Wednesday" ? null : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.WednesdayCopy} onChange={(e) => { this.handleCheckBox(e, "Wednesday", "copy") }} size='small' emed_tid='renderSession_popup_Wednesday_checkBox' />} label={"Wednesday"} />}
                            {this.state.SelectedSesDay === "Thursday" ? null : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.ThursdayCopy} onChange={(e) => { this.handleCheckBox(e, "Thursday", "copy") }} size='small' emed_tid='renderSession_popup_Thursday_checkBox' />} label={"Thursday"} />}
                            {this.state.SelectedSesDay === "Friday" ? null : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FridayCopy} onChange={(e) => { this.handleCheckBox(e, "Friday", "copy") }} size='small' emed_tid='renderSession_popup_Friday_checkBox' />} label={"Friday"} />}
                            {this.state.SelectedSesDay === "Saturday" ? null : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.SaturdayCopy} onChange={(e) => { this.handleCheckBox(e, "Saturday", "copy") }} size='small' emed_tid='renderSession_popup_Saturday_checkBox' />} label={"Saturday"} />}
                            {this.state.SelectedSesDay === "Sunday" ? null : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.SundayCopy} onChange={(e) => { this.handleCheckBox(e, "Sunday", "copy") }} size='small' emed_tid='renderSession_popup_Sunday_checkBox' />} label={"Sunday"} />}
                        </Box>
                    </Box>

                    <FormControl sx={{ marginLeft: "1vw" }}>
                        <RadioGroup
                            value={this.state.SessionType}
                            sx={{ display: 'flex', flexDirection: 'row' }}
                            onChange={(e) => { this.setState({ SessionType: e.target.value }) }}
                        >
                            <FormControlLabel value="all_week" control={<Radio size='small' inputProps={{ 'emed_tid': `renderSession_all_week_radio_testID` }} />} label="All Week" />
                            <FormControlLabel value="special_session" control={<Radio size='small'inputProps={{ 'emed_tid': `renderSession_specialSession_radio_testID` }}  />} label="Special Session" />
                        </RadioGroup>
                    </FormControl>
                    <Box className="eMed_CheckBox_Group" sx={{ width: "30vw", display: this.state.SessionType === "special_session" ? 'flex' : 'none' }}>
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.WeekArray?.includes("1 week")} onChange={(e) => { this.handleCheckBox(e, "1 week") }} size='small' emed_tid='renderSession_popup_week1_checkBox' />} label={"1st Week"} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.WeekArray?.includes("2 week")} onChange={(e) => { this.handleCheckBox(e, "2 week") }} size='small' emed_tid='renderSession_popup_week2_checkBox' />} label={"2nd Week"} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.WeekArray?.includes("3 week")} onChange={(e) => { this.handleCheckBox(e, "3 week") }} size='small' emed_tid='renderSession_popup_week3_checkBox' />} label={"3rd Week"} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.WeekArray?.includes("4 week")} onChange={(e) => { this.handleCheckBox(e, "4 week") }} size='small' emed_tid='renderSession_popup_week4_checkBox' />} label={"4rd Week"} />
                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.WeekArray?.includes("5 week")} onChange={(e) => { this.handleCheckBox(e, "5 week") }} size='small' emed_tid='renderSession_popup_week5_checkBox' />} label={"5th Week"} />
                    </Box>

                    {this.state.SessionRemainder ?
                        <Box sx={{ display: 'flex', width: '30vw' }}>
                            <Typography sx={{ fontSize: "0.9vw" }}>{`Warning : The interval minutes could not be evenly split. so, adjust the session end time for evenly distributed slots.`}</Typography>
                            <Button
                                variant='outlined'
                                color='warning'
                                size='small'
                                emed_tid='renderSession_popup_ok_btn'
                                onClick={() => {
                                    let EndTime = new Date(this.state.SessionEndTime)
                                    EndTime.setMinutes(EndTime.getMinutes() - this.state.SessionRemainder);
                                    this.setState({
                                        SessionEndTime: EndTime ? EndTime : this.state.SessionEndTime,
                                        SessionRemainder: 0
                                    })
                                }}
                            >{`OK`}</Button>
                        </Box>
                        : null}

                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: "1vw" }}>
                    <Button
                        variant='outlined'
                        sx={{ textTransform: 'capitalize' }}
                        emed_tid={'Session_clear'}
                        onClick={() => { this.ClearAlldatas() }}>Close</Button>
                    <Button
                        sx={{ textTransform: 'capitalize' }}
                        variant='contained'
                        emed_tid={'Session_add'}
                        disabled={this.state.SessionStartTime === null || isNaN(Date.parse(this.state.SessionStartTime)) || this.state.SessionEndTime === null || isNaN(Date.parse(this.state.SessionEndTime))}
                        onClick={() => {
                            if (!this.state.IntervalValue) {
                                this.errorMessage(`Enter the ${this.state.SessionConfigType === "INTERVAL" ? "interval minutes" : "patient count"} for this session`)
                            } else if (this.Timeto24Hr(this.state.SessionStartTime) === this.Timeto24Hr(this.state.SessionEndTime)) {
                                this.errorMessage(`Session Start and End Time can't be same`)
                            } else if (this.state.SessionType === "special_session" && this.state.WeekArray?.length === 0) {
                                this.errorMessage(`Select the Week for this session`)
                            }
                            else {
                                this.addSession()
                            }
                        }}
                    >Add Session</Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderSessions = (day, DaySessions = []) => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: "center" }}>
                {
                    DaySessions?.map((list, index) => {
                        return (
                            <Box
                                className="eMed_sessions_div"
                                sx={{}}

                            >
                                <Typography>{`S${index + 1}`}</Typography>
                                <Typography
                                    onClick={() => {
                                        this.setState({
                                            OpenSessionPopup: true,
                                            SelectedSessionDatas: { "name": day, "session_count": index + 1 },
                                            SessionStartTime: list?.start_time ? this.convertToJSDate(list?.start_time) : null,
                                            SessionEndTime: list?.end_time ? this.convertToJSDate(list?.end_time) : null,
                                            IntervalValue: list?.interval ? list?.interval : "",
                                            WeekArray: list?.special_sessions ? list?.special_sessions.split(",") : [],
                                            SessionType: list?.is_special_session ? "special_session" : "all_week",
                                            EditSessionIndex: index,
                                            SelectedSesDay: day
                                        })
                                    }}
                                    fontWeight={600}>{` : ${this.convertTo12Hour(list?.start_time)} - ${this.convertTo12Hour(list?.end_time)}`}</Typography>
                                <IconButton onClick={() => { this.setState({ SessionDeletePop: { "day": day, "index": index } }) }} emed_tid='session_delete_btn_testID'><DeleteOutlineIcon color='error' /></IconButton>
                            </Box>
                        )
                    })
                }
                <Box
                    onClick={() => {
                        this.setState({
                            OpenSessionPopup: true,
                            SelectedSessionDatas: { "name": day, "session_count": DaySessions?.length + 1 },
                            SelectedSesDay: day
                        })
                    }} emed_tid='session_addSession_btn_testID'
                    sx={{ border: '1px solid #BDBDBD', padding: '0.5vw', borderRadius: "0.4vw", marginLeft: "0.5vw", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', width: "9vw" }}>
                    <AddCircleOutlineIcon />
                    <Typography marginLeft={"0.8vw"} fontSize={"0.9rem"}>Add Session</Typography>
                </Box>
            </Box>
        )
    }

    addSession() {
        try {
            let List = this.state.SelectedSesDay ? JSON.parse(JSON.stringify(this.state.SessionsFullList[this.state.SelectedSesDay])) : []
            let newSession = {
                // "session_name": `Session ${List?.length + 1}`,
                "start_time": this.Timeto24Hr(this.state.SessionStartTime),
                "end_time": this.Timeto24Hr(this.state.SessionEndTime),
                "type": this.state.SessionConfigType === "INTERVAL" ? "Time" : "Count",
                "interval": this.state.IntervalValue,
                "is_special_session": this.state.SessionType === "special_session" ? true : false,
                "special_sessions": this.state.WeekArray ? this.state.WeekArray?.join() : ""
            }
            if (this.state.SessionStartTime >= this.state.SessionEndTime) {
                this.errorMessage(`Start time is greater than end time`)
            } else if (this.state.EditSessionIndex !== null) {
                if (this.isTimeSlotAvailable(List, newSession, this.state.EditSessionIndex)) {

                    if(List[this.state.EditSessionIndex]?.api_index || List[this.state.EditSessionIndex]?.api_index === 0){
                        newSession["api_index"] = List[this.state.EditSessionIndex]?.api_index
                    }
                    List[this.state.EditSessionIndex] = newSession
                    if (List?.length > 1) {
                        List.sort((a, b) => {
                            return this.timeStringToMinutes(a.start_time) - this.timeStringToMinutes(b.start_time);
                        });
                    }
                    this.setSessiondatas(List, newSession)
                } else {
                    this.errorMessage("The new session overlaps with existing sessions.")
                }
            } else {
                if (this.isTimeSlotAvailable(List, newSession)) {
                    List.push(newSession)
                    if (List?.length > 1) {
                        List.sort((a, b) => {
                            return this.timeStringToMinutes(a.start_time) - this.timeStringToMinutes(b.start_time);
                        });
                    }
                    this.setSessiondatas(List, newSession)
                } else {
                    this.errorMessage("The new session overlaps with existing sessions.")
                }
            }
        } catch (e) {

        }
    }

    setSessiondatas(List, newSession) {
        try {
            let states = this.state
            states.SessionsFullList[states.SelectedSesDay] = List
            if (states.MondayCopy) {
                if (this.isTimeSlotAvailable(states.SessionsFullList.Monday ? states.SessionsFullList.Monday : [], newSession)) {
                    states.SessionsFullList.Monday?.push(newSession)
                } else {
                    let newList = []
                    newList.push(newSession)
                    states.SessionsFullList.Monday = newList
                }
            }
            if (states.TuesdayCopy) {
                if (this.isTimeSlotAvailable(states.SessionsFullList.Tuesday ? states.SessionsFullList.Tuesday : [], newSession)) {
                    states.SessionsFullList.Tuesday?.push(newSession)
                } else {
                    let newList = []
                    newList.push(newSession)
                    states.SessionsFullList.Tuesday = newList
                }
            }
            if (states.WednesdayCopy) {
                if (this.isTimeSlotAvailable(states.SessionsFullList.Wednesday ? states.SessionsFullList.Wednesday : [], newSession)) {
                    states.SessionsFullList.Wednesday?.push(newSession)
                } else {
                    let newList = []
                    newList.push(newSession)
                    states.SessionsFullList.Wednesday = newList
                }
            }
            if (states.ThursdayCopy) {
                if (this.isTimeSlotAvailable(states.SessionsFullList.Thursday ? states.SessionsFullList.Thursday : [], newSession)) {
                    states.SessionsFullList.Thursday?.push(newSession)
                } else {
                    let newList = []
                    newList.push(newSession)
                    states.SessionsFullList.Thursday = newList
                }
            }
            if (states.FridayCopy) {
                if (this.isTimeSlotAvailable(states.SessionsFullList.Friday ? states.SessionsFullList.Friday : [], newSession)) {
                    states.SessionsFullList.Friday?.push(newSession)
                } else {
                    let newList = []
                    newList.push(newSession)
                    states.SessionsFullList.Friday = newList
                }
            }
            if (states.SaturdayCopy) {
                if (this.isTimeSlotAvailable(states.SessionsFullList.Saturday ? states.SessionsFullList.Saturday : [], newSession)) {
                    states.SessionsFullList.Saturday?.push(newSession)
                } else {
                    let newList = []
                    newList.push(newSession)
                    states.SessionsFullList.Saturday = newList
                }
            }
            if (states.SundayCopy) {
                if (this.isTimeSlotAvailable(states.SessionsFullList.Sunday ? states.SessionsFullList.Sunday : [], newSession)) {
                    states.SessionsFullList.Sunday?.push(newSession)
                } else {
                    let newList = []
                    newList.push(newSession)
                    states.SessionsFullList.Sunday = newList
                }
            }
            this.setState({ SessionsFullList: states.SessionsFullList }, () => {
                this.ClearAlldatas()
                this.SortEverySessions()
            })
        }
        catch (e) {

        }
    }

    SortEverySessions() {
        try {
            let MondaySession = this.state.SessionsFullList.Monday.sort((a, b) => { return this.timeStringToMinutes(a.start_time) - this.timeStringToMinutes(b.start_time); });
            let TuesdaySession = this.state.SessionsFullList.Tuesday.sort((a, b) => { return this.timeStringToMinutes(a.start_time) - this.timeStringToMinutes(b.start_time); });
            let WednesdaySession = this.state.SessionsFullList.Wednesday.sort((a, b) => { return this.timeStringToMinutes(a.start_time) - this.timeStringToMinutes(b.start_time); });
            let ThursdaySession = this.state.SessionsFullList.Thursday.sort((a, b) => { return this.timeStringToMinutes(a.start_time) - this.timeStringToMinutes(b.start_time); });
            let FridaySession = this.state.SessionsFullList.Friday.sort((a, b) => { return this.timeStringToMinutes(a.start_time) - this.timeStringToMinutes(b.start_time); });
            let SaturdaySession = this.state.SessionsFullList.Saturday.sort((a, b) => { return this.timeStringToMinutes(a.start_time) - this.timeStringToMinutes(b.start_time); });
            let SundaySession = this.state.SessionsFullList.Sunday.sort((a, b) => { return this.timeStringToMinutes(a.start_time) - this.timeStringToMinutes(b.start_time); });
            this.setState({
                SessionsFullList: {
                    "Monday": MondaySession,
                    "Tuesday": TuesdaySession,
                    "Wednesday": WednesdaySession,
                    "Thursday": ThursdaySession,
                    "Friday": FridaySession,
                    "Saturday": SaturdaySession,
                    "Sunday": SundaySession,
                },
            })
        } catch (e) {

        }
    }

    ClearAlldatas() {
        this.setState({
            OpenSessionPopup: false,
            SessionStartTime: null,
            SessionEndTime: null,
            EditSessionIndex: null,
            IntervalValue: "",
            SelectedSesDay: null,
            ConFLictDay: null,
            WeekArray: [],
            MondayCopy: false,
            TuesdayCopy: false,
            ThursdayCopy: false,
            FridayCopy: false,
            SaturdayCopy: false,
            SundayCopy: false,
            ConFLictDay: null,
            ClearPopup: false,
            SessionDeletePop: {},
            SessionType: "all_week"
        })
    }

    popupClose() {
        this.setState({ ConFLictDay: null, ClearPopup: false, SavePopup : false, SessionDeletePop: {} })
    }

    deleteSession() {
        let Array = JSON.parse(JSON.stringify(this.state.SessionsFullList))
        Array[this.state.SessionDeletePop?.day].splice(this.state.SessionDeletePop?.index, 1)
        let DeletedArray = JSON.parse(JSON.stringify(this.state.DeletedSessionsList))
        if(this.state.SessionsFullList[this.state.SessionDeletePop?.day][this.state.SessionDeletePop?.index]?.api_index  ){
            // let NewObject = this.state.SessionsFullList[this.state.SessionDeletePop?.day][this.state.SessionDeletePop?.index]
            // delete NewObject.session_name
            let NewObject = this.state.DuplicateSessList[this.state.SessionDeletePop?.day].find((item)=>(item?.api_index === this.state.SessionsFullList[this.state.SessionDeletePop?.day][this.state.SessionDeletePop?.index]?.api_index))
            if(NewObject){

            DeletedArray[this.state.SessionDeletePop?.day] = [...DeletedArray[this.state.SessionDeletePop?.day], NewObject]
                      
        }
        }
        this.setState({ SessionsFullList: Array, DeletedSessionsList : DeletedArray,  ConFLictDay: null, ClearPopup: false, SessionDeletePop: {} })
    }
    ClearSessions() {
        this.setState({
            SessionsFullList: {
                "Monday": [],
                "Tuesday": [],
                "Wednesday": [],
                "Thursday": [],
                "Friday": [],
                "Saturday": [],
                "Sunday": [],
            }
        }, () => { this.ClearAlldatas() })
    }

    ReplaceSession() {
        let States = this.state
        States[`${this.state.ConFLictDay}Copy`] = true
        States[`ConFLictDay`] = null
        this.setState({ States })

    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }


    postRoomNo = () => {
        try {
            var states = this.state
            var data = {
                "room_no": states?.DoctorRoomNo ? states?.DoctorRoomNo : ""
            }
            if (this.props?.FromFO) {
                data["doctor_id"] = this.props?.SelectedDoctorDetails?.doctor_id ? this.props?.SelectedDoctorDetails?.doctor_id : null
            } else {
                data["clinic_id"] = localGetItem('SelectedDoctorClinicId') ? +localGetItem('SelectedDoctorClinicId') : null
            }
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.DOC_ROOMNO_CONFIG).
                then((response) => {
                    if (response.data.status === "success") {
                        this.ClearAlldatas()
                        this.GetDocRoomNO()
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderSessionNames(sessions) {
        const updatedSessions = sessions.map((session, index) => ({
            ...session,
            session_name: `Session ${index + 1}`
        }));
        return updatedSessions
    }
    PostSessionDatas = () => {
        try {
            var states = this.state
            var data = {
                "session_data": [
                    {
                        "id": states?.IdList?.Monday ? states?.IdList?.Monday : null,
                        "day": "Monday",
                        "sessions": states?.SessionsFullList?.Monday ? this.renderSessionNames(states?.SessionsFullList?.Monday) : []
                    },
                    {
                        "id": states?.IdList?.Tuesday ? states?.IdList?.Tuesday : null,
                        "day": "Tuesday",
                        "sessions": states?.SessionsFullList?.Tuesday ? this.renderSessionNames(states?.SessionsFullList?.Tuesday) : []
                    },
                    {
                        "id": states?.IdList?.Wednesday ? states?.IdList?.Wednesday : null,
                        "day": "Wednesday",
                        "sessions": states?.SessionsFullList?.Wednesday ? this.renderSessionNames(states?.SessionsFullList?.Wednesday) : []
                    },
                    {
                        "id": states?.IdList?.Thursday ? states?.IdList?.Thursday : null,
                        "day": "Thursday",
                        "sessions": states?.SessionsFullList?.Thursday ? this.renderSessionNames(states?.SessionsFullList?.Thursday) : []
                    },
                    {
                        "id": states?.IdList?.Friday ? states?.IdList?.Friday : null,
                        "day": "Friday",
                        "sessions": states?.SessionsFullList?.Friday ? this.renderSessionNames(states?.SessionsFullList?.Friday) : []
                    },
                    {
                        "id": states?.IdList?.Saturday ? states?.IdList?.Saturday : null,
                        "day": "Saturday",
                        "sessions": states?.SessionsFullList?.Saturday ? this.renderSessionNames(states?.SessionsFullList?.Saturday) : []
                    },
                    {
                        "id": states?.IdList?.Sunday ? states?.IdList?.Sunday : null,
                        "day": "Sunday",
                        "sessions": states?.SessionsFullList?.Sunday ? this.renderSessionNames(states?.SessionsFullList?.Sunday) : []
                    },
                ],
                "deleted_sessions": [
                    {
                        "id": states?.IdList?.Monday ? states?.IdList?.Monday : null,
                        "day": "Monday",
                        "sessions": states?.DeletedSessionsList?.Monday ? states?.DeletedSessionsList?.Monday : []
                    },
                    {
                        "id": states?.IdList?.Tuesday ? states?.IdList?.Tuesday : null,
                        "day": "Tuesday",
                        "sessions": states?.DeletedSessionsList?.Tuesday ? states?.DeletedSessionsList?.Tuesday : []
                    },
                    {
                        "id": states?.IdList?.Wednesday ? states?.IdList?.Wednesday : null,
                        "day": "Wednesday",
                        "sessions": states?.DeletedSessionsList?.Wednesday ? states?.DeletedSessionsList?.Wednesday : []
                    },
                    {
                        "id": states?.IdList?.Thursday ? states?.IdList?.Thursday : null,
                        "day": "Thursday",
                        "sessions": states?.DeletedSessionsList?.Thursday ? states?.DeletedSessionsList?.Thursday : []
                    },
                    {
                        "id": states?.IdList?.Friday ? states?.IdList?.Friday : null,
                        "day": "Friday",
                        "sessions": states?.DeletedSessionsList?.Friday ? states?.DeletedSessionsList?.Friday : []
                    },
                    {
                        "id": states?.IdList?.Saturday ? states?.IdList?.Saturday : null,
                        "day": "Saturday",
                        "sessions": states?.DeletedSessionsList?.Saturday ? states?.DeletedSessionsList?.Saturday : []
                    },
                    {
                        "id": states?.IdList?.Sunday ? states?.IdList?.Sunday : null,
                        "day": "Sunday",
                        "sessions": states?.DeletedSessionsList?.Sunday ? states?.DeletedSessionsList?.Sunday : []
                    },
                ],
            }
            if (this.props?.FromFO) {
                data["doctor_id"] = this.props?.SelectedDoctorDetails?.doctor_id ? this.props?.SelectedDoctorDetails?.doctor_id : null
            } else {
                data["clinic_id"] = localGetItem('SelectedDoctorClinicId') ? +localGetItem('SelectedDoctorClinicId') : null
            }
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.DOC_SESSION_CONFIG).
                then((response) => {
                    if (response.data.status === "success") {
                        this.ClearAlldatas()
                        this.LoaderFunction(false)
                        this.GetSessionDatas()
                        this.successMessage(response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    
    saveSessions = () => {
        this.setState({ SavePopup: false }, () => this.PostSessionDatas() )
    }


    render() {
        return (
            <Box>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', marginTop: "0.5vw" }}>
                    <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', }}>
                        <TextField
                            size='small'
                            value={this.state.DoctorRoomNo ? this.state.DoctorRoomNo : ""}
                            label={"Room No"}
                            inputProps={{ maxLength: 10, emed_tid: 'sessionConfig_roomNo_inputField_testID' }}
                            placeholder='Name'
                            sx={{ width: "15vw", fontSize: '0.8vw' }}
                            onChange={(event) => {
                                let alphanum = CommonValidation.alphanumValidation(event.target.value);
                                if (alphanum || event.target.value === '') {
                                    this.setState({
                                        DoctorRoomNo: event.target.value
                                    })
                                }
                            }}
                        />
                        <Button sx={{ textTransform: 'capitalize', marginLeft:'0.5vw' }} variant='contained' emed_tid='sessionConfig_roomNo_btn_testID' disabled={this.state.DisableBtn} onClick={() => { this.setState({ DisableBtn: true }, () => this.postRoomNo() )}}>Save Room No</Button>
                    </Box>
                    <Box sx={{ marginRight: "1vw" }}>
                        {/* <Button sx={{ textTransform: 'capitalize', marginRight: "2vw" }} variant='outlined' size='small' onClick={() => { this.setState({ ClearPopup: true }) }} emed_tid='sessionConfig_clearAllSession_testID'>Clear all Sessions</Button> */}
                        <Button sx={{ textTransform: 'capitalize' }} disabled={this.state.DisableBtn} emed_tid='sessionConfig_saveSession_testID' variant='contained' size='small' onClick={() => {
                            this.setState({
                                // DisableBtn: true,
                                SavePopup: true
                            })
                        }}>Save Session</Button>
                    </Box>
                </Box>
                <Box sx={{ width: this.props?.FromFO ? "79vw" : "73vw", marginTop: "0.5vw" }}>
                    <TableContainer>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="eMed_Sticky_first_Column" sx={{ fontWeight: 600, width: "8vw", backgroundColor: Colors.DataTblHeaderbg }}>Day</TableCell>
                                    <TableCell className="eMed_Sticky_first_Column" sx={{ fontWeight: 600, zIndex: "1", width: '65vw', backgroundColor: Colors.DataTblHeaderbg }}>Sessions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                                    <TableCell className="eMed_Sticky_first_Column" sx={{ border: '1px solid #dddddd', fontWeight: 600 }}>Monday</TableCell>
                                    <TableCell>{this.renderSessions("Monday", this.state.SessionsFullList?.Monday)}</TableCell>
                                </TableRow>
                                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                                    <TableCell className="eMed_Sticky_first_Column" sx={{ border: '1px solid #dddddd', fontWeight: 600 }}>Tuesday</TableCell>
                                    <TableCell>{this.renderSessions("Tuesday", this.state.SessionsFullList?.Tuesday)}</TableCell>
                                </TableRow>
                                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                                    <TableCell className="eMed_Sticky_first_Column" sx={{ border: '1px solid #dddddd', fontWeight: 600 }}>Wednesday</TableCell>
                                    <TableCell>{this.renderSessions("Wednesday", this.state.SessionsFullList?.Wednesday)}</TableCell>
                                </TableRow>
                                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                                    <TableCell className="eMed_Sticky_first_Column" sx={{ border: '1px solid #dddddd', fontWeight: 600 }}>Thursday</TableCell>
                                    <TableCell>{this.renderSessions("Thursday", this.state.SessionsFullList?.Thursday)}</TableCell>
                                </TableRow>
                                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                                    <TableCell className="eMed_Sticky_first_Column" sx={{ border: '1px solid #dddddd', fontWeight: 600 }}>Friday</TableCell>
                                    <TableCell>{this.renderSessions("Friday", this.state.SessionsFullList?.Friday)}</TableCell>
                                </TableRow>
                                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                                    <TableCell className="eMed_Sticky_first_Column" sx={{ border: '1px solid #dddddd', fontWeight: 600 }}>Saturday</TableCell>
                                    <TableCell>{this.renderSessions("Saturday", this.state.SessionsFullList?.Saturday)}</TableCell>
                                </TableRow>
                                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                                    <TableCell className="eMed_Sticky_first_Column" sx={{ border: '1px solid #dddddd', fontWeight: 600 }}>Sunday</TableCell>
                                    <TableCell>{this.renderSessions("Sunday", this.state.SessionsFullList?.Sunday)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {this.renderSessionDialog()}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.ConFLictDay ? <DeletePopup
                    DeletTitle={`There is a session time overlap with existing ${this.state.ConFLictDay} sessions. Are you sure you want to replace this session?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.ReplaceSession.bind(this)}
                    ButtonText={"Replace Session"}
                /> : null}
                {this.state.ClearPopup ? <DeletePopup
                    DeletTitle={`Are you sure? Do you want to clear the all sessions`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.ClearSessions.bind(this)}
                    ButtonText={"Clear all Sessions"}
                /> : null}
                {this.state.SavePopup ? <DeletePopup
                    DeletTitle={`Make sure there are no 'Out of Office' or 'Late by' statuses for future sessions, as they will impact those sessions. Are you sure you want to change the sessions?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.saveSessions.bind(this)}
                    ButtonText={"Save"}
                /> : null}
                {Object.keys(this.state.SessionDeletePop ? this.state.SessionDeletePop : {})?.length ? <DeletePopup
                    DeletTitle={`Make sure there is no 'Out of Office' or 'Late by' for the session, as it will impact the next session. Are you sure you want to remove the session?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteSession.bind(this)}
                    ButtonText={"Remove"}
                /> : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
