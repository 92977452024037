import React, { Component } from 'react';
import { Stack, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, InputLabel, FormHelperText, Tooltip, Box, Typography, Collapse, TablePagination, InputAdornment } from '@mui/material'
import './home.css'
import { ImagePaths } from '../../../Utility/ImagePaths';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Trans, withTranslation } from 'react-i18next';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { t } from "i18next";
import CreditBillPopUp from '../../FrontOffice/BillingTransaction/CreditBillPopUp';
import CommonGridHeader from "../../../Components/Common Components/CommonComponents";
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Colors } from '../../../Styles/Colors';
import SearchIcon from '@mui/icons-material/Search';
import CreditBillCompletePoP from '../../FrontOffice/BillingTransaction/CreditBillCompletePoP';


const ITEM_HEIGHT = 48;
const specimanList = [
    { "service_name": "calcium", "lab_type": "Self" },
    { "service_name": "glucose", "lab_type": "Self" },
    { "service_name": "sodium", "lab_type": "Self" },
    { "service_name": "pottassium", "lab_type": "Self" },
    { "service_name": "chloride", "lab_type": "Self" },
    { "service_name": "blood urea nitrogen(BUN)", "lab_type": "Self" },
    { "service_name": "creatinine", "lab_type": "Self" }
]

const multi_option = [
    { "label": "ReceivePayment", "value": "Receive Payment" },
    { "label": "ViewPreviousReport", "value": "View Previous Report" },

]
const Previous_List = [
    {
        invoice_number: "1232"
    },
    {
        invoice_number: "12223"
    }
]
class TestReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            reportList: [],
            isSpecimanClick: false,
            specimanList: specimanList,
            consumablesName: "",
            consumableValue: "",
            consumableUnit: "",
            consumableList: [],
            sampleCollectedIn: [],
            isFilterOption: false,
            selectedIndex: "",
            pageSize: 10,
            previousReportList: Previous_List,
            isViewReport: false,
            selectedTableRow: {},
            preReportList: [],
            search_key: "",
            mobileNumber: "",
            patientName: "",
            QueryFromDate: "",
            QueryToDate: "",
            dateFilter: 0,
            isErrorMsg: false,
            isErrorMsgText: "",
            isSuccessMsg: false,
            isSuccessMsgText: "",
            isSelected: false,
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
            },
            CreditBillPopUp: false,
            SelectedBill: {},
            labTestAmountData: {},
            tblPage: 0,
            tblPageSize: 10,
            tableSelectedIndex: null,
            TableSearchText : "",
            disableBtn: false,
        }
    }
    componentDidMount() {
        this.getTestSummaryList()
    }
    componentDidUpdate(PrevProps) {
        if (PrevProps.search_key != this.props.search_key) {
            var { search_key } = this.props
            this.setState({
                search_key: search_key ? search_key : ""
            }, () => {
                var data = parseInt(this.state.search_key)
                let type = typeof (data ? data : "")

                if (type == "number") {
                    this.setState({
                        mobileNumber: this.state.search_key ? this.state.search_key : "",
                        patientName: ""
                    }, () => {
                        this.getTestSummaryList()
                    })
                }
                else {
                    this.setState({
                        patientName: this.state.search_key ? this.state.search_key : "",
                        mobileNumber: ""
                    }, () => {
                        this.getTestSummaryList()
                    })
                }
            })
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: "",
            isSuccessMsg: false,
            isSuccessMsgText: "",
        })
    }

    getTestSummaryList = () => {
        RestAPIService.getAll(Serviceurls.LAB_PATINET_STATUS + "?result_status=Result Completed" + "&search_key1=" + this.state.patientName + "&search_key2=" + this.state.mobileNumber + "&from_date=" + this.state.FilterDatas.FromDate + "&to_date=" + this.state.FilterDatas.ToDate)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        reportList: response.data.data
                    }, () => {
                        var { reportList } = this.state
                        reportList.forEach(data => { data.is_multi_option = false; });

                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error?.response?.data?.message
                })
            })
    }
    getPreviousReport = () => {
        RestAPIService.getAll(Serviceurls.LAB_PREVIOUS_REPORT + "?patient_id=" + this.state.selectedTableRow?.patient_id)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        preReportList: response.data.data
                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
    }
    CustomDates = (FromDate, ToDate) => {
        this.setState({
            QueryFromDate: FromDate,
            QueryToDate: ToDate,
            datePickerPop: false
        }, () => this.getTestSummaryList())
    }
    PopClose = () => {
        this.setState({
            datePickerPop: false,
            QueryFromDate: "",
            QueryToDate: "",
            dateFilter: 0,
        })
    }
    dateConvertion = (d) => {
        var dateForm = new Date(d)
        let date = DateTime.fromJSDate(dateForm).toFormat("dd-MM-yyyy")
        return date
    }
    DateHelperText = () => {
        let fromDate = this.dateConvertion(this.state.QueryFromDate)
        let toDate = this.dateConvertion(this.state.QueryToDate)
        if (this.state.dateFilter === 0) {
            return ""
        }
        else if (this.state.dateFilter === 1) {
            return DateTime.now().toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 2) {
            return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 3) {
            return `${fromDate}  to  ${toDate}`
        }
        else if (this.state.dateFilter === 4) {
            return `${fromDate}  to  ${toDate}`
        }
        else if (this.state.dateFilter === 5) {
            return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
        }
    }

    handleDate = () => {
        var today = DateTime.now().toFormat('yyyy-MM-dd')
        var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
        var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
        var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')

        if (this.state.dateFilter === 0) {
            this.setState({
                QueryFromDate: "",
                QueryToDate: "",
            }, () => this.getTestSummaryList())
        }
        else if (this.state.dateFilter === 1) {
            this.setState({
                QueryFromDate: today,
                QueryToDate: today,
            }, () => this.getTestSummaryList()
            )

        }
        else if (this.state.dateFilter === 2) {
            this.setState({
                QueryFromDate: yesterday,
                QueryToDate: yesterday,
            }, () => this.getTestSummaryList())
        }
        else if (this.state.dateFilter === 3) {
            this.setState({
                QueryFromDate: startweek,
                QueryToDate: yesterday,
            }, () => this.getTestSummaryList())
        }
        else if (this.state.dateFilter === 4) {
            this.setState({
                QueryFromDate: startmonth,
                QueryToDate: yesterday,
            }, () => this.getTestSummaryList())
        }
        else if (this.state.dateFilter === 5) {
            this.setState({ datePickerPop: true })
        }
    }

    renderSpecimanBottomView = (header, value) => {
        return (
            <div className='Lab_status_speciman_bottom'>
                <p className='Lab_Status_Header1'>{header}</p>
                <p className='Lab_Status_split'>{" : "}</p>
                <p className='Lab_Status_Value'>{value}</p>
            </div>
        )
    }
    renderTextField = (title, value, key, flex) => {
        return (
            < TextField
                name={title}
                className="consumablesField"
                sx={{ margin: "0.5vw", flex: flex, height: '2vh' }}
                size="small"
                inputProps={{
                    style: {
                        height: "2vh",
                    },
                }}
                placeholder={title}
                value={value}
                onChange={(e) => {
                    var states = this.state
                    states[key] = e.target.value
                    this.setState({ states })
                }}
                type={"text"}
            />
        )
    }
    onClickStatusDelivery = (summary_id, tbldata) => {
        var data = {
            "result_summary_id": summary_id,
            "result_status": 'Result Delivered'
        }
        RestAPIService.updateWithOutId(data, Serviceurls.LAB_PATIENT_RESULT_PUT).
            then((response) => {
                if (response.data.status === "success") {
                    // this.individualReportPrint(this.state.selectedTableRow?.id) // Comment for Specimen Collection flow confirmed for ashwath and muji
                    
                    this.setState({
                        isSuccessMsg: true,
                        isSuccessMsgText: response.data.message
                    })
                    // this.getTestSummaryList()
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message
                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.message
                    })
                }
            })
    }
    dateConvertion = (d) => {
        var dateForm = new Date(d)
        let data = DateTime.fromJSDate(dateForm).toFormat("dd-MM-yyyy")
        return data
    }
    individualReportPrint = (id, newID) => {
        try {
            this.setState({disableBtn: true})
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + `?result_summary_id=${id}&result_item_id=${newID}`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({disableBtn: false})
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message,
                        disableBtn: false,
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message,
                disableBtn: false,
            })
        }
    }

    FilterDate = (From, To, Index) => {
        this.state.FilterDatas.FromDate = From
        this.state.FilterDatas.ToDate = To
        this.state.FilterDatas.DateIndex = Index
        this.setState({ FilterDatas: this.state.FilterDatas }, () => {
            this.getTestSummaryList()
        })
    }

    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            CreditBillPopUp: false,
            anchorEl: false
        })
    }

    CrebillPosted(message = "", receiptNO = null, phrReceipt = null, error = false) {
        this.setState({
            CreditBillPopUp: false,

        }, () => {
            if(error){
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: message,
                    anchorEl: false
                })
            }else{
                this.setState({
                    isSuccessMsg: true,
                    isSuccessMsgText: message,
                    anchorEl: false
                })
            }
            this.getTestSummaryList()
        })
    }

    getReceivePaymentAmountData = (cellvalues) => {
        try {
            let params = ""
            let ipAdmissionId = cellvalues?.ip_admission_id
            let op_appointment_id = cellvalues?.appointment_id
            let lab_bill_summary_id = cellvalues?.lab_bill_summary_id

            if ((ipAdmissionId !== null && lab_bill_summary_id !== null)) {
                params = "?ip_admission_id=" + ipAdmissionId
            } else if (ipAdmissionId === null && op_appointment_id !== null && lab_bill_summary_id !== null) {
                params = "?appointment_id=" + op_appointment_id
            } else if ((ipAdmissionId === null && op_appointment_id === null && lab_bill_summary_id !== null)) {
                params = "?lab_bill_summary=" + lab_bill_summary_id
            }
            RestAPIService.getAll(Serviceurls.LAB_STATUS_AND_TEST_REPORT_AMOUNT_GET + params).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            labTestAmountData: response.data.data
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error?.response?.data?.message
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    renderTestReportTable = () => {
        try {
            let filtereddata = this.state.reportList.filter((data) => {
                return (
                    (data?.patient_name === null ? null : data?.patient_name.toLowerCase().includes(this.state.TableSearchText.toLowerCase()))
                );
            });
            filtereddata?.slice(
                this.state.tblPage * this.state.tblPageSize,
                this.state.tblPage * this.state.tblPageSize +
                this.state.tblPageSize)?.forEach((element, index) => {
                    let page = this.state.tblPage
                    let pageSize = this.state.tblPageSize
                    let serial = (page * pageSize) + (index + 1)
                    element.serial = serial
                })
            return (
                <Box component={'div'} border={'1px solid lightgray'} height={'67vh'} overflow={'auto'}>
                    <Box component={'div'} height={'57vh'} overflow={'auto'}>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>S.No</TableCell>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Patient Details</TableCell>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Mobile Number</TableCell>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Module Type</TableCell>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Total Test</TableCell>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filtereddata?.length > 0 && filtereddata?.slice(
                                    this.state.tblPage * this.state.tblPageSize,
                                    this.state.tblPage * this.state.tblPageSize +
                                    this.state.tblPageSize)?.map((item, index) => (
                                        <><TableRow key={index}
                                            onClick={() => {
                                                this.setState({
                                                    tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                                })
                                            }}
                                        >
                                            <TableCell>{item?.serial}</TableCell>
                                            <TableCell>
                                                <div style={{ fontSize: '0.9vw', fontWeight: '600' }}>{`${item?.patient_name} ${item?.patient_age}`}</div>
                                                <div style={{ fontSize: '0.8vw', color: Colors.grayShade }}>{`${item?.patient_account_number ? item?.patient_account_number : '-'} | ${item?.patient_tag_name ? item?.patient_tag_name : '-'}`}</div>
                                            </TableCell>
                                            <TableCell>{item?.patient_mobile}</TableCell>
                                            <TableCell>{item?.bill_type}</TableCell>
                                            <TableCell>{`${item?.completed_test} / ${item?.total_test}`}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    size='small'
                                                    onClick={() => {
                                                        this.setState({
                                                            tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                                        })
                                                    }}
                                                >
                                                    {this.state.tableSelectedIndex === index ?
                                                        <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                            <TableRow>
                                                {this.renderTestReportSecondTable(item, index)}
                                            </TableRow></>
                                    ))}
                            </TableBody>
                        </Table>
                    </Box>
                    <TablePagination
                        className='eMed_tbl_pagination'
                        sx={{ borderTop: '1px solid lightgray' }}
                        component='div'
                        rowsPerPageOptions={[10, 20, 30]}
                        count={filtereddata?.length}
                        rowsPerPage={this.state.tblPageSize}
                        page={this.state.tblPage}
                        onPageChange={(event, newPage) => { this.setState({ tblPage: newPage }) }}
                        onRowsPerPageChange={(event) => { this.setState({ tblPageSize: event.target.value, tblPage: 0 }) }}
                    />
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderTestReportSecondTable = (item, index) => {
        try {
            return (
                <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={this.state.tableSelectedIndex === index} timeout={'auto'}>
                        <Box component={'div'} margin={1} className='eMed_Lab_Sts_Tbl_Two'>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Date</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill No</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill Type</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Barcode Number</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Result Status</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Total Test</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item?.data?.length > 0 && item?.data?.map((secItem, secIndex) => (
                                        <TableRow key={secIndex} sx={{ backgroundColor : secItem?.bill_type === "cancelled" ? Colors.disableComponentColor : "white"}}>
                                            <TableCell>{secItem?.created_date__date}</TableCell>
                                            <TableCell>{secItem?.bill_number}</TableCell>
                                            <TableCell>
                                                <Box sx={{ display:'flex', justifyContent:'flex-start', alignContent:'center', flexDirection:'column'}}>
                                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", textTransform:'capitalize' }}>{secItem?.bill_type ? secItem?.bill_type : "-"}</Typography>
                                                    <Typography sx={{ fontSize: "0.875rem", textTransform:'capitalize' }}>{secItem?.payment_status ? secItem?.payment_status : "-"}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{secItem?.barcode}</TableCell>
                                            <TableCell sx={{color : secItem?.bill_type === "cancelled" ? Colors.red : "black"}}>{secItem?.bill_type === "cancelled" ? "Cancelled" : secItem?.status}</TableCell>
                                            <TableCell>{`${secItem?.completed_test} / ${secItem?.total_test}`}</TableCell>
                                            <TableCell>
                                                <Box component={'div'} display={'flex'} alignItems={'center'}>
                                                    <Button className="Common_Btn_Min_Width"
                                                        disabled={secItem?.bill_type === "cancelled"}
                                                        onClick={() => {
                                                            this.setState({
                                                                selectedTableRow: secItem
                                                            })
                                                            this.props.selectedTab()
                                                        }}
                                                    >
                                                        <Tooltip placement="top" title={t("Result")} >
                                                            <Link to={{ pathname: "/Results", state: { resultList: secItem } }}>
                                                                <div><Box component={'img'} src={ImagePaths.Result.default} className="" alt='result' height={'1.8vw'} width={'1.8vw'} position={'relative'} top={'0.2vw'} /></div>
                                                            </Link>
                                                        </Tooltip>
                                                    </Button>
                                                    {secItem?.status === "Result Completed" ?
                                                        <Button className='Common_Btn_Min_Width'
                                                            disabled={secItem?.bill_type === "cancelled"}
                                                            onClick={() => {
                                                                this.setState({
                                                                    selectedTableRow: secItem
                                                                })
                                                                if (secItem.status !== "Result Delivered" && CheckAccessFunc("Laboratory", "Home", "Test Report", null, "Tab")?.editAccess) {
                                                                    this.onClickStatusDelivery(secItem?.id, secItem )
                                                                }
                                                            }}
                                                        >
                                                            <Tooltip placement="top" title={t("Move to Delivered")}>
                                                            <Link to={{ pathname: "/Results", state: { resultList: secItem, screen: "Two" } }}>
                                                                <Box component={'img'} src={ImagePaths.Check.default} alt='check' height={'1.8vw'} width={'1.8vw'} />
                                                            </Link>
                                                            </Tooltip>
                                                        </Button> :
                                                        secItem?.lab_bill_summary__status === "Partially Saved" ?
                                                            <Button className='Common_Btn_Min_Width'>
                                                                <Tooltip placement="top" title={t("Partially Saved")}>
                                                                    <Box component={'img'} src={ImagePaths.Tick.default} alt='tick' height={'1.8vw'} width={'1.8vw'} />
                                                                </Tooltip>
                                                            </Button> :
                                                            secItem?.status === "Result Delivered" ?
                                                                <Button className='Common_Btn_Min_Width' emed_tid='TestReport_Print_Btn'
                                                                disabled={this.state.disableBtn ? true : secItem?.bill_type === "cancelled"}
                                                                    onClick={() => {
                                                                        // this.individualReportPrint(secItem?.id) // Comment for Specimen Collection flow confirmed for ashwath and muji
                                                                    }}
                                                                >
                                                                    <Tooltip placement="top" title={t("View")}>
                                                                    <Link to={{ pathname: "/Results", state: { resultList: secItem, screen: "Two" } }}>
                                                                        <Box component={'img'} src={ImagePaths.LabViewButton.default} alt='print' height={'1.8vw'} width={'1.8vw'} />
                                                                    </Link>
                                                                    </Tooltip>
                                                                </Button> : null
                                                    }
                                                    <Button className='Common_Btn_Min_Width'
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={this.state.isSelected ? 'long-menu' : undefined}
                                                        aria-expanded={this.state.isSelected ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        disabled={(secItem?.bill_type === "cancelled") || this.state.CreditBillPopUp}
                                                        onClick={(e) => {
                                                            this.setState({
                                                                anchorEl: e.currentTarget,
                                                                billSummaryID: secItem?.lab_bill_summary_id,
                                                                selectedTableRow: secItem,
                                                                isSelected: true,
                                                            }, () => {
                                                                this.getPreviousReport()
                                                                // this.getReceivePaymentAmountData(secItem)
                                                            })
                                                        }}
                                                    >
                                                        <MoreVertIcon sx={{ color: 'black' }} />
                                                    </Button>
                                                </Box>
                                                {this.renderMenu(secItem)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell ></>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    disableMoreOptions(item) {
        let { selectedTableRow } = this.state
        switch (item?.value) {
            case "ReceivePayment":
                if (selectedTableRow?.payment_status === 'paid') {
                    return true
                } else {
                    return false
                }
                default: return false
        }
    }

    renderMenu = (secItem) => {
        try {
            let menuList = [
                { "value": "ViewPreviousReport", "label": "View Previous Report" },
                { "value": "ReceivePayment", "label": "Receive Payment" },
            ]
            return (
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    elevation={2}
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={(e) => {
                        this.setState({
                            anchorEl: null
                        })
                    }}
                >
                    {menuList?.map((option, index) => (
                        <MenuItem
                            key={index}
                            disabled={this.disableMoreOptions(option)}
                            onClick={() => {
                                this.setState({
                                    selectedOption: option.value,
                                    isTestReport: true
                                }, () => {
                                    if (this.state.selectedOption == "ReceivePayment") {
                                        // this.props.selectedTab(0, this.state.selectedTableRow, false, TextTrackCueList)
                                        // setTimeout(() => {
                                        //     this.props.selectedTab(0, this.state.selectedTableRow, false, false)
                                        // }, 2000);
                                        this.setState({ SelectedBill: this.state.selectedTableRow, CreditBillPopUp: true })
                                    }
                                    if (this.state.selectedOption == "ViewPreviousReport") {
                                        this.setState({
                                            isViewReport: true
                                        })
                                    }
                                })
                            }}
                        >{option?.label}</MenuItem>
                    ))}
                </Menu>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        var { selectedTableRow, reportList } = this.state
        const { t } = this.props
        // const columns = [
        //     {
        //         field: 'S.No', headerName: t('S.No'), sortable: false, flex: 0.1,
        //         renderCell: (index) => index.api.getRowIndex(index.row.id) + 1
        //     },
        //     { field: 'patient_name', headerName: t('PatientName'), sortable: false, flex: 0.15,
        //     renderCell:(cellvalues)=>(
        //         <Box>
        //         {cellvalues?.row?.patient_name ? 
        //         cellvalues?.row?.patient_name?.length > 10 ? 
        //         <Tooltip placement='top' title={cellvalues?.row?.patient_name}>
        //             <div>{`${cellvalues?.row?.patient_name.slice(0,10)}... (${cellvalues.row.patient__gender == "m" ? "M" : cellvalues.row.patient__gender == "f" ? "F" : cellvalues.row.patient__gender == "t" ? "T" : null }) (${cellvalues.row.patient_age ? `${cellvalues.row.patient_age}` : "-"})`}</div>
        //         </Tooltip> :
        //         `${cellvalues?.row?.patient_name} (${cellvalues.row.patient__gender == "m" ? "M" : cellvalues.row.patient__gender == "f" ? "F" : cellvalues.row.patient__gender == "t" ? "T" : null }) (${cellvalues.row.patient_age ? `${cellvalues.row.patient_age}` : "-"})` : "-"
        //         }
        //         <div style={{display: 'flex', flexDirection: 'row'}}>
        //         <Typography id='eMed_Com_Patient_UhidText'>{(cellvalues?.row?.patient__patient_account_number) + ' | ' }</Typography>
        //             {cellvalues?.row?.ip_number?.length > 15 ?
        //                 <Tooltip title={cellvalues?.row?.ip_number} placement={'top'} arrow>
        //                     <Typography id='eMed_Com_Patient_UhidText'>{cellvalues?.row?.ip_number ? '...' + cellvalues?.row?.ip_number.slice(14) : '-'}</Typography>
        //                 </Tooltip> : <Typography id='eMed_Com_Patient_UhidText'>{cellvalues?.row?.ip_number ? cellvalues?.row?.ip_number : cellvalues?.row?.appointment_id ? cellvalues?.row?.appointment_id : '-'}</Typography>}
        //         </div>
        //         </Box>
        //     ) },
        //     { field: 'patient__mobile_number', headerName: t('MobileNumber'), sortable: false, flex: 0.15,
        //     renderCell:(params)=>(<div>{params?.row?.patient__mobile_number ? params?.row?.patient__mobile_number : "-"}</div>)},
        //     { field: 'invoice_number', headerName: t('BillNo'), sortable: false, flex: 0.15, headerAlign: "center", align: "center",
        //     renderCell:(params)=>(<div>{params?.row?.invoice_number ? params?.row?.invoice_number : params?.row?.temp_bill_number}</div>) },
        //     {
        //         field: 'created_date__date', headerName: t('Date'), sortable: false, flex: 0.13, headerAlign: "center", align: "center",
        //         renderCell: (cellvalues) => {
        //             var date = this.dateConvertion(cellvalues.row.result_completed_date__date)
        //             return (
        //                 <p>{date ? date : "-"}</p>
        //             )
        //         }

        //     }, { field: 'status', headerName: t('ResultStatus'), sortable: false, flex: 0.18, headerAlign: "center", align: "center",},
        //     {
        //         field: '', headerName: t('Action'), sortable: false, flex: 0.14, headerAlign: "center", align: "center",
        //         renderCell: (cellvalues) => {
        //             return (
        //                 <Stack direction="row">
        //                     <Tooltip placement="top" title={t("Result")} arrow>
        //                         <div className="eMed_tst_action"
        //                             onClick={() => {
        //                                 this.setState({
        //                                     selectedTableRow: cellvalues.row
        //                                 })
        //                                 this.props.selectedTab()
        //                             }}
        //                         >
        //                             <Link to={{ pathname: "/Results", state: { resultList: cellvalues.row } }}>
        //                                 <div><img src={ImagePaths.Result.default} className="labImageStyle" alt='result'></img></div>
        //                             </Link></div>
        //                     </Tooltip>
        //                     <Tooltip placement="top" title={t("ResultComplete")} arrow>
        //                         <div>
        //                         {cellvalues.row.status == "Result Completed" ?
        //                             <div className='eMed_cursor'><img src={ImagePaths.Check.default} className="labImageStyle" alt='check'
        //                                 onClick={() => {
        //                                     this.setState({
        //                                         selectedTableRow: cellvalues.row
        //                                     })
        //                                    if(cellvalues.row.status != "Result Delivered" && CheckAccessFunc("Laboratory", "Home", "Test Report", null, "Tab")?.editAccess) {
        //                                     this.onClickStatusDelivery(cellvalues.row.id)
        //                                    }
        //                                 }} /></div> :  cellvalues.row.lab_bill_summary__status == "Partially Saved" ?
        //                             <div className='eMed_cursor'><img src={ImagePaths.Tick.default} className="labImageStyle" alt='tick'/></div> :cellvalues.row.status == "Result Delivered" ?
        //                             <div className='eMed_cursor'><img src={ImagePaths.PrintIcons.default} className="labImageStyle" alt='print'
        //                               onClick={() => {
        //                                 this.individualReportPrint(cellvalues.row.id)
        //                               }}
        //                             /></div> : null
        //                         }
        //                         </div>
        //                     </Tooltip>
        //                     <div className='eMed_tst_icon'>
        //                         <IconButton
        //                             aria-label="more"
        //                             id="long-button"
        //                             aria-controls={cellvalues.is_multi_option}
        //                             aria-expanded={cellvalues.is_multi_option}
        //                             aria-haspopup="true"
        //                             onClick={(e) => {
        //                                 this.setState({
        //                                     anchorEl: e.currentTarget,
        //                                     billSummaryID: cellvalues.row.lab_bill_summary,
        //                                     selectedTableRow: cellvalues.row,
        //                                     isSelected: true,
        //                                 }, () => {
        //                                     this.getPreviousReport()
        //                                     this.getReceivePaymentAmountData(cellvalues)
        //                                 })
        //                             }}
        //                         >
        //                             <MoreVertIcon />
        //                         </IconButton>
        //                         {(this.state.selectedTableRow.id === cellvalues.row.id && this.state.isSelected) ? 
        //                         <Menu
        //                             id="long-menu"
        //                             MenuListProps={{
        //                                 'aria-labelledby': 'long-button',
        //                             }}

        //                             anchorEl={this.state.anchorEl}
        //                             open={Boolean(this.state.anchorEl)}
        //                             onClose={(e) => {
        //                                 this.setState({
        //                                     anchorEl: null
        //                                 })
        //                             }}
        //                             PaperProps={{
        //                                 style: {
        //                                     maxHeight: ITEM_HEIGHT * 4.5,
        //                                     width: '12vw',
        //                                     marginRight: "2.5vw"
        //                                 },
        //                             }}
        //                         >
        //                             {multi_option.map((option) => (
        //                                 <MenuItem key={option} disabled={this.state.labTestAmountData?.total_balance_amount === 0 && option.value == "Receive Payment" ? true : false} selected={option === 'Pyxis'} onClick={() => {
        //                                     this.setState({
        //                                         selectedOption: option.label,
        //                                         isTestReport: true
        //                                     }, () => {
        //                                         if (this.state.selectedOption == "ReceivePayment") {
        //                                             // this.props.selectedTab(0, this.state.selectedTableRow, false, TextTrackCueList)
        //                                             // setTimeout(() => {
        //                                             //     this.props.selectedTab(0, this.state.selectedTableRow, false, false)
        //                                             // }, 2000);
        //                                             this.setState({ SelectedBill: this.state.selectedTableRow,CreditBillPopUp:true }) 
        //                                         }
        //                                         if (this.state.selectedOption == "ViewPreviousReport") {
        //                                             this.setState({
        //                                                 isViewReport: true
        //                                             })
        //                                         }
        //                                     })
        //                                 }}>
        //                                     {t(option.label)}
        //                                 </MenuItem>
        //                             ))}
        //                         </Menu>
        //                         : null}
        //                     </div>
        //                 </Stack>
        //             )
        //         }
        //     }

        // ]
        return (
            <div className='Lab_Status_Container'>
                <div className='lab_status_sub_header'>
                    <div className='eMed_tst_date_pick'>
                    <Box className="eMed_Search_Box_">
                            <TextField
                                variant='standard'
                                placeholder='Search'
                                value={this.state.TableSearchText}
                                onChange={(e) => { this.setState({ TableSearchText: e.target.value }) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ width: '15vw' }} />
                        </Box>
                        <Box width={"10vw"}>
                        <CommonDatePicker
                            FromDate={this.state.FilterDatas.FromDate}
                            ToDate={this.state.FilterDatas.ToDate}
                            DateIndex={this.state.FilterDatas.DateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                        </Box>
                    </div>
                    {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}
                    {/* <button className="lab_status_printBtn" ><img
                        onClick={() => {
                            this.testReportPrint()
                        }}
                        className="Lab_Status_Subcontainer_Img" src={ImagePaths.PrintOff.default} alt={"print"} /></button>
                    <button className="lab_status_printBtn" ><img className="Lab_Status_Subcontainer_Img" src={ImagePaths.ExportOff.default} alt={"export"} /></button> */}
                </div>
                <Box className='labDataGrid'>
                    {/* <DataGrid
                        {...reportList}
                        // getRowClassName={(params) =>  params.row.status == "Result Delivered" ? 'Lab_report_hightlight' : null}
                        rows={this.state.reportList}
                        columns={columns}
                        hideFooterSelectedRowCount
                        getRowId={(row) => row.id}
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(newPageSize) => {
                            this.setState({ pageSize: newPageSize })
                        }}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        headerHeight={40}
                    /> */}
                    {this.renderTestReportTable()}
                </Box>
                <div>
                    {this.state.selectedOption == "ViewPreviousReport" ?
                        < Dialog
                            className="emed_DialogboxOrder"
                            open={this.state.isViewReport}
                            onClose={() => {
                                this.setState({
                                    isViewReport: false
                                })
                            }}
                            maxWidth={"md"}
                        >
                            <div className='PreviousReportPopUp' style={{width: '45vw'}}>
                                <div className='sample_collect_popup'>
                                    <p>{t("PreviousReport")}</p>
                                    <Button variant="text" onClick={() => {
                                        this.setState({
                                            selectedOption: "",
                                            isViewReport: false
                                        })
                                    }}>X</Button>
                                </div>
                                <TableContainer className='Lab_Previous_Report_Con' component={Paper}>
                                    <Table stickyHeader size="small">
                                        <TableHead id=' ' sx={{ height: "4vh" }} >
                                            <TableRow>
                                                <TableCell id="lab_home_table_Head" sx={{ flex: 0.25 }} align="left" >{t("InvoiceNumber")}</TableCell>
                                                <TableCell id="lab_home_table_Head" sx={{ flex: 0.25 }} align="left" >{t("InvoiceDate")}</TableCell>
                                                <TableCell id="lab_home_table_Head" sx={{ flex: 0.25 }} align="left" >{t("Result Completed Date")}</TableCell>
                                                <TableCell id="lab_home_table_Head" sx={{ flex: 0.25 }} align="left"> {t("Action")} </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="Lab_Status_Table_body" sx={{ height: "7vh" }}>
                                            {this.state.preReportList && this.state.preReportList.length > 0 ?
                                                this.state.preReportList.map((item, index) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell align="left">{item.bill_number ? item.bill_number : '-'}</TableCell>
                                                            <TableCell align="left">{item.bill_date ? item.bill_date : '-'}</TableCell>
                                                            <TableCell align="left">{item.result_completed_date ? item.result_completed_date : '-'}</TableCell>
                                                            <TableCell align="left">
                                                                <IconButton size='small'
                                                                    onClick={() => {
                                                                        this.individualReportPrint(item.id, item.result_item_id)
                                                                    }}
                                                                > <img width="30vw" height="30vh" src={ImagePaths.PrintIcons.default} alt="print"></img></IconButton>
                                                            </TableCell>

                                                        </TableRow>)
                                                }) :
                                                <TableRow>
                                                    <TableCell align="center">{t("NoRecordsFound")}</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Dialog> : null
                    }
                </div>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : this.state.isSuccessMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.isSuccessMsgText}
                            msgPop={this.msgClose.bind(this)}
                        /> : null
                }
                {
                    this.state.CreditBillPopUp ?
                        <CreditBillCompletePoP
                            modules={['lab']}
                            Module_ID_Key_Name={"bill_id"}
                            Module_ID_Key_Value={this.state.SelectedBill?.lab_bill_summary_id}
                            POST_Module_ID_Key_Name={this.state.SelectedBill?.ip_admission_id ? "ip_admission_id" : this.state.SelectedBill?.appointment_id ? "appointment_id" : "bill_id"}
                            POST_Module_ID_Key_Value={this.state.SelectedBill?.ip_admission_id ? this.state.SelectedBill?.ip_admission_id : this.state.SelectedBill?.appointment_id ? this.state.SelectedBill?.appointment_id : this.state.SelectedBill?.lab_bill_summary_id}
                            BillData={this.state.SelectedBill}
                            CrebillPosted={this.CrebillPosted.bind(this)}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            CollectedIn={"LAB"}
                        /> : null}

            </div >

        );
    }
    specimanClose = () => {
        this.setState({
            specimanClose: false
        })
    }
    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
            page: 0
        });
    };
}
export default withTranslation()(TestReport)