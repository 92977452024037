import axios from "axios";
import { DateTime } from "luxon";
import { localGetItem } from "../../Utility/Services/CacheProviderService";

// export const RemedyIdentity = {
//     "authToken": "7oVk5ECEksXktmukyGgjdrF1v9Q+PcUGXC1dOVgFT/U=",
//     "his_name": "EMEDHUB"
// }
export const RemedyHospitalCode = "H_35605"
// export const RemedyHISName = "EMEDHUB"
export const RemedyHospitalName = "EMEDHUB HOSPITAL"

export const GenderList = [
    { id: "1", label: "Female" },
    { id: "2", label: "Male" }
];

export const RelationShipList = [
    { id: "3", label: "Mother" },
    { id: "4", label: "Father" },
    { id: "5", label: "Brother" },
    { id: "1", label: "Self" },
    { id: "6", label: "Sister" },
    { id: "7", label: "Aunt" },
    { id: "8", label: "Daughter" },
    { id: "9", label: "Son" },
    { id: "10", label: "Daughter-in-Law" },
    { id: "11", label: "Son-in-Law" },
    { id: "12", label: "Father-in-Law" },
    { id: "13", label: "Mother-in-Law" },
    { id: "14", label: "Brother-in-Law" },
    { id: "15", label: "Sister-in-Law" },
    { id: "16", label: "Grand Father" },
    { id: "17", label: "Grand Mother" },
    { id: "18", label: "Grand Son" },
    { id: "19", label: "Grand Daughter" },
    { id: "20", label: "Husband" },
    { id: "21", label: "Neice" },
    { id: "22", label: "Nephew" },
    { id: "23", label: "Uncle" },
    { id: "2", label: "Wife" },
    { id: "24", label: "Other" },
];

export const AdmissionTypeList = [
    { id: "6", label: "Dialysis" },
    { id: "1", label: "Emergency" },
    { id: "2", label: "Planned" },
    { id: "3", label: "Day care" },
    { id: "4", label: "Maternity" },
    { id: "5", label: "Accident" },
]

export const DeliveryModeList = [
    { id: "1", label: "Abortion" },
    { id: "2", label: "LSCS" },
    { id: "3", label: "Normal" },
    { id: "4", label: "Post Natal Admission" },
    { id: "5", label: "Pre Natal Admission" },
]

export const TreatmentTypeList = [
    { id: "3", label: "CANCER" },
    { id: "2", label: "MEDICAL" },
    { id: "39", label: "SURGERY WITH MEDICAL" },
    { id: "1", label: "SURGICAL" },
    { id: "56", label: "TRAUMA" },
]

export const SurgicalTreamentSubType = [
    { id: "4", label: "ELECTIVE SURGERY" },
    { id: "5", label: "EXPLORATORY SURGERY" },
    { id: "6", label: "AMPUTATION" },
    { id: "7", label: "RE-PLANTATION" },
    { id: "8", label: "RECONSTRUCTIVE" },
    { id: "9", label: "COSMETIC SURGERY" },
    { id: "10", label: "TRANSPLANT" },
    { id: "11", label: "MINIMALLY INVASIVE SURGERY" },
    { id: "12", label: "OPEN SURGICAL PROCEDURE" },
    { id: "13", label: "LASER SURGERY" },
    { id: "14", label: "ROBOTIC SURGERY" },
    { id: "15", label: "MICRO SURGERY" },
    { id: "16", label: "MAJOR" },
    { id: "17", label: "MINOR" },
]

export const MedicalTreamentSubType = [
    { id: "18", label: "ACTIVE TREATMENT" },
    { id: "19", label: "CAUSAL TREATMENT" },
    { id: "20", label: "CONSERVATIVE TREATMENT" },
    { id: "21", label: "EMPIRIC TREATMENT" },
    { id: "22", label: "EXPECTANT TREATMENT" },
    { id: "23", label: "PALLIATIVE TREATMENT" },
    { id: "24", label: "PREVENTIVE TREATMENT" },
    { id: "25", label: "RATIONAL TREATMENT" },
    { id: "26", label: "SHOCK TREATMENT" },
    { id: "27", label: "SPECIFIC TREATMENT" },
    { id: "28", label: "SUPPORTIVE TREATMENT" },
    { id: "29", label: "SYMPTOMATIC TREATMENT" }
]

export const CancerTreamentSubType = [
    { id: "30", label: "SURGERY" },
    { id: "31", label: "CHEMOTHERAPY" },
    { id: "32", label: "RADIATION THERAPY" },
    { id: "33", label: "TARGETED THERAPY" },
    { id: "34", label: "IMMUNOTHERAPY" },
    { id: "35", label: "HYPERTHERMIA" },
    { id: "36", label: "STEM CELL TRANSPLANT" },
    { id: "37", label: "PHOTO-DYNAMIC THERAPY" },
    { id: "38", label: "SHOCK TREATMENT" },
]

export const SurgeryMedicalTreamentSubType = [
    { id: "42", label: "ELECTIVE SURGERY" },
    { id: "43", label: "EXPLORATORY SURGERY" },
    { id: "44", label: "AMPUTATION" },
    { id: "45", label: "RE-PLANTATION" },
    { id: "46", label: "RECONSTRUCTIVE" },
    { id: "47", label: "COSMETIC SURGERY" },
    { id: "48", label: "TRANSPLANT" },
    { id: "49", label: "MINIMALLY INVASIVE SURGERY" },
    { id: "50", label: "OPEN SURGICAL PROCEDURE" },
    { id: "51", label: "LASER SURGERY" },
    { id: "52", label: "ROBOTIC SURGERY" },
    { id: "53", label: "MICRO SURGERY" },
    { id: "54", label: "MAJOR" },
    { id: "55", label: "MINOR" },
]

export const TRAUMATreamentSubType = [
    { id: "57", label: "SURGICAL" },
    { id: "58", label: "MEDICAL" },
]

// export const RequestIdentity = {
//     "authToken": "fSdfTrMk7V43TnfroFe4wpM6Zl4gy2lc+wPuNPGS/tU=",
//     "his_name": "DECISIVEDGE"
// }


let dataURItoBlob = (file) => {
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            // Convert the result to a ByteArray
            const byteArray = new Uint8Array(e.target.result);
            // You can now use the byteArray as needed
            console.log(byteArray);
        };
        reader.readAsArrayBuffer(file);
    }
};

const DateFormateWithslash = (date) => {
    return DateTime.fromJSDate(new Date(date)).toFormat('dd/MM/yyyy')
};

const Time24Formate = (datetime) => {
    return DateTime.fromJSDate(new Date(datetime)).toLocaleString(DateTime.TIME_24_SIMPLE)
};

const byteArrayToBase64 = (byteArray) => {
    let binary = '';
    let len = byteArray.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(byteArray[i]);
    }
    return window.btoa(binary);
}

const PreAuthRequestData = (FormsData, isDraft = false, tab, PayerDeatils) => {
    let AttachmentArray = []
    let Insurance = JSON.parse(localGetItem("Insurance_Details"))
    let Identity = {
        "authToken": Insurance?.auth_token,
        "his_name": Insurance?.his_name
    }
    if (tab === "Preauthorisation") {
        if (FormsData?.attachments?.length > 0) {
            FormsData?.attachments?.forEach(element => {
                if (typeof (element?.filecontent) !== 'string') {
                    AttachmentArray.push({
                        "fileName": element?.filename ? element?.filename : "",
                        "fileContent": byteArrayToBase64(element?.filecontent),
                        "attachment_remarks": element?.remarks ? element?.remarks : ""
                    })
                }
            });
        }
    } else {
        if (FormsData?.claim_attachments?.length > 0) {
            FormsData?.claim_attachments?.forEach(element => {
                if (typeof (element?.filecontent) !== 'string') {
                    AttachmentArray.push({
                        "fileName": element?.filename ? element?.filename : "",
                        "fileContent": byteArrayToBase64(element?.filecontent),
                        "attachment_remarks": element?.remarks ? element?.remarks : ""
                    })
                }
            });
        }
    }

    let Data = {
        "ip_admission_id": FormsData?.ip_number?.id ? FormsData?.ip_number?.id : "",
        'type': tab === "Preauthorisation" ? "Preauthorisation" : "Claim Submission",
        "identity": Identity,
        "preauthdata": {
            "isdraftcase": isDraft,
            "ipnumber": FormsData?.ip_number?.ip_number ? FormsData?.ip_number?.ip_number : "",
            "rn_reference_no": FormsData?.rn_reference_no ? FormsData?.rn_reference_no : "",
            'payer_name': PayerDeatils?.PAYERNAME ? PayerDeatils?.PAYERNAME : "",
            "tpacode": FormsData?.tpa_code ? FormsData?.tpa_code : "",
            "hospitalcode": FormsData?.hospital_code ? FormsData?.hospital_code : "",
            "status_val": FormsData?.status_id?.name ? FormsData?.status_id?.name : "",
            "status_id": FormsData?.status_id?.id ? FormsData?.status_id?.id : "",
            "Client_Reference_No":/* FormsData?.ip_number ? FormsData?.ip_number : */"",
            "isspecialcase": "false",
            "zone_val": FormsData?.zone_id?.ZONENAME ? FormsData?.zone_id?.ZONENAME : "",
            "zone_id": FormsData?.zone_id?.ZONEID ? FormsData?.zone_id?.ZONEID : "",
            "insurance_val": FormsData?.insurance?.INSURANCE_NAME ? FormsData?.insurance?.INSURANCE_NAME : "",
            "insurance_id": FormsData?.insurance?.INSURANCE_ID ? FormsData?.insurance?.INSURANCE_ID : "",
            "corporate_val": FormsData?.corporate?.NAME ? FormsData?.corporate?.NAME : "",
            "corporate_id": FormsData?.corporate?.CODE ? FormsData?.corporate?.CODE : "",
            "employeeid": FormsData?.employee ? FormsData?.employee : "",
            "policyno": FormsData?.policy_number ? FormsData?.policy_number : "",
            "memberid": FormsData?.member_id ? FormsData?.member_id : "",
            "idcardno": FormsData?.id_card_no ? FormsData?.id_card_no : "",
            "patientfirstname": FormsData?.patient_first_name ? FormsData?.patient_first_name : "",
            "patientmiddlename": FormsData?.patient_middle_name ? FormsData?.patient_middle_name : "",
            "patientlastname": FormsData?.patient_last_name ? FormsData?.patient_last_name : "",
            "gender_val": FormsData?.gender?.label ? FormsData?.gender?.label : "",
            "gender_id": FormsData?.gender?.id ? FormsData?.gender?.id : "",
            "relationship_val": FormsData?.relationship?.label ? FormsData?.relationship?.label : "",
            "relationship_id": FormsData?.relationship?.id ? FormsData?.relationship?.id : "",
            "dob": FormsData?.date_of_birth ? DateFormateWithslash(FormsData?.date_of_birth) : "",
            "age_year": FormsData?.age_year ? FormsData?.age_year : "",
            "age_month": FormsData?.age_month ? FormsData?.age_month : "",
            "age_day": FormsData?.age_day ? FormsData?.age_day : "",
            "emailid": FormsData?.e_mail ? FormsData?.e_mail : "",
            "address": FormsData?.address ? FormsData?.address : "",
            "city_val": FormsData?.city ? FormsData?.city : "",
            // "city_id": FormsData?.ip_number ? FormsData?.ip_number : "",
            "state_val": FormsData?.state ? FormsData?.state : "",
            // "state_id":  FormsData?.ip_number ? FormsData?.ip_number : "",
            "pincode": FormsData?.pincode ? FormsData?.pincode : "",
            "mobileno": FormsData?.mobile_number ? FormsData?.mobile_number : "",
            "primaryfirstname": FormsData?.primary_first_name ? FormsData?.primary_first_name : "",
            "primarymiddlename": FormsData?.primary_middle_name ? FormsData?.primary_middle_name : "",
            "primarylastname": FormsData?.primary_last_name ? FormsData?.primary_last_name : "",
            "ailment_val": FormsData?.aliment?.AilmentName ? FormsData?.aliment?.AilmentName : "",
            "ailment_id": FormsData?.aliment?.AilmentCode ? FormsData?.aliment?.AilmentCode : "",
            "ailment_duration": FormsData?.aliment_duration ? FormsData?.aliment_duration : "",
            // "tariff_val": FormsData?.mobile_number ? FormsData?.mobile_number : "",
            // "tariff_id": FormsData?.mobile_number ? FormsData?.mobile_number : "",
            // "tariffrate": FormsData?.mobile_number ? FormsData?.mobile_number : "",
            "investigation": FormsData?.investigation ? FormsData?.investigation : "",
            "treatment_val": FormsData?.treatment?.label ? FormsData?.treatment?.label : "",
            "treatment_id": FormsData?.treatment?.id ? FormsData?.treatment?.id : "",
            // "hosptreatmentsubtype_id": FormsData?.mobile_number ? FormsData?.mobile_number : "",
            // "hospTreatmentSubtype_val": FormsData?.mobile_number ? FormsData?.mobile_number : "",
            "treatmentsubtype_val": FormsData?.treatment_subtype?.label ? FormsData?.treatment_subtype?.label : "",
            "treatmentsubtype_id": FormsData?.treatment_subtype?.id ? FormsData?.treatment_subtype?.id : "",
            "typeofanesthesia": `${FormsData?.Ga ? "GA" : ""}${FormsData?.La ? "|LA" : ""}${FormsData?.Sa ? "|SA" : ""}${FormsData?.Ea ? "|EA" : ""}${FormsData?.Ta ? "|TA" : ""}`,
            "roomtype_val": FormsData?.room_type?.PROVIDER_ROOM_TYPE ? FormsData?.room_type?.PROVIDER_ROOM_TYPE : "",
            "roomtype_id": FormsData?.room_type?.PROVIDER_ROOM_TYPE_ID ? FormsData?.room_type?.PROVIDER_ROOM_TYPE_ID : "",
            "roomrent": FormsData?.room_rent ? FormsData?.room_rent : "",
            "doa": FormsData?.doa_date ? DateFormateWithslash(FormsData?.doa_date) : "",
            "doa_time": FormsData?.doa_time ? Time24Formate(FormsData?.doa_time) : "",
            "dod": FormsData?.dod_date ? DateFormateWithslash(FormsData?.dod_date) : "",
            "dod_time": FormsData?.dod_time ? Time24Formate(FormsData?.dod_time) : "",
            "durationofstay": FormsData?.duration_of_stay ? FormsData?.duration_of_stay : "",
            "surgerydate": FormsData?.surgery_date ? DateFormateWithslash(FormsData?.surgery_date) : "",
            "billamount": FormsData?.bill_amount ? FormsData?.bill_amount : "",
            "doctorname_val": FormsData?.doctor_name ? FormsData?.doctor_name : "",
            "admissiontype_val": FormsData?.admission_type ? FormsData?.admission_type?.label : "",
            "admissiontype_id": FormsData?.admission_type ? FormsData?.admission_type?.id : "",
            "provisionaldiagnosis_val": FormsData?.provisional_diagnosis?.DIAGNOSIS_NAME ? FormsData?.provisional_diagnosis?.DIAGNOSIS_NAME : "",
            "provisionaldiagnosis_id": FormsData?.provisional_diagnosis?.DIAGNOSIS_ID ? FormsData?.provisional_diagnosis?.DIAGNOSIS_ID : "",
            "diagnosissubtype_val": FormsData?.diagnosis_subtype ? FormsData?.diagnosis_subtype : "",
            // "diagnosissubtype_id": FormsData?.surgery_date ? FormsData?.surgery_date : "",
            "provisinaltreatment_val": FormsData?.provisional_treatment?.TREATMENT_NAME ? FormsData?.provisional_treatment?.TREATMENT_NAME : "",
            "provisinaltreatment_id": FormsData?.provisional_treatment?.TREATMENT_ID ? FormsData?.provisional_treatment?.TREATMENT_ID : "",
            "billnumber": FormsData?.bill_number ? FormsData?.bill_number : "",
            "patienthospitalid": FormsData?.patient_hospital ? FormsData?.patient_hospital : "",
            "panno": FormsData?.pan_no ? FormsData?.pan_no : "",
            "aadharno": FormsData?.aadhar_number ? FormsData?.aadhar_number : "",
            "suminsured": FormsData?.sum_insured ? FormsData?.sum_insured : "",
            "rohiniid": FormsData?.rohini_id ? FormsData?.rohini_id : "",
            "dateOffirstconsultation": FormsData?.first_consult_date ? DateFormateWithslash(FormsData?.first_consult_date) : "",
            "surgerycode": FormsData?.surgery_code ? FormsData?.surgery_code : "",
            "maternity_g": FormsData?.maternity_g ? FormsData?.maternity_g : "",
            "maternity_p": FormsData?.maternity_p ? FormsData?.maternity_p : "",
            "maternity_l": FormsData?.maternity_l ? FormsData?.maternity_l : "",
            "maternity_a": FormsData?.maternity_a ? FormsData?.maternity_a : "",
            "lmp": FormsData?.lmp ? DateFormateWithslash(FormsData?.lmp) : "",
            "edd": FormsData?.edd ? DateFormateWithslash(FormsData?.edd) : "",
            "deliverymode_val": FormsData?.delivery_mode?.label ? FormsData?.delivery_mode?.label : "",
            "deliverymode_id": FormsData?.delivery_mode?.id ? FormsData?.delivery_mode?.id : "",
            "hospitalremarks": FormsData?.hospital_remark ? FormsData?.hospital_remark : "",
            "past_history_of_present_ailment": FormsData?.past_history ? FormsData?.past_history : "",
            "intensivecare": FormsData?.intensive_care ? FormsData?.intensive_care : "",
            "othertreatments": FormsData?.other_treatment ? FormsData?.other_treatment : "",
            "present_ailment_due_to_ped_remarks": FormsData?.present_ailment_remarks ? FormsData?.present_ailment_remarks : "",
            "is_present_ailment_due_to_ped": FormsData?.is_present_ailment_due_to_ped ? 'true' : "false",
            "clinicalfindings": FormsData?.clinical_findings ? FormsData?.clinical_findings : "",
            "pulse": FormsData?.pulse_vital ? FormsData?.pulse_vital : "",
            "bp": FormsData?.bp_vital ? FormsData?.bp_vital : "",
            "cns": FormsData?.cns_vital ? FormsData?.cns_vital : "",
            "pa": FormsData?.pa_vital ? FormsData?.pa_vital : "",
            "rs": FormsData?.rs_vital ? FormsData?.rs_vital : "",
            "cvs": FormsData?.cs_vital ? FormsData?.cs_vital : "",
            "presentillness_alcohol": FormsData?.is_alcohol ? FormsData?.is_alcohol : false,
            "presentillness_infertility": FormsData?.is_infertility ? FormsData?.is_infertility : false,
            "presentillness_congenital_external_disease": FormsData?.is_congenital_external_disease ? FormsData?.is_congenital_external_disease : false,
            "presentillness_self_poisoning_injury": FormsData?.is_self_poisoning_injury ? FormsData?.is_self_poisoning_injury : false,
            "presentillness_hiv": FormsData?.is_hiv ? FormsData?.is_hiv : false,
            "presentillness_cosmetic_surgery": FormsData?.is_consmetic_surgery ? FormsData?.is_consmetic_surgery : false,
            // "presentillnessisdueto_alcohol": FormsData?.is_pregnancy_related ? FormsData?.is_pregnancy_related : false,
            "presentillness_pregnancy_related": FormsData?.is_pregnancy_related ? FormsData?.is_pregnancy_related : false,
            "presentillness_none_of_these": FormsData?.is_none_of_these ? FormsData?.is_none_of_these : false,
            "hypertension": FormsData?.hypertension?.radio_value ? FormsData?.hypertension?.radio_value : "",
            "hypertension_duration": FormsData?.hypertension?.duration_count || FormsData?.hypertension?.duration_type ? `${FormsData?.hypertension?.duration_count} ${FormsData?.hypertension?.duration_type ? FormsData?.hypertension?.duration_type : ""}` : "",
            "hypertension_remarks": FormsData?.hypertension?.remarks ? FormsData?.hypertension?.remarks : "",
            "diabetes": FormsData?.diabetes?.radio_value ? FormsData?.diabetes?.radio_value : "",
            "diabetes_duration": FormsData?.diabetes?.duration_count || FormsData?.diabetes?.duration_type ? `${FormsData?.diabetes?.duration_count} ${FormsData?.diabetes?.duration_type ? FormsData?.diabetes?.duration_type : ""}` : "",
            "diabetes_remarks": FormsData?.diabetes?.remarks ? FormsData?.diabetes?.remarks : "",
            "heartdisease": FormsData?.heart_disease?.radio_value ? FormsData?.heart_disease?.radio_value : "",
            "heartdisease_duration": FormsData?.heart_disease?.duration_count || FormsData?.heart_disease?.duration_type ? `${FormsData?.heart_disease?.duration_count} ${FormsData?.heart_disease?.duration_type ? FormsData?.heart_disease?.duration_type : ""}` : "",
            "heartdisease_remarks": FormsData?.heart_disease?.remarks ? FormsData?.heart_disease?.remarks : "",
            "copd": FormsData?.copd?.radio_value ? FormsData?.copd?.radio_value : "",
            "copd_duration": FormsData?.copd?.duration_count || FormsData?.copd?.duration_type ? `${FormsData?.copd?.duration_count} ${FormsData?.copd?.duration_type}` : "",
            "copd_remarks": FormsData?.copd?.remarks ? FormsData?.copd?.remarks : "",
            "stdhiv": FormsData?.stdhiv?.radio_value ? FormsData?.stdhiv?.radio_value : "",
            "stdhiv_duration": FormsData?.stdhiv?.duration_count || FormsData?.stdhiv?.duration_type ? `${FormsData?.stdhiv?.duration_count} ${FormsData?.stdhiv?.duration_type ? FormsData?.stdhiv?.duration_type : ""}` : "",
            "stdhiv_remarks": FormsData?.stdhiv?.remarks ? FormsData?.stdhiv?.remarks : "",
            "asthma": FormsData?.asthma?.radio_value ? FormsData?.asthma?.radio_value : "",
            "asthma_duration": FormsData?.asthma?.duration_count || FormsData?.asthma?.duration_type ? `${FormsData?.asthma?.duration_count} ${FormsData?.asthma?.duration_type ? FormsData?.asthma?.duration_type : ""}` : "",
            "asthma_remarks": FormsData?.asthma?.remarks ? FormsData?.asthma?.remarks : "",
            "epilepsy": FormsData?.epilepsy?.radio_value ? FormsData?.epilepsy?.radio_value : "",
            "epilepsy_duration": FormsData?.epilepsy?.duration_count || FormsData?.epilepsy?.duration_type ? `${FormsData?.epilepsy?.duration_count} ${FormsData?.epilepsy?.duration_type ? FormsData?.epilepsy?.duration_type : ""}` : "",
            "epilepsy_remarks": FormsData?.epilepsy?.remarks ? FormsData?.epilepsy?.remarks : "",
            "cancer": FormsData?.cancer?.radio_value ? FormsData?.cancer?.radio_value : "",
            "cancer_duration": FormsData?.cancer?.duration_count || FormsData?.cancer?.duration_type ? `${FormsData?.cancer?.duration_count} ${FormsData?.cancer?.duration_type ? FormsData?.cancer?.duration_type : ""}` : "",
            "cancer_remarks": FormsData?.cancer?.remarks ? FormsData?.cancer?.remarks : "",
            "arthritis": FormsData?.arthritis?.radio_value ? FormsData?.arthritis?.radio_value : "",
            "arthritis_duration": FormsData?.arthritis?.duration_count || FormsData?.arthritis?.duration_type ? `${FormsData?.arthritis?.duration_count} ${FormsData?.arthritis?.duration_type ? FormsData?.arthritis?.duration_type : ""}` : "",
            "arthritis_remarks": FormsData?.arthritis?.remarks ? FormsData?.arthritis?.remarks : "",
            "congenitaldisease": FormsData?.congenital_disease?.radio_value ? FormsData?.congenital_disease?.radio_value : "",
            "congenitalmarriage": FormsData?.consanguineous_marriage ? FormsData?.consanguineous_marriage : "",
            "otherdiseases": FormsData?.other_diseases?.radio_value ? FormsData?.other_diseases?.radio_value : "",
            "otherdiseases_duration": FormsData?.other_diseases?.duration_count || FormsData?.other_diseases?.duration_type ? `${FormsData?.other_diseases?.duration_count} ${FormsData?.other_diseases?.duration_type ? FormsData?.other_diseases?.duration_type : ""}` : "",
            "otherdiseases_remarks": FormsData?.other_diseases?.remarks ? FormsData?.other_diseases?.remarks : "",
            "alcoholdrugs": FormsData?.alcohol_drugs?.radio_value ? FormsData?.alcohol_drugs?.radio_value : "",
            "alcoholdrugs_duration": FormsData?.alcohol_drugs?.duration_count || FormsData?.alcohol_drugs?.duration_type ? `${FormsData?.alcohol_drugs?.duration_count} ${FormsData?.alcohol_drugs?.duration_type ? FormsData?.alcohol_drugs?.duration_type : ""}` : "",
            "alcoholdrugs_remarks": FormsData?.alcohol_drugs?.remarks ? FormsData?.alcohol_drugs?.remarks : "",
            "smokinghistory": FormsData?.smoking_history?.radio_value ? FormsData?.smoking_history?.radio_value : "",
            "smokinghistory_duration": FormsData?.smoking_history?.duration_count || FormsData?.smoking_history?.duration_type ? `${FormsData?.smoking_history?.duration_count} ${FormsData?.smoking_history?.duration_type ? FormsData?.smoking_history?.duration_type : ""}` : "",
            "smokinghistory_remarks": FormsData?.smoking_history?.remarks ? FormsData?.smoking_history?.remarks : "",
            "cause_of_injury": FormsData?.cause_of_injury ? FormsData?.cause_of_injury : "",
            "medico_legal_mlc": FormsData?.medico_legel_mlc ? FormsData?.medico_legel_mlc : "false",
            "medico_legal_nonmlc": FormsData?.medico_legel_non_mlc ? FormsData?.medico_legel_non_mlc : "false",
            "accident_fir": FormsData?.accident_fir ? FormsData?.accident_fir : "false",
            "accident_date": FormsData?.accident_date ? DateFormateWithslash(FormsData?.accident_date) : "",
            "member_rank": FormsData?.member_rank ? FormsData?.member_rank : "",
            // "entitlement_val": FormsData?.accident_fir ? FormsData?.accident_fir : "",
            // "entitlement_id": FormsData?.accident_fir ? FormsData?.accident_fir : "",
            // "is_billdetails_packages": FormsData?.bill_details_package ? FormsData?.bill_details_package : "",
            // "query_delay_reason_val": "",
            // "query_delay_reason_id": "",
            // "basic_pay": ""
        },
        "attachments": AttachmentArray
    }
    if (tab === "Claim Submission") {
        Data["preauthdata"]["PatientPaidAmount"] = FormsData?.PatientPaidAmt;
        Data["preauthdata"]["HospClaimedAmt"] = FormsData?.HospitalClaimedAmt;
    }

    return Data
}

export class RemediServiceUrls {
    static REMEDI_BASE_URL = "https://demo.remediware.com/"
    static PAYER_LIST_GET = "HISAPI_RN_STAGING/RNHIS/PayerList"
    static ZONE_LIST_GET = "HISAPI_RN_STAGING/RNHIS/ZoneList"
    static INSURANCE_LIST_GET = "HISAPI_RN_STAGING/RNHIS/InsuranceCompany"
    static CORPORATE_LIST_GET = "HISAPI_RN_STAGING/RNHIS/Corporate"
    static ROOMTYPE_LIST_GET = "HISAPI_RN_STAGING/RNHIS/RoomType"
    static AILMENT_LIST_GET = "HISAPI_RN_STAGING/RNHIS/ailment"
    static PROVI_DIAGNOSIS_LIST_GET = "HISAPI_RN_STAGING/RNHIS/ProvisionalDiagnosis"
    static PROVI_TREATMENT_LIST_GET = "HISAPI_RN_STAGING/RNHIS/ProvisionalTreatment"
    static SUBMIT_PREAUTH = "HISAPI_RN_STAGING/RNHIS/submitpreauth"
    static SUBMIT_CLAIMS = "HISAPI_RN_STAGING/RNHIS/submitclaims"
    static MEMBER_VALIDATION = "HISAPI_RN_STAGING/RNHIS/PayerBeneficiaryDetails"
    static PRE_AUTH_POST = "HISAPI_RN_STAGING/RNHIS/submitpreauth"
}

export { PreAuthRequestData, dataURItoBlob }
