import { Box, Button, Divider, IconButton, Step, StepLabel, Stepper, Tab, Tabs } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import PreAuthFormOne from './PreAuthFormOne';
import PreAuthFormTwo from './PreAuthFormTwo';
import ClaimSubmissionForm from './ClaimSubmissionForm';
import PayerDetail from './PayerDetail';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { RemediServiceUrls, RemedyHospitalCode } from './RemedinetConstants';
import RestAPIService from '../../Utility/Services/RestAPIService';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { localGetItem } from '../../Utility/Services/CacheProviderService';
import { Serviceurls } from '../../Utility/API/Serviceurls';

class RemedinetForms extends Component {
    constructor(props) {
        super(props)
        let AttachmentArray = this.props.history?.location?.state?.History?.attachments ? this.props.history?.location?.state?.History?.attachments : []
        this.state = {
            SelectedTab: this.props.history?.location?.state?.redirectto ? this.props.history?.location?.state?.redirectto : "Preauthorisation",
            FormStepperCount: 0,
            isPreAuthSubmitted: true,
            isForEditView: this.props.history?.location?.state?.isForEditView ? this.props.history?.location?.state?.isForEditView : false,
            isForView: this.props.history?.location?.state?.isForView ? this.props.history?.location?.state?.isForView : false,
            selectedPayerId: this.props.history?.location?.state?.selectedPayerCode,
            selectedPayerDetail: this.props.history?.location?.state?.selectedPayer,
            isNewRequest: this.props.history?.location?.state?.NewReq ? this.props.history?.location?.state?.NewReq : false,
            HomeData: this.props.history?.location?.state?.HomeData ? this.props.history?.location?.state?.HomeData : {},
            FormsDatas: {
                "hypertension": {
                    "radio_value": "Not Metioned"
                },
                "diabetes": {
                    "radio_value": "Not Metioned"
                },
                "heart_disease": {
                    "radio_value": "Not Metioned"
                },
                "copd": {
                    "radio_value": "Not Metioned"
                },
                "stdhiv": {
                    "radio_value": "Not Metioned"
                },
                "asthma": {
                    "radio_value": "Not Metioned"
                },
                "epilepsy": {
                    "radio_value": "Not Metioned"
                },
                "cancer": {
                    "radio_value": "Not Metioned"
                },
                "arthritis": {
                    "radio_value": "Not Metioned"
                },
                "other_diseases": {
                    "radio_value": "Not Metioned"
                },
                "alcohol_drugs": {
                    "radio_value": "Not Metioned"
                },
                "smoking_history": {
                    "radio_value": "Not Metioned"
                },
                "congenital_disease": {
                    "radio_value": "Not Metioned"
                },
                "consanguineous_marriage": "false",
                "medico_legel_mlc": "false",
                "medico_legel_non_mlc": "false",
                'accident_fir': "false",
                "tpa_code": this.props.history?.location?.state?.selectedPayerCode,
                "hospital_code": RemedyHospitalCode,
                "attachments": AttachmentArray.map((list) => (
                    {
                        "filecontent": list?.approval_file_Content,
                        "filename": list?.approval_file_name,
                        "remarks": list?.attachment_remarks
                    }
                )),
                "claim_attachments": [],
            },
            ZoneList: [],
            CorporateList: [],
            RoomTypeList: [],
            AlimentList: [],
            InsuranceList: [],
            // ProvisionalDiagList: [],
            // ProvTreatmentList: []
            HistoyData: this.props.history?.location?.state?.History,
            disableAllDatas: this.props.history?.location?.state?.History?.is_pre_discharge_approved ? this.props.history?.location?.state?.History?.is_pre_discharge_approved : false,
        }
    }

    componentDidMount() {
        let Insurance = JSON.parse(localGetItem("Insurance_Details"))
        this.setState({
            Identity: {
                "authToken": Insurance?.auth_token,
                "his_name": Insurance?.his_name
            }
        }, () => {
            this.GetZoneList()
            this.GetCorporateList()
            this.getRoomTypelist()
            this.getAilmentlist()
            this.getInsuranceList()
        })
        // this.getProDiagList()
        // this.getProTreatList()
    }


    GetZoneList() {
        try {
            let Data = {
                "url": RemediServiceUrls.REMEDI_BASE_URL + RemediServiceUrls.ZONE_LIST_GET,
                "payload": {
                    "PAYERCODE": this.state.selectedPayerId,
                    "identity": this.state?.Identity
                }

            }

            RestAPIService.create(Data, Serviceurls.REMEDINET_BACKEND).
                then((response) => {
                    console.log(response);
                    let zoneData = response?.data?.Data
                    this.setState({
                        ZoneList: zoneData?.ZONDETAILS
                    })
                }).catch(error => {
                    console.log(error)
                    // if(error?.response?.data?.status === "fail"){
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.response.data.message)
                    // }else{
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.message)
                    // }   
                })
        }
        catch (e) {
            console.log(e.message);
        }
    }

    GetCorporateList = () => {
        try {
            let Data = {
                "url": RemediServiceUrls.REMEDI_BASE_URL + RemediServiceUrls.CORPORATE_LIST_GET,
                "payload": {
                    "PAYERCODE": this.state.selectedPayerId,
                    "PROVIDERCODE": "DEMOH01",
                    "identity": this.state?.Identity
                }

            }

            RestAPIService.create(Data, Serviceurls.REMEDINET_BACKEND).
                then((response) => {
                    console.log(response);
                    let corpData = response?.data?.Data
                    this.setState({
                        CorporateList: corpData?.CORPORATE
                    })
                }).catch(error => {
                    console.log(error)
                    // if(error?.response?.data?.status === "fail"){
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.response.data.message)
                    // }else{
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.message)
                    // }   
                })
        }
        catch (e) {
            console.log(e.message);
        }
    }

    getRoomTypelist = () => {
        try {
            let Data = {
                "url": RemediServiceUrls.REMEDI_BASE_URL + RemediServiceUrls.ROOMTYPE_LIST_GET,
                "payload": {
                    "PAYERCODE": this.state.selectedPayerId,
                    "PROVIDERCODE": "DEMOH01",
                    "identity": this.state?.Identity
                }

            }

            RestAPIService.create(Data, Serviceurls.REMEDINET_BACKEND).
                then((response) => {
                    console.log(response);
                    let roomData = response?.data?.Data
                    this.setState({
                        RoomTypeList: roomData?.ROOMTYPES
                    })
                }).catch(error => {
                    console.log(error)
                    // if(error?.response?.data?.status === "fail"){
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.response.data.message)
                    // }else{
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.message)
                    // }   
                })
        }
        catch (e) {
            console.log(e.message);
        }
    }

    getAilmentlist = (val) => {
        try {
            let Data = {
                "url": RemediServiceUrls.REMEDI_BASE_URL + RemediServiceUrls.AILMENT_LIST_GET,
                "payload": {
                    "AilmentDetail": "fever",
                    "identity": this.state?.Identity
                }

            }

            RestAPIService.create(Data, Serviceurls.REMEDINET_BACKEND).
                then((response) => {
                    console.log(response);
                    let ailmentData = response?.data?.Data
                    this.setState({
                        AlimentList: ailmentData
                    })
                }).catch(error => {
                    console.log(error)
                    // if(error?.response?.data?.status === "fail"){
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.response.data.message)
                    // }else{
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.message)
                    // }   
                })
        }
        catch (e) {
            console.log(e.message);
        }
    }

    getInsuranceList = () => {
        try {
            let Data = {
                "url": RemediServiceUrls.REMEDI_BASE_URL + RemediServiceUrls.INSURANCE_LIST_GET,
                "payload": {
                    "PAYERCODE": this.state.selectedPayerId,
                    "identity": this.state?.Identity
                }

            }

            RestAPIService.create(Data, Serviceurls.REMEDINET_BACKEND).
                then((response) => {
                    console.log(response);
                    let insData = response?.data?.Data
                    this.setState({
                        InsuranceList: insData?.INSURANCECOMPANY
                    })
                }).catch(error => {
                    console.log(error)
                    // if(error?.response?.data?.status === "fail"){
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.response.data.message)
                    // }else{
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.message)
                    // }   
                })
        }
        catch (e) {
            console.log(e.message);
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    MovetoNext(FormsDatas, Count) {
        this.setState({
            FormsDatas: FormsDatas,
            FormStepperCount: Count,
            // SelectedTab: this.props.history?.location?.state?.redirectto === "Claim Submission" ? "Claim Submission" : "Preauthorisation",
        })
    }

    MovetoClaimTab(FormsDatas, Count) {
        this.setState({
            FormsDatas: FormsDatas,
            SelectedTab: "Claim Submission",
            FormStepperCount: Count,
            isPreAuthSubmitted: true
        })
    }

    MovetoPayerTab(ClaimDatas) {
        this.setState({
            ClaimDatas: ClaimDatas,
            isPreAuthSubmitted: true
        })
    }
    showToastMsg = (message, isError) => {
        if (isError) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: message
            })
        } else {
            this.setState({
                successMsg: true,
                successMsgText: message
            })
        }
    }


    render() {
        let StepList = ["Patient Details", "Additional Info"]
        console.log("index", this.state.FormsDatas);
        console.log(this.props)
        return (
            <Box>
                <Box component={'div'} className='eMed_Remedi_form_tab'>
                    <IconButton onClick={() => { this.props?.history.push({ pathname: `/Insurance/` }) }}>
                        <ChevronLeftIcon color='primary' />
                    </IconButton>
                    <Tabs
                        value={this.state.SelectedTab}
                        onChange={(e, newValue) => this.setState({
                            SelectedTab: newValue,
                        })}
                    >
                        <Tab value="Preauthorisation" label="Pre-Authorisation" className='eMed_tab_header eMed_Remedi_form_tab_content' />
                        <Tab disabled={this.state.isNewRequest || !this.state.isPreAuthSubmitted || !this.props.history?.location?.state?.History?.is_pre_discharge_approved} value="Claim Submission" label="Claim Submission" className='eMed_tab_header eMed_Remedi_form_tab_content' />
                        {/* {this.state.isForEditView ? <Tab value="Payer detail" label="Payer detail" className='eMed_tab_header eMed_Remedi_form_tab_content' /> : null} */}
                        <Tab disabled={!this.state.isPreAuthSubmitted} value="Payer detail" label="Payer detail" className='eMed_tab_header eMed_Remedi_form_tab_content' />
                    </Tabs>
                </Box>
                {
                    this.state.SelectedTab === "Preauthorisation" ?
                        <Box>
                            <Box component={'div'} className='eMed_Remedi_form_stepper'>
                                <Stepper activeStep={this.state.FormStepperCount} sx={{ width: '50vw' }}>
                                    {StepList.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                            <Divider />
                            <Box>
                                {this.state.FormStepperCount === 0 ? <PreAuthFormOne MovetoNext={this.MovetoNext.bind(this)} MovetoClaimTab={this.MovetoClaimTab.bind(this)} FormsDatas={this.state.FormsDatas} showToastMsg={this.showToastMsg.bind(this)} Allstates={this.state} page={this.state.SelectedTab} History={this.state.HistoyData} isEdit={this.state.isForEditView} isView={this.state.isForView} history={this.props.history} /> : <PreAuthFormTwo MovetoNext={this.MovetoNext.bind(this)} FormsDatas={this.state.FormsDatas} showToastMsg={this.showToastMsg.bind(this)} page={this.state.SelectedTab} Payer={this.state.selectedPayerDetail} History={this.state.HistoyData} history={this.props.history} Allstates={this.state} />}
                            </Box>
                        </Box> :
                        this.state.SelectedTab === "Claim Submission" ?
                            // <ClaimSubmissionForm FormsDatas={this.state.FormsDatas} showToastMsg={this.showToastMsg.bind(this)} /> :
                            <Box>
                                <Box component={'div'} className='eMed_Remedi_form_stepper'>
                                    <Stepper activeStep={this.state.FormStepperCount} sx={{ width: '50vw' }}>
                                        {StepList.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>
                                <Divider />
                                <Box>
                                    {this.state.FormStepperCount === 0 ? <PreAuthFormOne MovetoNext={this.MovetoNext.bind(this)} MovetoClaimTab={this.MovetoClaimTab.bind(this)} FormsDatas={this.state.FormsDatas} showToastMsg={this.showToastMsg.bind(this)} Allstates={this.state} page={this.state.SelectedTab} History={this.state.HistoyData} isEdit={this.state.isForEditView} isView={this.state.isForView} history={this.props.history} /> : <PreAuthFormTwo MovetoNext={this.MovetoNext.bind(this)} FormsDatas={this.state.FormsDatas} showToastMsg={this.showToastMsg.bind(this)} page={this.state.SelectedTab} Payer={this.state.selectedPayerDetail} History={this.state.HistoyData} history={this.props.history} Allstates={this.state} />}
                                </Box>
                            </Box> :
                            this.state.SelectedTab === "Payer detail" ? <PayerDetail HomeData={this.state?.HomeData} /> : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(RemedinetForms)