import React, { Component } from 'react';
import { Autocomplete, Box, Button, Stack, Tooltip, Typography, TextField, Drawer, FormControl, InputLabel, Select, MenuItem, ButtonGroup, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import Loader from '../../../Components/Loader';
import { DateTime } from 'luxon';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';

class AntibioticSusReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            CultureReportData: [],
            groupData: [],
            testNameData: [],
            groupName: null,
            testName: null,
            FilterOpen: false,
            total_negative: 0,
            total_positive: 0,
            total_others: 0,
            disableBtn: false,
            patientName: '',
            moduleType: '',
            report_type: "positive",
        }
    }

    componentDidMount() {
        this.GetCultureTestReport()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }


    GetCultureTestReport = () => {
        try {
            let { moduleType } = this.state;
            this.LoaderFunction(true);
            this.setState({ disableBtn: true })
            RestAPIService.getAll(`${Serviceurls.ANTIBIOTIC_TEST_REPORT}?module=${moduleType}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&organisms_type=${this.state.report_type}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        let Datas = response.data.data ? response.data.data : []
                        let HeaderDatas = []
                        let ColumnData = []
                        if (Datas?.length > 0) {
                            HeaderDatas = Object.keys(Datas[0]).filter((list) => (list !== "bacteria_name" && list !== "total"))
                            ColumnData = Datas
                        }
                        this.setState({
                            HeaderData: HeaderDatas,
                            RowDatas: ColumnData,
                            isLoader: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    GetCultureTestReportExcel = () => {
        const { t } = this.props;
        try {
            this.setState({ disableBtn: true })
            RestAPIService.excelGet(`${Serviceurls.ANTIBIOTIC_TEST_REPORT}?module=${this.state.moduleType}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&organisms_type=${this.state.report_type}&export_type=excel`)
            .then((response) => {
                if (response.data) {
                    var pom = document.createElement('a');
                    var csvContent = response.data; //here we load our csv data 

                    let filename = response.headers["content-disposition"].split("filename=")[1]

                    var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                    var url = URL.createObjectURL(blob);
                    pom.href = url;
                    pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                    pom.click();
                    this.setState({ disableBtn: false })
                }
            }).catch((error) => {
                this.errorMessage(error.response.data?.message)
                this.setState({ disableBtn: false })
            })
        } catch (error) {
            this.errorMessage(t("PrintError"))
            this.setState({ disableBtn: false })
        }
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }


    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    closeFilter = () => {
        this.setState({
            FilterOpen: false
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <FormControl size='small' sx={{ width: '20vw' }}>
                            <InputLabel id="demo-simple-select-label">Select Module</InputLabel>
                            <Select
                                value={this.state.moduleType}
                                label="Select Result Type"
                                onChange={(e) => {
                                    this.setState({
                                        moduleType: e.target.value
                                    })
                                }}
                            >
                                <MenuItem value={""}>{"All"}</MenuItem>
                                <MenuItem value={"op"}>{"OP"}</MenuItem>
                                <MenuItem value={"ip"}>{"IP"}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                groupName: null,
                                testName: null,
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                patientName: '',
                                moduleType: '',
                            },
                                () => this.GetCultureTestReport())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid='Census_Report_FilterSearch_btn'
                        onClick={() => {
                            this.GetCultureTestReport()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    render() {
        console.log(this.state);
        this.state.CultureReportData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <ButtonGroup sx={{ marginRight: '1vw' }}>
                            <Button variant={this.state.report_type === "positive" ? 'contained' : 'outlined'} sx={{ width: "8.5vw", textTransform: 'capitalize' }} onClick={() => {
                                this.setState({ report_type: "positive" }, () => { this.GetCultureTestReport() })
                            }}>{t("Positive")}</Button>
                            <Button variant={this.state.report_type === "negative" ? 'contained' : 'outlined'} sx={{ width: "8.5vw", textTransform: 'capitalize' }} onClick={() => {
                                this.setState({ report_type: "negative" }, () => { this.GetCultureTestReport() })
                            }}>{t("Negative")}</Button>
                        </ButtonGroup>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.GetCultureTestReportExcel() }}>
                                <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                            </Button>
                        </Box>

                    </Box>
                </Box>
                <Box component={'div'} sx={{ height: '66vh', width: '92vw', margin: 'auto', backgroundColor: 'white' }}>
                    <Paper sx={{ width: '100%', height: '100%' }}>
                        <TableContainer sx={{ height: '65vh' }}>
                            <Table stickyHeader size='small' >
                                <TableHead>
                                    {this.state.HeaderData?.length > 0 ?
                                        <TableRow>
                                            <TableCell id='eMed_config_TableRow1' className={"eMed_TbL_stickyHeader"} align='center'>{"Organism"}</TableCell>
                                            <TableCell id='eMed_config_TableRow1' className={"eMed_TbL_stickyHeader"} align='center'>{"Total"}</TableCell>
                                            {this.state.HeaderData.map((item) => (
                                                <TableCell id='eMed_config_TableRow1' align='center'>{item}</TableCell>
                                            ))}
                                        </TableRow> : null
                                    }

                                </TableHead>
                                <TableBody>
                                    {this.state.RowDatas?.length > 0 ? this.state.RowDatas?.map((item) => {
                                        return (
                                            <TableRow>
                                                <TableCell align='center' sx={{ fontWeight: 600 }}>{item?.bacteria_name ? item?.bacteria_name : ""}</TableCell>
                                                <TableCell align='center'>{item?.total ? item?.total : ""}</TableCell>
                                                {this.state.HeaderData?.map((list, index) => (<TableCell align='center'>{item[list]}</TableCell>))}
                                            </TableRow>
                                        )
                                    }) : <Typography sx={{ marginTop: "10vw", marginLeft: "40vw" }}>No Records To Be Shown</Typography>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(AntibioticSusReport)

