import { Autocomplete, Box, Button, Checkbox, Drawer, FormControl, FormControlLabel, Grow, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';

import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { t } from 'i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Toolbar } from 'mui-rte';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import { formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions';
import { DateTime } from 'luxon';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import '../../FrontOffice/FOReports/reports.css'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';

class OTSurgeryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CancelledAppointment: 0,
      FilterOpen: false,
      page: 0,
      pageSize: 10,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      startDate: null,
      dateIndex: 1,
      appointmentPatientData: [],
      appointmentDoctors: null,
      appointmentTotalPatient: null,
      TotalReschedule: null,
      TotalNoShow: null,
      TotalCompleted: null,
      TotalCancelled: null,
      appointmentTypeList: [],
      doctorNameList: [],
      specializationList: [],
      showBox: false,
      showNPcard: false,
      patientType: [],
      selectedSurgeryStatus: [],
      disableBtn : false,
    }

  }
  componentDidMount() {
    this.getCancelledData()
  }



  getCancelledData = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.GET_OT_CancelledReport + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&surgery_status=Cancelled`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              appointmentPatientData: response?.data?.data ? response?.data?.data : [],
              CancelledAppointment: response?.data?.cancelled,
            }, () => { this.LoaderFunction(false) })
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error?.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error?.message)
    }
  }

  PrintCancelledData() {
    try {
      RestAPIService.getAll(Serviceurls.GET_OT_CancelledReport + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&surgery_status=Cancelled&export_type=print`).
        then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          }
        }).catch((error) => {
          this.errorMessage(error?.message)
        })
    } catch (e) {
      this.errorMessage(e?.message)
    }
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }
  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.fromDate) },
      { label: "To Date", value: formatDate(this.state.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>

    )
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return(
    <Box component={"div"} className='eMed_rts_filter_container'>
      <Box component={"div"} className='eMed_filter_Top'>
        <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
        <Button  emed_tid = {"LabCloseButton_testID"} onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
          <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
        </Button>
      </Box>
      <Box className='eMed_rts_middle'>
        <Box className='eMed_date_filter'>
          <CommonDatePicker
            FromDate={this.state.fromDate}
            ToDate={this.state.toDate}
            DateIndex={this.state.dateIndex}
            SelectedDates={this.FilterDate.bind(this)}
            HideAllMenu={true} />
        </Box>
      </Box>
      <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
        <Button variant='outlined' size="small" emed_tid ={"Clear_testID01"}
          onClick={() => {
            this.setState({
              fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
              toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
              dateIndex: 1,
               selectedSurgeryStatus: []
            }, () =>{ this.getCancelledData()})
          }}
          id="eMed_Config_btn">{t("Clear")}</Button>
        <Button variant='contained' size="small" emed_tid ={"Search_TestId01"}
          onClick={() => {
            this.getCancelledData()
            this.setState({ FilterOpen: false })
          }}
          id="eMed_Config_btn">{t("Search")}</Button>
      </Stack>
    </Box>
    )
  }
  render() {
    const { t } = this.props
    this.state.appointmentPatientData?.forEach((element, index) => { element.sno = index + 1 })
    const column = [
      {
        field: "patient_name", headerName: t("Patient Detail"), flex: 0.162,
        renderCell: (params) => (<CommonPatientDetails data={params?.row} showDetailed={true} />)
      },
      {
        field: "package_name", headerName: t("Package Name"), flex: 0.123,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.package_name ? params?.row?.package_name?.length > 20 ?
          <div><Tooltip placement='top' title={params?.row?.package_name} arrow><div>{params?.row?.package_name.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.package_name : "-"}</Box>)
      },
      {
        field: "surgery_date", headerName: t("Surgery Date/Time"), flex: 0.142, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.surgery_date ? (params?.row?.surgery_date) : '-'}</Box>)
      },
      {
        field: "surgery_name", headerName: t("Surgery Name"), flex: 0.141,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.surgery_name ? params?.row?.surgery_name?.length > 20 ?
          <div><Tooltip placement='top' title={params?.row?.surgery_name} arrow><div>{params?.row?.surgery_name.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.surgery_name : "-"}</Box>)
      },

      {
        field: "anaesthetist", headerName: t("Anaesthetist Name"), flex: 0.142,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.anaesthetist ? params?.row?.anaesthetist?.length > 20 ?
          <div><Tooltip placement='top' title={params?.row?.anaesthetist} arrow><div>{params?.row?.anaesthetist.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.anaesthetist : "-"}</Box>)
      },
      {
        field: "cancel_date_time", headerName: t("Cancelled Date & Time"), flex: 0.142, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.cancel_date_time ? (params?.row?.cancel_date_time) : '-'}</Box>)
      },
      {
        field: "cancel_reason", headerName: t("Cancel Reason"), flex: 0.142, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.cancel_reason ? params?.row?.cancel_reason?.length >20?
           <div><Tooltip placement ={"top"} title ={params?.row?.cancel_reason} arrow >{<div>{params?.row?.cancel_reason?.slice(0,20)+"..."}</div>}</Tooltip></div> : params?.row?.cancel_reason : '-'}</Box>)
      },
    ]
    return (
      <Box component={'div'} className='eMed_rts_container' >
        <Box component={"div"} className='eMed_Top_Container'>
          <Box component={"div"} className='eMed_Cards_Container'>
            {AmountsCard(t("Cancelled"), this.state.CancelledAppointment, true)}
          </Box>
          <Box component={"div"} className='eMed_Action_Container'>
            <Box component={"div"} className='eMed_Filter_Icon_div'>
              <Button className='eMed_usrconf_btn' emed_tid ={"Filter_TestID01"} onClick={() => { this.setState({ FilterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
              </Button>
            </Box>
            <Button className='eMed_usrconf_btn' emed_tid ={"LabPrintIcon_TestID01"}  onClick={() => { this.PrintCancelledData() }}>
              <img src={ImagePaths.LabPrintIcon.default} alt="upload" className='eMed_action_img' />
            </Button>
          </Box>
        </Box>
        <Box component={'div'} className='eMed_rts_table'>
          <DataGrid
            rows={this.state.appointmentPatientData}
            columns={column}
            getRowId={(row) => row.sno}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
            loading={this.state.isLoader}
          />

        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick : this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

}
export default withTranslation()(OTSurgeryReport)