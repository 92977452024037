import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import { ImagePaths } from '../../Utility/ImagePaths';
import DescriptionIcon from '@mui/icons-material/Description';
import ClearIcon from '@mui/icons-material/Clear';
import { PreAuthRequestData, RemediServiceUrls } from './RemedinetConstants';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import Loader from '../../Components/Loader'
import { ShowBase64Document } from '../../Components/Common Components/CommonComponents';
import VisibilityIcon from '@mui/icons-material/Visibility';

class PreAuthFormTwo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            CheckValidation: false,
            FormsDatas: this.props?.FormsDatas,
            selectedTab: this.props?.page === "Preauthorisation" ? "Preauthorisation" : "Claim Submission",
            PayerDetail: this.props?.Payer,
            ShowDocument: null,
            disableAllDatas: this.props?.Allstates?.disableAllDatas ? true : false,

        }
        this.uploadAttachment = React.createRef();
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                // FormsDatas: this.props?.FormsDatas
                selectedTab: this.props?.page === "Preauthorisation" ? "Preauthorisation" : "Claim Submission",
                disableAllDatas: this.props?.Allstates?.disableAllDatas ? true : false,
            })
        }
    }
    handleChangeTextBox = (e, key) => {
        let FormData = this.state?.FormsDatas
        switch (key) {
            case 'ip_number':
                FormData["ip_number"] = e.target.value
                break
            default:
                FormData[key] = e.target.value
                break;
        }
        this.setState({
            FormsDatas: FormData,
            CheckValidation: false
        })
    }


    FormTextBox = (label, key, WidthinVW = 18, isMandatory = false, MaxLenth = 50, Disable = false) => {
        try {
            return (
                <Box className='eMed_Form_textBoxes' sx={{ width: `${WidthinVW}vw` }}>
                    <TextField
                        label={`${label}  ${isMandatory ? "*" : ""}`}
                        inputProps={{ maxLength: MaxLenth, emed_tid: `preauth_${key}` }}
                        disabled={Disable || this.state.disableAllDatas}
                        variant="standard"
                        sx={{ width: `${WidthinVW - 2}vw` }}
                        error={isMandatory && this.state?.CheckValidation && !this.state?.FormsDatas[key]}
                        value={this.state?.FormsDatas[key] ? this.state?.FormsDatas[key] : ""}
                        onChange={(e) => { this.handleChangeTextBox(e, key) }} />
                </Box>
            )
        }
        catch (e) {

        }
    }

    FormDateSelect = (label, key, WidthinVW = 18, isMandatory = false, Disable = false) => {
        try {
            return (
                <Box className='eMed_Form_textBoxes' sx={{ width: `${WidthinVW}vw` }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={Disable || this.state.disableAllDatas}
                            value={this.state?.FormsDatas[key] ? this.state?.FormsDatas[key] : null}
                            name={"datePicker"}
                            inputFormat='DD-MM-YYYY'
                            views={["year", "month", "day"]}
                            onChange={
                                (newDate) => {
                                    let FormData = this.state?.FormsDatas
                                    FormData[key] = newDate
                                    this.setState({
                                        FormsDatas: FormData
                                    })
                                }}
                            renderInput={(params) =>
                                <div style={{ position: "relative", display: "inline-block" }} >
                                    <TextField
                                        autoComplete='off'
                                        onKeyDown={(e) => e.preventDefault()}
                                        {...params}
                                        size='small'
                                        label={`${label}  ${isMandatory ? "*" : ""}`}
                                        variant="standard"
                                        sx={{ width: `${WidthinVW - 2}vw` }}
                                        inputProps={{
                                            ...params.inputProps,
                                            'emed_tid': `preauth_${key}`,
                                        }}
                                        error={isMandatory && this.state?.CheckValidation && !this.state?.FormsDatas[key]} />
                                    <IconButton style={{ position: "absolute", top: ".6rem", margin: "auto", right: "1rem", display: this.state?.FormsDatas[key] ? 'inline' : 'none' }}
                                       disabled={this.state.disableAllDatas}
                                       onClick={() => {
                                            let FormData = this.state?.FormsDatas
                                            FormData[key] = null
                                            if (key === "date_of_birth") {
                                                FormData["age_year"] = ""
                                                FormData["age_month"] = ""
                                                FormData["age_day"] = ""
                                            }
                                            this.setState({
                                                FormsDatas: FormData
                                            })
                                        }}>
                                        <ClearIcon />
                                    </IconButton>
                                </div>}
                        />
                    </LocalizationProvider>
                </Box>
            )
        }
        catch (e) {

        }
    }


    FormTwoRows = (label, key, Disable = false) => {
        let DurationList = ["Day(s)", "Month(s)", "Year(s)"]
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '90vw' }}>
                <Typography width={"10vw"}>{label}</Typography>
                <FormControl sx={{ marginLeft: "1vw" }}>
                    <RadioGroup
                        value={this.state?.FormsDatas[key]?.radio_value ? this.state?.FormsDatas[key]?.radio_value : ""}
                        sx={{ display: 'flex', flexDirection: 'row' }}
                        onChange={(e) => {
                            let FormData = this.state?.FormsDatas
                            let Obj = FormData[key] ? FormData[key] : {}
                            Obj.radio_value = e.target.value
                            if (e.target.value === "Not Metioned" || e.target.value === "false") {
                                Obj.duration_count = ""
                                Obj.duration_type = ""
                                Obj.remarks = ""
                            }
                            FormData[key] = Obj
                            this.setState({
                                FormsDatas: FormData
                            })
                        }}
                    >
                        <FormControlLabel value="true" control={<Radio disabled={Disable || this.state.disableAllDatas} size='small' />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio disabled={Disable || this.state.disableAllDatas} size='small' />} label="No" />
                        <FormControlLabel value="Not Metioned" control={<Radio disabled={Disable || this.state.disableAllDatas} size='small' />} label="Not Metioned" />
                    </RadioGroup>
                </FormControl>
                {key === "congenital_disease" ?
                    <Box sx={{ width: "50.5vw", paddingTop: '1vw', display: 'flex', alignItems: 'center' }}>
                        <Typography fontSize={"1vw"} paddingLeft={"1vw"}>Consanguineous Marriage</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <FormControl sx={{ marginLeft: "1vw" }}>
                                <RadioGroup
                                    value={this.state?.FormsDatas?.consanguineous_marriage ? this.state?.FormsDatas?.consanguineous_marriage : "false"}
                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                    onChange={(e) => {
                                        let FormData = this.state?.FormsDatas
                                        FormData.consanguineous_marriage = e.target.value
                                        this.setState({
                                            FormsDatas: FormData
                                        })
                                    }}
                                >
                                    <FormControlLabel value="true" control={<Radio disabled={Disable || this.state.disableAllDatas} size='small' />} label="Yes" />
                                    <FormControlLabel value="false" control={<Radio disabled={Disable || this.state.disableAllDatas} size='small' />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box> :
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Box sx={{ width: `7vw`, marginRight: '1vw' }}>
                                <TextField
                                    disabled={this.state?.FormsDatas[key]?.radio_value === "Not Metioned" || this.state?.FormsDatas[key]?.radio_value === "false" || Disable || this.state.disableAllDatas}
                                    label={"Duration"}
                                    variant="standard"
                                    // error={isMandatory && this.state?.CheckValidation && !this.state?.FormsDatas[key]}
                                    value={this.state?.FormsDatas[key]?.duration_count ? this.state?.FormsDatas[key]?.duration_count : ""}
                                    inputProps={{ maxLength: 3, emed_tid: `additional_dur_${key}` }}
                                    onChange={(e) => {
                                        let FormData = this.state?.FormsDatas
                                        let Obj = FormData[key] ? FormData[key] : {}
                                        Obj.duration_count = e.target.value
                                        if (!Obj?.duration_type) {
                                            Obj.duration_type = "Day(s)"
                                        }
                                        if (e.target.value === "") {
                                            Obj.duration_type = ""
                                        }
                                        FormData[key] = Obj
                                        this.setState({
                                            FormsDatas: FormData
                                        })
                                    }} />
                            </Box>
                            <Select
                                size='small'
                                sx={{ width: '7vw', paddingTop: '1vw', paddingLeft: '0.5vw' }}
                                variant='standard'
                                value={this.state?.FormsDatas[key]?.duration_type ? this.state?.FormsDatas[key]?.duration_type : ""}
                                label={"Duration"}
                                disabled={this.state?.FormsDatas[key]?.radio_value === "Not Metioned" || this.state?.FormsDatas[key]?.radio_value === "false" || Disable || this.state.disableAllDatas}
                                onChange={(e) => {
                                    let FormData = this.state?.FormsDatas
                                    let Obj = FormData[key] ? FormData[key] : {}
                                    Obj.duration_type = e.target.value
                                    FormData[key] = Obj
                                    this.setState({
                                        FormsDatas: FormData
                                    })
                                }}
                            >
                                {
                                    DurationList.map((list, index) => (
                                        <MenuItem key={index} value={list}>{list}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>
                        <TextField
                            variant="standard"
                            sx={{ width: `30vw` }}
                            label={"Remarks"}
                            inputProps={{ maxLength: 200, emed_tid: `additional_remarks_${key}` }}
                            disabled={this.state?.FormsDatas[key]?.radio_value === "Not Metioned" || this.state?.FormsDatas[key]?.radio_value === "false" || Disable || this.state.disableAllDatas}
                            // error={isMandatory && this.state?.CheckValidation && !this.state?.FormsDatas[key]}
                            value={this.state?.FormsDatas[key]?.remarks ? this.state?.FormsDatas[key]?.remarks : ""}
                            onChange={(e) => {
                                let FormData = this.state?.FormsDatas
                                let Obj = FormData[key] ? FormData[key] : {}
                                Obj.remarks = e.target.value
                                FormData[key] = Obj
                                this.setState({
                                    FormsDatas: FormData
                                })
                            }} />
                    </>
                }
            </Box>
        )
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    ValidateForm() {
        try {
            let FormDatas = this.state.FormsDatas
            if (
                (FormDatas?.admission_type && (FormDatas?.admission_type?.id != 5 || (FormDatas?.cause_of_injury && FormDatas?.accident_fir && FormDatas?.accident_date))) &&
                (this.state.selectedTab === "Preauthorisation" ? FormDatas?.attachments?.length > 0 : FormDatas?.claim_attachments?.length > 0)
            ) {
                if (this.state.FormsDatas?.status_id?.id === "2") {
                    let Formdata = this.state.FormsDatas
                    Formdata.dod_date = new Date()
                    Formdata.dod_time = new Date()
                    this.setState({
                        FormDatas: Formdata
                    }, () => {
                        let RequestData = PreAuthRequestData(this.state.FormsDatas, false, this.state.selectedTab, this.state.PayerDetail)
                        console.log((RequestData));
                        this.postPreAuthForm(RequestData)
                    })
                } else {
                    let RequestData = PreAuthRequestData(this.state.FormsDatas, false, this.state.selectedTab, this.state.PayerDetail)
                    console.log((RequestData));
                    this.postPreAuthForm(RequestData)
                }
            } else {
                if (!(FormDatas?.admission_type?.id != 5 || (FormDatas?.cause_of_injury && FormDatas?.accident_fir && FormDatas?.accident_date))) {
                    this.props?.showToastMsg("Enter All Mandatory Fields", true)
                } else {
                    this.props?.showToastMsg("Attach atleast one attachment", true)
                }
            }
        }
        catch (e) {

        }
    }

    // dataURItoByteArray = async(e) => {
    //     let states = this.state?.FormsDatas
    //     let Array = states.attachments ? states.attachments : []

    //     const reader = new FileReader();
    //     var file = e.target.files[0]

    //     reader.onload = (event) => {
    //     const arrayBuffer = event.target.result;
    //     const byteArray = new Uint8Array(arrayBuffer);

    //     console.log(byteArray)
    //     Array.push({ filecontent: byteArray, filename: e.target.files[0]?.name, remarks: "" })
    //     states.attachments = Array
    //     this.setState({ states })
    //     };
    //     reader.readAsArrayBuffer(file);
    // }

    dataURItoByteArray = async (e) => {
        let { FormsDatas } = this.state;
        // let attachments = (this.state.selectedTab === "Preauthorisation") ? (FormsDatas.attachments ? FormsDatas.attachments : []) : (FormsDatas.claim_attachments ? FormsDatas.claim_attachments : []);

        const reader = new FileReader();
        const file = e.target.files[0];

        if (!file) {
            console.error("No file selected");
            return;
        }

        reader.onerror = (error) => {
            console.error("File reading error: ", error);
        };

        reader.onload = (event) => {
            const arrayBuffer = event.target.result;
            const byteArray = new Uint8Array(arrayBuffer);

            console.log(byteArray);

            if (this.state.selectedTab === "Preauthorisation") {
                FormsDatas.attachments.push({
                    filecontent: byteArray,
                    filename: file.name,
                    remarks: ""
                });
            } else {
                FormsDatas.claim_attachments.push({
                    filecontent: byteArray,
                    filename: file.name,
                    remarks: ""
                });
            }


            this.setState({
                FormsDatas: {
                    ...FormsDatas,
                }
            });
        };

        reader.readAsArrayBuffer(file);
    };

    postPreAuthForm = (data) => {
        let { history } = this.props
        this.LoaderFunction(true)
        try {
            let PreAuthData = {
                "url": RemediServiceUrls.REMEDI_BASE_URL + `${this.state.selectedTab === "Claim Submission" ? RemediServiceUrls.SUBMIT_CLAIMS : RemediServiceUrls.PRE_AUTH_POST}`,
                "payload": data
            }
            RestAPIService.create(PreAuthData, Serviceurls.REMEDINET_BACKEND).
                then((response) => {
                    if (response?.data?.Status === 'success') {
                        this.setState({
                            isloader: false
                        }, () => {
                            this.successMessage(this.state.selectedTab === "Claim Submission" ? "Claim Submission Successfully Submitted" : "Pre-Authorisation Successfully Submitted")
                            history.push({ pathname: '/Insurance' })
                        })
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error?.message)
                    }
                })
        } catch (e) {

        }
    }

    handleDocViewClose() {
        this.setState({
            ShowDocument: null
        })
    }

    render() {
        console.log(this.state.FormsDatas);
        console.log("two", this.state, this.props);
        return (
            <Box>
                <Box className="eMEd_Form_body_div">
                    <Box className='eMed_Form_Div_body'>
                        <Typography className='eMed_Form_Div_Title'>Past Illness And Chronic Details</Typography>
                        <Box className='eMed_Form_body_inputs_div'>
                            {this.FormTwoRows("Hypertension", "hypertension", false)}
                            {this.FormTwoRows("Diabetes", "diabetes", false)}
                            {this.FormTwoRows("Heart Disease", "heart_disease", false)}
                            {this.FormTwoRows("Copd", "copd", false)}
                            {this.FormTwoRows("Stdhiv", "stdhiv", false)}
                            {this.FormTwoRows("Asthma", "asthma", false)}
                            {this.FormTwoRows("Epilepsy", "epilepsy", false)}
                            {this.FormTwoRows("Cancer", "cancer", false)}
                            {this.FormTwoRows("Arthritis", "arthritis", false)}
                            {this.FormTwoRows("Congenital Disease", "congenital_disease", false)}
                            {this.FormTwoRows("Other diseases", "other_diseases", false)}
                            {this.FormTwoRows("Alcohol Drugs", "alcohol_drugs", false)}
                            {this.FormTwoRows("Smoking History", "smoking_history", false)}
                        </Box>
                    </Box>
                    <Box className='eMed_Form_Div_body'>
                        <Typography className='eMed_Form_Div_Title'>Injury Details</Typography>
                        <Box className='eMed_Form_body_inputs_div'>
                            {this.FormTextBox("Cause of Injury", "cause_of_injury", 18, this.state?.FormsDatas?.admission_type?.id == 5, 150, false)}
                            <Box sx={{ width: "18vw", paddingTop: '1vw' }}>
                                <Typography fontSize={"0.8vw"} paddingLeft={"1vw"} fontWeight={600}>Medico legal mlc {this.state?.FormsDatas?.admission_type?.id == 5 ? "*" : ""}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormControl sx={{ marginLeft: "1vw" }}>
                                        <RadioGroup
                                            value={this.state?.FormsDatas?.medico_legel_mlc ? this.state?.FormsDatas?.medico_legel_mlc : ""}
                                            sx={{ display: 'flex', flexDirection: 'row' }}
                                            onChange={(e) => {
                                                let FormData = this.state?.FormsDatas
                                                FormData.medico_legel_mlc = e.target.value
                                                this.setState({
                                                    FormsDatas: FormData
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="true" control={<Radio disabled={this.state.disableAllDatas} size='small' />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio disabled={this.state.disableAllDatas} size='small' />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box sx={{ width: "18vw", paddingTop: '1vw' }}>
                                <Typography fontSize={"0.8vw"} paddingLeft={"1vw"} fontWeight={600}>Medico legal non-mlc {this.state?.FormsDatas?.admission_type?.id == 5 ? "*" : ""}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormControl sx={{ marginLeft: "1vw" }}>
                                        <RadioGroup
                                            value={this.state?.FormsDatas?.medico_legel_non_mlc ? this.state?.FormsDatas?.medico_legel_non_mlc : ""}
                                            sx={{ display: 'flex', flexDirection: 'row' }}
                                            onChange={(e) => {
                                                let FormData = this.state?.FormsDatas
                                                FormData.medico_legel_non_mlc = e.target.value
                                                this.setState({
                                                    FormsDatas: FormData
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="true" control={<Radio disabled={this.state.disableAllDatas} size='small' />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio disabled={this.state.disableAllDatas} size='small' />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box sx={{ width: "18vw", paddingTop: '1vw' }}>
                                <Typography fontSize={"0.8vw"} paddingLeft={"1vw"} fontWeight={600}>Accident FIR {this.state?.FormsDatas?.admission_type?.id == 5 ? "*" : ""}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormControl sx={{ marginLeft: "1vw" }}>
                                        <RadioGroup
                                            value={this.state?.FormsDatas?.accident_fir ? this.state?.FormsDatas?.accident_fir : ""}
                                            sx={{ display: 'flex', flexDirection: 'row' }}
                                            onChange={(e) => {
                                                let FormData = this.state?.FormsDatas
                                                FormData.accident_fir = e.target.value
                                                this.setState({
                                                    FormsDatas: FormData
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="true" control={<Radio disabled={this.state.disableAllDatas} size='small' />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio disabled={this.state.disableAllDatas} size='small' />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Box>
                            {/* {this.FormTextBox("Accident FIR", "accident_fir", 18, this.state?.FormsDatas?.admission_type?.id == 5, 100, false)} */}
                            {this.FormDateSelect("Accident Date", "accident_date", 18, this.state?.FormsDatas?.admission_type?.id == 5, false)}
                            {this.FormTextBox("Member Rank", "member_rank", 18, false, 100, false)}
                            {/* {this.FormTextBox("Entitlement ID", "entitlement_id", 18, false, 50, false)} */}
                            {/* {this.FormTextBox("Basic Pay", "basic_pay", 18, false, 10, false)} */}
                            {/* {this.FormTextBox("Is Bill details packages", "bill_details_package", 18, false, 50, false)} */}
                            {/* {this.FormTextBox("Query delay reason id", "query_delay_reason_id", 18, false, 50, false)} */}
                        </Box>
                    </Box>
                    <Box className='eMed_Form_Div_body'>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography className='eMed_Form_Div_Title'>Attachments</Typography>
                            <input
                                onChange={(e) => {
                                    // let states = this.state?.FormsDatas
                                    // let Array = states.attachments ? states.attachments : []
                                    this.dataURItoByteArray(e)
                                    // Array.push({ filecontent: content, filename: e.target.files[0]?.name, remarks: "" })
                                    // states.attachments = Array
                                    // this.setState({ states })
                                    // e.target.value = ''
                                }}
                                hidden
                                accept="application/pdf, image/jpg, image/jpeg, image/png"
                                type="file"
                                ref={this.uploadAttachment} />
                            <Button size='small' disabled={this.state.selectedTab === "Preauthorisation" ? this.state.disableAllDatas : (this.state.FormsDatas?.claim_attachments?.length === 1)} variant='contained' sx={{ marginLeft: '2vw', textTransform:'capitalize' }} onClick={() => this.uploadAttachment.current.click()}>
                                {this.state.selectedTab === "Preauthorisation" ? "Add Attachment" : "Add Consolidated Attachment"}
                            </Button>
                        </Box>
                        <Box>
                            {
                                this.state.selectedTab === "Preauthorisation" ?
                                    this.state.FormsDatas?.attachments?.length > 0 ?
                                        this.state.FormsDatas?.attachments.map((list, index) => (
                                            <Box className='eMed_document_div'>
                                                <DescriptionIcon color='primary' />
                                                <Typography width={"28vw"} paddingX={"1vw"}>{list?.filename?.length > 40 ? <Tooltip placement="top" title={list?.filename} arrow><div>{list?.filename.slice(0, 40) + "..."}</div></Tooltip> : list?.filename ? list?.filename : "-"}</Typography>
                                                <TextField
                                                    label={`Attachment Remarks`}
                                                    variant="standard"
                                                    sx={{ width: `35vw` }}
                                                    value={list?.remarks ? list?.remarks : ""}
                                                    disabled={this.state.disableAllDatas}
                                                    inputProps={{ maxLength: 250 }}
                                                    onChange={(e) => {
                                                        let states = this.state.FormsDatas.attachments
                                                        states[index].remarks = e.target.value
                                                        this.setState({ states })
                                                    }} />
                                                {
                                                    (typeof (list?.filecontent) === "string") ?
                                                        <IconButton
                                                            sx={{ visibility: typeof (list?.filecontent) === "string" ? 'visible' : 'hidden' }}
                                                            onClick={() => { this.setState({ ShowDocument: list }) }}
                                                        >
                                                            <VisibilityIcon color='primary' />
                                                        </IconButton> :
                                                        <IconButton
                                                            disabled={this.state.disableAllDatas}
                                                            onClick={() => {
                                                                let states = this.state.FormsDatas.attachments
                                                                states.splice(index, 1)
                                                                this.setState({ states })
                                                            }}>
                                                            <ClearIcon color='error' />
                                                        </IconButton>
                                                }
                                            </Box>
                                        ))

                                        : null
                                    : this.state.FormsDatas?.claim_attachments?.length > 0 ?
                                        this.state.FormsDatas?.claim_attachments.map((list, index) => (
                                            <Box className='eMed_document_div'>
                                                <DescriptionIcon color='primary' />
                                                <Typography width={"28vw"} paddingX={"1vw"}>{list?.filename?.length > 40 ? <Tooltip placement="top" title={list?.filename} arrow><div>{list?.filename.slice(0, 40) + "..."}</div></Tooltip> : list?.filename ? list?.filename : "-"}</Typography>
                                                <TextField
                                                    label={`Attachment Remarks`}
                                                    variant="standard"
                                                    sx={{ width: `35vw` }}
                                                    value={list?.remarks ? list?.remarks : ""}
                                                    inputProps={{ maxLength: 250 }}
                                                    onChange={(e) => {
                                                        let states = this.state.FormsDatas.claim_attachments
                                                        states[index].remarks = e.target.value
                                                        this.setState({ states })
                                                    }} />
                                                {
                                                    (typeof (list?.filecontent) === "string") ?
                                                        <IconButton
                                                            sx={{ visibility: typeof (list?.filecontent) === "string" ? 'visible' : 'hidden' }}
                                                            onClick={() => { this.setState({ ShowDocument: list }) }}
                                                        >
                                                            <VisibilityIcon color='primary' />
                                                        </IconButton> :
                                                        <IconButton
                                                            // disabled={this.state.disableAllDatas}
                                                            onClick={() => {
                                                                let states = this.state.FormsDatas.claim_attachments
                                                                states.splice(index, 1)
                                                                this.setState({ states })
                                                            }}>
                                                            <ClearIcon color='error' />
                                                        </IconButton>
                                                }
                                            </Box>
                                        ))

                                        : null
                            }
                        </Box>
                    </Box>
                </Box>
                <Box className="eMEd_Form_Foot_Btn_div">
                    {/* <Button size='small' sx={{ textTransform: 'capitalize' }} variant='outlined'>Save Draft</Button> */}
                    <Button size='small' sx={{ textTransform: 'capitalize' }} variant='outlined' onClick={() => {
                        this.props.MovetoNext(this.state.FormsDatas, 0)
                    }}>Back
                    </Button>
                    <Box>
                        <Button
                            size='small'
                            sx={{ textTransform: 'capitalize', marginLeft: '1vw' }}
                            variant='contained'
                            disabled={this.state.selectedTab === "Claim Submission" ? false : this.state.disableAllDatas}
                            onClick={() => {
                                this.setState({
                                    CheckValidation: true
                                }, () => { this.ValidateForm() })
                            }}>Submit
                        </Button>
                    </Box>
                </Box>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.ShowDocument ? <ShowBase64Document Data={this.state.ShowDocument} handleClose={this.handleDocViewClose.bind(this)} /> : null}
            </Box>
        )
    }
}
export default withTranslation()(PreAuthFormTwo)