import React, { Component } from 'react'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { Colors } from '../../../Styles/Colors'
import { DataGrid } from '@mui/x-data-grid'
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'


class CompletedReports extends Component {
    constructor(props) {
        super(props)
        this.state = {
            testDatas: [],
            page: 0,
            pageSize: 10,
            isErrorMsg: false,
            isErrorMsgText: '',
            Patient_Details: this.props.patientDetails ? this.props.patientDetails : {},
            isLoader: false,
        }
    }

    componentDidMount() {
        this.getCompletedTests()
    }

    getCompletedTests = () => {
        try {
            let id = this.state.Patient_Details?.id ? this.state.Patient_Details?.id : null
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_COMPLETED_TEST + `?result_summary_id=${id}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.setState({
                            testDatas: response?.data?.data?.test_data ? response?.data?.data?.test_data : []
                        })
                    }
                })
                .catch(e => {
                    this.LoaderFunction(false)
                    if (e.response.data.status === 'fail') {
                        this.errorMessage(e?.response?.data?.message)
                    } else {
                        this.errorMessage(e?.message)
                    }
                })
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e?.message)
        }
    }

    getResultPrint = (id) => {
        try {
            let resultSumID = this.state.Patient_Details?.id ? this.state.Patient_Details?.id : null
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + `?result_summary_id=${resultSumID}&result_item_id=${id}`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ isPrintClicked: false })
                }).catch((e) => {
                        this.errorMessage(e.message)
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    gridToolBar = () => {
        return (
            <Box>
                <CommonGridToolBarWithFilterText />
            </Box>

        )
    }

    renderTable = () => {
        const { t } = this.props
        this.state.testDatas.forEach((element, index) => element.sno = index + 1)
        const columns = [
            { field: "sno", sortable: false, flex: 0.10, headerName: "S.No" },
            {
                field: "result_completed_date", headerName: "Date", flex: 0.40, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.result_completed_date ? params?.row?.result_completed_date : '-'}</Box>)
            },
            {
                field: "description", headerName: "Service Name", flex: 0.40, type: 'string',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.description ? params?.row?.description : '-'}</Box>)
            },
            {
                field: "result", headerName: "Result", flex: 0.10, type: "string",
                renderCell: (params) => (
                    <Box component={'div'}>
                        <Tooltip title={'Print'} placement='top'>
                            <IconButton onClick={() => {
                               this.getResultPrint(params?.row?.test_ids)
                            }}>
                                <img style={{ width: '2vw', height: '2vw' }} src={ImagePaths.PrintIcons.default} alt='print' />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )
            },
        ]
        return (
            <Box component={'div'} sx={{ height: '61vh', width: '92vw', margin: 'auto' }}>
                <DataGrid
                    rows={this.state.testDatas}
                    columns={columns}
                    getRowId={(row) => row['sno']}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    localeText={{
                        toolbarColumns: "",
                        toolbarDensity: "",
                        toolbarExport: "",
                        toolbarFilters: "",
                        toolbarExportPrint: ""
                    }}
                    components={{
                        Toolbar: this.gridToolBar,
                        NoRowsOverlay: () => (
                            <Stack className='eMed_conf_nodata'>
                                {t("NoRecordsFound")}
                            </Stack>
                        )
                    }}
                    onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                    onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                    rowsPerPageOptions={[10, 20, 30]}
                    pagination
                    headerHeight={40}
                    disableSelectionOnClick
                    loading={this.state.isLoader}
                />
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    render() {
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background, width: '94vw', height: '78vh', padding: '1vw' }}>
                <Box component={'div'} sx={{ backgroundColor: 'white', width: '92.5vw', height: '75vh', border: '1px solid lightgray', borderRadius: '.3vw' }}>
                    <Box component={'div'} sx={{ margin: '1vw' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '.9vw' }}>{this.state.Patient_Details?.patient_name + ` (${this.state.Patient_Details?.patient_age} / ${this.state.Patient_Details?.patient__gender} )`.toUpperCase()}</Typography>
                        <Typography sx={{ fontSize: '.8vw', color: "#888888" }}>{`${"UHID"} : ${this.state.Patient_Details?.ip_number ? `${this.state.Patient_Details?.patient__patient_account_number} (IP No : ${this.state.Patient_Details?.ip_number})` : this.state.Patient_Details?.patient__patient_account_number}`}</Typography>
                        <Typography sx={{ fontSize: '.8vw', color: "#888888" }}>{`${"Mobile No"} : ${this.state.Patient_Details?.patient__mobile_number}`}</Typography>
                        <Typography sx={{ fontSize: '.8vw', color: "#888888" }}>{`${"Barcode Number"} : ${this.state.Patient_Details?.barcode ? this.state.Patient_Details?.barcode : "-"}`}</Typography>
                    </Box>
                    {this.renderTable()}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(CompletedReports);