import React, { Component, Fragment } from 'react'
import './Remedinet.css'
import { Autocomplete, Box, Button, Collapse, Divider, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material'
import { withTranslation } from 'react-i18next';
import { AmountsCard } from '../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../Utility/ImagePaths';
import { Colors } from '../../Styles/Colors';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { AmountFormat, formatDate, timeOnlyConvert } from '../../Components/CommonFunctions/CommonFunctions';
import CommonGridHeader, { CommonEditButton, CommonPatientDetails, CommonPopUp } from '../../Components/Common Components/CommonComponents';
import { RemediServiceUrls, RemedyHospitalCode } from './RemedinetConstants';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { DataGrid } from '@mui/x-data-grid'
import { DateTime } from 'luxon';
import { localGetItem } from '../../Utility/Services/CacheProviderService';

class RemedinetTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {
                "startTime": null,
                "endTime": null,
                "onError": false,
            },
            timeError: false,
            InsuranceData: [],
            payerList: [],
            isPayerPopup: false,
            SelectedPayer: [],
            historyData: [],
            CardDetails: []
        }
    }

    componentDidMount() {
        let Insurance = JSON.parse(localGetItem("Insurance_Details"))
        this.setState({
            Identity: {
                "authToken": Insurance?.auth_token,
                "his_name": Insurance?.his_name
            }
        }, () => {
            this.GetPayerList()
            this.GetInsurancePatientList()
        })
    }

    GetInsurancePatientList() {
        this.LoaderFunction(true)
        try {
            RestAPIService.getAll(Serviceurls.REMEDINET_HOME)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            InsuranceData: response.data.data,
                            CardDetails: response.data
                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                        this.LoaderFunction(false)
                    } else {
                        this.errorMessage(error.message)
                        this.LoaderFunction(false)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.LoaderFunction(false)
        }
    }

    getHistoryDetails(data) {
        this.LoaderFunction(true)
        try {
            RestAPIService.getAll(Serviceurls.REMEDINET_INSURANCE_HISTORY + '?id=' + data?.id)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            historyData: response.data.data
                        }, () => {
                            this.LoaderFunction(false)
                            if (!data?.is_pre_discharge_approved) {
                                this.props.history?.push({ pathname: '/Insurance/InsuranceSubmission', state: { selectedPayer: data?.payer_name, selectedPayerCode: data?.tpacode, History: response.data.data[0], isForEditView: true, redirectto: "Preauthorisation", HomeData: data } })
                            } else {
                                this.props.history?.push({ pathname: '/Insurance/InsuranceSubmission', state: { selectedPayer: data?.payer_name, selectedPayerCode: data?.tpacode, History: response.data.data[0], isForEditView: true, redirectto: "Claim Submission", HomeData: data } })
                            }
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                        this.LoaderFunction(false)
                    } else {
                        this.errorMessage(error.message)
                        this.LoaderFunction(false)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.LoaderFunction(false)
        }
    }

    GetPayerList() {
        try {
            let Data = {
                "url": RemediServiceUrls.REMEDI_BASE_URL + RemediServiceUrls.PAYER_LIST_GET,
                "payload": {
                    "HOSPITALCODE": RemedyHospitalCode,
                    "identity": this.state.Identity
                }
            }
            RestAPIService.create(Data, Serviceurls.REMEDINET_BACKEND).
                then((response) => {
                    console.log(response);
                    this.setState({
                        payerList: response?.data?.Data
                    })
                }).catch(error => {
                    console.log(error)
                    // if(error?.response?.data?.status === "fail"){
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.response.data.message)
                    // }else{
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.message)
                    // }   
                })
        }
        catch (e) {
            console.log(e.message);
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderTableOne = (item, firstIndex) => {
        const { t } = this.props
        return (
            <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse
                    in={this.state.selectedIndex === firstIndex}
                    timeout={'auto'}
                    unmountOnExit
                >
                    <Box margin={1} className='eMed_Pat_Bill_Tbl_Two'>
                        <Table>
                            <TableHead sx={{ backgroundColor: Colors.Background }}>
                                <TableRow>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("S No")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Request Type")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Date & Time")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Status")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Approved Amount")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Form Status")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item?.length > 0 ? item?.map((list, secondIndex) => {
                                    return (
                                        <><TableRow key={secondIndex}>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{secondIndex + 1}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{list?.request_type ? list.request_type : "-"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{list?.date ? list.date : "-"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{list?.status ? list.status : "-"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{list?.approv_amount ? list.approv_amount : "-"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{list?.form_status ? list.form_status : "-"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center' sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                                <Button
                                                    className='eMed_Pat_tbl_two_btn'
                                                    onClick={() => {
                                                        this.props.history?.push({ pathname: '/Insurance/InsuranceSubmission', state: { isForEditView: true } })
                                                    }}
                                                >
                                                    <Tooltip placement='top' title={'Edit'} arrow>
                                                        <img src={false ? ImagePaths.DisabledEdit.default : ImagePaths.LabEditButton.default} className='eMed_Pat_Bill_img' alt='print' />
                                                    </Tooltip>
                                                </Button>
                                                <Button
                                                    className='eMed_Pat_tbl_two_btn'
                                                    onClick={() => {
                                                        this.props.history?.push({ pathname: '/Insurance/InsuranceSubmission', state: { isForEditView: true } })
                                                    }}
                                                >
                                                    <Tooltip placement='top' title={'View'} arrow>
                                                        <img src={ImagePaths.ViewContained.default} className='eMed_Pat_Bill_img' alt='viewBill' />
                                                    </Tooltip>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        </>
                                    )
                                }) : <TableRow>
                                    <TableCell /><TableCell />
                                    <TableCell /><TableCell align='center'>
                                        {t("NoRecordsFound")}</TableCell><TableCell />
                                    <TableCell /><TableCell />
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell></>
        )
    }

    payerPopUpClose = () => {
        this.setState({
            isPayerPopup: false,
            SelectedPayer: []
        })
    }

    _renderPayerList = () => {
        var { payerList, SelectedPayer } = this.state
        return (
            <div>
                <Autocomplete
                    size="small"
                    // disablePortal
                    id="combo-box-demo"
                    options={payerList}
                    // renderOption={(option) => <Fragment>{option.PAYERNAME}</Fragment>}
                    getOptionLabel={(options) => options?.PAYERNAME ? options?.PAYERNAME : ''}
                    onChange={(e, newValue) => {
                        this.setState({
                            SelectedPayer: newValue
                        })
                    }}
                    value={SelectedPayer}
                    sx={{ width: '12vw' }}
                    renderInput={(params) => <TextField {...params} label={'Select Payer'} size='small' />}
                />
            </div>
        )
    }

    payerClick = () => {
        var { SelectedPayer } = this.state
        if (this.state.SelectedPayer?.PAYERNAME) {
            this.props.history?.push({ pathname: '/Insurance/InsuranceSubmission', state: { selectedPayer: SelectedPayer, selectedPayerCode: SelectedPayer?.PAYERCODE, NewReq: true } })
        } else {
            this.errorMessage("Select Payer from List")
        }
    }

    handleEdit = (item) => {
        this.getHistoryDetails(item)
    }

    render() {
        let { t } = this.props
        const columns = [
            {
                field: "requested_date", headerName: t("Date & Time"), flex: 0.08, align: "center", headerAlign: "center",
                renderCell: (params) => (
                    <Box component={'div'} >
                        <div>{params?.row?.requested_date ? params?.row?.requested_date.split(" ")[0] : '-'}</div>
                        <div style={{ fontSize: "0.9vw" }}>{params?.row?.requested_date ? timeOnlyConvert(params?.row?.requested_date.split(" ")[1]) : '-'}</div>
                    </Box>)
            },
            {
                field: 'patient_name', headerName: t('Patient Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.20,
                renderCell: ({ row }) => (<CommonPatientDetails data={row} showAdmission={false} />), density: 'compact'
            },
            {
                field: "payer_name", headerName: t("Payer Details"), flex: 0.153,
                renderCell: (params) => (<Box component={'div'}>
                    <div>{params?.row?.payer_name?.length > 20 ?
                        <Tooltip placement='top' title={params?.row?.payer_name} arrow><div>{params?.row?.payer_name.slice(0, 20) + "..."}</div></Tooltip>
                        : params?.row?.payer_name ? params?.row?.payer_name : "-"}</div>
                    <div style={{ fontSize: "0.9vw" }}>{`TPA Code : ${params?.row?.tpacode ? params?.row?.tpacode : "-"}`}</div>
                </Box>)
            },
            {
                field: "insurance_company_name", headerName: t("Insurance Details"), flex: 0.153,
                renderCell: (params) => (<Box component={'div'}>
                    <div>{params?.row?.insurance_company_name?.length > 20 ?
                        <Tooltip placement='top' title={params?.row?.insurance_company_name} arrow><div>{params?.row?.insurance_company_name.slice(0, 20) + "..."}</div></Tooltip>
                        : params?.row?.insurance_company_name ? params?.row?.insurance_company_name : "-"}</div>
                    <div style={{ fontSize: "0.9vw" }}>{`${params?.row?.policy_number ? params?.row?.policy_number : "-"}`}</div>
                </Box>)
            },
            {
                field: "admission_date", headerName: t("DOA"), flex: 0.08, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.admission_date ? params?.row?.admission_date.length > 20 ?
                    <Tooltip title={params?.row?.admission_date?.split(" ")[0]} placement='top' arrow><div>{(params?.row?.admission_date?.split(" ")[0]).slice(0, 20) + '...'}</div></Tooltip> : params?.row?.admission_date?.split(" ")[0] : "-"}</Box>)
            },
            {
                field: "discharge_date", headerName: t("DOD"), flex: 0.08, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.discharge_date ? params?.row?.discharge_date.length > 20 ?
                    <Tooltip title={params?.row?.discharge_date?.split(" ")[0]} placement='top' arrow><div>{(params?.row?.discharge_date?.split(" ")[0]).slice(0, 20) + '...'}</div></Tooltip> : params?.row?.discharge_date?.split(" ")[0] : "-"}</Box>)
            },
            {
                field: "status", headerName: t("Status"), flex: 0.15,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.status ? params?.row?.status?.length > 25 ?
                    <Tooltip placement='top' title={params?.row?.status} arrow><div style={{ fontWeight: 600 }}>{params?.row?.status.slice(0, 25) + "..."}</div></Tooltip>
                    : <div style={{ fontWeight: 600 }}>{params?.row?.status}</div> : "-"}</Box>)
            },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.06, sortable: false, hideable: false, align: "center", renderCell: (cellvalue) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={4}>
                            {cellvalue?.row?.status === "Cancel" ?
                                <Button className='eMed_Pat_tbl_two_btn'
                                    onClick={() => {
                                        this.props.history?.push({ pathname: '/Insurance/InsuranceSubmission', state: { isForView: true } })
                                    }}
                                >
                                    <Tooltip placement='top' title={'View'} arrow>
                                        <img src={ImagePaths.ViewContained.default} className='eMed_Pat_Bill_img' alt='viewBill' />
                                    </Tooltip>
                                </Button>
                                :
                                <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                                    <CommonEditButton size="1.5vw" onClick={this.handleEdit.bind(this, cellvalue.row)} />
                                </Stack>

                            }
                        </Stack>
                    )
                }
            }
        ]
        return (
            <div style={{ backgroundColor: "#F5F8F7" }}>
                <Box component={"div"} className='eMed_Top_Container' sx={{ height: '8vh' }}>
                    <Box component={"div"} className='eMed_Cards_Container' width={"75%"}>
                        {/* {AmountsCard(t("Total Form"), (this.state.CardDetails?.total_count ? this.state.CardDetails?.total_count : 0), true)}
                        {AmountsCard(t("Rejected"), (this.state.CardDetails?.rejected_count ? this.state.CardDetails?.rejected_count : 0), true)}
                        {AmountsCard(t("Claimed"), (this.state.CardDetails?.claimed_count ? this.state.CardDetails?.claimed_count : 0), true)}
                        {AmountsCard(t("Pending"), (this.state.CardDetails?.pending_count ? this.state.CardDetails?.pending_count : 0), true)} */}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container' width={'25%'} >
                        <Button variant='contained' size='small' sx={{ textTransform: "capitalize" }} onClick={() => {
                            this.setState({
                                isPayerPopup: true
                            })
                        }}>
                            New Request
                        </Button>
                        <Divider orientation='vertical' sx={{ height: '4vh', paddingX: '0.5vw' }} />
                        {/* <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' >
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box className="eMed_Remedinet_table_div">
                    <DataGrid
                        rows={this.state.InsuranceData}
                        columns={columns}
                        getRowId={(row) => row.id}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        rowHeight={80}
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                {(this.state.isPayerPopup) ?
                    <CommonPopUp
                        width={"35vw"} popUpClose={this.payerPopUpClose} title={t("Payrlst")} justifyRight={true}
                        component={this._renderPayerList} buttonOne={t("Close")}
                        buttonOneAction={this.payerPopUpClose} buttonTwo={t("Confirm")}
                        buttonTwoAction={this.payerClick} disableBtn2={false}
                    /> : null
                }
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
            </div>
        )
    }
}
export default withTranslation()(RemedinetTable)