import { Box, Button, Modal, Tab, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import { CommonPatientCard, CommonPatientDetails, DeletePopup } from '../../../../../Components/Common Components/CommonComponents'
import { Colors } from '../../../../../Styles/Colors'
import { ImagePaths } from '../../../../../Utility/ImagePaths'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import { CheckAccessFunc } from '../../../../../Components/CommonFunctions/CommonFunctions'
import { getCachevalue, localGetItem } from '../../../../../Utility/Services/CacheProviderService'

let isUser = null;

export default class RadSavedBill extends Component {

  constructor(props) {
    super(props)
    this.state = {
      patientData: {},
      attender_mobile: '',
      attender_name: '',
      radSavedBilldata: [],
      savedBillList: [],
      openViewPopup: false,
      removeData: false,
      selectedId: null,
      deletePopup: false
    }
  }

  componentDidMount() {
    isUser = JSON.parse(localGetItem('loggedInUserInfo'))

    let patientData = JSON.parse(getCachevalue('RadPatientData'))
    this.setState({
      patientData: patientData
    }, () => this.getSavedBillData())
  }

  getSavedBillData = () => {
    try {
      let params = ''
      if (this.state.patientData?.ip_admission_id !== null && this.state.patientData?.ip_admission_id !== undefined) {
        params = `?admission_id=${this.state.patientData?.ip_admission_id}`
      } else if (this.state.patientData?.appointment_id !== null && this.state.patientData?.appointment_id !== undefined) {
        params = `?appointment_id=${this.state.patientData?.appointment_id}`
      } else {
        let date = this.state.patientData?.invoice_date?.slice(0, 10)?.split('-')
        let invoiceDate = date ? date[2] + "-" + date[1] + "-" + date[0] : ""
        params = `?patient_id=${this.state.patientData?.patient_id}&bill_date=${invoiceDate}`
      }
      RestAPIService.getAll(Serviceurls.RAD_SAVED_BILL_GET + params)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              radSavedBilldata: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getSavedBillList = (id) => {
    try {
      RestAPIService.getAll(Serviceurls.RAD_SAVED_BILL_ITEM_GET + '?bill_summary_id=' + id)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              savedBillList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  deleteSavedBillData = () => {
    try {
      let data = {}
      this.setState({ removeData: true })
      RestAPIService.delete(Serviceurls.RAD_SAVED_BILL_GET + '?id=' + this.state.selectedId, data)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getSavedBillData()
            this.setState({ removeData: false, selectedId: null, deletePopup: false })
          }
        }).catch((error) => {
          this.setState({ removeData: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  renderViewPopup = () => {
    try {
      return (
        <Box component={'div'}>
          <Modal open={this.state.openViewPopup}>
            <Box component={'div'} className='rad_popup_style'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                <Typography fontSize={'0.9vw'} fontWeight={600}>Saved Bill Details</Typography>
                <Button className='Common_Btn_Min_Width'>
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
              <Box component={'div'}>

              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (item) => {
    try {
      this.setState({
        deletePopup: true,
        selectedId: item?.id
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose = () => {
    this.setState({
      deletePopup: false,
      selectedId: null,
    })
  }

  render() {
    let data = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
      lineFour: "Contact Number",
      LineFive: `${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
    }
    let visibility = this.props?.history?.location?.state?.fromCancelBill || this.props?.history?.location?.state?.fromCancelBill
    return (
      <Box component={'div'}>
        <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: '73vh' }}>
          <Box component={'div'} height={"9.5vh"}>
            <Box component={'div'} display={'flex'}>
              <Box component={'div'} className='eMed_nur_notes_details' ml={'0.5vw'}>
                <CommonPatientDetails data={this.state.patientData} />
              </Box>
              {/* <CommonPatientCard details={data} showDetailed={true} /> */}
            </Box>
          </Box>
          <Box component={'div'} border={'1px solid lightgray'} m={'0.5vw'} height={'62vh'} overflow={'auto'} sx={{ backgroundColor: Colors.white }}>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Date And Time</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Bill Number</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Module</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Doctor Name</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Total Bill Amount</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.radSavedBilldata?.length > 0 ? this.state.radSavedBilldata?.map((item, index) => (
                  <TableRow>
                    <TableCell>
                      <Typography>{item?.invoice_date ? item?.invoice_date : '-'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item?.bill_number ? item?.bill_number : '-'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item?.module ? item?.module : '-'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item?.doctor_fname ? item?.doctor_fname : '-'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item?.net_amount ? item?.net_amount : '-'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Box component={'div'} display={'flex'} sx={{visibility:visibility ? 'hidden' : 'visible'}}>
                        <Button className='Common_Btn_Min_Width' emed_tid='rad_sav_crte_bill_btn'
                          disabled={!CheckAccessFunc('Radiology', "Home", 'Billing', 'Saved Bill', "SubTab")?.editAccess}
                          onClick={() => {
                            this.props.history.push({ pathname: '/RadiologyHome/Patient/Billing', state: { billSummaryId: item?.id, fromSavedBill: true, Reset:true } })
                          }}
                        >
                          <Box component={'img'} src={ImagePaths.CreateBill.default} height={'1.5vw'} width={'1.5vw'} />
                        </Button>
                        <Button className='Common_Btn_Min_Width' emed_tid='rad_sav_dlt_btn'
                          disabled={!CheckAccessFunc('Radiology', "Home", 'Billing', 'Saved Bill', "SubTab")?.editAccess}
                          onClick={() => { this.onDeleteHandler(item) }}
                        >
                          <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell colSpan={7} align='center'>No Records To Be Shown</TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletePopup ?
          <DeletePopup
            DeletTitle={`Are you sure do you want to delete the saved bill ?`}
            deleteAlertPopupClose={this.popupClose}
            removeData={this.deleteSavedBillData}
            disable={this.state.removeData}
          />
          : null}
      </Box>
    )
  }
}
