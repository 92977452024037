import { Box, Button, IconButton, Menu, MenuItem, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { ConfirmPopup, ReasonPopup } from '../../../../Components/Common Components/CommonComponents';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import moment from 'moment';
import Loader from '../../../../Components/Loader'
import { DateTime } from 'luxon';
import { convertDateSlashToHyphen } from '../../../../Components/CommonFunctions/CommonFunctions';

export default class DocScheduleConfig extends Component {

  constructor(props) {
    super(props)
    this.webcamRef = React.createRef();
    this.state = {
      temp_patient_img: null,
      SelectedTab: "two",
      SessionsFullList: {
        "Monday": [],
        "Tuesday": [],
        "Wednesday": [],
        "Thursday": [],
        "Friday": [],
        "Saturday": [],
        "Sunday": [],
      },
      WeekArray: [],
      SessionStartTime: null,
      SessionEndTime: null,
      IntervalValue: "",
      isOutofOffice: false,
      isLateBy: false,
      alertPop: false,
      currentWeek: 0,
      miniTime: null,
      maxiTime: null,
      Starterror: null,
      Enderror: null,
      sessionName: "",
      sessionID: null,
      isOFFDisabled: false,
      isLatebyDisabled: false,
      renderScreenAgain: false,
      selectedDay: '',
      selectedDate: '',
      LateByDelete : null
    }
    this.EmergencySocket = null
  }

  componentDidMount() {
    this.getScheduledData()
    this.WebSocketConnention()
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.DocScheduleGet != this.props.DocScheduleGet) || this.props.renderScreenAgain) {
      let startOfWeek = moment().add(this.state.currentWeek, 'weeks').startOf('isoWeek');
      this.getScheduledData(startOfWeek.format("YYYY-MM-DD"));
      if(this.props.renderScreenAgain){
        this.props.rendered()
      }
    }
  }

  getScheduledData = (date = null) => {
    this.LoaderFunction(true)
    try {
      let currentDate = moment().format('YYYY-MM-DD');
      let selectedDate = this.state.selectedDate ? convertDateSlashToHyphen(this.state.selectedDate) : ''
      let API_URL = ""
      if (this.props?.FromFO) {
        API_URL = Serviceurls.DOC_SCHEDULE_DATA_GET + `?doctor_id=${this.props?.SelectedDoctorDetails?.doctor_id ? this.props?.SelectedDoctorDetails?.doctor_id : ""}&date=${date ? date : selectedDate ? selectedDate : currentDate}`
      } else {
        API_URL = Serviceurls.DOC_SCHEDULE_DATA_GET + `?clinic_id=${localGetItem('SelectedDoctorClinicId') ? +localGetItem('SelectedDoctorClinicId') : null}&date=${date ? date : selectedDate ? selectedDate : currentDate}`
      }
      RestAPIService.getAll(API_URL).
        then((response) => {
          if (response.data.status === "success") {
            let Response = response.data.data ? response.data.data : {}
            let SessionList = {
              "Monday": [],
              "Tuesday": [],
              "Wednesday": [],
              "Thursday": [],
              "Friday": [],
              "Saturday": [],
              "Sunday": [],
            }
            this.LoaderFunction(false)
            if (Array.isArray(Response) && Response.length > 0) {
              Response.forEach(item => {
                const day = item?.day;
                const sessions = item?.sessions || [];
                if (SessionList.hasOwnProperty(day)) {
                  SessionList[day] = sessions;
                }
              });
            }
            let IdList = {
              "Monday": Response?.find((list)=>(list?.day === "Monday")) ? Response?.find((list)=>(list?.day === "Monday"))?.id : null,
              "Tuesday":Response?.find((list)=>(list?.day === "Tuesday")) ? Response?.find((list)=>(list?.day === "Tuesday"))?.id : null,
              "Wednesday": Response?.find((list)=>(list?.day === "Wednesday")) ? Response?.find((list)=>(list?.day === "Wednesday"))?.id : null,
              "Thursday": Response?.find((list)=>(list?.day === "Thursday")) ? Response?.find((list)=>(list?.day === "Thursday"))?.id : null,
              "Friday":Response?.find((list)=>(list?.day === "Friday")) ? Response?.find((list)=>(list?.day === "Friday"))?.id : null,
              "Saturday": Response?.find((list)=>(list?.day === "Saturday")) ? Response?.find((list)=>(list?.day === "Saturday"))?.id : null,
              "Sunday": Response?.find((list)=>(list?.day === "Sunday")) ? Response?.find((list)=>(list?.day === "Sunday"))?.id : null,
            }
            this.setState({
              SessionsFullList: SessionList,
              IdList: IdList
            }, () => {
              this.setState({
                WeekArray: this.getWeekDates(this.state.currentWeek)
              })
            })
          }
          else {
            this.errorMessage(response.data.message)
            this.LoaderFunction(false)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
          this.LoaderFunction(false)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
      this.LoaderFunction(false)
    }
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  WebSocketConnention = () => {
    let userData = JSON.parse(localGetItem("loggedInUserInfo"))
    let lateByChannel = userData?.late_channel_name
    let outOfficeChannel = userData?.out_channel_name
    this.LateBySocket = new WebSocket(`${Serviceurls.WEB_SOCKET_DOMAIN}${Serviceurls.DOC_RUNNINGLATE_WEBSOCKET}${lateByChannel}/`);
    this.OutOfOfficeSocket = new WebSocket(`${Serviceurls.WEB_SOCKET_DOMAIN}${Serviceurls.DOC_OUTOFOFFICE_WEBSOCKET}${outOfficeChannel}/`);

    const socketMapping = {
      LateBySocket: this.LateBySocket,
      OutOfOfficeSocket: this.OutOfOfficeSocket,
    };

    Object.entries(socketMapping)?.forEach(([name, socket]) => {
      socket.onopen = () => {
        console.log(`${name} WebSocket connected`);
      }
      socket.onclose = () => {
        console.log(`${name} WebSocket disconnected`);
      }
      socket.onerror = (error) => {
        console.log(`${name} WebSocket connected`, error);
        socket.close()
      }
      socket.onmessage = (e) => {
        const data = JSON.parse(e.data);
        console.log(`${name} WebSocket Message`, data);
      }
    })
  }

  postOutofOffice = (comments) => {
    try {
      var states = this.state
      let sessionData = `${states.selectedDate}-${states?.selectedDay}-${states?.sessionName}`
      var data = {
        "from_date": states?.selectedDate.split("/").reverse().join("-"),
        "to_date": states?.selectedDate.split("/").reverse().join("-"),
        "session": sessionData ? sessionData : '',
        "out_reason": comments
      }
      if (this.props?.FromFO) {
        data["doctor_id"] = this.props?.SelectedDoctorDetails?.doctor_id ? this.props?.SelectedDoctorDetails?.doctor_id : null
      } else {
        data["clinic_id"] = localGetItem('SelectedDoctorClinicId') ? +localGetItem('SelectedDoctorClinicId') : null
      }
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.DOC_SCHEDULE_OUTOFOFFICE).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ isOutofOffice: false, selectedDay: '' }, () => {
              if (!this.props?.FromFO) { this.OutOfOfficeSocket.send(JSON.stringify({ id: response?.data?.data?.id })) }
              this.getScheduledData()
            })
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postLateBy = () => {
    try {
      var states = this.state
      var data = {
        "session_id": states?.sessionID,
        "session_name": states?.sessionName,
        "from_time": states?.SessionStartTime ? this.Timeto24Hr(this.state.SessionStartTime) : null,
        "to_time": states?.SessionEndTime ? this.Timeto24Hr(this.state.SessionEndTime) : null,
        "session_date": states?.selectedDate ? states?.selectedDate.split("/").reverse().join("-") : null
      }
      if (this.props?.FromFO) {
        data["doctor_id"] = this.props?.SelectedDoctorDetails?.doctor_id ? this.props?.SelectedDoctorDetails?.doctor_id : null
      } else {
        data["clinic_id"] = localGetItem('SelectedDoctorClinicId') ? +localGetItem('SelectedDoctorClinicId') : null
      }
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.DOC_RUNNING_LATE_POST).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ alertPop: false, SessionStartTime: null, SessionEndTime: null }, () => {
              if (!this.props?.FromFO) { this.LateBySocket.send(JSON.stringify({ id: response?.data?.data?.id })) }
              this.getScheduledData()
            })
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  Timeto24Hr(dateTimeString) {
    const date = new Date(dateTimeString);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    hours = String(hours).padStart(2, '0');
    minutes = String(minutes).padStart(2, '0');
    seconds = String(seconds).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  getWeekDates = (weekOffset) => {
    const startOfWeek = moment().add(weekOffset, 'weeks').startOf('isoWeek');
    const endOfWeek = moment().add(weekOffset, 'weeks').endOf('isoWeek');

    let days = [];
    let day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(day.format('DD/MM/YYYY'));
      day = day.clone().add(1, 'd');
    }

    return days;
  };

  handlePreviousWeek = () => {
    this.setState((prevState) => ({
      currentWeek: prevState.currentWeek - 1
    }), () => {
      let startOfWeek = moment().add(this.state.currentWeek, 'weeks').startOf('isoWeek');
      this.getScheduledData(startOfWeek.format("YYYY-MM-DD"));
    });
  };

  handleNextWeek = () => {
    this.setState((prevState) => ({
      currentWeek: prevState.currentWeek + 1
    }), () => {
      let startOfWeek = moment().add(this.state.currentWeek, 'weeks').startOf('isoWeek');
      this.getScheduledData(startOfWeek.format("YYYY-MM-DD"));
    });
  };

  convertTo12Hour(time) {
    const [hour, minute] = time.split(':').map(Number);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
  }

  handleValidation = () => {
    const { SessionStartTime, SessionEndTime, Starterror, Enderror } = this.state;
    if (this.Timeto24Hr(SessionStartTime) === this.Timeto24Hr(SessionEndTime)) {
      this.errorMessage(`Session Start and End Time can't be same`)
    } else if (SessionStartTime && SessionEndTime && SessionStartTime !== 'Invalid Date' && SessionEndTime !== 'Invalid Date' && (!Starterror || Starterror === '') && (!Enderror || Enderror === '')) {
      this.setState({ alertPop: true });
    } else {
      this.errorMessage('Invalid Time');
    }
  };

  LateByPopup = () => {
    try {
      let states = this.state
      return (
        <Modal open={states.isLateBy}>
          <Box className='eMed_New_Ip_Popup' sx={{ width: "30vw", height: "15vw" }}>
            <Box component={'div'} mb={'0.5vw'} position={'sticky'}>
              <Box display={'flex'} justifyContent={'space-between'} paddingLeft={'1vw'} alignItems={'center'} height={'3vw'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Late-By Timings</Typography>
              </Box>
            </Box>
            <Box margin={"1vw"}>
              <Typography>Late-By Timings</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: "1vw" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label='Start Time'
                    minTime={moment(this.state.miniTime, 'hh:mm A')}
                    maxTime={moment(this.state.maxiTime, 'hh:mm A')}
                    value={this.state.SessionStartTime}
                    onError={(error) => this.setState({ Starterror: error })}
                    onChange={(newStartTime) => this.setState({ SessionStartTime: newStartTime?.$d ? newStartTime?.$d : null })}
                    renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "HH:MM (AM|PM)", "emed_tid": "doctor_schedule_startTime" }} style={{ width: "90%", marginRight: "1vw" }} size="small" />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label='End Time'
                    minTime={moment(this.state.miniTime, 'hh:mm A')}
                    maxTime={moment(this.state.maxiTime, 'hh:mm A')}
                    value={this.state.SessionEndTime}
                    onError={(error) => this.setState({ Enderror: error })}
                    onChange={(newStartTime) => this.setState({ SessionEndTime: newStartTime?.$d ? newStartTime?.$d : null })}
                    renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "HH:MM (AM|PM)", "emed_tid": "doctor_schedule_endTime" }} style={{ width: "98%" }} size="small" />}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box component={'div'}>
              <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} p={'0.5vw'}>
                <Button
                  sx={{ height: '2vw', width: '5vw', mr: '1vw' }}
                  variant='outlined'
                  emed_tid="LateBy_popup_cancel_btn"
                  onClick={() => { this.setState({ isLateBy: false, SessionStartTime: null, SessionEndTime: null }) }}
                >Cancel</Button>
                <Button
                  sx={{ height: '2vw', width: '5vw', mr: '1vw' }}
                  variant='contained'
                  emed_tid="LateBy_popup_add_btn"
                  onClick={() => { this.handleValidation() }}
                >Add</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message,
      disableBtn: false,
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message,
      disableBtn: false,
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      alertPop: false,
      disableBtn: false,
      LateByDelete : null
    })
  }

  confirmSave = () => {
    this.setState({
      isLateBy: false,
      disableBtn: true,
    }, () => {
      this.postLateBy()
    })
  }

  popupClose = () => {
    this.setState({
      isOutofOffice: false
    })
  }

  confirmDelete(){
    try{
        var states = this.state
        let API_URL = "";
        if (this.props?.FromFO) {
          API_URL = Serviceurls.DOC_RUNNING_LATE_POST + `?doctor_id=${this.props?.SelectedDoctorDetails?.doctor_id ? this.props?.SelectedDoctorDetails?.doctor_id : ""}&id=${states?.LateByDelete ? states?.LateByDelete : ""}`
        } else {
          API_URL = Serviceurls.DOC_RUNNING_LATE_POST + `?clinic_id=${localGetItem('SelectedDoctorClinicId') ? +localGetItem('SelectedDoctorClinicId') : null}&id=${states?.LateByDelete ? states?.LateByDelete : ""}`
        }
        RestAPIService.delete(API_URL).
        then((response) => {
            if (response.data.status === "success") {
                this.successMessage(response.data.message)
                this.setState({
                  LateByDelete : null
                }, ()=> { this.getScheduledData() })
            } else {
                this.errorMessage(response.data.message)
            }
        }).catch(error => {
            this.errorMessage(error.message)
        })
    }
    catch(e){
        this.errorMessage(e.message)
    }
}

  renderSessions = (day, DaySessions = [], days) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: "center" }}>
        {
          DaySessions?.map((list, index) => {
            let date = days ? convertDateSlashToHyphen(days) : ""
            let currentDateTime = new Date()
            let currentDate = DateTime.fromJSDate(currentDateTime).toFormat("yyyy-MM-dd")
            let currentTime = DateTime.fromJSDate(currentDateTime).toFormat('HH:mm')
            let disable = false
            if(currentDate === date){
              let endingTime = this.convertTo12Hour(list?.end_time);
              let convert24 = DateTime.fromFormat(endingTime, 'h:mm a');
              let endTime = convert24.toFormat('HH:mm');
              if(endTime <= currentTime){
                disable= true
              }
            }else if(date < currentDate){
              disable = true
            }
            return (
              <Typography>
                <Box sx={{ width: "18.5vw", border: list?.out_of_office ? "1px solid #ff0000" : '1px solid #BDBDBD', opacity: disable ? 0.5 : 1 }} className="eMed_sessions_div">
                  <Typography color={list?.out_of_office ? "#ff0000" : null}>{`S${index + 1}`}</Typography>
                  <Typography color={list?.out_of_office ? "#ff0000" : null} fontWeight={600}>{` - ${this.convertTo12Hour(list?.start_time)} - ${this.convertTo12Hour(list?.end_time)}`}</Typography>
                  <Typography style={{ display: "flex", flexDirection: "row", marginLeft: "1vw" }}>
                    <IconButton disabled={disable} emed_tid={`renderSession_S${index + 1}_dropDown_btn`} onClick={(e) => {
                      this.setState({
                        selectedDay: day,
                        anchorEl: e.currentTarget, 
                        sessionID: this.state?.IdList?.[day],
                        sessionName: list?.session_name, miniTime: this.convertTo12Hour(list?.start_time),
                        maxiTime: this.convertTo12Hour(list?.end_time), selectedDate: days,
                        isLatebyDisabled: list?.running_late, isOFFDisabled: list?.out_of_office
                      })
                    }}><img src={ImagePaths.DropDown.default} alt='Drop Down' style={{ width: "0.75vw", height: "0.75vw" }} /></IconButton>
                    {list?.running_late ? <Tooltip title={disable ? `Late By - ${this.convertTo12Hour(list?.from_time)} - ${this.convertTo12Hour(list?.to_time)}.`: `Late By - ${this.convertTo12Hour(list?.from_time)} - ${this.convertTo12Hour(list?.to_time)}. Click the icon to delete the late request.`} placement='top' arrow><IconButton disabled={disable} emed_tid={`renderSession_S${index + 1}_lateBy_info_btn`} onClick={()=>{ this.setState({ LateByDelete : list?.late_id ? list?.late_id : null})}}><img src={ImagePaths.Info.default} alt='Info' style={{ width: "1.2vw", height: "1.2vw" }} /></IconButton></Tooltip> : null}
                  </Typography>
                </Box>
                <Menu
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl) && this.state.sessionID === this.state?.IdList?.[day] && day === this.state.selectedDay}
                  onClose={() => { this.setState({ anchorEl: null }) }}
                >
                  <MenuItem disabled={this.state.isOFFDisabled} onClick={() => { this.setState({ anchorEl: false, isOutofOffice: true }) }}>Out of Office</MenuItem>
                  <MenuItem disabled={this.state.isLatebyDisabled || this.state.isOFFDisabled} onClick={() => {
                    this.setState({ anchorEl: false }, () => { this.setState({ isLateBy: true, }) })
                  }}>Late-By</MenuItem>
                </Menu>
              </Typography>
            )
          })
        }
      </Box>
    )
  }

  render() {
    const { t } = this.props
    const weekDates = this.state.WeekArray
    return (
      <Box>
        <Box sx={{ width: this.props?.FromFO ? "79vw" : "73vw", marginTop: "0.5vw" }}>
          <TableContainer>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell className="eMed_Sticky_first_Column" sx={{ fontWeight: 600, width: "8vw", backgroundColor: Colors.DataTblHeaderbg }}>Day/Date</TableCell>
                  <TableCell className="eMed_Sticky_first_Column" sx={{ fontWeight: 600, zIndex: "1", width: '65vw', backgroundColor: Colors.DataTblHeaderbg }}>Sessions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                  <TableCell className="eMed_Sticky_first_Column" sx={{ width: "10vw", border: '1px solid #dddddd' }}>
                    <IconButton sx={{ width: "120%", border: "1px solid #e0dcdc", borderRadius: "0", marginLeft: "-0.75vw" }} onClick={() => { this.handlePreviousWeek() }} emed_tid="doctor_schedule_upArrow_btn"><img src={ImagePaths.upArrow.default} alt='Drop Down' style={{ width: "0.75vw", height: "0.75vw" }} /></IconButton>
                    <Typography sx={{ fontWeight: 600, fontSize: "0.9vw" }}>Monday</Typography>
                    <Typography sx={{ fontSize: "0.85vw" }} >{weekDates[0]}</Typography>
                  </TableCell>
                  <TableCell>{this.renderSessions("Monday", this.state.SessionsFullList?.Monday, weekDates[0])}</TableCell>
                </TableRow>
                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                  <TableCell className="eMed_Sticky_first_Column" sx={{ width: "10vw", border: '1px solid #dddddd' }}>
                    <Typography sx={{ fontSize: "0.9vw", fontWeight: 600 }}>Tuesday</Typography>
                    <Typography sx={{ fontSize: "0.85vw" }} >{weekDates[1]}</Typography>
                  </TableCell>
                  <TableCell>{this.renderSessions("Tuesday", this.state.SessionsFullList?.Tuesday, weekDates[1])}</TableCell>
                </TableRow>
                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                  <TableCell className="eMed_Sticky_first_Column" sx={{ width: "10vw", border: '1px solid #dddddd' }}>
                    <Typography sx={{ fontSize: "0.9vw", fontWeight: 600 }}>Wednesday</Typography>
                    <Typography sx={{ fontSize: "0.85vw" }} >{weekDates[2]}</Typography>
                  </TableCell>
                  <TableCell>{this.renderSessions("Wednesday", this.state.SessionsFullList?.Wednesday, weekDates[2])}</TableCell>
                </TableRow>
                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                  <TableCell className="eMed_Sticky_first_Column" sx={{ width: "10vw", border: '1px solid #dddddd' }}>
                    <Typography sx={{ fontSize: "0.9vw", fontWeight: 600 }}>Thursday</Typography>
                    <Typography sx={{ fontSize: "0.85vw" }} >{weekDates[3]}</Typography>
                  </TableCell>
                  <TableCell>{this.renderSessions("Thursday", this.state.SessionsFullList?.Thursday, weekDates[3])}</TableCell>
                </TableRow>
                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                  <TableCell className="eMed_Sticky_first_Column" sx={{ width: "10vw", border: '1px solid #dddddd' }}>
                    <Typography sx={{ fontSize: "0.9vw", fontWeight: 600 }}>Friday</Typography>
                    <Typography sx={{ fontSize: "0.85vw" }} >{weekDates[4]}</Typography>
                  </TableCell>
                  <TableCell>{this.renderSessions("Friday", this.state.SessionsFullList?.Friday, weekDates[4])}</TableCell>
                </TableRow>
                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                  <TableCell className="eMed_Sticky_first_Column" sx={{ width: "10vw", border: '1px solid #dddddd' }}>
                    <Typography sx={{ fontSize: "0.9vw", fontWeight: 600 }}>Saturday</Typography>
                    <Typography sx={{ fontSize: "0.85vw" }} >{weekDates[5]}</Typography>
                  </TableCell>
                  <TableCell>{this.renderSessions("Saturday", this.state.SessionsFullList?.Saturday, weekDates[5])}</TableCell>
                </TableRow>
                <TableRow sx={{ height: "8vh", border: '1px solid #dddddd' }}>
                  <TableCell className="eMed_Sticky_first_Column" sx={{ width: "10vw", border: '1px solid #dddddd' }}>
                    <Typography sx={{ fontWeight: 600 }}>Sunday</Typography>
                    <Typography sx={{ fontSize: "0.85vw" }} >{weekDates[6]}</Typography>
                    <IconButton sx={{ width: "120%", border: "1px solid #e0dcdc", borderRadius: "0", marginLeft: "-0.75vw" }} onClick={() => { this.handleNextWeek() }} emed_tid="doctor_schedule_downArrow_btn"><img src={ImagePaths.downArrow.default} alt='Drop Down' style={{ width: "0.75vw", height: "0.75vw" }} /></IconButton>
                  </TableCell>
                  <TableCell>{this.renderSessions("Sunday", this.state.SessionsFullList?.Sunday, weekDates[6])}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {this.LateByPopup()}
        {this.state.isOutofOffice ? <ReasonPopup
          title={"Description *"}
          AlertPopupClose={this.popupClose.bind(this)}
          label={"Enter the Reason"}
          btntext={'Cancel'}
          btnvarient={'outlined'}
          btncolor={'error'}
          btntext1={'Confirm'}
          btnvarient1={'contained'}
          sendCmt={this.postOutofOffice.bind(this)} /> : null}
        {this.state.alertPop ?
          <ConfirmPopup
            cnfTitle={'Confirmation'}
            cnfContent={"Are you sure do you want to add late by in this session ?"}
            cnfAlertPopupClose={this.msgClose.bind(this)}
            cnfDisable={this.state.disableBtn}
            cnfData={this.confirmSave.bind(this)}
          />
          : null}
        {this.state.LateByDelete ?
          <ConfirmPopup
            cnfTitle={'Confirmation'}
            cnfContent={"Are you sure do you want to delete this late by request ?"}
            cnfAlertPopupClose={this.msgClose.bind(this)}
            cnfDisable={this.state.disableBtn}
            cnfData={this.confirmDelete.bind(this)}
          />
          : null}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
