import {
  Box, Button, Divider, InputAdornment, Modal, Paper, Stack,
  TextField, Tooltip, Typography, IconButton, Menu, MenuItem, Drawer, FormControlLabel, Checkbox
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./PharmaSettings.css"
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Colors } from "../../../Styles/Colors";
import { CommonPopUp } from "../../../Components/Common Components/CommonComponents";
import { formatDate, CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import EditStockPopUp from './EditStockPopUp';
import SearchIcon from '@mui/icons-material/Search';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

let userAccess = null;
let RoleData = null;
class PharmaStockCorrection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 10,
      rowCount: 0,
      StockDataList: [],
      VendorList: [],
      SelectedWantedList: [],
      SelectedDurg: {},
      SearchText: "",
      isForViewOnly: false,
      anchorEl: null,
      selectedRow: null,
      isSelected: false,
      selectedOption: "",
      historyDataList: [],
      FilterOpen: false,
      includeZero: false,
      batchNo: ''
    }
  }

  componentDidMount() {
    RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"));
    userAccess = CheckAccessFunc(RoleData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy", "Inventory", 'Stock Correction', null, "Tab");
    this.GetStockDataList()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  GetStockDataList() {
    try {
      this.LoaderFunction(true);
      let brandName = this.state.SearchText?.replaceAll('&', '%26')
      RestAPIService.getAll(`${Serviceurls.PHARMA_STOCK_CORRECTION_LIST}?page=${this.state.page + 1}&page_size=${this.state.pageSize}&brand_name=${brandName}&order=${"brand_name"}&batch_no=${this.state.batchNo}&include_zero=${this.state.includeZero}`).
        then((response) => {
          if (response.data.status === "Success") {
            this.LoaderFunction(false);
            let Data = response.data?.data;
            this.setState({
              StockDataList: Data?.stock_list_items?.result,
              rowCount: Data?.stock_list_items?.total_count,
            })
          }
          else {
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false);
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getStockHistory = (data) => {
    try {
      RestAPIService.getAll(`${Serviceurls.PHARMA_STOCK_CORRECTION_HISTORY}?drug_id=${data.drug_id}&stock_id=${data.stock_id}`).
        then((response) => {
          if (response.data.status === "Success") {
            this.setState({
              historyDataList: response.data?.data,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  MenuList = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "History", label: t("History") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            emed_tid="sc_morevert"
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => (
              <MenuItem emed_tid={`sc ${option.label}`} key={option} onClick={() => { this.setState({ anchorEl: null, selectedOption: option.value }, () => this.getStockHistory(data)) }}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  stockHistoryClose = () => {
    this.setState({
      selectedOption: "",
      isSelected: false,
      selectedRow: null,
      historyDataList: [],
    })
  }

  _showStockHistory = () => {
    const { t } = this.props;
    let { historyDataList } = this.state;
    historyDataList?.forEach((element, index) => { element.sno = index + 1 })
    const historyColumn = [
      {
        field: 'modified_date', headerName: t("UpdatedDate"), sortable: false, hideable: false, width: 250, headerAlign: "center", align: "center", renderCell: ({ row }) => (
          <Box component={'div'}>{row?.modified_date ? formatDate(row?.modified_date) : '-'}</Box>
        )
      },
      {
        field: 'modified_by', headerName: t('UpdatedBy'), width: 350, sortable: false, hideable: false, renderCell: ({ row }) => {
          return (<div>{row?.modified_by?.length > 20 ? <Tooltip placement="top" title={row?.modified_by} arrow><div>{row?.modified_by.slice(0, 15) + "..."}</div></Tooltip> : row?.modified_by ? row?.modified_by : "-"}</div>)
        }
      },
      {
        field: 'drug_name', headerName: t('BrandName'), width: 250, sortable: false, hideable: false, renderCell: ({ row }) => {
          return (
            <div>{row?.drug_name?.length > 20 ? <Tooltip placement="top" title={row?.drug_name} arrow><div>{row?.drug_name.slice(0, 15) + "..."}</div></Tooltip> : row?.drug_name ? row?.drug_name : "-"}</div>
          )
        }
      },
      {
        field: 'generic_name', headerName: t('GenericName'), sortable: false, hideable: false, width: 250, renderCell: ({ row }) => {
          return (
            <div>{row?.generic_name?.length > 20 ? <Tooltip placement="top" title={row?.generic_name} arrow><div>{row?.generic_name.slice(0, 15) + "..."}</div></Tooltip> : row?.generic_name ? row?.generic_name : "-"}</div>
          )
        }
      },
      {
        field: 'dosage_type', headerName: t('DosageType'), sortable: false, hideable: false, width: 250, renderCell: ({ row }) => {
          return (
            <div>{row?.dosage_type?.length > 20 ? <Tooltip placement="top" title={row?.dosage_type} arrow><div>{row?.dosage_type.slice(0, 15) + "..."}</div></Tooltip> : row?.dosage_type ? row?.dosage_type : "-"}</div>
          )
        }
      },
      {
        field: 'drug_strength', headerName: t('DosageStrength'), sortable: false, hideable: false, width: 250, renderCell: ({ row }) => {
          return (
            <div>{row?.drug_strength?.length > 20 ? <Tooltip placement="top" title={row?.drug_strength} arrow><div>{row?.drug_strength.slice(0, 15) + "..."}</div></Tooltip> : row?.drug_strength ? row?.drug_strength : "-"}</div>
          )
        }
      },
      {
        field: 'schedule', headerName: t('Schedule'), width: 250, sortable: false, hideable: false, renderCell: ({ row }) => (
          <div>{row?.schedule?.length > 20 ? <Tooltip placement="top" title={row?.schedule} arrow><div>{row?.schedule.slice(0, 15) + "..."}</div></Tooltip> : row?.schedule ? row?.schedule : "-"}</div>
        )
      },
      {
        field: 'batch_no', headerName: t('BatchNo'), width: 250, sortable: false, hideable: false, headerAlign: "center", align: "center", renderCell: ({ row }) => (
          <div>{row?.batch_no?.length > 20 ? <Tooltip placement="top" title={row?.batch_no} arrow><div>{row?.batch_no.slice(0, 15) + "..."}</div></Tooltip> : row?.batch_no ? row?.batch_no : "-"}</div>
        )
      },
      {
        field: 'expiry_date', headerName: t('ExpiryDate'), width: 250, sortable: false, hideable: false, headerAlign: "center", align: "center", renderCell: ({ row }) => (
          <Box component={'div'}>{row?.expiry_date ? (row?.expiry_date) : '-'}</Box>)
      },
      {
        field: 'from_quantity', headerName: t('PrevQty'), width: 250, sortable: false, hideable: false, type: "number", renderCell: ({ row }) => (
          <div>{row?.from_quantity ? row?.from_quantity : "-"}</div>
        )
      },
      {
        field: 'adjusted_quantity', headerName: t('AdjQty'), width: 250, sortable: false, hideable: false, type: "number", renderCell: ({ row }) => (
          <div>{row?.adjusted_quantity ? row?.adjusted_quantity : "-"}</div>
        )
      },
      {
        field: 'to_quantity', headerName: t('AvailableQty'), width: 250, sortable: false, hideable: false, type: "number", renderCell: ({ row }) => (
          <div>{row?.to_quantity ? row?.to_quantity : "-"}</div>
        )
      },
      {
        field: 'mrp_per_quantity', headerName: t('MRP/Qty'), width: 250, sortable: false, hideable: false, type: "number", renderCell: ({ row }) => (
          <div>{row?.mrp_per_quantity ? row?.mrp_per_quantity : "-"}</div>
        )
      },
      {
        field: 'hsn_code', headerName: t('HSNCode'), width: 250, sortable: false, hideable: false, type: "number", renderCell: ({ row }) => (
          <div>{row?.hsn_code?.length > 20 ? <Tooltip placement="top" title={row?.hsn_code} arrow><div>{row?.hsn_code.slice(0, 15) + "..."}</div></Tooltip> : row?.hsn_code ? row?.hsn_code : "-"}</div>
        )
      },
      {
        field: 'reorder_threshold', headerName: t('ReorderThreshold'), width: 250, sortable: false, hideable: false, type: "number", renderCell: ({ row }) => (
          <div>{row?.reorder_threshold?.length > 20 ? <Tooltip placement="top" title={row?.reorder_threshold} arrow><div>{row?.reorder_threshold.slice(0, 15) + "..."}</div></Tooltip> : row?.reorder_threshold ? row?.reorder_threshold : "-"}</div>
        )
      },
      {
        field: 'expiry_alert_period', headerName: t('ExpiryPeriod'), width: 250, sortable: false, hideable: false, type: "number", renderCell: ({ row }) => (
          <div>{row?.expiry_alert_period?.length > 20 ? <Tooltip placement="top" title={row?.expiry_alert_period} arrow><div>{row?.expiry_alert_period.slice(0, 15) + "..."}</div></Tooltip> : row?.expiry_alert_period ? row?.expiry_alert_period : "-"}</div>
        )
      }, {
        field: 'physical_box_no', headerName: t('PhyBoxNo'), width: 250, sortable: false, hideable: false, type: "number", renderCell: ({ row }) => (
          <div>{row?.physical_box_no?.length > 20 ? <Tooltip placement="top" title={row?.physical_box_no} arrow><div>{row?.physical_box_no.slice(0, 15) + "..."}</div></Tooltip> : row?.physical_box_no ? row?.physical_box_no : "-"}</div>
        )
      },
      {
        field: 'group', headerName: t('Grouping'), width: 250, sortable: false, hideable: false, type: "number", renderCell: ({ row }) => (
          <div>{row?.group?.length > 20 ? <Tooltip placement="top" title={row?.group} arrow><div>{row?.group.slice(0, 15) + "..."}</div></Tooltip> : row?.group ? row?.group : "-"}</div>
        )
      },
      {
        field: 'comments', headerName: t('Reason'), width: 250, sortable: false, hideable: false, headerAlign: "center", align: "center", renderCell: ({ row }) => (
          <div>{row?.comments?.length > 20 ? <Tooltip placement="top" title={row?.comments} arrow><div>{row?.comments.slice(0, 15) + "..."}</div></Tooltip> : row?.comments ? row?.comments : "-"}</div>
        )
      },
      {
        field: 'sales_discount_for_bill', headerName: t('SalesDiscount%'), width: 250, sortable: false, hideable: false, type: "number", renderCell: ({ row }) => (
          <div>{row?.sales_discount_for_bill?.length > 20 ? <Tooltip placement="top" title={row?.sales_discount_for_bill} arrow><div>{row?.sales_discount_for_bill.slice(0, 15) + "..."}</div></Tooltip> : row?.sales_discount_for_bill ? row?.sales_discount_for_bill : "-"}</div>
        )
      }
    ];
    return (
      <Box id="eMed_pharma_settings_stkTbl">
        <DataGrid
          rows={historyDataList ? historyDataList : []}
          columns={historyColumn}
          getRowId={(row) => row.sno}
          disableColumnMenu
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          headerHeight={40}
        />
      </Box>
    )
  }

  printStockCrt = () => {
    try {
      RestAPIService.getAll(`${Serviceurls.PHARMA_STOCK_CORRECTION_LIST}?brand_name=${""}&export_type=${"pdf"}`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getStockCrtExcel = () => {
    const { t } = this.props;
    try {
      RestAPIService.excelGet(`${Serviceurls.PHARMA_STOCK_CORRECTION_LIST}?export_type=${"excel"}&brand_name=${""}`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
          }
        }).catch((error) => {
          this.errorMessage(error.response.data?.message)
        })
    } catch (error) {
      this.errorMessage(t("PrintError"))
    }
  }

  renderTable = () => {
    // const filtereddata = this.state.StockDataList.filter((data) => {
    //   return (
    //     (data.drug_name === null ? null : data.drug_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
    //     (data.generic_name === null ? null : data.generic_name.toLowerCase().includes(this.state.SearchText.toLowerCase()))
    //   );
    // });
    this.state.StockDataList.forEach((element, index) => {
      let page = this.state.page
      let pageSize = this.state.pageSize
      let serial = (page * pageSize) + (index + 1)
      element.serial = serial
      // element.sno = index + 1
    })
    const { t } = this.props
    const columns = [
      {
        field: "serial", flex: 0.03, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.serial ? params?.row?.serial : "-"}</Box>)
      },
      {
        field: "drug_name", flex: 0.15, headerName: t("DrugDetails"), headerAlign: "left", align: "left",
        renderCell: (params) => {
          let DosageTypeHSN = `${params?.row?.dosage_type ? params?.row?.dosage_type : "-"} | ${params?.row?.hsn_code ? params?.row?.hsn_code : "-"}`
          let DosageTypeHSNTooltip = `Dosage Type : ${params?.row?.dosage_type ? params?.row?.dosage_type : "-"} | HSN Code : ${params?.row?.hsn_code ? params?.row?.hsn_code : "-"}`

          return (
            <Box component={'div'}>
              <Typography fontSize={"0.8vw"} fontWeight={600}>{params?.row?.drug_name ? params?.row?.drug_name : "-"}</Typography>
              <Typography fontSize={"0.8vw"}>{params?.row?.generic_name ? params?.row?.generic_name : "-"}</Typography>
              <Box>
                <Tooltip placement='right' title={DosageTypeHSNTooltip} arrow>
                  {DosageTypeHSN.length > 15 ? <Typography fontSize={"0.8vw"}>{DosageTypeHSN.slice(0, 15) + "..."}</Typography> :
                    <Typography fontSize={"0.8vw"}>{DosageTypeHSN}</Typography>}
                </Tooltip>
              </Box>
            </Box>
          )
        }
      },
      {
        field: "drug_strength", flex: 0.1, headerName: t("Strength"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.drug_strength ? params?.row?.drug_strength : "-"}</Box>)
      },
      {
        field: "schedules", flex: 0.1, headerName: t("Schedule"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.schedules ? params?.row?.schedules : "-"}</Box>)
      },
      {
        field: "batch_no", flex: 0.1, headerName: t("Batch No"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.batch_no ? params?.row?.batch_no : "-"}</Box>)
      },
      {
        field: "expiry_date", flex: 0.1, headerName: t("Expiry Date"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.expiry_date ? params?.row?.expiry_date : "-"}</Box>)
      },
      {
        field: "stock_quantity", flex: 0.1, headerName: t("Avail Qty"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.stock_quantity ? params?.row?.stock_quantity : "-"}</Box>)
      },
      {
        field: "free_quantity", flex: 0.1, headerName: t("Free Qty"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.free_quantity ? params?.row?.free_quantity : "-"}</Box>)
      },
      {
        field: "mrp_per_quantity", flex: 0.1, headerName: t("MRP/Qty"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.mrp_per_quantity ? params?.row?.mrp_per_quantity : "-"}</Box>)
      },
      {
        field: "buying_price", flex: 0.1, headerName: t("Buying Price Per Unit"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.buying_price ? params?.row?.buying_price : "-"}</Box>)
      },
      {
        field: "reorder_threshold", flex: 0.1, headerName: t("Reorder Threshold"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.reorder_threshold ? params?.row?.reorder_threshold : "-"}</Box>)
      },
      {
        field: "expiry_alert_period", flex: 0.1, headerName: t("Expiry Alert"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.expiry_alert_period ? params?.row?.expiry_alert_period : "-"}</Box>)
      },
      {
        field: "physical_box_no", flex: 0.1, headerName: t("Box No"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.physical_box_no ? params?.row?.physical_box_no : "-"}</Box>)
      },
      {
        field: "grouping", flex: 0.1, headerName: t("Grouping"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.grouping ? params?.row?.grouping : "-"}</Box>)
      },
      {
        field: "action", flex: 0.1, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          {userAccess?.editAccess ?
            <Tooltip title={t("Edit Durg")} placement="top" arrow>
              <Button emed_tid="sc_edit_dug" className='eMed_usrconf_btn' onClick={() => {
                this.setState({
                  isEditStockPopUp: true, SelectedDurg: params?.row
                })
              }}>
                <img src={ImagePaths.LabEditButton.default} alt="edit" className='eMed_action_img' />
              </Button>
            </Tooltip> : null}
          <Tooltip title={t("View Drug")} placement="top" arrow>
            <Button emed_tid="sc_view_dug" className='eMed_usrconf_btn' onClick={() => {
              this.setState({
                isEditStockPopUp: true, SelectedDurg: params?.row, isForViewOnly: true
              })
            }}>
              <img src={ImagePaths.LabViewButton.default} alt="view" className='eMed_action_img' />
            </Button>
          </Tooltip>
          {this.MenuList(params?.row)}
        </Box>
      }
    ]
    return (
      <Box component={"div"} className='eMed_Purcahse_Table_Container'>
        <Stack component={"div"}>
          <Paper className='eMed_Purchase_Table_Wrapper' >
            <DataGrid
              rows={this.state.StockDataList}
              columns={columns}
              getRowId={(row) => row['serial']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    physical_box_no: false,
                    grouping: false,
                    expiry_alert_period: false,
                    free_quantity: false,
                    buying_price: false,
                  }
                }
              }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              paginationMode='server'
              rowCount={this.state.rowCount}
              onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.GetStockDataList()) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.GetStockDataList())}
              loading={this.state.isLoader}
              density='comfortable'
            />
          </Paper>
        </Stack>
      </Box>
    )
  }

  onStockEditClose(ApiCall = false, Msg = "") {
    this.setState({
      isEditStockPopUp: false,
      SelectedDurg: {},
      isForViewOnly: false
    }, () => {
      if (ApiCall) {
        this.GetStockDataList()
        this.successMessage(Msg)
      }
    })
  }

  renderStockEditPopUp = () => {
    const { t } = this.props
    return (
      <Modal open={this.state.isEditStockPopUp}>
        <EditStockPopUp SelectedDurg={JSON.stringify(this.state.SelectedDurg)} PopUpclose={this.onStockEditClose.bind(this)} isForViewOnly={this.state.isForViewOnly} />
      </Modal>
    )
  }

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  renderFilterScreen = () => {
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{('Filter')}</Typography>
          <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box component={'div'} p={'2vw 1vw 1vw 1vw'} maxHeight={'32vw'} overflow={'auto'}>
          <TextField
            size='small'
            sx={{ width: '20vw' }}
            label='Batch Number'
            placeholder='Enter Batch Number'
            autoComplete='off'
            value={this.state.batchNo}
            onChange={(e) => {
              this.setState({ batchNo: e.target.value }, () => {
                if (this.state.batchNo === '') {
                  this.setState({ includeZero: false })
                }
              })
            }}
          />
          <FormControlLabel control={<Checkbox size='small' disabled={this.state.batchNo === ''} checked={this.state.includeZero} onChange={(e) => this.setState({ includeZero: e.target.checked })} />} label="Search Include Zero Quantity" />
        </Box>
        <Box component={'div'} display={'flex'} justifyContent={'center'}>
          <Button variant='outlined' sx={{ height: '2vw', mr: '1vw', textTransform: 'capitalize' }}
            onClick={() => this.setState({
              batchNo: '',
              includeZero: false,
              FilterOpen: false
            },() => this.GetStockDataList())}
          >Clear</Button>
          <Button variant='contained' sx={{ height: '2vw', mr: '1vw', textTransform: 'capitalize' }}
            onClick={() => { this.setState({ FilterOpen: false }, () => this.GetStockDataList()) }}
          >Search</Button>
        </Box>
      </Box>
    )
  }


  render() {
    const { t } = this.props;
    return (
      <div>
        <div className='eMed_Purchase_Table_top' style={{ justifyContent: 'space-between' }}>
          <div></div>
          <div className='eMed_Purchase_Table_top' style={{ justifyContent: 'space-between' }}>
            <Box className="eMed_Search_Box_">
              <TextField
                variant='standard'
                placeholder='Search Drug'
                value={this.state.SearchText}
                onChange={(e) => {
                  this.setState({ SearchText: e.target.value },
                    () => {
                      if (this.state.SearchText?.length >= 2 || this.state.SearchText?.length === 0) {
                        this.GetStockDataList()
                      }
                    })
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ emed_tid: "sc_drug_search" }}
                sx={{ width: '15vw' }} />
              <Box component={'div'}>
                <Button className='Common_Btn_Min_Width'
                  sx={{ height: '2vw', width: '2vw', ml: '0.5vw' }}
                  onClick={() => { this.setState({ FilterOpen: true }) }}
                >
                  <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Box>
            </Box>

            {/* <Tooltip title="Print" placement='top' arrow>
              <Button className='eMed_usrconf_btn' emed_tid="sc_print" onClick = {() => this.printStockCrt()}>
                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
              </Button></Tooltip> */}

            {/* <Tooltip title="Export" placement='top' arrow>
            <Button className='eMed_usrconf_btn' emed_tid="sc_export" onClick = {() => this.getStockCrtExcel()}>
              <img src={ImagePaths.LabUploadButton.default} alt="export" className='eMed_action_img' />
            </Button></Tooltip> */}
          </div>
        </div>
        {this.renderTable()}
        {this.renderStockEditPopUp()}
        {this.state.selectedOption === "History" ?
          <CommonPopUp width={"75vw"}
            backgroundColor={Colors.white}
            popUpClose={this.stockHistoryClose.bind(this)}
            title={t("History")}
            component={this._showStockHistory.bind(this)} />
          : null
        }
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
      </div>
    )
  }
}
export default withTranslation()(PharmaStockCorrection)