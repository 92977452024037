import { Autocomplete, Box, Button, Checkbox, Drawer, FormControl, FormControlLabel, Grow, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { CommonGridToolBarWithFilterText, CommonPatientDetails, CommonTimeRangePicker } from '../../../Components/Common Components/CommonComponents';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { formatDate, AmountFormat, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../Styles/Colors';
import { CurrencySymbol } from '../../../Utility/Constants';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

const PatientType = [
    { patient_type: "New Patients", value: "new_patient" },
    { patient_type: "Review Patients", value: "review_patient" },
    { patient_type: "Old Patients", value: "established_patient" }
]

const Surgery_Status = ['Arrived', "Requested", 'Booked', 'Approved', 'In Surgery', 'Completed', 'Rejected', 'No Show', 'Reschedule', 'Cancelled']
class OTSurgeryReports extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            doctorName: [],
            specialization: [],
            appointmentType: [],
            startDate: null,
            dateIndex: 1,
            appointmentPatientData: [],
            appointmentDoctors: null,
            appointmentTotalPatient: null,
            TotalReschedule: null,
            TotalNoShow: null,
            TotalCompleted: null,
            TotalCancelled: null,
            appointmentTypeList: [],
            doctorNameList: [],
            specializationList: [],
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            showBox: false,
            showNPcard: false,
            patientType: [],
            FilterDatas: {
                "startTime": null,
                "endTime": null,
                "onError": false,
            },
            timeError: false,
            isOPT: false,
            selectedSurgeryStatus: []
        }
    }

    componentDidMount() {
        let logginedData = localGetItem('loggedInUserInfo')
        let loggedInUserInfo = JSON.parse(logginedData)
        this.setState({
            isOPT: loggedInUserInfo?.is_opt
        }, () => { this.getSurgeryNotesData() })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, () => {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas: this.state.FilterDatas
            })
        })
    }

    getSurgeryNotesData = () => {
        try {
            this.LoaderFunction(true)
            let appointmentDate = this.state.startDate ? DateTime.fromJSDate(this.state.startDate).toFormat('yyyy-MM-dd') : "";
            let doctorId = []; let specializationId = []; let appoinmentType = []; let patientType = [];
            this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
            this.state.specialization.forEach(element => specializationId.push(element.id))
            this.state.appointmentType.forEach(element => appoinmentType.push(element.appoinment_type))
            this.state.patientType.forEach(element => patientType.push(element.value))
            // var { FilterDatas } = this.state
            // let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            // let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            // let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            // let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAll(Serviceurls.OT_SURGERY_REPORT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&approved_status=${""}&patient_search=${""}&surgeon_doctor_name=${""}&surgery_status=${this.state.selectedSurgeryStatus}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            appointmentPatientData: response.data.data,
                            appointmentTotalPatient: response.data.total_patient,
                            TotalReschedule: response.data.reschedule,
                            TotalNoShow: response.data.no_show,
                            TotalCompleted: response.data.completed,
                            TotalCancelled: response.data.cancelled,
                            // FilteredFromDate: this.state.fromDate,
                            // FilteredToDate: this.state.toDate
                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    printReports = () => {
        try {
            let appointmentDate = this.state.startDate ? DateTime.fromJSDate(this.state.startDate).toFormat('yyyy-MM-dd') : "";
            let doctorId = []; let specializationId = []; let appoinmentType = [];
            this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
            this.state.specialization.forEach(element => specializationId.push(element.id))
            this.state.appointmentType.forEach(element => appoinmentType.push(element.appoinment_type))
            var { FilterDatas } = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.excelGet(Serviceurls.OT_SURGERY_REPORT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&approved_status=${""}&patient_search=${""}&surgeon_doctor_name=${""}&surgery_status=${this.state.selectedSurgeryStatus}&export_type=excel`)
                .then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data?.message
                    })
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    getDoctorNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            doctorNameList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getAppointmentTypeList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPAPPOINTMENT)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            appointmentTypeList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getSpecializationList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPLGET)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            specializationList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    selectedTime = (start, end, error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }

    onChangeCheckBox = (e, key) => {
        try {
            let states = this.state
            states[key] = e.target.checked
            this.setState({ states })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    {/* <Box sx={{ width: '22vw' }}>
                        <CommonTimeRangePicker
                            startTime={this.state.FilterDatas?.startTime}
                            endTime={this.state.FilterDatas?.endTime}
                            selectedTime={this.selectedTime.bind(this)}
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            onError={this.state.FilterDatas.onError}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.doctorNameList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectDoctor')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker
                                inputFormat='DD-MM-YYYY'
                                label={t('SelectFrom')}
                                value={this.state.startDate}
                                name="startDate"
                                onChange={(newDate) => { this.setState({ startDate: newDate.$d }) }}
                                renderInput={(params) => <TextField style={{ width: '20vw' }} size='small' {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.specializationList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.name)}
                            value={this.state.specialization}
                            onChange={(event, newValue) => {
                                this.setState({ specialization: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectSpecial')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.appointmentTypeList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.appoinment_type)}
                            value={this.state.appointmentType}
                            onChange={(event, newValue) => {
                                this.setState({ appointmentType: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectAppointType')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={PatientType?.map((item) => item)}
                            getOptionLabel={(option) => (option?.patient_type)}
                            value={this.state.patientType}
                            onChange={(event, newValue) => {
                                this.setState({ patientType: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectPatientType')}
                                />
                            )}
                        />
                    </Box> */}
                    <Box component={'div'}>
                        <Autocomplete
                            multiple
                            limitTags={2}
                            size='small'
                            options={Surgery_Status}
                            getOptionLabel={(options) => options}
                            onChange={(e, value) => {
                                this.setState({
                                    selectedSurgeryStatus: value
                                })
                            }}
                            disableCloseOnSelect
                            disablePortal
                            autoComplete='off'
                            value={this.state.selectedSurgeryStatus}
                            id="combo-box-demo"
                            sx={{ width: "21vw" }}
                            renderInput={(params) => <TextField {...params} label={'Select Surgery Status'} size='small' />}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                doctorName: [], startDate: null,
                                specialization: [], appointmentType: [], patientType: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime": null,
                                    "endTime": null,
                                    "onError": false
                                },
                                timeError: false, selectedSurgeryStatus: []
                            }, () => this.getSurgeryNotesData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getSurgeryNotesData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    renderApproval = (Data) => {
        var foApproval = ""; var doctorApproval = ""; var otApproval = ""
        if (Data.is_fo_approved || Data.is_doctor_approved || Data.is_ot_approved) {
            if (Data.is_fo_approved) {
                foApproval = "FO"
            }
            if (Data.is_doctor_approved) {
                doctorApproval = "DOCTOR"
            }
            if (Data.is_ot_approved) {
                otApproval = "OT"
            }
        }
        return <div>{`${foApproval}${(foApproval !== "" && doctorApproval !== "") ? ", " + doctorApproval : doctorApproval}${((doctorApproval !== "" || foApproval !== "") && otApproval !== "") ? ", " + otApproval : otApproval}`}</div>
    }

    render() {
        this.state.appointmentPatientData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "patient_name", headerName: t("PatientName"), flex: 0.178,
                renderCell: (params) => (<CommonPatientDetails data={params?.row} showDetailed={true} />)
            },
            {
                field: "surgery_date", headerName: t("Surgery Date/Time"), flex: 0.122, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.surgery_date ? (params?.row?.surgery_date) : '-'}</Box>)
            },
            {
                field: "surgery_name", headerName: t("Surgery"), flex: 0.131,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.surgery_name ? params?.row?.surgery_name?.length > 20 ?
                    <div><Tooltip placement='top' title={params?.row?.surgery_name} arrow><div>{params?.row?.surgery_name.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.surgery_name : "-"}</Box>)
            },
            {
                field: "surgeon_name", headerName: t("Surgeon Doctor"), flex: 0.141,
                renderCell: (params) => (
                    <Box>
                        {params?.row?.surgeon_name?.length > 20 ?
                        <Tooltip placement='top' title={params?.row?.surgeon_name}><Typography fontSize={'0.9vw'}>{params?.row?.surgeon_name.slice(0, 20) + "..."}</Typography></Tooltip> : <Typography fontSize={'0.9vw'}>{params?.row?.surgeon_name ? params?.row?.surgeon_name : '-'}</Typography>}
                        {params?.row?.department_name?.length > 20 ?
                        <Tooltip placement='top' title={params?.row?.department_name}><Typography fontSize={'0.75vw'}>{params?.row?.department_name.slice(0, 20) + "..."}</Typography></Tooltip> : <Typography fontSize={'0.75vw'}>{params?.row?.department_name ? params?.row?.department_name : '-'}</Typography>}
                    </Box>
                )
            },
            {
                field: "anaesthetist", headerName: t("Anaesthetist"), flex: 0.141,
                renderCell: (params) => (
                    <Box>
                        <Box component={'div'}>{params?.row?.anaesthetist ? params?.row?.anaesthetist?.length > 20 ?
                             <div><Tooltip placement='top' title={params?.row?.anaesthetist} arrow><div>{params?.row?.anaesthetist.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.anaesthetist : "-"}
                        </Box>
                        <Box component={'div'}>{params?.row?.anaesthesia_type? params?.row?.anaesthesia_type?.length > 20 ?
                            <div><Tooltip placement='top' title={params?.row?.anaesthesia_type} arrow><div>{params?.row?.anaesthesia_type.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.anaesthesia_type :"-"}
                        </Box>
                       
                    </Box>)
            },
            {
                field: "package_name", headerName: t("Package Details"), flex: 0.141,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.package_name ? params?.row?.package_name?.length > 20 ?
                    <div><Tooltip placement='top' title={params?.row?.package_name} arrow><div>{params?.row?.package_name.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.package_name : "-"}</Box>)
            },
            // {
            //     field: "amount_net", headerName: `${t("Approved By")}`, flex: 0.131,
            //     renderCell: (params) => (<Box component={'div'}>{this.renderApproval(params?.row)}</Box>)
            // },
            {
                field: "status", headerName: t("Surgery Status"), flex: 0.131,
                renderCell: (params) => (<Box component={'div'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.status ? params?.row?.status : null}</Typography>
                    {params?.row?.cancel_reason ? params?.row?.cancel_reason?.length > 20 ?
                        <Tooltip placement='top' title={params?.row?.cancel_reason}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.cancel_reason?.slice(0, 20) + '...'}</Typography></Tooltip> :
                        <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.cancel_reason ? params?.row?.cancel_reason : null}</Typography> :

                        params?.row?.reschedule_reason ? params?.row?.reschedule_reason?.length > 20 ?
                            <Tooltip placement='top' title={params?.row?.reschedule_reason}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.reschedule_reason?.slice(0, 20) + '...'}</Typography></Tooltip> :
                            <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.reschedule_reason ? params?.row?.reschedule_reason : null}</Typography> : null
                    }</Box>)
            }
        ]
        const Power_columns = [
            {
                field: "patient_name", headerName: t("PatientName"), flex: 0.2,
                renderCell: (params) => (<CommonPatientDetails data={params?.row} showDetailed={true} />)
            },
            {
                field: "surgery_date", headerName: t("Surgery Date/Time"), flex: 0.122, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.surgery_date ? formatDate(params?.row?.surgery_date) : '-'}</Box>)
            },
            {
                field: "surgery_name", headerName: t("Surgery"), flex: 0.131,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.surgery_name ? params?.row?.surgery_name?.length > 20 ?
                    <div><Tooltip placement='top' title={params?.row?.surgery_name} arrow><div>{params?.row?.surgery_name.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.surgery_name : "-"}</Box>)
            },
            {
                field: "iol_power", headerName: t("Power"), flex: 0.1,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.iol_power ? params?.row?.iol_power?.length > 20 ?
                    <div><Tooltip placement='top' title={params?.row?.iol_power} arrow><div>{params?.row?.iol_power.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.iol_power : "-"}</Box>)
            },
            {
                field: "surgeon_name", headerName: t("Surgeon Doctor"), flex: 0.141,
                renderCell: (params) => (
                    <Box>
                        {params?.row?.surgeon_name?.length > 20 ?
                            <Tooltip placement='top' title={params?.row?.surgeon_name}><Typography fontSize={'0.9vw'}>{params?.row?.surgeon_name.slice(0, 20) + "..."}</Typography></Tooltip> : <Typography fontSize={'0.9vw'}>{params?.row?.surgeon_name ? params?.row?.surgeon_name : '-'}</Typography>}
                        {params?.row?.department_name?.length > 20 ?
                            <Tooltip placement='top' title={params?.row?.department_name}><Typography fontSize={'0.75vw'}>{params?.row?.department_name.slice(0, 20) + "..."}</Typography></Tooltip> : <Typography fontSize={'0.75vw'}>{params?.row?.department_name ? params?.row?.department_name : '-'}</Typography>}
                    </Box>
                )
            },
            {
                field: "anaesthetist", headerName: t("Anaesthetist"), flex: 0.141,
                renderCell: (params) => (
                    <Box>
                        <Box component={'div'}>{params?.row?.anaesthetist ? params?.row?.anaesthetist?.length > 20 ?
                             <div><Tooltip placement='top' title={params?.row?.anaesthetist} arrow><div>{params?.row?.anaesthetist.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.anaesthetist : "-"}
                        </Box>
                        <Box component={'div'}>{params?.row?.anaesthesia_type? params?.row?.anaesthesia_type?.length > 20 ?
                            <div><Tooltip placement='top' title={params?.row?.anaesthesia_type} arrow><div>{params?.row?.anaesthesia_type.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.anaesthesia_type :"-"}
                        </Box>
                       
                    </Box>)
            },
            {
                field: "package_name", headerName: t("Package Details"), flex: 0.141,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.package_name ? params?.row?.package_name?.length > 20 ?
                    <div><Tooltip placement='top' title={params?.row?.package_name} arrow><div>{params?.row?.package_name.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.package_name : "-"}</Box>)
            },
            // {
            //     field: "amount_net", headerName: `${t("Approved By")}`, flex: 0.141,
            //     renderCell: (params) => (<Box component={'div'}>{this.renderApproval(params?.row)}</Box>)
            // },
            {
                field: "status", headerName: t("Surgery Status"), flex: 0.131, align: "center", headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.status ? params?.row?.status : "-"}</Typography>
                    {params?.row?.cancel_reason ? params?.row?.cancel_reason?.length > 15 ?
                        <Tooltip placement='top' title={params?.row?.cancel_reason}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.cancel_reason?.slice(0, 15) + '...'}</Typography></Tooltip> :
                        <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.cancel_reason ? params?.row?.cancel_reason : null}</Typography> :

                        params?.row?.reschedule_reason ? params?.row?.reschedule_reason?.length > 15 ?
                            <Tooltip placement='top' title={params?.row?.reschedule_reason}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.reschedule_reason?.slice(0, 15) + '...'}</Typography></Tooltip> :
                            <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.reschedule_reason ? params?.row?.reschedule_reason : null}</Typography> : null
                    }
                </Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Total Surgery"), this.state.appointmentTotalPatient, true)}
                        {AmountsCard(t("Completed"), this.state.TotalCompleted, true)}
                        {AmountsCard(t("Rescheduled"), this.state.TotalReschedule, true)}
                        {AmountsCard(t("Cancelled"), this.state.TotalCancelled, true)}
                        {AmountsCard(t("No show"), this.state.TotalNoShow, true)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        {/* <Button className='eMed_usrconf_btn' onClick={() => { this.printReports() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button> */}
                        <Button className='eMed_usrconf_btn' onClick={() => { this.printReports() }}>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.appointmentPatientData}
                        columns={this.state.isOPT ? Power_columns : columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(OTSurgeryReports)