import React, { Component } from "react";
import './patientDetails.css';
import { Box, Paper, TextField, Button, Stack, Typography, Tooltip, Grid, IconButton, FormControl, Select, MenuItem, InputLabel, Modal } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonGridHeader, { CommonPatientDetails, CommonPatientCard } from "../../../../Components/Common Components/CommonComponents";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { Colors } from "../../../../Styles/Colors";
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { getCachevalue, localGetItem } from "../../../../Utility/Services/CacheProviderService";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { CheckAccessFunc } from "../../../../Components/CommonFunctions/CommonFunctions";
import Loader from '../../../../Components/Loader';
import { Bucket_Name } from "../../../../Utility/Constants";

let AWS = require('aws-sdk');
let s3 = new AWS.S3({
  accessKeyId: "AKIAT7TG52UHOEIYAJH4",
  secretAccessKey: "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",
  region: "ap-south-1"
});

let to_date = new Date();

let reportType = [
  { label: "Laboratory", value: "Laboratory" },
  { label: "Radiology", value: "Radiology" },
  { label: "Special", value: "Special" },
  { label: "Eye Test", value: "Eye Test" },
  { label: "Investigation", value: "Investigation" }
];
class TestReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testReport: [],
      patientData: {},
      attenderData: {},
      open: false,
      popUpOpen: true,
      reportDetails: {},
      date: to_date,
      imgUpload: [],
      selectedImage: null,
      isImgClicked: false,
      isViewClicked: false,
      viewData: [],
      viewOpen: true,
      viewImage: "",
      currentIndex: 0,
      showSuccessResponse: false,
      showErrorResponse: false,
      responseMessage: "",
      withOutAppointment: {},
      clinic_id: null,
      common_uhid: false,
    }
    this.uploadImage = React.createRef();
  }
  componentDidMount() {
    let patientDetails = this.props.fromOT ? getCachevalue("OTpatientData") : getCachevalue("patientData")
    let patients = JSON.parse(patientDetails)
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var selectedClinicId = JSON.parse(localGetItem("SelectedDoctorClinicId")) 
    var clinic_id = myUser?.clinic_id ? myUser?.clinic_id : myUser?.SelectedDoctorClinicId ? myUser?.SelectedDoctorClinicId : null
    this.setState({
      attenderData: patients,
      withOutAppointment: details,
      clinic_id: selectedClinicId ? selectedClinicId : clinic_id
    }, () => {
      this._getTestReport();
    })
  }

  getS3Key(doc_key) {

    return new Promise(resolve => {
      var parms = {
        Bucket: Bucket_Name,
        Key: doc_key, Expires: 300
      };
      s3.getSignedUrl("getObject", parms, (err, url) => {
        if (err) {
          resolve(err)
        } else {
          var data = {
            "data_url": url
          }
          resolve(data)
        }
      })
    })
  }

  getFoLabTestReportPrint = (resultSummaryId,labId, newID) => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + `?result_summary_id=${resultSummaryId}&result_item_id=${newID}&laboratory_id=${labId}`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.responseSuccessErrorMessage(error.response.data.message)
          } else {
            this.responseSuccessErrorMessage(error.message)
          }
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false);
    }
  }

  async renderImage(imageData, pdfData) {
    let tempArr = [];
    if (imageData.length > 0) {
      for (let i = 0; i < imageData.length; i++) {
        let image = imageData[i].result_img_url
        if (image !== null) {
          let doc_key = image;
          let getData = await this.getS3Key(doc_key)
          getData["type"] = "image";
          tempArr.push(getData)
        }
      }
    }
    if (pdfData.length > 0) {
      for (let i = 0; i < pdfData.length; i++) {
        let pdf = pdfData[i].result_img_url
        if (pdf !== null) {
          let pdf_key = pdf;
          let getPdf = await this.getS3Key(pdf_key)
          getPdf["type"] = "pdf"
          tempArr.push(getPdf)
        }
      }
    }
    this.setState({
      viewData: tempArr,
      viewImage: tempArr[0]
    })
  }
  _getTestReport = () => {
    let states = this.state;
    let url = "";
    this.setState({
      testReport: [],
      patientData: {},
    })
    try {
      if (states["fromDate"] && states["toDate"]) {
        url = `${Serviceurls.FO_TEST_REPORT}?patient_id=${states["attenderData"]["patient_id"]}&clinic_id=${this.state.clinic_id}&from_date=${states["fromDate"]}&to_date=${states["toDate"]}}`
      } else {
        url = `${Serviceurls.FO_TEST_REPORT}?patient_id=${states["attenderData"]["patient_id"]}&appointment_id=${states?.attenderData?.appointment_id}&clinic_id=${this.state.clinic_id}`
      } RestAPIService.getAll(url)
        .then(response => {
          let data = response?.data;
          let states = this.state;
          if (response.data.status === "success") {
            states["testReport"] = data?.data;
            states["patientData"] = data?.patient_data && data?.patient_data[0];
            states["common_uhid"] =  data?.common_uhid
            this.setState({
              states
            });
          }
        })
        .catch(error => {
          if (error.response?.data.status === "fail") {
            this.responseSuccessErrorMessage(error.response?.data.message, false);
          } else {
            this.responseSuccessErrorMessage(error.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }

  handleClear = () => {
    this.setState(
      {
        reportDetails: {},
        imgUpload: [],
        selectedImage: null,
        viewData: [],
        viewImage: null,
        date: to_date,
      })
  }

  lodaerFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  dataURItoBlob = (dataURI) => {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  handleAdd = () => {
    const { t } = this.props
    let { reportDetails, imgUpload, attenderData, date } = this.state;
    let isAvl = true;
    let Message = "";
    if (Object.keys(reportDetails).length === 0 && imgUpload.length === 0) { isAvl = false; Message = t("Fillmandatory") }
    else if (!reportDetails.report_type) { isAvl = false; Message = `${t("Enter")} ${t("ReportType")}` }
    else if (!date) { isAvl = false; Message = `${t("Enter")} ${t("Date")}` }
    else if (imgUpload.length === 0) { isAvl = false; Message = `${t("Select")} ${t("Files")}` }
    if (isAvl) {
      const dataForm = new FormData();
      dataForm.append("patient_id", attenderData.patient_id ? attenderData.patient_id : "");
      dataForm.append("appointment_id", (this.props?.fromOT ? attenderData.appointment : attenderData.appointment_id ? attenderData.appointment_id : ""));
      dataForm.append("report_date", date ? DateTime.fromJSDate(date).toFormat('yyyy-MM-dd') : DateTime.fromJSDate(to_date).toFormat('yyyy-MM-dd'));
      dataForm.append("report_type", reportDetails.report_type ? reportDetails.report_type : "");
      dataForm.append("comment", reportDetails.comment ? reportDetails.comment : "");
      dataForm.append("clinic_id", this.state.clinic_id ? this.state.clinic_id : "");
      for (let i = 0; i < imgUpload.length; i++) {
        if (imgUpload[i]["type"] === "application/pdf") {
          dataForm.append("report_file_pdf", imgUpload[i])
        }
        if (imgUpload[i]["type"] !== "application/pdf") {
          dataForm.append("report_file", imgUpload[i])
        }
      }
      try {
        this.lodaerFunction(true)
        RestAPIService.create(dataForm, Serviceurls.FO_TEST_REPORT)
          .then(response => {
            if (response.data.status === "success") {
              this.handleClear();
              this._getTestReport();
              this.lodaerFunction(false)
              this.responseSuccessErrorMessage(response.data.message, true);

            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.lodaerFunction(false)
              this.responseSuccessErrorMessage(error.response.data.message, false);
            }
          });
      } catch (e) {
        this.lodaerFunction(false)
        this.responseSuccessErrorMessage(e.message, false);
      }
    } else {
      this.responseSuccessErrorMessage(Message, false)
    }
  }

  responseSuccessErrorMessage(message, type) {
    this.setState({
      showSuccessResponse: message ? type : false,
      showErrorResponse: message ? !type : false,
      responseMessage: message
    })
  }

  onKeyDown = (e) => {
    e.preventDefault();
  };

  renderTextBox(label, statekey, value, width) {
    const { t } = this.props;
    let { reportDetails, open } = this.state;
    return (
      <div id="eMed_DocRepo_divs">
        {statekey === "report_type" ?
          <FormControl sx={{ width: width ? width : "14.5vw" }} size="small">
            <InputLabel>{t(label)}</InputLabel>
            <Select
              value={value ? value : ""}
              name={statekey}
              label={t(label) + "*"}
              onChange={(event) => {
                reportDetails["report_type"] = event.target.value;
                this.setState({
                  reportDetails
                })
              }}
              MenuProps={{
                style: { maxHeight: 350 },
              }}
            >
              {
                reportType?.length > 0 ? reportType.map((list, index) => (
                  <MenuItem key={index} value={list.value} >{list.label}</MenuItem>
                ))
                  : null
              }
            </Select>
          </FormControl>
          :
          statekey === "date" ?
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // open={open}
                // onOpen={() => {
                //   this.setState({
                //     open: true
                //   })
                // }}
                // onClose={() => {
                //   this.setState({
                //     open: false
                //   })
                // }}
                inputFormat="dd-MM-yyyy"
                label={label ? t(label) : ""}
                name={statekey}
                value={value ? value : null}
                autoComplete="off"
                maxDate={DateTime.now()}
                onChange={
                  (value) => {
                    this.setState({
                      date: value
                    })
                  }
                }
                renderInput={(params) => <TextField onKeyDown={(e) => this.onKeyDown(e)} size="small" sx={{ width: width ? width : "14.5vw" }} {...params} onClick={() => { this.setState({ open: true }) }} />}
              />
            </LocalizationProvider>
            : <TextField
              label={label ? t(label) : ""}
              name={statekey}
              value={value ? value : ""}
              size="small"
              sx={{ width: width ? width : "14.5vw" }}
              multiline={statekey === "comment" ? true : false}
              rows={statekey === "comment" ? 3 : null}
              onChange={(event) => {
                reportDetails["comment"] = event.target.value.trimStart()
                this.setState({ reportDetails })
              }}
            />
        }
      </div>
    )
  }

  popUpClose = () => {
    this.setState({
      isImgClicked: false,
      popUpOpen: false,
      selectedImage: null
    })
  }

  popUpViewClose = () => {
    this.setState({
      isViewClicked: false,
      viewOpen: false,
      currentIndex: 0,
      viewData: [],
      viewImage: null
    })
  }

  onImageRemove = (index) => {
    let { imgUpload } = this.state;
    // let deletedArr = [...imgUpload]
    // let index = deletedArr.findIndex(image => String(image["name"]).toLowerCase() === String(imgName).toLowerCase())
    imgUpload.splice(index, 1)
    this.setState({
      imgUpload: this.state.imgUpload,
    })
  }

  handleChange = async (e) => {
    const imageList = [];
    let imageFiles = [...e.target.files];
    let clearedArr = imageFiles?.filter((image, i) => {
      let file = image?.name;
      return String(file).toLowerCase() !== String(imageFiles[i + 1]?.name).toLowerCase()
    });
    // convert images to base64;
    for (let i = 0; i < clearedArr.length; i++) {
      imageList.push(clearedArr[i])
    }
    const newList = await Promise.all(imageList)
    this.setState({
      imgUpload: newList
    })
  }

  _getBase64(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      const { type, name, size } = file;
      reader.addEventListener('load', () => {
        resolve({
          name,
          type,
          size,
          data_url: reader.result
        })
      });
      reader.readAsDataURL(file);
    })
  }
  renderImgCard = () => {
    const { t } = this.props;
    let imageListLen = this.state.imgUpload.length;
    return (
      <Stack className="eMed_DocRepo_imgDiv" justifyContent="center" alignItems="center" sx={{ backgroundColor: Colors.SecondaryFill }}>
        <input
          type="file"
          multiple
          // accept="application/pdf, image/jpg, image/jpeg, image/png"
          accept="image/jpg, image/jpeg, image/png"
          onChange={this.handleChange}
          ref={this.uploadImage}
          style={{ display: "none" }}
        />
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
          <Stack className="eMed_DocRepo_imgSubDiv" justifyContent="center" alignItems="center" spacing={0.5}>
            <IconButton size="small" disabled={imageListLen === 6 || this.state.withOutAppointment?.is_op ? true : false} onClick={() => this.uploadImage.current.click()}><img id="eMed_upload_img" src={imageListLen === 6 ? ImagePaths.LabUploadButton.default : ImagePaths.Export.default} alt="Upload" /></IconButton>
            <Typography id="eMed_img_lblTxt" >{imageListLen === 6 ? t("MaxUpload") : t("uploadFile")}</Typography>
            {imageListLen === 6 ? null : <Typography id="eMed_img_subTxt" sx={{ marginLeft: "0.5vw" }}>{t("imgFormat")}</Typography>}
          </Stack>
          {
            imageListLen > 0 ?
              <Stack className="eMed_Testrep_Img_item" direction="row" justifyContent={imageListLen <= 5 ? "center" : "flex-start"} alignItems="center">
                {imageListLen > 0 && this.state.imgUpload.map((image, index) => (
                  <div key={index} className="eMed_Testrep_Img" style={{ marginRight: "0.5vw" }}>
                    <Tooltip title={typeof (image) === "string" ? null : image?.name} arrow placement='top'>
                      <img
                        onClick={() => {
                          if (typeof (image) === "string") {
                            if (image.split('.').pop() === "jpg" || image.split('.').pop() === "jpeg" || image.split('.').pop() === "JPG" || image.split('.').pop() === "png") {
                              this.setState({ isImgClicked: true, popUpOpen: true, selectedImage: image })
                            }
                            else {
                              window.open(image)
                            }
                          }
                        }}
                        src={typeof (image) === "string" ?
                          (image.split('.').pop() === "jpg" || image.split('.').pop() === "jpeg" || image.split('.').pop() === "JPG" || image.split('.').pop() === "png" ?
                            image : ImagePaths.DocumentPreview.default) : ((image?.type === "image/jpeg" || image?.type === "image/png") ? ImagePaths.ImagePreview.default : ImagePaths.DocumentPreview.default)}
                        className='eMed_Testrep_Img' /></Tooltip>
                    <div><IconButton id="eMed_Testrep_Icon" size="small"
                      onClick={() => {
                        this.setState({ selectedImage: null }, () => { this.onImageRemove(index) });
                      }}>
                      <CancelTwoToneIcon fontSize='small' color="error" />
                    </IconButton></div>
                  </div>
                ))}
              </Stack>
              : null
          }
        </Stack>
      </Stack>
    )
  }

  prevImage = (index) => {

    let { viewData } = this.state;

    let currentIndex = parseInt(index) || 0;

    if (currentIndex >= 1) {
      this.setState({
        currentIndex: currentIndex - 1,
        viewImage: viewData[currentIndex - 1]
      })
    }
  }

  nextImage = (index) => {

    let { viewData } = this.state;

    let currentIndex = parseInt(index) || 0;

    if ((currentIndex + 1) < viewData.length) {
      this.setState({
        currentIndex: currentIndex + 1,
        viewImage: viewData[currentIndex + 1]
      })
    }
  }
  msgClose() {
    this.setState({
      showSuccessResponse: false,
      showErrorResponse: false,
      responseMessage: ''
    })
  }

  render() {
    const { t } = this.props;
    let { reportDetails, testReport, popUpOpen, viewData, viewOpen, date } = this.state;
    testReport?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'report_date', headerAlign: "center", headerName: t('Date'), align: "center", flex: 0.20, renderCell: (cellvalues) => {
          return (<Typography>{cellvalues.row.report_date ? cellvalues.row.report_date : "-"}</Typography>)
        }
      },
      {
        field: 'report_type', headerName: t('ReportType'), flex: 0.20, renderCell: (cellvalues) => {
          return (
            <div>{cellvalues.row?.report_type?.length > 20 ? <Tooltip placement="top" title={cellvalues.row?.report_type} arrow><div>{cellvalues.row?.report_type.slice(0, 15) + "..."}</div></Tooltip> : cellvalues.row.report_type ? cellvalues.row.report_type : "-"}</div>
          )
        }
      },
      {
        field: 'report_name', headerName: t('TestDetails'), flex: 0.30, renderCell: (cellvalues) => {
          return (
            <div>{cellvalues.row?.report_name?.length > 20 ? <Tooltip placement="top" title={cellvalues.row?.report_name} arrow><div>{cellvalues.row?.report_name.slice(0, 15) + "..."}</div></Tooltip> : cellvalues.row.report_name ? cellvalues.row.report_name : "-"}</div>
          )
        }
      },
      {
        field: 'actions', headerAlign: "center", headerName: t('Status'), flex: 0.20, align: "center", sortable: false, hideable: false, renderCell: ({ row }) => {
          let imageFile = row.file_name.length > 0 ? row.file_name : [];
          let pdfFile = row.pdf_file?.length > 0 ? row.pdf_file : [];
          return (
            <Box component={'div'}>
              {(row?.status === 'Result Inprogress') || (row?.status === "Specimen Collected") ? row?.status :
                ((row?.status === 'Result Delivered') || (row?.status === 'Result Completed')) ?
                  <Button sx={{ textTransform: 'capitalize' }} className='Common_Btn_Min_Width'
                    onClick={() => { this.getFoLabTestReportPrint(row?.id, row?.laboratory_id, row.result_item_id) }}
                  >View</Button>
                  :
                  <Button sx={{ textTransform: 'capitalize' }} className='Common_Btn_Min_Width'
                    onClick={() => {
                      if (row?.file_name?.length > 0) {
                        this.setState({
                          isViewClicked: true,
                          viewOpen: true,
                          viewData: [],
                          viewImage: null
                        }, () => this.renderImage(imageFile, pdfFile))
                      } else {
                        this.responseSuccessErrorMessage('No image to be view',false)
                      }
                    }}
                  >View</Button>
              }
            </Box>
            // <Typography sx={{ color: Colors.Primary, cursor: "pointer" }}
            //   onClick={() => {
            //     this.setState({
            //       isViewClicked: true,
            //       viewOpen: true,
            //       viewData: [],
            //       viewImage: null
            //     },
            //       () => { this.renderImage(imageFile, pdfFile) })
            //   }}>{t("View")}</Typography>
          )
        }, disableExport: true
      }
    ]
    let branchFeild = {
      field: 'branch_name', headerName: t('Branch Name'), flex: 0.20, renderCell: ({ row }) => {
        return (<div>{row?.branch_name?.length > 18 ? <Tooltip placement="top" title={row?.branch_name} arrow><div>{row?.branch_name.slice(0, 15) + "..."}</div></Tooltip> : row.branch_name ? row.branch_name : "-"}</div>)
      }
    }
    if (this.state.common_uhid) {
      columns.splice(2, 0, branchFeild)
    }
    let data = {
      lineOne: t("AttenderName"),
      lineTwo: `${this.state.patientData?.attender_name ? this.state.patientData?.attender_name : this.state.attenderData.attender_name ? this.state.attenderData.attender_name :  "-"}`,
      lineFour: t('ContactNumber'),
      LineFive: `${this.state.patientData?.attender_mobile ? this.state.patientData?.attender_mobile :this.state.attenderData.attender_mobile ? this.state.attenderData.attender_mobile : "-"}`
    }
    return (
      <Box id="eMed_DocRepo_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
        <Grid container className="eMed_DocRepo_Box">
          <Stack className="eMed_card_header" direction="row" justifyContent="space-evenly" alignItems="center" spacing={1} >
            <Stack className="eMed_card_div" sx={{ width: "23vw", backgroundColor: Colors.white }} >
              <CommonPatientDetails data={this.state.attenderData} />
            </Stack>
            <CommonPatientCard details={data} showDetailed={true} />
          </Stack>
          <Grid container item spacing={3.5} className="eMed_DocRepo_mainDiv">
            <Grid item xs={8.1}>
              <Paper className="eMed_DocRepo_Div" sx={{ height: this.props.fromOT ? "63vh" : "70vh" }} elevation={1}>
                <DataGrid
                  rows={testReport}
                  columns={columns}
                  getRowId={(row) => row['sno']}
                  localeText={{
                    toolbarColumns: "",
                    toolbarDensity: "",
                    toolbarExport: "",
                    toolbarFilters: "",
                    toolbarExportPrint: ""
                  }}
                  disableSelectionOnClick
                  components={{
                    Toolbar: CommonGridHeader.CommonToolBar,
                    NoRowsOverlay: () => {
                      return (
                        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                          {t("NoRecordsFound")}
                        </Stack>
                      )
                    }
                  }}
                  headerHeight={40}
                  hideFooter
                />
              </Paper>
            </Grid>
            <Grid item xs={3.9}>
              <Paper className="eMed_DocRepo_Div" sx={{ height: this.props.fromOT ? "63vh" : "70vh" }} elevation={1}>
                <div className='eMed_DocRepo_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                  <p className='eMed_DocRepo_rightHead_txt'>{t("TestReport")}</p>
                </div>
                <div className="eMed_DocRepo_subDiv">
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    {this.renderTextBox("SelectReportType", "report_type", reportDetails["report_type"], "15vw")}
                    {this.renderTextBox("", "date", date, "11vw")}
                  </Stack>
                  {this.renderTextBox("Comments", "comment", reportDetails["comment"], "27vw")}
                  {this.renderImgCard()}
                </div>
                <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                  <Button variant='outlined' size="small" id="eMed_Config_btn" disabled={CheckAccessFunc("front_office", "Home", "Test Reports", null, "Tab")?.editAccess ? this.state.withOutAppointment?.is_op : true} onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                  <Button variant='contained' size="small" emed_tid="Test_Report_Add_btn" id="eMed_Config_btn" disabled={this.state.isLoader ? true : CheckAccessFunc("front_office", "Home", "Test Reports", null, "Tab")?.editAccess ? this.state.withOutAppointment?.is_op : true} onClick={() => { this.handleAdd() }}>{t("Add")}</Button>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {
          this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
        }
        {
          this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
        }
        {
          this.state.isImgClicked && this.state.selectedImage ?
            <Modal open={popUpOpen}>
              <Box id="eMed_DocRepo_popup">
                <Stack id="eMed_DocRepo_popupDiv" direction="column" justifyContent="center" alignItems="center" p={2}>
                  <Stack id="eMed_DocRepo_popupClose" direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: "0.6vw" }}>
                    <Typography sx={{ fontWeight: 600 }}>{this.state.selectedImage.name ? this.state.selectedImage.name : "-"}</Typography>
                    <IconButton size="small" onClick={this.popUpClose}>
                      <img className="eMed_DocRepo_popupIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} />
                    </IconButton>
                  </Stack>
                  {this.state.selectedImage.type === "application/pdf" ?
                    <iframe className="eMed_DocRepo_img" scroll="no" src={this.state.selectedImage.data_url} alt="uploadedimg" style={{ overflow: "hidden" }} ></iframe>
                    : <img className="eMed_DocRepo_img" src={this.state.selectedImage.data_url} alt="uploadedimg" />
                  }
                </Stack>
              </Box>
            </Modal>
            : null
        }
        {
          this.state.isViewClicked && viewData.length > 0 ?
            <Modal open={viewOpen}>
              <Box id="eMed_DocRepo_popup">
                <Stack id="eMed_DocRepo_popupDiv" direction="column" justifyContent="center" alignItems="center" p={2}>
                  <Stack id="eMed_DocRepo_popupClose" direction="row" justifyContent="flex-end" alignItems="center"><IconButton size="small" onClick={this.popUpViewClose} pr={2}><img className="eMed_DocRepo_popupIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton></Stack>
                  <Stack className="eMed_DocRepo_img" justifyContent="center" alignItems="center">
                    {this.state.viewImage.type === "pdf" ?
                      // <iframe className="eMed_DocRepo_img" scroll="no" src={this.state.viewImage.data_url ? this.state.viewImage.data_url : ""} alt="uploadedimg" style={{ overflow: "hidden" }} ></iframe>
                      <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                        <img style={{ width: '12vw', height: '12vw' }} src={ImagePaths.DocumentPreview.default} alt="slide" />
                        <Button variant="contained" onClick={() => { window.open(this.state.viewImage.data_url) }}>Download Document</Button>
                      </Box>
                      : <img className="eMed_DocRepo_img" src={this.state.viewImage.data_url ? this.state.viewImage.data_url : ""} alt="slide" style={{ overflow: "hidden" }} />}
                  </Stack>
                  <Stack className="eMed_testrp_div" direction="row" justifyContent="space-between" alignItems="center">
                    <IconButton size="small" className="eMed_testrp_arrow" onClick={() => { this.prevImage(this.state.currentIndex) }}><KeyboardDoubleArrowLeftIcon className="eMed_testrp_arrowSize" fontSize="large" color="primary" /></IconButton>
                    <IconButton size="small" className="eMed_testrp_arrow" onClick={() => { this.nextImage(this.state.currentIndex) }}><KeyboardDoubleArrowRightIcon className="eMed_testrp_arrowSize" fontSize="large" color="primary" /></IconButton>
                  </Stack>
                </Stack>
              </Box>
            </Modal>
            : null
        }
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
export default withTranslation()(TestReport);

