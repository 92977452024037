import React, { Component } from "react";
import {
    Box, Button, Stack, InputLabel,
    MenuItem, Select, TextField, Modal,
    FormControl, RadioGroup, Radio, FormControlLabel, Tooltip, Autocomplete, IconButton, Divider, Typography, Drawer
} from "@mui/material";
import { ImagePaths } from "../../../Utility/ImagePaths";
import { Trans, withTranslation } from 'react-i18next';
import { DataGrid } from "@mui/x-data-grid";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import CommonGridHeader from "../../../Components/Common Components/CommonComponents";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { localGetItem } from "../../../Utility/Services/CacheProviderService";
import CommonDatePicker from "../../../Components/Common Components/CommonDatePicker";
import moment from 'moment/moment';
import { DateTime } from 'luxon'

class OTStockTrans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockListData: [],
            isOPT: false,
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            startDate: null,
            dateIndex: 1,
            selected_method: 'Drug',
            FilterDatas: {
                "startTime": null,
                "endTime": null,
                "onError": false,
            },
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        }
    }

    componentDidMount = () => {
        let logginedData = localGetItem('loggedInUserInfo')
        let loggedInUserInfo = JSON.parse(logginedData)
        this.setState({
            isOPT: loggedInUserInfo?.is_opt
        }, () => { this.getStockList() })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, () => {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas: this.state.FilterDatas
            })
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    getStockList = () => {
        try {
            let ServiceURL = this.state.selected_method === "IOL" ? Serviceurls.STOCK_IOL_USAGE_TRANSACTION : Serviceurls.GET_STOCK_USAGE_TRANSACTION
            RestAPIService.getAll(ServiceURL + "?brand_name=" + "" + "&generic_name=" + "" + "&from_date=" + this.state.fromDate + "&to_date=" + this.state.toDate).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            stockListData: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    getPrintStock = (id) => {
        try {
            let ServiceURL = this.state.selected_method === "IOL" ? Serviceurls.STOCK_IOL_USAGE_TRANSACTION : Serviceurls.GET_STOCK_USAGE_TRANSACTION
            RestAPIService.getAll(ServiceURL + `?brand_name=${""}&generic_name=${""}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&export_type=pdf`).
                then((response) => {
              if (response) {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                  [response.data],
                  { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
              }
            }).catch((error) => {
              this.errorMessage(error.message)
            })
        } catch (e) {
          this.errorMessage(e.message)
        }
      }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    {/* <Box sx={{ width: '22vw' }}>
                        <CommonTimeRangePicker
                            startTime={this.state.FilterDatas?.startTime}
                            endTime={this.state.FilterDatas?.endTime}
                            selectedTime={this.selectedTime.bind(this)}
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            onError={this.state.FilterDatas.onError}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.doctorNameList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectDoctor')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker
                                inputFormat='DD-MM-YYYY'
                                label={t('SelectFrom')}
                                value={this.state.startDate}
                                name="startDate"
                                onChange={(newDate) => { this.setState({ startDate: newDate.$d }) }}
                                renderInput={(params) => <TextField style={{ width: '20vw' }} size='small' {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.specializationList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.name)}
                            value={this.state.specialization}
                            onChange={(event, newValue) => {
                                this.setState({ specialization: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectSpecial')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.appointmentTypeList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.appoinment_type)}
                            value={this.state.appointmentType}
                            onChange={(event, newValue) => {
                                this.setState({ appointmentType: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectAppointType')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={PatientType?.map((item) => item)}
                            getOptionLabel={(option) => (option?.patient_type)}
                            value={this.state.patientType}
                            onChange={(event, newValue) => {
                                this.setState({ patientType: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectPatientType')}
                                />
                            )}
                        />
                    </Box> */}
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                doctorName: [], startDate: null,
                                specialization: [], appointmentType: [], patientType: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime": null,
                                    "endTime": null,
                                    "onError": false
                                },
                                timeError: false,
                            }, () => this.getStockList())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getStockList()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    render() {
        this.state.stockListData.forEach((element, index) => { element.sno = index + 1 })
        const { t } = this.props
        const columns = [
            {
                field: 'date', headerName: t('Date'), sortable: false, flex: 0.121, headerAlign: "center", align: "center",
                renderCell: (params) => (<div>{(params.row.date).split("-").reverse().join("-")}</div>)
            },
            {
                field: 'brand_name', headerName: t('BrandName'), sortable: false, flex: 0.111,
                renderCell: (params) => (<div>{params?.row?.brand_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name.slice(0, 14) + "..."}</div></Tooltip> : params?.row?.brand_name}</div>)
            },
            {
                field: 'generic_name', headerName: t('Generic Name'), sortable: false, flex: 0.111,
                renderCell: (params) => (<div>{params?.row?.generic_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.generic_name} arrow><div>{params?.row?.generic_name.slice(0, 14) + "..."}</div></Tooltip> : params?.row?.generic_name}</div>)
            },
            { field: 'batch_no', headerName: t('BatchNumber'), sortable: false, flex: 0.111, type: "number" },
            { field: 'dosage_type', headerName: t('Dosage Type'), sortable: false, flex: 0.111 },
            { field: 'dosage_strength', headerName: t("Dosage Strength"), sortable: false, flex: 0.111, type: "number" },
            { field: 'usage_quantity', headerName: t('Used Quantity'), sortable: false, flex: 0.111, type: "number" },
            { field: 'avaliable_quantity', headerName: t('Available Quantity'), sortable: false, flex: 0.111, type: "number" }
        ]
        const IOL_columns = [
            {
                field: 'date', headerName: t('Date'), sortable: false, flex: 0.121, headerAlign: "center", align: "center",
                renderCell: (params) => (<div>{(params.row.date).split("-").reverse().join("-")}</div>)
            },
            {
                field: 'brand_name', headerName: t('BrandName'), sortable: false, flex: 0.111,
                renderCell: (params) => (<div>{params?.row?.brand_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name.slice(0, 14) + "..."}</div></Tooltip> : params?.row?.brand_name}</div>)
            },
            {
                field: 'model_name', headerName: t('Model Name'), sortable: false, flex: 0.111,
                renderCell: (params) => (<div>{params?.row?.model_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.model_name} arrow><div>{params?.row?.model_name.slice(0, 14) + "..."}</div></Tooltip> : params?.row?.model_name}</div>)
            },
            { field: 'batch_number', headerName: t('BatchNumber'), sortable: false, flex: 0.111, type: "number" },
            { field: 'iol_type', headerName: t('IOL Type'), sortable: false, flex: 0.111 },
            { field: 'iol_power', headerName: t("IOL Power"), sortable: false, flex: 0.111, type: "number" },
            { field: 'usage_quantity', headerName: t('Used Quantity'), sortable: false, flex: 0.111, type: "number" },
            { field: 'avil_quantity', headerName: t('Available Quantity'), sortable: false, flex: 0.111, type: "number" }
        ]
        return (
            <div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                        <Box component={'div'} className='eMEd_pharma_Home_header'>
                            {this.state.isOPT ? <RadioGroup value={this.state.selected_method}
                                sx={{ display: 'flex', flexDirection: 'row', width: '18vw' }}
                                onClick={(e) => this.setState({ selected_method: e.target.value }, () => { this.getStockList() })}
                            >
                                <FormControlLabel value={'Drug'} control={<Radio size='small' />} label='Drug' />
                                <FormControlLabel value={'IOL'} control={<Radio size='small' />} label='IOL' />
                            </RadioGroup> : null}
                        </Box>
                    </div>
                    <div className='eMed_Purchase_Table_top'>
                        <div>   
                           <Button className='eMed_usrconf_btn' onClick={() => {this.getPrintStock()}}>
                                <img src={ImagePaths.LabPrintIcon.default} alt="Print" className='eMed_action_img' />
                            </Button>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                            <Divider orientation='vertical' />
                        </div>
                    </div>
                </div>
                <div className="stock_table_container">
                    <DataGrid
                        rows={this.state.stockListData}
                        rowCount={this.state.total}
                        page={this.state.page}
                        columns={this.state.selected_method === "IOL" ? IOL_columns : columns}
                        hideFooterSelectedRowCount
                        getRowId={(row) => row["sno"]}
                        pageSize={this.state.pageSize}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                    />
                </div>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}
export default withTranslation()(OTStockTrans);