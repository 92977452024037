import { ReactComponent as DashboardIcon } from '../Assets/Icons/ManagePatientProfile.svg';
export class ImagePaths {
    static Logo = require("../Assets/Logo/logo.png");
    static Accounts = require("../Assets/Icons/Accounts.svg");
    static BranchManagement = require("../Assets/Icons/BranchManagement.svg");
    static Dashboard = <DashboardIcon />;
    static DrugRegistry = require("../Assets/Icons/DrugRegistry.svg");
    static Home = require("../Assets/Icons/Home.svg");
    static IPManagement = require("../Assets/Icons/IPManagement.svg");
    static Inventory = require("../Assets/Icons/Inventory.svg");
    static LabManagement = require("../Assets/Icons/LabManagement.svg");
    static ManagePatientProfile = require("../Assets/Icons/ManagePatientProfile.svg");
    static OPManagement = require("../Assets/Icons/OPManagement.svg");
    static PharamcyManagement = require("../Assets/Icons/PharamcyManagement.svg");
    static ProfileManagement = require("../Assets/Icons/ProfileManagement.svg");
    static Reports = require("../Assets/Icons/Reports.svg");
    static Support = require("../Assets/Icons/Support.svg");
    static Transactions = require("../Assets/Icons/Transactions.svg");
    static VendorManagement = require("../Assets/Icons/VendorManagement.svg");
    static LabHomeOff = require("../Assets/Images/Home-Default.svg")
    static LabHomeOn = require("../Assets/Images/Home.svg")
    static LabReportOff = require("../Assets/Images/Report-Default.svg")
    static LabReportOn = require("../Assets/Images/Report.svg")
    static LabSettingOff = require("../Assets/Images/Settings-Default.svg")
    static LabSettingOn = require("../Assets/Images/Settings.svg")
    static LabInventrytOff = require("../Assets/Images/Inventory_default.svg")
    static LabInventryOn = require("../Assets/Images/Inventory.svg")
    static LabPurchaseOff = require("../Assets/Images/Cart_default.svg")
    static LabPurchaseOn = require("../Assets/Images/Cart.svg")
    static AddIcon = require("../Assets/Icons/AddIcon.svg")
    static CrossIcon = require("../Assets/Icons/CrossIcon.svg")
    static SavedBill = require("../Assets/Icons/SavedBills.svg")
    static Speciman = require("./../Assets/Icons/speciman.svg")
    static Result = require("../Assets/Icons/Results.svg")
    static Transmit = require("../Assets/Icons/Transmit.svg")
    static Default = require("../Assets/Icons/Patient_Default.png")
    static Delete = require("../Assets/Icons/DeleteIcon.svg")
    static Edit = require("../Assets/Icons/EditIcon.svg")
    static LabBackButton = require("../Assets/Icons/BackButton.png")
    static LabCloseButton = require("../Assets/Icons/close.png")
    static LabUploadButton = require("../Assets/Icons/UploadButton.png")
    static LabPrintIcon = require('../Assets/Icons/PrintButton.svg')
    static deliveryBox = require('../Assets/Icons/deliveryBoxButton.png')
    static shoppingCart = require('../Assets/Icons/AddCart.png')
    static deliveryClose = require('../Assets/Icons/deliveryBoxClose.png')
    static Warning = require('../Assets/Icons/Warning.svg')
    static LabEditButton = require('../Assets/Icons/EditButton.png')
    static LabDeleteButton = require('../Assets/Icons/DeleteButtonNew.svg')
    static LabViewButton = require('../Assets/Icons/ViewButton.png')
    static LabPlayButton = require('../Assets/Icons/PlayButton.png')
    static LabPauseButton = require('../Assets/Icons/PauseButton.png')
    static LabDateImage = require('../Assets/Icons/DateImage.png')
    static Close = require("../Assets/Icons/close.svg")
    static pendingAction = require('../Assets/Icons/pending_actions.png')
    static checkCircle = require('../Assets/Icons/check_circle.png')
    static Check = require("../Assets/Icons/check.svg")
    static vector = require('../Assets/Icons/Vector.png')
    static ResultDisable = require("../Assets/Icons/DisableResult.svg")
    static Tick = require("../Assets/Icons/Tick.svg")
    static Wallet = require("../Assets/Icons/Vector.svg")
    static PrintIcons = require("../Assets/Icons/PrintIcon.png")
    static disabledCart = require("../Assets/Icons/cart.svg")
    static opConfig = require("../Assets/Icons/opconfig.svg")
    static referral = require("../Assets/Icons/referral.svg")
    static userConfig = require("../Assets/Icons/UserConfig.svg")
    static corporateConfig = require("../Assets/Icons/CorprateConfig.svg")
    static demographicConfig = require("../Assets/Icons/Demographics.svg")
    static upArrow = require("../Assets/Icons/upArrow.svg")
    static downArrow = require("../Assets/Icons/downArrow.svg")
    static BillingOn = require("../Assets/Images/Billing.svg")
    static BillingOff = require("../Assets/Images/Billing_Default.svg")
    static AddNewPatient = require("../Assets/Icons/AddNewPatient.svg")
    static NewPatient = require("../Assets/Icons/New.svg")
    static EstablishedPatient = require("../Assets/Icons/Established.svg")
    static ReviewPatient = require("../Assets/Icons/Review.svg")
    static BookedPatient = require("../Assets/Icons/BookedPatient.svg")
    static ArrviedPatient = require("../Assets/Icons/ArrviedPatient.svg")
    static Nursepatient = require("../Assets/Icons/Nursepatient.svg")
    static DocPatient = require("../Assets/Icons/DocPatient.svg")
    static CompletedPatient = require("../Assets/Icons/CompletedPatient.svg")
    static CancelPatient = require("../Assets/Icons/CancelPatient.svg")
    static Male = require("../Assets/Icons/Male.svg")
    static Female = require("../Assets/Icons/Female.svg")
    static Phone = require("../Assets/Icons/phone.svg")
    static Web = require("../Assets/Icons/computer.svg")
    static PayCompleted = require("../Assets/Icons/PayCompleted.svg")
    static PayPending = require("../Assets/Icons/PayPending.svg")
    static Comments = require("../Assets/Icons/Comments.svg")
    static StatusArrived = require("../Assets/Icons/StatusArrived.svg")
    static StatusNurse = require("../Assets/Icons/StatusNurse.svg")
    static StatusDoctor = require("../Assets/Icons/StatusDoctor.svg")
    static StatusCompleted = require("../Assets/Icons/StatusCompleted.svg")
    static CancelAppoinment = require("../Assets/Icons/CancelAppointment.svg")
    static BillCreated = require("../Assets/Icons/CreateBill.svg")
    static StatusArrivedFill = require("../Assets/Icons/StatusArrivedFill.svg")
    static StatusNurseFill = require("../Assets/Icons/StatusNurseFill.svg")
    static StatusDoctorFill = require("../Assets/Icons/StatusDoctorFill.svg")
    static BookedPatientFill = require("../Assets/Icons/BookedPatientFill.svg")
    static CompletedPatientFill = require("../Assets/Icons/CompletedPatientFill.svg")
    static CancelPatients = require("../Assets/Icons/CancelPatients.svg")
    static Clinic = require("../Assets/Images/Clinic.svg")
    static FO = require("../Assets/Images/FO.svg")
    static DragIndicator = require("../Assets/Images/drag_indicator.svg")
    static ActiveBox = require("../Assets/Icons/activeBox.svg")
    static DisabledActiveBox = require("../Assets/Icons/DisabledActiveBox.svg")
    static InActiveBox = require("../Assets/Icons/inactiveBox.svg")
    static LockBtn = require('../Assets/Icons/Lock.svg')
    static LockBtnoutline = require('../Assets/Icons/LockBtnoutline.svg')
    static CreateBill = require('../Assets/Icons/CreateBill.svg')
    static Filter = require('../Assets/Icons/FilterIcon.svg')
    static Appointment = require('../Assets/Icons/AppoinmentIcon.svg')
    static Export = require('../Assets/Icons/ExportTheme.svg')
    static CardIcon = require('../Assets/Icons/CardIcon.svg')
    static CashIcon = require('../Assets/Icons/CashIcon.svg')
    static DisabledCreateBill = require('../Assets/Icons/DisabledCreateBill.svg')
    static BankTransferIcon = require('../Assets/Icons/BankTransferIcon.svg')
    static UPIIcon = require('../Assets/Icons/UPIIcon.svg')
    static ChequeIcon = require('../Assets/Icons/ChequeIcon.svg')
    static DisabledHome = require('../Assets/Icons/HomeIcon.svg')
    static UploadBtnTheme = require('../Assets/Icons/Upload_Button.svg')
    static UploadPatientDP = require('../Assets/Icons/Patient_DP_Upload.svg')
    static Walkin = require('../Assets/Icons/Walkin.svg')
    static CancelAppDisabled = require('../Assets/Icons/CancelAppDisabled.svg')
    static FrontOffice = require('../Assets/Icons/FrontOffice.svg')
    static DisabledEdit = require('../Assets/Icons/DisabledEdit.svg')
    static RoomConfig = require('../Assets/Icons/RoomConfig.svg')
    static TextBoxSearchIcon = require('../Assets/Icons/TextBoxSearchIcon.svg')
    static StarFill = require('../Assets/Icons/StarFill.svg')
    static StarUnfill = require('../Assets/Icons/StarUnfill.svg')
    static Clander = require('../Assets/Icons/calendar.svg')
    static DisabledDelete = require('../Assets/Icons/DisabledDelete.svg')
    static IpHomeOn = require('../Assets/Icons/IpHomeOn.svg')
    static IpHomeOff = require('../Assets/Icons/IpHomeOff.svg')
    static NewEditcon = require('../Assets/Icons/edit_icon.svg')
    static Search = require("../Assets/Icons/Search_Button.svg")
    static ClearIcon = require('../Assets/Icons/ClearIcon.svg')
    static Rupee = require('../Assets/Icons/currency_rupee.svg')
    static RupeeOff = require("../Assets/Icons/currency_rupee2.svg")
    static Dollor = require("../Assets/Icons/Dollor.svg")
    static FrontOfficeOff = require("../Assets/Icons/frontOfficeOff.svg")
    static RequestsIcon = require("../Assets/Icons/RequestsIcon.svg")
    static DeclineIcon = require("../Assets/Icons/DeclineIcon.svg")
    static AcceptIcon = require("../Assets/Icons/AcceptIcon.svg")
    static IPLabOn = require("../Assets/Icons/labReportOn.svg")
    static IPLabOff = require("../Assets/Icons/labReportOff.svg")
    static EmptyOff = require("../Assets/Icons/emptyOff.svg")
    static EmptyOn = require("../Assets/Icons/emptyOn.svg")
    static AfterOff = require("../Assets/Icons/AfterOf.svg")
    static AfterOn = require("../Assets/Icons/AfterOn.svg")
    static BeforeOn = require("../Assets/Icons/beforeOn.svg")
    static BeforeOff = require("../Assets/Icons/beforeOff.svg")
    static ExternalOn = require("../Assets/Icons/externalOn.svg")
    static ExternalOff = require("../Assets/Icons/externalOff.svg")
    static WithFoodOn = require("../Assets/Icons/withFoodOn.svg")
    static WithFoodOff = require("../Assets/Icons/withFoodOf.svg")
    static DisabledAddIcon = require("../Assets/Icons/DisabledAddIcon.svg")
    static DisabledClearIcon = require("../Assets/Icons/DisabledClearIcon.svg")
    static Doctor_Icon = require("../Assets/Icons/Doctor_male_icon.svg")
    static TransmitOn = require("../Assets/Icons/transmitOn.svg")
    static TransmitOff = require("../Assets/Icons/transmitOfff.svg")
    static Laboratory = require("../Assets/Icons/laboratory.png")
    static Hospital = require("../Assets/Icons/hospital.png")
    static FrontOfficeLogo = require("../Assets/Icons/FontOfficeLogo.png")
    static medicationTransmitOn = require("../Assets/Icons/mediTransmit.svg")
    static medicationTransmitOff = require("../Assets/Icons/medicationTransmitOff.svg")
    static UserAccess = require("../Assets/Icons/User_Access.svg")
    static A4Select = require("../Assets/Icons/A4Select.svg")
    static A4Unselect = require("../Assets/Icons/A4Unselect.svg")
    static LandscapeSelect = require("../Assets/Icons/LandscapeSelect.svg")
    static LandscapeUnselect = require("../Assets/Icons/LandscapeUnselect.svg")
    static PortraitSelect = require("../Assets/Icons/PortraitSelect.svg")
    static PortraitUnselect = require("../Assets/Icons/PortraitUnselect.svg")
    static A5Select = require("../Assets/Icons/A5Select.svg")
    static A5Unselect = require("../Assets/Icons/A5Unselect.svg")
    static NewUserImg = require("../Assets/Images/Add_User_Module.svg")
    static AddUserButton = require("../Assets/Icons/Add_button.svg")
    static HomeUser = require("../Assets/Icons/Home_Black.svg")
    static ReportsUser = require("../Assets/Icons/Reports_black.svg")
    static BillTransUser = require("../Assets/Icons/Trans_Black.svg")
    static UserEditOn = require("../Assets/Icons/User_Edit.svg")
    static UserEditOff = require("../Assets/Icons/User_Edit_off.svg")
    static IpHomeUser = require("../Assets/Icons/IPHome_Black.svg")
    static View = require("../Assets/Icons/view.svg")
    static ImagePreview = require("../Assets/Icons/ImagePreview.svg")
    static DocumentPreview = require("../Assets/Icons/DocumentPreview.svg")
    static ViewButtonOff = require("../Assets/Icons/ViewButtonOff.svg")
    static SuccessAnimationGif = require("../Assets/Animation/Success.gif")
    static Print = require("../Assets/Icons/Print.svg")
    static Optometry = require("../Assets/Icons/Optometry.svg")
    static OptometryFill = require("../Assets/Icons/OptometryFill.svg")
    static ScanSurgery = require("../Assets/Icons/ScanSurgery.svg")
    static ScanSurgeryFill = require("../Assets/Icons/ScanSurgeryFIll.svg")
    static BillOn = require("../Assets/Icons/BillOn.svg")
    static BillOff = require("../Assets/Icons/BillOff.svg")
    static DefaultAppointType = require("../Assets/Icons/DefaultAppointType.svg")
    static IpCheckoutOn = require("../Assets/Icons/ipcheckout.svg")
    static PendingNega = require("../Assets/Icons/PayPendingNegative.svg")
    static PainHighRange = require("./../Assets/Icons/PainHign.svg")
    static PainLowRange = require("./../Assets/Icons/PainLow.svg")
    static ReserveRoom = require("./../Assets/Icons/roomreserve.svg")
    static PharmaPurchase = require("./../Assets/Icons/PharmaPurchase.svg")
    static Transfer = require("./../Assets/Icons/Transfer_Icon.svg")
    static AttenderCheckOut = require("./../Assets/Icons/AttenderCheckOut.svg")
    static PharmacyDashboard = require("./../Assets/Icons/PharmacyDashboard.svg")
    static PharmacyIPReturn = require("./../Assets/Icons/IPReturn.svg")
    static PharmacyApp = require("./../Assets/Icons/PhramacyApp.svg")
    static PharmacySaveBill = require("./../Assets/Icons/PhramacySavedBills.svg")
    static Pharmacypres = require("./../Assets/Icons/PharmacyPrescription.svg")
    static PharmacyIPRequest = require("./../Assets/Icons/IPRequest.svg")
    static CalenderIcon = require("./../Assets/Icons/CalenderIcon.svg")
    static MobileCardIcon = require("./../Assets/Icons/MobileCardIcon.svg")
    static AvatarCardIcon = require("./../Assets/Icons/AvatarCardIcon.svg")
    static ExportIcon = require("./../Assets/Icons/ExportIcon.svg")
    static IPNumberCard = require("./../Assets/Icons/IPNumberCard.svg")
    static StkTransferOff = require("./../Assets/Icons/StkTransferOff.svg")
    static StkTransferOn = require("./../Assets/Icons/StkTransferOn.svg")
    static DescriptionCard = require("./../Assets/Icons/DescriptionCard.svg")
    static RoomCardImg = require("./../Assets/Icons/RoomCardImg.svg")
    static ReturnDrugOn = require("./../Assets/Icons/returnDrug.svg")
    static stockHistory = require("./../Assets/Icons/stockHistory.svg")
    static PharmacyCard = require("./../Assets/Icons/PharmacyCard.svg")
    static Lab_New_Save = require('./../Assets/Icons/check_circle_Green.svg')
    static Lab_New_Green_Edit = require('./../Assets/Icons/edit_document_green.svg')
    static Telephonic = require("./../Assets/Icons/Telephonic.svg")

    static CallenderIcon = require("../Assets/Icons/Cal_Icon.svg")
    static NoshowIcon = require("../Assets/Icons/NoshowIcon.svg")
    static RescheduleIcon = require("../Assets/Icons/RescheduleIcon.svg")
    static CancelIcon = require("../Assets/Icons/CancelIcon.svg")
    static CheckGreenIcon = require("../Assets/Icons/CheckGreenIcon.svg")
    static SurgAprroved = require("../Assets/Icons/domain_verification.svg")
    static WithDoc = require("../Assets/Icons/surgical_sterilization.svg")
    static Tick = require("../Assets/Icons/check_circle.svg")
    static TickDefault = require("../Assets/Icons/check_circle_default.svg")
    static TickDisable = require("../Assets/Icons/check_circle_disabled.svg")
    static WithDocDefault = require("../Assets/Icons/surgical_sterilization_default.svg")
    static WithDocDisable = require("../Assets/Icons/surgical_sterilization_disabled.svg")
    static SurgAprrovedDefault = require("../Assets/Icons/domain_verification_default.svg")
    static SurgAprrovedDisable = require("../Assets/Icons/domain_verification_disabled.svg")
    static OT = require("../Assets/Images/operation_theatre.png")
    static Counsellor = require("../Assets/Images/discussion.png")
    static CancelRed = require("../Assets/Icons/cancel_red_icon.svg")
    static Draft = require("../Assets/Icons/draft.svg")
    static Clock = require("../Assets/Icons/Clock.svg")
    static HospitalConfig = require("../Assets/Icons/HospitalConfig.svg")
    static Users = require("../Assets/Icons/Users.svg")
    static HighlightedClock = require("../Assets/Icons/High_lited_clock.png")
    static NormalClock = require("../Assets/Icons/Normal_clock.png")
    static LabPrintIconOff = require("../Assets/Icons/Print_icon_disabled.svg")
    static ExpandView = require("../Assets/Icons/expand.svg")
    static ChartView = require("../Assets/Icons/chartView.svg")
    static TableView = require("../Assets/Icons/tableView.svg")
    static DownloadIcon = require("../Assets/Icons/download.svg")
    static Graph1 = require("../Assets/Icons/Graph1.svg")
    static Graph2 = require("../Assets/Icons/Graph2.svg")
    static Graph3 = require("../Assets/Icons/Graph3.svg")
    static Graph4 = require("../Assets/Icons/Graph4.svg")
    static Product = require("../Assets/Icons/product.svg")
    static LowStock = require("../Assets/Icons/lowStock.svg")
    static ShortExpiry = require("../Assets/Icons/shortExpiry.svg")
    static DashboardBed = require("../Assets/Icons/dashboardbed.svg")
    static DashboardOp = require("../Assets/Icons/dashboardOp.svg")
    static DashboardExpense = require("../Assets/Icons/dashboardExpense.svg")
    static DashboardTick = require("../Assets/Icons/dashboardTick.svg")
    static ProfitIcon = require("../Assets/Icons/profit.svg")
    static LossIcon = require("../Assets/Icons/loss.svg")
    static ChartDashboardOff = require("../Assets/Icons/ChartDashboardOff.svg")
    static ChartDashboardOn = require("../Assets/Icons/ChartDashboardOn.svg")
    static DeleteOutline = require("../Assets/Images/DeleteOutline.svg")
    static DocNutritionOn = require('../Assets/Icons/RegistryOn.svg')
    static DocNutritionOff = require('../Assets/Icons/NutritionChartOn.svg')
    static DocRegistryOn = require('../Assets/Icons/RegistryOff.svg')
    static DocRegistryOff = require('../Assets/Icons/NutritionChartOff.svg')
    static DoctorIcon = require('../Assets/Icons/Doctor_Icon.jpg')
    static Transfer = require('../Assets/Icons/Transfer.svg')
    static TransferDisable = require('../Assets/Icons/Transfer_Disble.svg')
    static DoctorActiveCircle = require('../Assets/Icons/Active_circle.svg')
    static DoctorInactiveCircle = require('../Assets/Icons/Inactive_circle.svg')
    static CounsellorTransfer = require('../Assets/Icons/Counseller_Transfer.svg')
    static Info = require('../Assets/Icons/info.svg')
    static branchialIndex = require('../Assets/Images/BranchialIndex.svg')
    static zoomInDot = require('../Assets/Icons/zoomInDot.svg')
    static PreviousPrescription = require('../Assets/Icons/previous_history.svg')
    static Notes = require('../Assets/Icons/Note.svg')
    static DeleteOutline = require("../Assets/Images/DeleteOutline.svg")
    static FloorIcon = require("../Assets/Icons/FloorIcon.svg")
    static WardIcon = require("../Assets/Icons/WardIcon.svg")
    static AvailabelRoom = require("../Assets/Icons/AvailabelRoom.svg")
    static AC_Icon = require("../Assets/Icons/AC.svg")
    static UserSetting = require("../Assets/Icons/Setting_User.svg")
    static Ip_list = require("../Assets/Icons/IP_List.svg")
    static CreateMealPlan = require('../Assets/Icons/Create_Meal_Plan.svg')
    static MealFood = require('../Assets/Icons/Meal_Food.svg')
    static MealJuice = require('../Assets/Icons/Meal_Juice.svg')
    static MealTime = require('../Assets/Icons/Meal_Clock.svg')
    static Back_Arrow = require("../Assets/Icons/Back_Btn.svg")
    static Success_msg = require("../Assets/Icons/Success.svg")
    static Error_msg = require("../Assets/Icons/Error.svg")
    static Ip_list = require("../Assets/Icons/IP_List.svg")
    static UserSetting = require("../Assets/Icons/Setting_User.svg")
    static GonioscopyImage = require('../Assets/Images/gonioscopyImage.svg')
    static ResultHistory = require("../Assets/Icons/ResultHistory.svg")
    static ResultOff = require("../Assets/Icons/ResultsOff.svg")
    static PlusIcon = require("../Assets/Icons/Plus.svg")
    static MinusIcon = require("../Assets/Icons/Minus.svg")
    static ReadyForCheckout = require("../Assets/Icons/ReadyForCheckout.svg")
    static Tablet = require("../Assets/Icons/Tablets.png")
    static Black = require("../Assets/Icons/Black Circle.svg")
    static Blue = require("../Assets/Icons/Blue Circle.svg")
    static Brown = require("../Assets/Icons/Brown Circle.svg")
    static Green = require("../Assets/Icons/Green Circle.svg")
    static Orange = require("../Assets/Icons/Orange Circle.svg")
    static Red = require("../Assets/Icons/Red Circle.svg")
    static Undo = require("../Assets/Icons/undo.png")
    static Redo = require("../Assets/Icons/redo.png")
    static TransAppoint = require("../Assets/Icons/TransmitAppointment.svg")
    static EyeView = require("../Assets/Icons/View Icon.svg")
    static copy = require("../Assets/Icons/Copy Icon.svg")
    static Play = require("../Assets/Icons/play_arrow.svg")
    static Append = require("../Assets/Icons/playlist_remove.svg")
    static Download = require("../Assets/Icons/DownloadColor.svg")
    static Assessment = require("../Assets/Images/Assessment.jpg")
    static ReadyForCheckout = require("../Assets/Icons/ReadyForCheckout.svg")
    static LockRed = require("../Assets/Icons/Lockred.svg")
    static White = require("../Assets/Icons/White Circle.svg")
    static Eraser = require("../Assets/Icons/Eraser.svg")
    static ViewContained = require("../Assets/Icons/ViewContained.svg")
    static InsuranceON = require("../Assets/Icons/InsuranceON.svg")
    static InsuranceOFF = require("../Assets/Icons/InsuranceOFF.svg")
    static Radiology = require("../Assets/Icons/Radiology.svg")
    static CanteenModule = require("../Assets/Icons/CanteenModule.svg")
    static AddCircle = require("../Assets/Icons/AddCircle.svg")
    static otListon = require("../Assets/Icons/OTListOn.svg")
    static otListoff = require("../Assets/Icons/OTListOff.svg")
    static ApproveActive = require("../Assets/Icons/approved_enabled.svg")
    static ApproveDisable = require("../Assets/Icons/approved_disabled.svg")
    static Mic = require("../Assets/Images/mic.svg")
    static Check = require("../Assets/Images/check.svg")
    static close = require("../Assets/Images/close.svg")
    static reply = require("../Assets/Images/replay.svg")
    static AssetManagement = require("../Assets/Images/asset_management.png")
    static Complaintson = require("../Assets/Icons/ActiveComplaints.svg")
    static Complaintsoff = require("../Assets/Icons/DisableComplaints.svg")
    static BarCodeIcons = require("../Assets/Icons/Icon Button.svg")
    static OTSurgeryConvert = require("../Assets/Icons/OTsurgeryConvert.svg")
    static OTSurgeryConvertDisaable = require("../Assets/Icons/OTsurgeryConvertDisable.svg")
    static PreHistory = require("../Assets/Icons/History.svg")
    static Payment = require("../Assets/Icons/Payments.svg")
    static DisPayment = require("../Assets/Icons/DisablePayments.svg")
    static DisSumTestGet = require("../Assets/Icons/DisSumTestGet.svg")
    static Insurance = require("../Assets/Icons/InsuranceConfig.svg")
    static Recall = require("../Assets/Icons/assignment_return.svg")
    static isDocUploaded  = require("../Assets/Icons/isDocUploaded.svg")
    static isDocNotUploaded  = require("../Assets/Icons/isDocNotUploaded.svg")
    static NewClearIcon = require("../Assets/Icons/Clear_Icon_New.svg")
    static ChangeCredit = require("../Assets/Icons/ChangeCredit.svg")
    static outlinedEdit = require("../Assets/Icons/outlinedEdit.svg")
    static outlinedEditDis = require("../Assets/Icons/outlinedEditDis.svg")
    static Brush = require("../Assets/Icons/Brush.svg")
    static HorizonBarCode = require("../Assets/Icons/Horizo Barcode.svg") 
    static AlignLeft = require("../Assets/Icons/align_horizontal_left.svg")
    static AlignCenter = require("../Assets/Icons/align_horizontal_center.svg")
    static AlignRight = require("../Assets/Icons/align_horizontal_right.svg")
    static DisAlignLeft = require("../Assets/Icons/align_horizontal_left-B.svg")
    static DisAlignCenter = require("../Assets/Icons/align_horizontal_center-B.svg")
    static DisAlignRight = require("../Assets/Icons/align_horizontal_right-B.svg")
    static Graph = require("../Assets/Icons/Graph.svg")
    static TableChange = require("../Assets/Icons/TableChange_Icon.svg")
    static RefreshIcon = require("../Assets/Icons/refresh_Icon.svg")
    static SyncIcon = require("../Assets/Icons/sync_problem.svg")
    static SyncWarning = require("../Assets/Icons/sync_warning.svg")
    static SyncCompleted = require("../Assets/Icons/Sync Completed.svg")
    static SmsConfig = require("../Assets/Icons/SmsConfig.svg")
    static withCounsellorOn = require('../Assets/Icons/With_Consultant_On.svg')
    static withCounsellorOff = require('../Assets/Icons/With_Consultant_Off.svg')
    static DropDown = require("../Assets/Icons/arrow_drop_down.svg")
    static Notification = require("../Assets/Icons/notification.svg")
    static DocCallOffIcon = require("../Assets/Icons/DocCallOffIcon.svg")
    static DocCallOnIcon = require("../Assets/Icons/DocCallOnIcon.svg")
    static NotiTick = require("../Assets/Icons/NotiTick.svg")
    static CameraCapture = require("../Assets/Icons/camera.svg")
    static ColorTick = require("../Assets/Icons/ColorTick.svg")
    static DisTick = require("../Assets/Icons/DisTick.svg")
    static AllergyAlert = require("../Assets/Icons/Allergy.svg")
    static withOptCompletedOn = require('../Assets/Icons/withOptometryOn.svg')
    static withOptCompletedOff = require('../Assets/Icons/optometryCompleted.svg')
    static OptometristEye = require("../Assets/Icons/Optometrist.svg")
    static UMAHeaderImage = require("../Assets/Images/UMAHeader.png")
    static MasonicHeaderImage = require("../Assets/Images/MasonicHeader.jpg")
    static AppStore = require("../Assets/Images/appstore.png")
    static PlayStore = require("../Assets/Images/playstore.png")
    static QRIcon = require("../Assets/Icons/qr_code.svg")
    static DisableUpload = require("../Assets/Icons/DisableUpload.svg")
    
}

export default { DashboardIcon }