import React, { Component } from "react";
import '../Configuration.css';
import { Box, Paper, TextField, Button, Stack, Tooltip, Grid, FormControl, InputLabel, Select, MenuItem, Autocomplete, InputAdornment, FormControlLabel, Checkbox, Typography, RadioGroup, Radio } from "@mui/material";
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup } from "../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { Colors } from "../../../Styles/Colors";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem, localSetItem } from "../../../Utility/Services/CacheProviderService";
class DoctorConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docDetails: [],
            spcInfo: [],
            selectedItem: {
                "slot_based_on": 'INTERVAL'
            },
            current_page: 0,
            rowsPerPage: 10,
            deletedPopup: false,
            doctor_name: "",
            doctor_id: null,
            isEdit: false,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",
            isQmsConfig: false,
            orgSelectedData: {},
            changeBasedOn: false,
        }
    }
    componentDidMount() {
        let userDetails = JSON.parse(localGetItem("loggedInUserInfo"))
        let qmsConfig = userDetails?.is_qms_enabled
        this.setState({ isQmsConfig: qmsConfig ? qmsConfig : false })
        this._getDoctorDetails();
        this._getSpecInfo();
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    _getDoctorDetails = () => {
        this.setState({
            docDetails: [],
            current_page: 0,
            rowsPerPage: 10
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME + `?is_doctor_list=true`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            docDetails: response.data.data,
                        },()=>{this.LoaderFunction(false)})
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error.response?.data.status === "fail") {
                        this.responseSuccessErrorMessage(error.response?.data?.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    _getSpecInfo = () => {
        this.setState({
            spcInfo: []
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION)
                .then(response => {
                    if (response.data.status === "success") {
                        let datas = response.data.data 
                        this.setState({
                            spcInfo: datas.filter(item => item.is_active === true),
                        },() => this.LoaderFunction(false));
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                    this.LoaderFunction(false)
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    getDoctorList = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME).
              then((response) => {
                if (response.data.status === "success") {
                  let doctor_ist = response.data.data ? response.data.data.filter((item) => (item.specialization_active === true)) : []
                  let doctorList = JSON.stringify(doctor_ist);
                  localSetItem("DoctorsList", doctorList)
                  this.LoaderFunction(false)
                }
                else {
                  this.errorMessage(response.data.message)
                  this.LoaderFunction(false)
                }
              }).catch((error) => {
                this.errorMessage(error.message)
                this.LoaderFunction(false)
              })
          }
          catch (e) {
            this.errorMessage(e.message)
            this.LoaderFunction(false)
          }
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

    handleEdit = (value) => {
        let { selectedItem } = this.state
        selectedItem = JSON.parse(JSON.stringify(value));
        this.setState({
            selectedItem,
            orgSelectedData: JSON.parse(JSON.stringify(value)),
            isEdit: true
        })
    }

    handleDelete = (value) => {
        if(!this.state.isEdit){
        this.setState({
            deletedPopup: true,
            doctor_name: value.doctor_name,
            doctor_id: value.doctor_id
        })
    }}

    changeBasedOnConfirm = () => {
        this.handleSave()
        this.setState({ changeBasedOn: false })
    }

    PopupClose = () => {
        this.setState({
            deletedPopup: false,
            changeBasedOn: false,
            doctor_name: "",
            doctor_id: null
        })
    }

    removeDataList = () => {
        try {
            RestAPIService.delete(`${Serviceurls.FO_CONFIG_OPDOCTORNAME}?doctor_id=${this.state.doctor_id ? this.state.doctor_id : 0}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            deletedPopup: false,
                            doctor_name: "",
                            doctor_id: null
                        }, () => {
                            this.responseSuccessErrorMessage(response.data.message, true);
                            this._getDoctorDetails();
                        })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    handleChange = (event) => {
        let { selectedItem } = this.state;
        let name = event.target.name;
        let value = event.target.value.trimStart();
        let isValid = false;
        switch (name) {
            case "registration_proof_number":
                isValid = CommonValidation.alphanumValidation(value);
                if (value === "" || isValid && value.length <= 50) {
                    selectedItem["registration_proof_number"] = value;
                }
                break;
            case "commission_percentage":
                isValid = CommonValidation.numberOnly(value)
                if (value === "" || isValid && value <= 100) {
                    selectedItem["commission_percentage"] = value;
                }
                break;
            case "doctor_name":
                isValid = CommonValidation.alphabetOnly(value);
                if (value === "" || isValid && value.length <= 50) {
                    selectedItem["doctor_name"] = value;
                }
                break;
            case "qualification": 
                selectedItem["qualification"] = value;
                break;
            default:
                isValid = CommonValidation.alphabetOnly(value);
                if (value === "" || isValid) {
                    selectedItem[name] = value;
                }
                break;
        }
        this.setState({
            selectedItem
        })
    }
    renderTextBox(label, statekey, value, width) {
        let { selectedItem, spcInfo } = this.state;
        const { t } = this.props;
        let status_list = [
            { label: "Approved", value: "Approved" },
            { label: "Not Approved", value: "Not Approved" }
        ];
        const defaultdata = [
            { name: 'No options Found' }
        ]
        return (
            <div id="eMed_srvConfig_divs">
                {
                    statekey === "specialization_name" ?
                        <Autocomplete
                            disableClearable
                            value={value ? value : ""}
                            sx={{ width: width ? width : "14vw" }}
                            size="small"
                            disabled={this.state.isEdit ? true : false}
                            options={spcInfo.length != 0 ? spcInfo : defaultdata}
                            getOptionLabel={option => typeof (option) === "string" ? option : option.name}
                            onChange={(event, newSpec) => {
                                selectedItem["specialization_id"] = newSpec.id;
                                selectedItem["specialization_name"] = newSpec.name;
                                this.setState({
                                    selectedItem
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t(label) + " *"}
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                        />
                        : statekey === "status" ?
                            <FormControl sx={{ width: width ? width : "14vw" }} size="small">
                                <InputLabel>{t(label) + " *"}</InputLabel>
                                <Select
                                    value={value ? value : ""}
                                    name={statekey}
                                    label={t(label) + " *"}
                                    disabled={this.state.isEdit ? true : false}
                                    onChange={(event) => {
                                        selectedItem["status"] = event.target.value;
                                        this.setState({
                                            selectedItem
                                        })
                                    }}
                                >
                                    {
                                        status_list.map((list, index) => (
                                            <MenuItem key={index} value={list.value} >{list.label}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            :
                            <TextField
                                sx={{ width: width ? width : "14vw" }}
                                autoComplete="off"
                                required = {statekey !== "commission_percentage"}
                                disabled={(this.state.isEdit && this.state.selectedItem?.status === "Approved" && label != "Qualification" && label != "Commission" || (this.state.isEdit && this.state.selectedItem?.status !== "Approved")) ? true : false}
                                size="small"
                                label={label === "Commission" ? `${t(label)} (%)` : t(label)}
                                name={statekey}
                                value={value ? value : ""}
                                onChange={this.handleChange}
                                InputProps={{
                                    startAdornment: statekey === "doctor_dr__first_name" ? <InputAdornment position="start">{'Dr'}</InputAdornment> : false
                                  }}
                            />
                }
            </div>
        )
    }
    handleClear = () => {
        this.setState({
            selectedItem: { "slot_based_on": 'INTERVAL' },
            isEdit: false
        })
    }
    handleSave = () => {
        const { t } = this.props
        let { selectedItem } = this.state;
        let isAvl = true;
        let Message = "";
        if (Object.keys(selectedItem).length === 0) { isAvl = false; Message = t("Fillmandatory") }
        else if (!selectedItem.doctor_dr__first_name) { isAvl = false; Message = `${t("Enter")} ${t("DoctorName")}` }
        else if (!selectedItem.specialization_name) { isAvl = false; Message = `${t("Enter")} ${t("Specialization")}` }
        else if (!selectedItem.qualification) {isAvl = false; Message = `${t("Enter")} ${t("Qualification")}`}
        else if (!selectedItem.registration_proof_number) {isAvl = false; Message = `${t("Enter")} ${t("RegistrationNo")}`}
        else if (!selectedItem.approved_by) {isAvl = false; Message = `${t("Enter")} ${t("VerifiedBy")}`}
        else if (!selectedItem.status) {isAvl = false; Message = `${t("Enter")} ${t("Status")}`}
        if (isAvl) {
            // api call
            selectedItem["doctor_name"] = selectedItem["doctor_dr__first_name"];
            selectedItem["specialization"] = selectedItem["specialization_id"];
            selectedItem["qualifications"] = selectedItem["qualification"];
            selectedItem["title"] = "Dr";
            delete selectedItem.specialization_id;
            delete selectedItem.specialization_name;
            delete selectedItem.qualification;
            let datas = JSON.parse(JSON.stringify(selectedItem))
            try {
                this.LoaderFunction(true)
                RestAPIService.create(datas, Serviceurls.FO_CONFIG_OPDOCTORNAME)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.handleClear();
                            this._getDoctorDetails();
                            this.LoaderFunction(false)
                            this.responseSuccessErrorMessage(response.data.message, true);
                            this.getDoctorList()
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.responseSuccessErrorMessage(error.response.data.message, false);
                        }
                    });
            } catch (e) {
                this.responseSuccessErrorMessage(e.message, false);
            }
        } else {
            this.responseSuccessErrorMessage(Message, false)
        }
    }
    msgClose(){
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
      }

    handleInactiveDoc = (e) =>{
        let {selectedItem} = this.state
        selectedItem["is_active"] = e.target.checked     
        this.setState({ selectedItem })
    }

    handleRadioGrpChange = (key, value) => {
        let {selectedItem} = this.state
        selectedItem[key] = value
        this.setState({ selectedItem })
    }
    
    renderRadio = (label, radioLabel1, radioLabel2, stateName, stateValue) => {
        return(
            <Box sx={{ display: 'flex', justifyContent: 'space-between', m: '0.5vw', ml: '2vw' }}>
                <Typography m={'0.5vw'}>{label}</Typography>
                <RadioGroup row sx={{ gap: '2vw', mr: '1vw' }} value={stateValue} onChange={(e) => { this.handleRadioGrpChange(stateName, e.target.value) }}>
                    <FormControlLabel value={'INTERVAL'} control={<Radio inputProps={{ 'emed_tid': `${stateName}_intervalBtn_testID` }} disabled={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Doctor List", "SubTab")?.editAccess}/>} label={radioLabel1} />
                    <FormControlLabel value={'PATIENT COUNT'} control={<Radio inputProps={{ 'emed_tid': `${stateName}_countBtn_testID` }} disabled={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Doctor List", "SubTab")?.editAccess}/>} label={radioLabel2} />
                </RadioGroup>
            </Box>
        )

    }

    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, docDetails, selectedItem, orgSelectedData } = this.state;
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.06, renderCell: (index) => index.api.getRowIndex(index.row.doctor_id) + 1 },
            {
                field: 'doctor_name', headerName: t('DoctorName'), flex: 0.18, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.doctor_name?.length > 15 ? <Tooltip placement="top" title={cellvalues?.row?.doctor_name} arrow><div>{cellvalues?.row?.doctor_name.slice(0, 10) + "..."}</div></Tooltip> : cellvalues?.row?.doctor_name}</div>
                    )
                }
            },
            {
                field: 'registration_proof_number', headerAlign: "right", headerName: t('RegistrationNo'), flex: 0.18, align : "right", renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.registration_proof_number?.length > 15 ? <Tooltip placement="top" title={cellvalues?.row?.registration_proof_number} arrow><div>{cellvalues?.row?.registration_proof_number ? cellvalues.row.registration_proof_number.slice(0, 10) + "..." : '-'}</div></Tooltip> : cellvalues?.row?.registration_proof_number ? cellvalues.row.registration_proof_number : '-'}</div>
                    )
                }
            },
            {
                field: 'approved_by', headerName: t('VerifiedBy'), flex: 0.16, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.approved_by?.length > 15 ? <Tooltip placement="top" title={cellvalues?.row?.approved_by} arrow><div>{cellvalues?.row?.approved_by.slice(0, 10) + "..."}</div></Tooltip> : cellvalues?.row?.approved_by}</div>
                    )
                }
            },
            {
                field: 'specialization_name', headerName: t('Specialization'), flex: 0.18, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.specialization_name?.length > 15 ? <Tooltip placement="top" title={cellvalues?.row?.specialization_name} arrow><div>{cellvalues?.row?.specialization_name.slice(0, 10) + "..."}</div></Tooltip> : cellvalues?.row?.specialization_name}</div>
                    )
                }
            },
            {
                field: 'commission_percentage', headerAlign: "right", headerName: `${t("Commission")} %`, align : "right", flex: 0.13,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.commission_percentage ? params?.row?.commission_percentage :
                    params?.row?.commission_percentage === 0 ? "0" : "-"}</Box>)
            },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.11, sortable: false, hideable: false, align: "center", renderCell: (cellvalues) => {
                    return (
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                            <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Doctor List", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleEdit.bind(this, cellvalues.row)} />
                            {/* <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "OP Service Configuration",  "Doctor Name", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleDelete.bind(this, cellvalues.row)} /> */}
                        </Stack>
                    )
                }
            }
        ]
        return (
            <Box  id="eMed_srvConfig_Container" sx={{backgroundColor: Colors.configHomeBg}}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            <DataGrid
                                rows={docDetails}
                                columns={columns}
                                getRowId={(row) => row.doctor_id}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: CommonGridHeader.CommonToolBar,
                                    NoRowsOverlay: () => {
                                        return (
                                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }
                                }}
                                headerHeight = {40}
                                rowCount={docDetails.length}
                                page={current_page}
                                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                pageSize={rowsPerPage}
                                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                rowsPerPageOptions={[10, 20, 30]}
                                pagination
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{t('DoctorConfiguration')}</p>
                            </div>
                            <div className="eMed_srvConfig_subDiv">
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    {this.renderTextBox('DoctorName', "doctor_dr__first_name", selectedItem.doctor_dr__first_name)}
                                    {this.renderTextBox('Specialization', "specialization_name", selectedItem.specialization_name)}
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    {this.renderTextBox('Qualification', "qualification", selectedItem.qualification)}
                                    {this.renderTextBox('RegistrationNo', "registration_proof_number", selectedItem.registration_proof_number)}
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    {this.renderTextBox('VerifiedBy', "approved_by", selectedItem.approved_by)}
                                    {this.renderTextBox('Status', "status", selectedItem.status)}
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    {this.renderTextBox('Commission', "commission_percentage", selectedItem.commission_percentage)}
                                    {<Box sx={{width: "14vw"}}>
                                        <FormControlLabel label="Active" labelPlacement="start" sx={{gap:'1vw'}} control={<Checkbox checked={selectedItem.is_active ? true : false} onChange={(e)=>{this.handleInactiveDoc(e)}} />} />
                                    </Box>}
                                </Stack>
                                {this.state.isQmsConfig ?
                                    <Box>
                                        <br />
                                        <hr />
                                        <Stack>
                                            <Typography fontWeight={'bold'} m={'1vw'}>Slot Configuration</Typography>
                                            {this.renderRadio("Based On", "Interval", "Patient Count", "slot_based_on", selectedItem.slot_based_on)}
                                        </Stack>
                                    </Box>
                                    : null}
                            </div>
                            <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                                <Button variant='outlined' emed_tid="doctor_config_clear_btn" size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                <Button variant='contained' emed_tid="doctor_config_save_btn" disabled={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Doctor List", "SubTab")?.editAccess} size="small" id="eMed_Config_btn" onClick={() => { 
                                    if(orgSelectedData?.slot_based_on !== selectedItem?.slot_based_on){
                                        this.setState({
                                            changeBasedOn: true
                                        })
                                    }else{
                                        this.handleSave()
                                    }
                                }}>{t("Save")}</Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={`${t("deleteMsg")}  ${this.state.doctor_name ? this.state.doctor_name : "-"} ?`}
                        DeleteNotify={t("effectEntireSetting")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                {this.state.changeBasedOn ?
                    <DeletePopup DeletTitle={`When you change the slot configuration based on type, so you must update those configurations accordingly`}
                        DeleteNotify={t("it will impact other slot configurations as well")}
                        ButtonText = {"OK"}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.changeBasedOnConfirm.bind(this)}
                    />
                    : null}
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
            </Box>
        )
    }
}
export default withTranslation()(DoctorConfig);