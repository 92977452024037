import { Autocomplete, Box, Button, Checkbox, FormControlLabel, IconButton, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import { AdmissionTypeList, CancerTreamentSubType, DeliveryModeList, GenderList, MedicalTreamentSubType, PreAuthRequestData, RelationShipList, RemediServiceUrls, SurgeryMedicalTreamentSubType, SurgicalTreamentSubType, TRAUMATreamentSubType, TreatmentTypeList } from './RemedinetConstants';
import CommonValidation from '../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import moment from "moment";
import { localGetItem } from '../../Utility/Services/CacheProviderService';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { DateTime } from 'luxon';
import ClearIcon from '@mui/icons-material/Clear';


class PreAuthFormOne extends Component {
    constructor(props) {
        super(props)
        this.state = {
            CheckValidation: false,
            FormsDatas: this.props?.FormsDatas,
            ZoneList: this.props?.Allstates?.ZoneList ? this.props?.Allstates?.ZoneList : [],
            InsuranceList: this.props?.Allstates?.InsuranceList ? this.props?.Allstates?.InsuranceList : [],
            CorporateList: this.props?.Allstates?.CorporateList ? this.props?.Allstates?.CorporateList : [],
            RoomTypeList: this.props?.Allstates?.RoomTypeList ? this.props?.Allstates?.RoomTypeList : [],
            AlimentList: this.props?.Allstates?.AlimentList ? this.props?.Allstates?.AlimentList : [],
            ProvisionalDiagList: [],
            ProvTreatmentList: [],
            statusList: this.props?.page === "Preauthorisation" ? [
                { id: '1', name: 'DRAFT' },
                { id: '36', name: 'SUBMITTED' },
                { id: '38', name: 'INFO SUBMITTED' },
                { id: '39', name: 'ENHANCE' },
                { id: '50', name: 'RECONSIDER' },
                { id: '37', name: 'CANCEL' },
                { id: '2', name: 'Pre-Discharge - Submitted' },
                { id: '6', name: 'Pre-Discharge - Info-submitted' },
                { id: '7', name: 'Pre-Discharge - Cancel Requested' },
            ] : [
                { id: '9', name: 'Cashless  - Submitted' },
                { id: '13', name: 'Cashless  - Info-submitted' },
            ],
            selectedPayerId: this.props?.Allstates?.selectedPayerId ? this.props?.Allstates?.selectedPayerId : null,
            selectedTab: this.props?.page === "Preauthorisation" ? "Preauthorisation" : "Claim Submission",
            IPLIST: [],
            HistoyData: this.props.History,
            DisabledFields: {},
            Identity: {},
            disableAllDatas: this.props?.Allstates?.disableAllDatas ? true : false,

        }
    }

    componentDidMount() {
        let Insurance = JSON.parse(localGetItem("Insurance_Details"))
        this.setState({
            Identity: {
                "authToken": Insurance?.auth_token,
                "his_name": Insurance?.his_name
            },
            FormsDatas: this.props?.FormsDatas,
        }, () => {
            this.getIPList()
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                FormsDatas: this.props?.FormsDatas,
                ZoneList: this.props?.Allstates?.ZoneList ? this.props?.Allstates?.ZoneList : [],
                InsuranceList: this.props?.Allstates?.InsuranceList ? this.props?.Allstates?.InsuranceList : [],
                CorporateList: this.props?.Allstates?.CorporateList ? this.props?.Allstates?.CorporateList : [],
                RoomTypeList: this.props?.Allstates?.RoomTypeList ? this.props?.Allstates?.RoomTypeList : [],
                AlimentList: this.props?.Allstates?.AlimentList ? this.props?.Allstates?.AlimentList : [],
                selectedPayerId: this.props?.Allstates?.selectedPayerId ? this.props?.Allstates?.selectedPayerId : null,
                selectedTab: this.props?.page === "Preauthorisation" ? "Preauthorisation" : "Claim Submission",
                disableAllDatas: this.props?.Allstates?.disableAllDatas ? true : false,
                statusList: this.props?.page === "Preauthorisation" ? [
                    { id: '1', name: 'DRAFT' },
                    { id: '36', name: 'SUBMITTED' },
                    { id: '38', name: 'INFO SUBMITTED' },
                    { id: '39', name: 'ENHANCE' },
                    { id: '50', name: 'RECONSIDER' },
                    { id: '37', name: 'CANCEL' },
                    { id: '2', name: 'Pre-Discharge - Submitted' },
                    { id: '6', name: 'Pre-Discharge - Info-submitted' },
                    { id: '7', name: 'Pre-Discharge - Cancel Requested' },
                ] : [
                    { id: '9', name: 'Cashless  - Submitted' },
                    { id: '13', name: 'Cashless  - Info-submitted' },
                ],
            })
        }
    }

    getIPList = () => {
        let FormData = this.state?.FormsDatas
        let disabledFields = {}
        try {
            RestAPIService.getAll(Serviceurls.CURRENT_IP_PATIENT_LIST).
                then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            IPLIST: response?.data?.data
                        }, () => {
                            if (!this.state?.FormsDatas?.ip_number && this.props?.isEdit) {
                                let IP_NUMBER = this.state.IPLIST.filter((item) => item?.ip_number == this.props.History?.ip_number)
                                FormData['ip_number'] = {
                                    id: IP_NUMBER[0]?.id,
                                    ip_number: IP_NUMBER[0]?.ip_number
                                }
                                disabledFields["IP Number"] = FormData["ip_number"]?.["ip_number"] === "" || FormData["corporate"]?.["ip_number"] == null;
                                this.setState({
                                    FormsDatas: FormData,
                                    DisabledFields: disabledFields
                                }, () => {
                                    this.fetchHistory()
                                })
                            } else {
                                this.addProvTretmentDiagList()
                            }
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    addProvTretmentDiagList = () => {
        let ProvDiagList = this.state.ProvisionalDiagList
        let FormData = this.state.FormsDatas
        if (FormData?.provisional_diagnosis && ProvDiagList?.length === 0) {
            ProvDiagList.push(FormData?.provisional_diagnosis)
        }

        let ProvTreatList = this.state.ProvTreatmentList
        if (FormData?.provisional_treatment && ProvTreatList?.length === 0) {
            ProvTreatList.push(FormData?.provisional_treatment)
        }
        this.setState({
            ProvisionalDiagList: ProvDiagList,
            ProvTreatmentList: ProvTreatList
        })
    }

    revertDateFromString = (dateString) => {
        return DateTime.fromFormat(dateString, 'dd/MM/yyyy').toJSDate();
    };

    revertTimeFromString = (timeString) => {
        return DateTime.fromFormat(timeString, 'HH:mm').toJSDate();
    };

    setAdditionValue = (radio = "Not Metioned", duration, remarks = "") => {
        return {
            "radio_value": radio,
            "duration_count": duration.replace(" Month(s)", "").replace(" Year(s)", "").replace(" Day(s)", ""),
            "duration_type": duration?.includes("Month(s)") ? "Month(s)" : duration?.includes("Year(s)") ? "Year(s)" : duration?.includes("Day(s)") ? "Day(s)" : "",
            "remarks": remarks
        }
    }

    fetchHistory() {
        let item = this.props.History?.history
        let FormData = this.state?.FormsDatas
        let disabledFields = {}

        if (this.props.History) {
            FormData["rn_reference_no"] = this.props.History?.rn_reference_no ? this.props.History?.rn_reference_no : "";
            FormData["tpa_code"] = item?.tpacode ? item?.tpacode : "";
            // FormData["status_id"] = {
            //     name: item?.status_val ? item?.status_val : "",
            //     id: item?.status_id ? item?.status_id : ""
            // }
            FormData["zone_id"] = {
                ZONENAME: item?.zone_val ? item?.zone_val : "",
                ZONEID: item?.zone_id ? item?.zone_id : ""
            }
            FormData["insurance"] = {
                INSURANCE_NAME: item?.insurance_val ? item?.insurance_val : "",
                INSURANCE_ID: item?.insurance_id ? item?.insurance_id : ""
            }
            FormData["corporate"] = {
                NAME: item?.corporate_val ? item?.corporate_val : "",
                CODE: item?.corporate_id ? item?.corporate_id : ""
            }
            FormData["doctor_name"] = item?.doctorname_val ? item?.doctorname_val : "";
            FormData["employee"] = item?.employeeid ? item?.employeeid : "";
            FormData["policy_number"] = item?.policyno ? item?.policyno : "";
            FormData["id_card_no"] = item?.idcardno ? item?.idcardno : "";
            FormData["member_id"] = item?.memberid ? item?.memberid : "";
            FormData["patient_first_name"] = item?.patientfirstname ? item?.patientfirstname : "";
            FormData["patient_middle_name"] = item?.patientmiddlename ? item?.patientmiddlename : "";
            FormData["patient_last_name"] = item?.patientlastname ? item?.patientlastname : "";
            FormData["mobile_number"] = item?.mobileno ? item?.mobileno : "";
            FormData["date_of_birth"] = item?.dob ? this.revertDateFromString(item?.dob) : "";
            FormData["lmp"] = item?.lmp ? this.revertDateFromString(item?.lmp) : "";
            FormData["edd"] = item?.edd ? this.revertDateFromString(item?.edd) : "";
            FormData["age_year"] = item?.age_year ? item?.age_year : "";
            FormData["bill_number"] = item?.billnumber ? item?.billnumber : "";
            FormData["patient_hospital"] = item?.patienthospitalid ? item?.patienthospitalid : "";
            FormData["pan_no"] = item?.panno ? item?.panno : "";
            FormData["aadhar_number"] = item?.aadharno ? item?.aadharno : "";
            FormData["sum_insured"] = item?.suminsured ? item?.suminsured : "";
            FormData["rohini_id"] = item?.rohiniid ? item?.rohiniid : "";
            FormData["maternity_a"] = item?.maternity_a ? item?.maternity_a : "";
            FormData["maternity_g"] = item?.maternity_g ? item?.maternity_g : "";
            FormData["maternity_l"] = item?.maternity_l ? item?.maternity_l : "";
            FormData["maternity_p"] = item?.maternity_p ? item?.maternity_p : "";
            
            FormData["past_history"] = item?.past_history_of_present_ailment ? item?.past_history_of_present_ailment : "";
            FormData["intensive_care"] = item?.intensivecare ? item?.intensivecare : "";
            FormData["other_treatment"] = item?.othertreatments ? item?.othertreatments : "";
            FormData["present_ailment_remarks"] = item?.present_ailment_due_to_ped_remarks ? item?.present_ailment_due_to_ped_remarks : "";
            FormData["is_present_ailment_due_to_ped"] = item?.is_present_ailment_due_to_ped === "true" ? true : false;
            FormData["clinical_findings"] = item?.clinicalfindings ? item?.clinicalfindings : "";
            FormData["pulse_vital"] = item?.pulse ? item?.pulse : "";
            FormData["bp_vital"] = item?.bp ? item?.bp : "";
            FormData["cns_vital"] = item?.cns ? item?.cns : "";
            FormData["pa_vital"] = item?.pa ? item?.pa : "";
            FormData["rs_vital"] = item?.rs ? item?.rs : "";
            FormData["cs_vital"] = item?.cvs ? item?.cvs : "";
            FormData["is_alcohol"] = item?.presentillness_alcohol ? item?.presentillness_alcohol : false;
            FormData["is_infertility"] = item?.presentillness_infertility ? item?.presentillness_infertility : false;
            FormData["is_congenital_external_disease"] = item?.presentillness_congenital_external_disease ? item?.presentillness_congenital_external_disease : false;
            FormData["is_self_poisoning_injury"] = item?.presentillness_self_poisoning_injury ? item?.presentillness_self_poisoning_injury : false;
            FormData["is_hiv"] = item?.presentillness_hiv ? item?.presentillness_hiv : false;
            FormData["is_consmetic_surgery"] = item?.presentillness_cosmetic_surgery ? item?.presentillness_cosmetic_surgery : false;
            FormData["is_pregnancy_related"] = item?.presentillness_pregnancy_related ? item?.presentillness_pregnancy_related : false;
            FormData["is_none_of_these"] = item?.presentillness_none_of_these ? item?.presentillness_none_of_these : false;
            FormData["first_consult_date"] = item?.dateOffirstconsultation ? this.revertDateFromString(item?.dateOffirstconsultation) : "";

            FormData["age_month"] = item?.age_month ? item?.age_month : "";
            FormData["age_day"] = item?.age_day ? item?.age_day : "";
            FormData["e_mail"] = item?.emailid ? item?.emailid : "";
            FormData["address"] = item?.address ? item?.address : "";
            FormData["city"] = item?.city_val ? item?.city_val : "";
            FormData["state"] = item?.state_val ? item?.state_val : "";
            FormData["pincode"] = item?.pincode ? item?.pincode : "";
            FormData["primary_first_name"] = item?.primaryfirstname ? item?.primaryfirstname : "";
            FormData["primary_middle_name"] = item?.primarymiddlename ? item?.primarymiddlename : "";
            FormData["primary_last_name"] = item?.primarylastname ? item?.primarylastname : "";
            FormData["investigation"] = item?.investigation ? item?.investigation : "";
            FormData["surgery_code"] = item?.surgerycode ? item?.surgerycode : "";
            FormData["hospital_remark"] = item?.hospitalremarks ? item?.hospitalremarks : "";

            FormData["Ga"] = item?.typeofanesthesia?.includes("GA");
            FormData["La"] = item?.typeofanesthesia?.includes("LA");
            FormData["Sa"] = item?.typeofanesthesia?.includes("SA");
            FormData["Ea"] = item?.typeofanesthesia?.includes("EA");
            FormData["Ta"] = item?.typeofanesthesia?.includes("TA");


            const dateString = item?.surgerydate ? item?.surgerydate : "";
            let jsDate = null;
            if (dateString) {
                const [day, month, year] = dateString ? dateString.split('/') : null;
                jsDate = dateString ? new Date(`${year}-${month}-${day}`) : null;
            }
            FormData["surgery_date"] = jsDate ? jsDate : null
            FormData["gender"] = {
                label: item?.gender_val ? item?.gender_val : "",
                id: item?.gender_id ? item?.gender_id : ""
            }
            FormData["relationship"] = {
                label: item?.relationship_val ? item?.relationship_val : "",
                id: item?.relationship_id ? item?.relationship_id : ""
            }
            FormData["aliment"] = {
                AilmentName: item?.ailment_val ? item?.ailment_val : "",
                AilmentCode: item?.ailment_id ? item?.ailment_id : ""
            }
            FormData["treatment"] = {
                label: item?.treatment_val ? item?.treatment_val : "",
                id: item?.treatment_id ? item?.treatment_id : ""
            }
            FormData["treatment_subtype"] = {
                label: item?.treatmentsubtype_val ? item?.treatmentsubtype_val : "",
                id: item?.treatmentsubtype_id ? item?.treatmentsubtype_id : ""
            }
            FormData["delivery_mode"] = {
                label: item?.deliverymode_val ? item?.deliverymode_val : "",
                id: item?.deliverymode_id ? item?.deliverymode_id : ""
            }
            FormData["room_type"] = {
                PROVIDER_ROOM_TYPE: item?.roomtype_val ? item?.roomtype_val : "",
                PROVIDER_ROOM_TYPE_ID: item?.roomtype_id ? item?.roomtype_id : ""
            }
            FormData['doa_date'] = item?.doa ? this.revertDateFromString(item?.doa) : "";
            FormData['doa_time'] = item?.doa_time ? this.revertTimeFromString(item?.doa_time) : "";
            FormData['dod_date'] = item?.dod ? this.revertDateFromString(item?.dod) : "";
            FormData['accident_date'] = item?.accident_date ? this.revertDateFromString(item?.accident_date) : "";
            FormData['dod_time'] = item?.dod_time ? this.revertTimeFromString(item?.dod_time) : "";
            FormData["duration_of_stay"] = item?.durationofstay ? item?.durationofstay : "";
            FormData["bill_amount"] = item?.billamount ? item?.billamount : "";
            FormData["admission_type"] = {
                label: item?.admissiontype_val ? item?.admissiontype_val : "",
                id: item?.admissiontype_id ? item?.admissiontype_id : ""
            }
            FormData["aliment_duration"] = item?.ailment_duration ? item?.ailment_duration : "";
            FormData["room_rent"] = item?.roomrent ? item?.roomrent : "";
            FormData["diagnosis_subtype"] = item?.diagnosissubtype_val ? item?.diagnosissubtype_val : "";
            let ProvDiagList = this.state.ProvisionalDiagList
            if (item?.provisionaldiagnosis_val) {
                ProvDiagList.push({
                    DIAGNOSIS_NAME: item?.provisionaldiagnosis_val ? item?.provisionaldiagnosis_val : "",
                    DIAGNOSIS_ID: item?.provisionaldiagnosis_id ? item?.provisionaldiagnosis_id : ""
                })
            }

            FormData["provisional_diagnosis"] = {
                DIAGNOSIS_NAME: item?.provisionaldiagnosis_val ? item?.provisionaldiagnosis_val : "",
                DIAGNOSIS_ID: item?.provisionaldiagnosis_id ? item?.provisionaldiagnosis_id : ""
            }
            let ProvTreatList = this.state.ProvTreatmentList
            if (item?.provisinaltreatment_val) {
                ProvTreatList.push({
                    TREATMENT_NAME: item?.provisinaltreatment_val ? item?.provisinaltreatment_val : "",
                    TREATMENT_ID: item?.provisinaltreatment_id ? item?.provisinaltreatment_id : ""
                })
            }
            FormData["provisional_treatment"] = {
                TREATMENT_NAME: item?.provisinaltreatment_val ? item?.provisinaltreatment_val : "",
                TREATMENT_ID: item?.provisinaltreatment_id ? item?.provisinaltreatment_id : ""
            }

            FormData["hypertension"] = this.setAdditionValue(item?.hypertension, item?.hypertension_duration, item?.hypertension_remarks)
            FormData["diabetes"] = this.setAdditionValue(item?.diabetes, item?.diabetes_duration, item?.diabetes_remarks)
            FormData["heart_disease"] = this.setAdditionValue(item?.heartdisease, item?.heartdisease_duration, item?.heartdisease_remarks)
            FormData["copd"] = this.setAdditionValue(item?.copd, item?.copd_duration, item?.copd_remarks)
            FormData["stdhiv"] = this.setAdditionValue(item?.stdhiv, item?.stdhiv_duration, item?.stdhiv_remarks)
            FormData["asthma"] = this.setAdditionValue(item?.asthma, item?.asthma_duration, item?.asthma_remarks)
            FormData["epilepsy"] = this.setAdditionValue(item?.epilepsy, item?.epilepsy_duration, item?.epilepsy_remarks)
            FormData["cancer"] = this.setAdditionValue(item?.cancer, item?.cancer_duration, item?.cancer_remarks)
            FormData["arthritis"] = this.setAdditionValue(item?.arthritis, item?.arthritis_duration, item?.arthritis_remarks)
            FormData["other_diseases"] = this.setAdditionValue(item?.otherdiseases, item?.otherdiseases_duration, item?.otherdiseases_remarks)
            FormData["alcohol_drugs"] = this.setAdditionValue(item?.alcoholdrugs, item?.alcoholdrugs_duration, item?.alcoholdrugs_remarks)
            FormData["smoking_history"] = this.setAdditionValue(item?.smokinghistory, item?.smokinghistory_duration, item?.smokinghistory_remarks)
            FormData["congenital_disease"] = {
                "radio_value": item?.congenitaldisease
            }
            FormData["consanguineous_marriage"] = item?.congenitalmarriage ? item?.congenitalmarriage : "false"
            FormData["medico_legel_mlc"] = item?.medico_legal_mlc ? item?.medico_legal_mlc : "false"
            FormData["medico_legel_non_mlc"] = item?.medico_legal_nonmlc ? item?.medico_legal_nonmlc : "false"
            FormData["accident_fir"] = item?.accident_fir ? item?.accident_fir : "false"
            FormData["cause_of_injury"] = item?.cause_of_injury ? item?.cause_of_injury : ""
            FormData["member_rank"] = item?.member_rank ? item?.member_rank : ""



            disabledFields["Zone"] = FormData["zone_id"]?.["ZONENAME"] === "" || FormData["zone_id"]?.["ZONENAME"] == null;
            disabledFields["Insurance"] = FormData["insurance"]?.["INSURANCE_NAME"] === "" || FormData["insurance"]?.["INSURANCE_NAME"] == null;
            disabledFields["Corporate"] = FormData["corporate"]?.["SHORTNAME"] === "" || FormData["corporate"]?.["SHORTNAME"] == null;
            disabledFields["Employee"] = FormData["employee"] === "" || FormData["employee"] == null
            disabledFields["Policy Number"] = FormData["dpolicy_number"] === "" || FormData["employee"] == null
            disabledFields["Member ID"] = FormData["member_id"] === "" || FormData["member_id"] == null
            disabledFields["ID Card No"] = FormData["id_card_no"] === "" || FormData["id_card_no"] == null

            disabledFields["Patient First Name"] = FormData["patient_first_name"] === "" || FormData["patient_first_name"] == null
            disabledFields["Patient Middle Name"] = FormData["patient_middle_name"] === "" || FormData["patient_middle_name"] == null
            disabledFields["Patient Last Name"] = FormData["patient_last_name"] === "" || FormData["patient_last_name"] == null
            disabledFields["Gender"] = FormData["gender"]?.["label"] === "" || FormData["gender"]?.["label"] == null;
            disabledFields["Relationship"] = FormData["relationship"]?.["label"] === "" || FormData["relationship"]?.["label"] == null;
            disabledFields["Date of Birth"] = FormData["date_of_birth"] === "" || FormData["date_of_birth"] == null
            disabledFields["Age Year"] = FormData["age_year"] === "" || FormData["age_year"] == null
            disabledFields["Age Month"] = FormData["age_month"] === "" || FormData["age_month"] == null
            disabledFields["Age Day"] = FormData["age_day"] === "" || FormData["age_day"] == null
            disabledFields["Mobile No"] = FormData["mobile_number"] === "" || FormData["mobile_number"] == null
            disabledFields["E Mail"] = FormData["e_mail"] === "" || FormData["e_mail"] == null
            disabledFields["Address"] = FormData["address"] === "" || FormData["address"] == null
            disabledFields["City"] = FormData["city"] === "" || FormData["city"] == null
            disabledFields["State"] = FormData["state"] === "" || FormData["state"] == null
            disabledFields["Pincode"] = FormData["pincode"] === "" || FormData["pincode"] == null
            disabledFields["Primary First Name"] = FormData["primary_first_name"] === "" || FormData["primary_first_name"] == null
            disabledFields["Primary Middle Name"] = FormData["primary_middle_name"] === "" || FormData["primary_middle_name"] == null
            disabledFields["Primary Last Name"] = FormData["primary_last_name"] === "" || FormData["primary_last_name"] == null
            disabledFields["Aliment"] = FormData["aliment"]?.["AilmentName"] === "" || FormData["aliment"]?.["AilmentName"] == null;
            disabledFields["Aliment Duration"] = FormData["aliment_duration"] === "" || FormData["aliment_duration"] == null
            disabledFields["Investigation"] = FormData["investigation"] === "" || FormData["investigation"] == null
            disabledFields["Treatment"] = FormData["treatment"]?.["label"] === "" || FormData["treatment"]?.["label"] == null;
            disabledFields["Room Type"] = FormData["room_type"]?.["PROVIDER_ROOM_TYPE"] === "" || FormData["room_type"]?.["PROVIDER_ROOM_TYPE"] == null;
            disabledFields["Room Rent"] = FormData["room_rent"] === "" || FormData["room_rent"] == null
            disabledFields["DOA"] = FormData["doa_date"] === "" || FormData["doa_date"] == null
            disabledFields["DOA Time"] = FormData["doa_time"] === "" || FormData["doa_time"] == null
            disabledFields["DOD"] = FormData["dod_date"] === "" || FormData["dod_date"] == null
            disabledFields["DOD Time"] = FormData["dod_time"] === "" || FormData["dod_time"] == null
            disabledFields["Duration of Stay"] = FormData["duration_of_stay"] === "" || FormData["duration_of_stay"] == null
            disabledFields["Bill Amount"] = FormData["bill_amount"] === "" || FormData["bill_amount"] == null
            disabledFields["Admission Type"] = FormData["admission_type"]?.["label"] === "" || FormData["admission_type"]?.["label"] == null;
            disabledFields["Provisional Diagnosis"] = FormData["provisional_diagnosis"]?.["DIAGNOSIS_NAME"] === "" || FormData["provisional_diagnosis"]?.["DIAGNOSIS_NAME"] == null;
            disabledFields["Provisional Treatment"] = FormData["provisional_treatment"]?.["TREATMENT_NAME"] === "" || FormData["provisional_treatment"]?.["TREATMENT_NAME"] == null;

            if (this.state.selectedTab === "Claim Submission") {
                FormData["PatientPaidAmt"] = item?.PatientPaidAmount ? item?.PatientPaidAmount : "";
                FormData["HospitalClaimedAmt"] = item?.HospClaimedAmt ? item?.HospClaimedAmt : "";
            }
            this.setState({
                FormsDatas: FormData,
                DisabledFields: disabledFields,
                ProvTreatmentList: ProvTreatList,
                ProvisionalDiagList: ProvDiagList
            })
        }
    }

    getProDiagList = (val) => {
        try {
            let Data = {
                "url": RemediServiceUrls.REMEDI_BASE_URL + RemediServiceUrls.PROVI_DIAGNOSIS_LIST_GET,
                "payload": {
                    "PRETEXT": val,
                    "PAYERCODE": this.state.selectedPayerId,
                    "identity": this.state.Identity
                }

            }

            RestAPIService.create(Data, Serviceurls.REMEDINET_BACKEND).
                then((response) => {
                    console.log(response);
                    if (response?.data?.Status === "Success") {
                        let proDiogData = response?.data?.Data
                        this.setState({
                            ProvisionalDiagList: proDiogData?.PROVISIONDIAGNOSIS
                        })
                    } else {
                        this.setState({
                            ProvisionalDiagList: []
                        })
                    }
                }).catch(error => {
                    console.log(error)
                    // if(error?.response?.data?.status === "fail"){
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.response.data.message)
                    // }else{
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.message)
                    // }   
                })
        }
        catch (e) {
            console.log(e.message);
        }
    }

    getProTreatList = (val) => {
        try {
            let Data = {
                "url": RemediServiceUrls.REMEDI_BASE_URL + RemediServiceUrls.PROVI_TREATMENT_LIST_GET,
                "payload": {
                    "PRETEXT": val,
                    "PAYERCODE": this.state.selectedPayerId,
                    "identity": this.state.Identity
                }

            }

            RestAPIService.create(Data, Serviceurls.REMEDINET_BACKEND).
                then((response) => {
                    console.log(response);
                    if (response?.data?.Status === "Success") {
                        let proTreatData = response?.data?.Data
                        this.setState({
                            ProvTreatmentList: proTreatData?.PROVISIONTREATMENT
                        })
                    } else {
                        this.setState({
                            ProvTreatmentList: []
                        })
                    }
                }).catch(error => {
                    console.log(error)
                    // if(error?.response?.data?.status === "fail"){
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.response.data.message)
                    // }else{
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.message)
                    // }   
                })
        }
        catch (e) {
            console.log(e.message);
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    handleChangeTextBox = (e, key) => {
        let FormData = this.state?.FormsDatas
        let Value = e.target.value
        let number = CommonValidation.numberOnly(Value);
        let alphanumericsplchar = CommonValidation.ALPHA_NUM_SPLCHAR(Value);
        let alphanumeric = CommonValidation.gstnumberValidation(Value);
        let alphabetOnly = CommonValidation.alphabetOnly(Value);
        switch (key) {
            case 'mobile_number':
            case 'bill_amount':
            case 'age_year':
            case 'age_month':
            case 'age_day':
            case 'PatientPaidAmt':
            case 'HospitalClaimedAmt':
            case 'duration_of_stay':
            case 'sum_insured':
            case 'maternity_g':
            case 'maternity_p':
            case 'maternity_l':
            case 'maternity_a':
            case 'aadhar_number':
                if ((number && +Value > 0) || Value === "") {
                    FormData[key] = Value
                }
                break
            case 'pincode':
                if ((number && +Value > 0) || Value === "") {
                    FormData[key] = Value
                    if (Value?.length === 6) {
                        fetch("https://api.postalpincode.in/pincode/" + +Value)
                            .then((res) => res.json())
                            .then(response => {
                                if (response && response[0].Status == "Success") {
                                    var data = response[0].PostOffice
                                    if (data && data.length > 0) {
                                        FormData.city = data[0].Division
                                        FormData.state = data[0].State
                                        this.setState({
                                            FormsDatas: FormData,
                                            CheckValidation: false
                                        })
                                    }
                                } else if (response.data.status == "fail") {
                                }
                            })
                            .catch(e => {
                            });
                    }
                }
                break
            case 'patient_first_name':
            case 'patient_middle_name':
            case 'patient_last_name':
            case 'primary_first_name':
            case 'primary_middle_name':
            case 'primary_last_name':
                if ((alphabetOnly) || Value === "" || !Value) {
                    FormData[key] = Value
                }
                break
            case 'member_id':
                if ((alphanumericsplchar) || Value === "" || !Value) {
                    FormData[key] = Value
                }
                break
            case 'pan_no':
                if ((alphanumeric) || Value === "" || !Value) {
                    FormData[key] = Value
                }
                break
            default:
                FormData[key] = Value
                break;
        }
        this.setState({
            FormsDatas: FormData,
            CheckValidation: false
        })
    }


    FormTextBox = (label, key, WidthinVW = 18, isMandatory = false, MaxLenth = 50, Disable = false, Validation = "") => {
        try {
            return (
                <Box className='eMed_Form_textBoxes' sx={{ width: `${WidthinVW}vw` }}>
                    <TextField
                        autoComplete='off'
                        disabled={Disable || ((key === "PatientPaidAmt" || key === "HospitalClaimedAmt") ? false : this.state.disableAllDatas)}
                        // disabled={Disable ? Disable : (!this.state?.DisabledFields[label] && this.props.isEdit && (key !== "PatientPaidAmt" || key !== "HospitalClaimedAmt")) }
                        label={`${label}  ${isMandatory ? "*" : ""}`}
                        inputProps={{ maxLength: MaxLenth, emed_tid: `preauth_${key}` }}
                        variant="standard"
                        sx={{ width: `${WidthinVW - 2}vw` }}
                        error={isMandatory && this.state?.CheckValidation && !this.state?.FormsDatas[key]}
                        value={this.state?.FormsDatas[key] ? this.state?.FormsDatas[key] : ""}
                        onChange={(e) => { this.handleChangeTextBox(e, key, Validation) }} />
                </Box>
            )
        }
        catch (e) {

        }
    }

    FormDateSelect = (label, key, WidthinVW = 18, isMandatory = false, Disable = false) => {
        try {
            return (
                <Box className='eMed_Form_textBoxes' sx={{ width: `${WidthinVW}vw` }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={Disable || this.state.disableAllDatas}
                            // disabled={Disable ? Disable : (!this.state?.DisabledFields[label] && this.props.isEdit)}
                            value={this.state?.FormsDatas[key] ? this.state?.FormsDatas[key] : null}
                            name={"datePicker"}
                            inputFormat='DD-MM-YYYY'
                            views={["year", "month", "day"]}
                            maxDate={key === "date_of_birth" ? new Date() : null}
                            onChange={
                                (newDate) => {
                                    let FormData = this.state?.FormsDatas
                                    FormData[key] = newDate
                                    if (key === "date_of_birth") {
                                        let dob = new Date(newDate);
                                        let m = moment(dob, "DD-MM-YYYY");
                                        var end_date = moment();
                                        var year_age = end_date.diff(m, 'year') || 0;
                                        // reset(year) year with difference year
                                        m = moment(m).add(year_age, 'years');
                                        var month_age = end_date.diff(m, 'month') || 0;
                                        // reset(month) month with difference month
                                        m = moment(m).add(month_age, 'months');
                                        var day_age = end_date.diff(m, 'days') || 0;
                                        FormData["age_year"] = year_age
                                        FormData["age_month"] = month_age
                                        FormData["age_day"] = day_age
                                    }
                                    this.setState({
                                        FormsDatas: FormData
                                    })
                                }}
                            renderInput={(params) =>
                                <div style={{ position: "relative", display: "inline-block" }} >
                                    <TextField
                                        autoComplete='off'
                                        onKeyDown={(e) => e.preventDefault()}
                                        {...params}
                                        size='small'
                                        label={`${label}  ${isMandatory ? "*" : ""}`}
                                        variant="standard"
                                        sx={{ width: `${WidthinVW - 2}vw` }}
                                        inputProps={{
                                            ...params.inputProps,
                                            'emed_tid': `preauth_${key}`,
                                        }}
                                        error={isMandatory && this.state?.CheckValidation && !this.state?.FormsDatas[key]} />
                                    <IconButton style={{ position: "absolute", top: ".6rem", margin: "auto", right: "1rem", display: this.state?.FormsDatas[key] ? 'inline' : 'none' }}
                                        disabled={this.state.disableAllDatas}
                                        onClick={() => {
                                            let FormData = this.state?.FormsDatas
                                            FormData[key] = null
                                            if (key === "date_of_birth") {
                                                FormData["age_year"] = ""
                                                FormData["age_month"] = ""
                                                FormData["age_day"] = ""
                                            }
                                            this.setState({
                                                FormsDatas: FormData
                                            })
                                        }}>
                                        <ClearIcon />
                                    </IconButton>
                                </div>
                            }
                        />
                    </LocalizationProvider>
                </Box>
            )
        }
        catch (e) {

        }
    }
    FormTimeSelect = (label, key, WidthinVW = 18, isMandatory = false, Disable = false) => {
        try {
            return (
                <Box className='eMed_Form_textBoxes' sx={{ width: `${WidthinVW}vw` }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            disabled={Disable || this.state.disableAllDatas}
                            // disabled={Disable ? Disable : (!this.state?.DisabledFields[label] && this.props.isEdit)}
                            format={'HH:mm'}
                            value={this.state?.FormsDatas[key] ? this.state?.FormsDatas[key] : null}
                            onChange={
                                (newDate) => {
                                    let FormData = this.state?.FormsDatas
                                    FormData[key] = newDate
                                    this.setState({
                                        FormsDatas: FormData
                                    })
                                }}
                            renderInput={(params) =>
                                <div style={{ position: "relative", display: "inline-block" }} >
                                    <TextField
                                        autoComplete='off'
                                        onKeyDown={(e) => e.preventDefault()}
                                        {...params}
                                        size='small'
                                        label={`${label}  ${isMandatory ? "*" : ""}`}
                                        variant="standard"
                                        sx={{ width: `${WidthinVW - 2}vw` }}
                                        inputProps={{
                                            ...params.inputProps,
                                            'emed_tid': `preauth_${key}`,
                                        }}
                                        error={isMandatory && this.state?.CheckValidation && !this.state?.FormsDatas[key]} />
                                    <IconButton style={{ position: "absolute", top: ".6rem", margin: "auto", right: "1rem", display: this.state?.FormsDatas[key] ? 'inline' : 'none' }}
                                       disabled={this.state.disableAllDatas}
                                       onClick={() => {
                                            let FormData = this.state?.FormsDatas
                                            FormData[key] = null
                                            this.setState({
                                                FormsDatas: FormData
                                            })
                                        }}>
                                        <ClearIcon />
                                    </IconButton>
                                </div>
                            }
                        />
                    </LocalizationProvider>
                </Box>
            )
        }
        catch (e) {

        }
    }

    FormCheckBox = (label, key, WidthinVW = null, Disable = false) => {
        try {
            return (
                <Box className='eMed_Form_textBoxes' sx={{ width: WidthinVW ? `${WidthinVW}vw` : 'auto' }}>
                    <FormControlLabel
                        control={<Checkbox
                            disabled={Disable || this.state.disableAllDatas}
                            emed_tid={`preauth_${key}`}
                            checked={this.state?.FormsDatas[key] ? this.state?.FormsDatas[key] : false}
                            onChange={(e) => {
                                let FormData = this.state?.FormsDatas
                                FormData[key] = e.target.checked
                                this.setState({
                                    FormsDatas: FormData
                                })
                            }}
                            size='small' />}
                        label={`${label}`} />
                </Box>
            )
        }
        catch (e) {

        }
    }

    FormAutoComplete = (label, List, labelKey, Valuekey, key, WidthinVW = 18, isMandatory = false, Disable = false) => {
        try {
            let States = this.state?.FormsDatas
            return (
                <Box className='eMed_Form_textBoxes' sx={{ width: `${WidthinVW}vw` }}>
                    <Autocomplete
                        clearIcon={false}
                        options={List}
                        getOptionLabel={(option) => option[labelKey]}
                        value={(List?.length > 0 && States[key]) ? States[key] : null}
                        disabled={label === "Status" ? false : (Disable || this.state.disableAllDatas)}
                        // disabled={!this.state?.DisabledFields[label] && label !== "Status" && this.props.isEdit  ? !this.state?.DisabledFields[label] : Disable}
                        onChange={(e, value) => {
                            let FormData = this.state?.FormsDatas
                            FormData[key] = value
                            if (key === "treatment") {
                                FormData.treatment_subtype = null
                            }
                            this.setState({
                                FormsDatas: FormData
                            }, () => {
                                if (key === "ip_number") {
                                    this.GetIPAppointmentDetails()
                                }
                            })
                        }}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={option[Valuekey]}>
                                {option[labelKey]}
                              </li>
                            )
                          }}
                        renderInput={(params) => <TextField
                            {...params}
                            disabled={label === "Status" ? false : (Disable || this.state.disableAllDatas)}
                            // disabled={!this.state?.DisabledFields[label] && label !== "Status" && this.props.isEdit  ? !this.state?.DisabledFields[label] : Disable}
                            size='small'
                            label={`${label}  ${isMandatory ? "*" : ""}`}
                            variant="standard"
                            sx={{ width: `${WidthinVW - 2}vw` }}
                            error={isMandatory && this.state?.CheckValidation && !this.state?.FormsDatas[key]}
                            inputProps={{
                                ...params.inputProps,
                                'emed_tid': `preauth_${key}`,
                            }}
                            onChange={(e) => {
                                if (key === "provisional_diagnosis") {
                                    if ((e.target.value).length == 0) {
                                        this.setState({
                                            ProvisionalDiagList: []
                                        })
                                    } else if ((e.target.value).length > 3) {
                                        this.getProDiagList(e.target.value)
                                    }
                                } else if (key === "provisional_treatment") {
                                    if ((e.target.value).length == 0) {
                                        this.setState({
                                            ProvTreatmentList: []
                                        })
                                    } else if ((e.target.value).length > 3) {
                                        this.getProTreatList(e.target.value)
                                    }
                                }
                            }}
                        // value={this.state?.FormsDatas[key] ? this.state?.FormsDatas[key] : ""}
                        // onChange={(e) => { this.handleChangeTextBox(e, key) }} 
                        />}
                    />
                </Box>
            )
        }
        catch (e) {

        }
    }

    GetIPAppointmentDetails() {
        try {
            const { history } = this.props;
            var UserDetails = JSON.parse(localGetItem("loggedInUserInfo"))
            RestAPIService.getAll(Serviceurls.FO_GET_IP_ADMISSION_DETAILS + "?ip_admission_id=" + this.state.FormsDatas?.ip_number?.id + '&clinic_id=' + UserDetails?.clinic_id).
                then((response) => {
                    if (response.data.status === "success") {
                        let FormData = this.state?.FormsDatas
                        let Response = response.data?.data ? response.data.data : {}
                        FormData["patient_first_name"] = Response?.patient_first_name ? Response?.patient_first_name : ""
                        FormData["patient_middle_name"] = Response?.patient_middle_name ? Response?.patient_middle_name : ""
                        FormData["patient_last_name"] = Response?.patient_last_name ? Response?.patient_last_name : ""
                        FormData["mobile_number"] = Response?.patient_mobile_number ? Response?.patient_mobile_number : ""
                        FormData["state"] = Response?.patient_state ? Response?.patient_state : ""
                        FormData["date_of_birth"] = Response?.dob ? new Date(Response?.dob) : ""
                        FormData["doa_date"] = Response?.admission_date ? new Date(Response?.admission_date) : ""
                        FormData["doa_time"] = Response?.admission_date ? new Date(Response?.admission_date) : ""
                        FormData["doctor_name"] = Response?.doctor_name ? Response?.doctor_name : ""
                        FormData["doctor"] = Response?.doctor_id ? Response?.doctor_id : ""
                        FormData["employee"] = Response?.employee_id ? Response?.employee_id : ""
                        FormData["medico_legel_mlc"] = Response?.medico_legal_case ? 'true' : 'false'
                        FormData["ip_admission_id"] = Response?.id ? Response?.id : ''
                        FormData["city"] = Response?.patient_city ? Response?.patient_city : ''
                        FormData["pincode"] = Response?.pincode ? Response?.pincode : ''
                        FormData["e_mail"] = Response?.email ? Response?.email : ''
                        FormData["gender"] = Response?.patient_gender === "f" ? { "id": "1", "label": "Female" } : Response?.patient_gender === "m" ? { id: "2", label: "Male" } : ""
                        FormData["address"] = `${Response?.patient_address_1 ? Response?.patient_address_1 : ""}`
                        if (Response?.dob) {
                            let dob = new Date(Response?.dob);
                            let m = moment(dob, "DD-MM-YYYY");
                            var end_date = moment();
                            var year_age = end_date.diff(m, 'year') || 0;
                            // reset(year) year with difference year
                            m = moment(m).add(year_age, 'years');
                            var month_age = end_date.diff(m, 'month') || 0;
                            // reset(month) month with difference month
                            m = moment(m).add(month_age, 'months');
                            var day_age = end_date.diff(m, 'days') || 0;
                            FormData["age_year"] = year_age
                            FormData["age_month"] = month_age
                            FormData["age_day"] = day_age
                        }
                        this.setState({
                            FormsDatas: FormData
                        })
                    }
                    else {
                        this.props?.showToastMsg(response.data.message, true)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.props?.showToastMsg(error.response.data.message, true)
                    } else {
                        this.props?.showToastMsg(error.message, true)
                    }
                })
        }
        catch (e) {
            this.props?.showToastMsg(e.message, true)
        }
    }

    SaveAsDraft() {
        let RequestData = PreAuthRequestData(this.state.FormsDatas, true)
        console.log((RequestData));
        this.postPreAuthForm(RequestData)
    }

    postPreAuthForm = (data) => {
        try {
            let PreAuthData = {
                "url": RemediServiceUrls.REMEDI_BASE_URL + RemediServiceUrls.PRE_AUTH_POST,
                "payload": data
            }
            RestAPIService.create(PreAuthData, Serviceurls.REMEDINET_BACKEND).
                then((response) => {
                    console.log(response);
                }).catch(error => {
                    console.log(error)
                    // if(error?.response?.data?.status === "fail"){
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.response.data.message)
                    // }else{
                    //     this.LoaderFunction(false)
                    //     this.errorMessage(error.message)
                    // }   
                })
        } catch (e) {

        }
    }

    ValidFormDatas() {
        try {
            let FormDatas = this.state.FormsDatas
            if (
                FormDatas?.ip_number?.ip_number &&
                FormDatas?.tpa_code &&
                FormDatas?.hospital_code &&
                // FormDatas?.status_id &&
                FormDatas?.zone_id &&
                (FormDatas?.corporate?.CODE ? FormDatas?.employee : true) &&
                FormDatas?.policy_number &&
                FormDatas?.member_id &&
                FormDatas?.patient_first_name &&
                FormDatas?.patient_middle_name &&
                FormDatas?.patient_last_name &&
                FormDatas?.gender &&
                (FormDatas?.corporate?.CODE ? FormDatas?.relationship : true) &&
                (FormDatas?.date_of_birth || FormDatas?.age_year) &&
                FormDatas?.mobile_number &&
                FormDatas?.mobile_number?.length === 10 &&
                (!FormDatas?.pincode || FormDatas?.pincode?.length === 6) &&
                FormDatas?.aliment &&
                FormDatas?.treatment &&
                (FormDatas?.treatment?.id != 1 || (FormDatas?.Ga || FormDatas?.La || FormDatas?.Sa || FormDatas?.Ea)) &&
                FormDatas?.room_type &&
                FormDatas?.doa_date &&
                FormDatas?.doa_time &&
                FormDatas?.dod_date &&
                FormDatas?.dod_time &&
                FormDatas?.duration_of_stay &&
                (FormDatas?.treatment?.id != 1 || FormDatas?.surgery_date) &&
                FormDatas?.bill_amount &&
                FormDatas?.admission_type &&
                FormDatas?.provisional_diagnosis &&
                FormDatas?.provisional_treatment &&
                (FormDatas?.admission_type?.id != 4 || (FormDatas?.maternity_g && FormDatas?.maternity_p && FormDatas?.maternity_l && FormDatas?.maternity_a && FormDatas?.lmp && FormDatas?.edd && FormDatas?.delivery_mode)) &&
                (FormDatas?.admission_type?.id != 5 || (FormDatas?.hospital_remark)) &&
                (this.state.selectedTab === "Claim Submission" ? (FormDatas?.PatientPaidAmt && FormDatas?.HospitalClaimedAmt) : true)
            ) {
                if (this.state.selectedTab === "Preauthorisation") {
                    this.props.MovetoNext(FormDatas, 1)
                } else {
                    this.props.MovetoClaimTab(FormDatas, 1)
                }
            } else {
                if (FormDatas?.mobile_number && FormDatas?.mobile_number.length !== 10) {
                    this.props?.showToastMsg("Invalid Mobile Number, Should be 10 digit", true)
                } else if (FormDatas?.pincode && FormDatas?.pincode?.length !== 6) {
                    this.props?.showToastMsg("Invalid Pincode, Should be 6 digit", true)
                } else {
                    this.props?.showToastMsg("Enter All Mandatory Fields", true)
                }

            }
        }
        catch (e) {

        }
    }

    render() {
        console.log(this.state);
        let States = this.state
        let { FormsDatas } = this.state
        return (
            <Box>
                <Box className="eMEd_Form_body_div">
                    <Box className='eMed_Form_Div_body'>
                        <Typography className='eMed_Form_Div_Title'>Basic Details</Typography>
                        <Box className='eMed_Form_body_inputs_div'>
                            {/* {this.FormTextBox("IP Number", "ip_number", 18, true, 30, false)} */}
                            {this.FormAutoComplete("IP Number", States?.IPLIST, "ip_number", "IP_NUMBER", "ip_number", 18, true, false)}
                            {this.FormTextBox("Rn Reference Number", "rn_reference_no", 18, false, 50, true)}
                            {this.FormTextBox("TPA Code", "tpa_code", 18, true, 50, true)}
                            {this.FormTextBox("Hospital Code", "hospital_code", 18, true, 50, true)}
                            {/* {this.FormTextBox("Statusid", "status_id", 18, true, 35, false)} */}
                            {this.FormAutoComplete("Status", States?.statusList, "name", "id", "status_id", 18, true, false)}
                            {/* {this.FormCheckBox("Is Specialcase", "is_specialcase", 18, false)} */}
                            {this.FormAutoComplete("Zone", States?.ZoneList, "ZONENAME", "ZONEID", "zone_id", 18, true, false)}
                            {this.FormAutoComplete("Insurance", States?.InsuranceList, "INSURANCE_NAME", "INSURANCE_ID", "insurance", 18, false, false)}
                            {this.FormAutoComplete("Corporate", States?.CorporateList, "NAME", "CODE", "corporate", 18, false, false)}
                            {this.FormTextBox("Employee", "employee", 18, FormsDatas?.corporate?.CODE ? true : false, 35, false)}
                            {this.FormTextBox("Policy Number", "policy_number", 18, true, 50, false)}
                            {this.FormTextBox("Member ID", "member_id", 18, true, 30, false)}
                            {this.FormTextBox("ID Card No", "id_card_no", 18, false, 50, false)}
                        </Box>
                    </Box>
                    <Box className='eMed_Form_Div_body'>
                        <Typography className='eMed_Form_Div_Title'>Patient Details</Typography>
                        <Box className='eMed_Form_body_inputs_div'>
                            {this.FormTextBox("Patient First Name", "patient_first_name", 18, true, 30, false)}
                            {this.FormTextBox("Patient Middle Name", "patient_middle_name", 18, true, 10, false)}
                            {this.FormTextBox("Patient Last Name", "patient_last_name", 18, true, 10, false)}
                            {this.FormAutoComplete("Gender", GenderList, "label", "id", "gender", 18, true, false)}
                            {this.FormAutoComplete("Relationship", RelationShipList, "label", "id", "relationship", 18, FormsDatas?.corporate?.CODE ? true : false, false)}
                            {this.FormDateSelect("Date of Birth", "date_of_birth", 18, FormsDatas?.age_year ? false : true, false)}
                            {this.FormTextBox("Age Year", "age_year", 9, FormsDatas?.date_of_birth ? false : true, 2, false)}
                            {this.FormTextBox("Age Month", "age_month", 9, false, 2, false)}
                            {this.FormTextBox("Age Day", "age_day", 9, false, 2, false)}
                            {this.FormTextBox("Mobile No", "mobile_number", 18, true, 10, false)}
                            {this.FormTextBox("E Mail", "e_mail", 27, false, 45, false)}
                            {this.FormTextBox("Address", "address", 36, false, 50, false)}
                            {this.FormTextBox("City", "city", 18, false, 35, false)}
                            {this.FormTextBox("State", "state", 18, false, 35, false)}
                            {/* ================= */}
                            {this.FormTextBox("Pincode", "pincode", 18, false, 6, false)}
                            {this.FormTextBox("Primary First Name", "primary_first_name", 18, false, 30, false)}
                            {this.FormTextBox("Primary Middle Name", "primary_middle_name", 18, false, 10, false)}
                            {this.FormTextBox("Primary Last Name", "primary_last_name", 18, false, 10, false)}
                            {this.FormAutoComplete("Aliment", States?.AlimentList, "AilmentName", "AilmentCode", "aliment", 18, true, false)}
                            {this.FormTextBox("Aliment Duration", "aliment_duration", 18, false, 200, false)}
                            {this.FormTextBox("Investigation", "investigation", 36, false, 200, false)}
                            {this.FormAutoComplete("Treatment", TreatmentTypeList, "label", "id", "treatment", 27, true, false)}
                            {this.FormAutoComplete("Treatment Subtype", FormsDatas?.treatment?.id == 3 ? CancerTreamentSubType : FormsDatas?.treatment?.id == 3 ? CancerTreamentSubType : FormsDatas?.treatment?.id == 2 ? MedicalTreamentSubType : FormsDatas?.treatment?.id == 39 ? SurgeryMedicalTreamentSubType : FormsDatas?.treatment?.id == 1 ? SurgicalTreamentSubType : FormsDatas?.treatment?.id == 56 ? TRAUMATreamentSubType : [], "label", "id", "treatment_subtype", 27, false, false)}
                            <Box sx={{ width: "23vw", paddingTop: '1vw' }}>
                                <Typography fontSize={"0.8vw"} paddingLeft={"1vw"} fontWeight={600} color={(FormsDatas?.treatment?.id == 1 && this.state?.CheckValidation && !(FormsDatas['Ga'] || FormsDatas['La'] || FormsDatas['Sa'] || FormsDatas['Ea'] || FormsDatas['Ta'])) ? 'red' : 'black'}>Types of Anaesthesia {FormsDatas?.treatment?.id == 1 ? "*" : ""}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {this.FormCheckBox("GA", "Ga", null, false)}
                                    {this.FormCheckBox("LA", "La", null, false)}
                                    {this.FormCheckBox("SA", "Sa", null, false)}
                                    {this.FormCheckBox("EA", "Ea", null, false)}
                                    {this.FormCheckBox("TA", "Ta", null, false)}
                                </Box>
                            </Box>
                            {this.FormAutoComplete("Room Type", States?.RoomTypeList, "PROVIDER_ROOM_TYPE", "PROVIDER_ROOM_TYPE_ID", "room_type", 18, true, false)}
                            {this.FormTextBox("Room Rent", "room_rent", 18, false, 8, false)}
                            {this.FormDateSelect("DOA", "doa_date", 15, true, false)}
                            {this.FormTimeSelect("DOA Time", "doa_time", 15, true, false)}
                            {this.FormDateSelect("DOD", "dod_date", 18, true, false)}
                            {this.FormTimeSelect("DOD Time", "dod_time", 18, true, false)}
                            {this.FormTextBox("Duration of Stay", "duration_of_stay", 18, true, 3, false)}
                            {this.FormDateSelect("Surgery Date", "surgery_date", 18, FormsDatas?.treatment?.id == 1, false)}
                            {this.FormTextBox("Bill Amount", "bill_amount", 18, true, 10, false)}
                            {this.FormTextBox("Doctor Name", "doctor_name", 18, false, 100, true)}
                            {this.FormAutoComplete("Admission Type", AdmissionTypeList, "label", "id", "admission_type", 18, true, false)}
                            {this.FormAutoComplete("Provisional Diagnosis", States?.ProvisionalDiagList, "DIAGNOSIS_NAME", "DIAGNOSIS_ID", "provisional_diagnosis", 18, true, false)}
                            {/* ======================= */}
                            {this.FormTextBox("Diagnosis Subtype", "diagnosis_subtype", 18, false, 100, false)}
                            {/* {this.FormAutoComplete("Diagnosis Subtype", States?.ZoneList, "ZONENAME", "ZONEID", "diagnosis_subtype", 18, false, false)} */}
                            {this.FormAutoComplete("Provisional Treatment", States?.ProvTreatmentList, "TREATMENT_NAME", "TREATMENT_ID", "provisional_treatment", 18, true, false)}
                            {/* {this.FormAutoComplete("Treatment Subtype", FormsDatas?.treatment?.id == 3 ? CancerTreamentSubType : FormsDatas?.treatment?.id == 3 ? CancerTreamentSubType : FormsDatas?.treatment?.id == 2 ? MedicalTreamentSubType : FormsDatas?.treatment?.id == 39 ? SurgeryMedicalTreamentSubType : FormsDatas?.treatment?.id == 1 ? SurgicalTreamentSubType : FormsDatas?.treatment?.id == 56 ? TRAUMATreamentSubType : [], "label", "id", "treatment_subtype", 27, false, false)} */}
                            {/* ======================= */}
                            {this.FormTextBox("Bill Number", "bill_number", 18, false, 100, false)}
                            {this.FormTextBox("Patient Hospital", "patient_hospital", 18, false, 100, false)}
                            {this.FormTextBox("PAN No", "pan_no", 18, false, 10, false)}
                            {this.FormTextBox("Aadhar No", "aadhar_number", 18, false, 12, false)}
                            {this.FormTextBox("Sum Insured", "sum_insured", 18, false, 12, false)}
                            {this.FormTextBox("ROHINI Id", "rohini_id", 18, false, 20, false)}
                            {this.FormDateSelect("Date of First Consultation", "first_consult_date", 18, false, false)}
                            {this.FormTextBox("Surgery Code", "surgery_code", 18, FormsDatas?.treatment?.id == 1, 50, false)}
                            {this.FormTextBox("Maternity G", "maternity_g", 9, FormsDatas?.admission_type?.id == 4, 2, false)}
                            {this.FormTextBox("Maternity P", "maternity_p", 9, FormsDatas?.admission_type?.id == 4, 2, false)}
                            {this.FormTextBox("Maternity L", "maternity_l", 9, FormsDatas?.admission_type?.id == 4, 2, false)}
                            {this.FormTextBox("Maternity A", "maternity_a", 9, FormsDatas?.admission_type?.id == 4, 2, false)}
                            {this.state.selectedTab === "Claim Submission" ? this.FormTextBox("Patient Paid Amount", "PatientPaidAmt", 18, true, 10, false) : null}
                            {this.state.selectedTab === "Claim Submission" ? this.FormTextBox("Hospital Claimed Amount", "HospitalClaimedAmt", 18, true, 10, false) : null}
                        </Box>
                    </Box>
                    <Box className='eMed_Form_Div_body'>
                        <Typography className='eMed_Form_Div_Title'>Treatment Details</Typography>
                        <Box className='eMed_Form_body_inputs_div'>
                            {this.FormDateSelect("LMP", "lmp", 18, FormsDatas?.admission_type?.id == 4, false)}
                            {this.FormDateSelect("EDD", "edd", 18, FormsDatas?.admission_type?.id == 4, false)}
                            {this.FormAutoComplete("Delivery Mode", DeliveryModeList, "label", "id", "delivery_mode", 18, FormsDatas?.admission_type?.id == 4, false)}
                            {this.FormTextBox("Hospital Remark", "hospital_remark", 18, FormsDatas?.admission_type?.id == 5, 1000, false)}
                            {this.FormTextBox("Past history of present ailment", "past_history", 18, false, 200, false)}
                            {this.FormTextBox("Intensive care", "intensive_care", 18, false, 200, false)}
                            {this.FormTextBox("Other treatments", "other_treatment", 18, false, 200, false)}
                            {this.FormTextBox("Present ailment due to ped remarks", "present_ailment_remarks", 18, false, 200, false)}
                            {this.FormCheckBox("Is present ailment due to ped", "is_present_ailment_due_to_ped", null, false)}
                            {this.FormTextBox("Clinical Findings", "clinical_findings", 18, false, 50, false)}
                            <Box sx={{ width: "36vw", paddingTop: '1vw' }}>
                                <Typography fontSize={"0.8vw"} paddingLeft={"1vw"} fontWeight={600}>Vitals</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    {this.FormTextBox("Pulse", "pulse_vital", 6, false, 15, false)}
                                    {this.FormTextBox("BP", "bp_vital", 6, false, 15, false)}
                                    {this.FormTextBox("CNS", "cns_vital", 6, false, 15, false)}
                                    {this.FormTextBox("PA", "pa_vital", 6, false, 15, false)}
                                    {this.FormTextBox("RS", "rs_vital", 6, false, 15, false)}
                                    {this.FormTextBox("CS", "cs_vital", 6, false, 15, false)}
                                </Box>
                            </Box>
                            <Box sx={{ width: "54vw", paddingTop: '1vw' }}>
                                <Typography fontSize={"0.8vw"} paddingLeft={"1vw"} fontWeight={600}>Present Illness Due to</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {this.FormCheckBox("Alcohol", "is_alcohol", null, false)}
                                    {this.FormCheckBox("Infertility", "is_infertility", null, false)}
                                    {this.FormCheckBox("Congenital External Disease", "is_congenital_external_disease", null, false)}
                                    {this.FormCheckBox("Self Poisoning Injury", "is_self_poisoning_injury", null, false)}
                                    {this.FormCheckBox("HIV", "is_hiv", null, false)}
                                    {this.FormCheckBox("Consmetic Surgery", "is_consmetic_surgery", null, false)}
                                    {this.FormCheckBox("Pregnancy Related", "is_pregnancy_related", null, false)}
                                    {this.FormCheckBox("None of these", "is_none_of_these", null, false)}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="eMEd_Form_Foot_Btn_div">
                    {/* <Button size='small' variant='outlined' sx={{ textTransform: 'capitalize' }} onClick={() => {
                        this.SaveAsDraft()
                    }}>Save Draft</Button> */}
                    <div></div>
                    <Button size='small' variant='contained' sx={{ textTransform: 'capitalize' }} onClick={() => {
                        this.setState({
                            CheckValidation: true
                        }, () => {
                            if (this.state?.FormsDatas?.status_id?.id === "1" && this.state?.FormsDatas?.ip_number) {
                                this.props.MovetoNext(this.state.FormsDatas, 1)
                            } else if (this.state?.FormsDatas?.status_id?.id === "1" && !this.state?.FormsDatas?.ip_number) {
                                this.props?.showToastMsg("Enter IP Number", true)
                            } else {
                                this.ValidFormDatas()
                            }
                        })
                    }}>Next</Button>
                </Box>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
            </Box>
        )
    }
}
export default withTranslation()(PreAuthFormOne)