import React, { Component } from 'react';
import { Typography, Box, IconButton, Tooltip } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../Utility/ImagePaths';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import CircularProgress from '@mui/material/CircularProgress';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';

class DrQueuePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drQueueData: this.props.data ? this.props.data : [],
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            isLoader: false,
            disableBtn: false,
        }
    }

    componentDidMount = () => {
        this.getDoctorQueueData()
    }

    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            this.getDoctorQueueData()
        }
    }

    getDoctorQueueData = () => {
        try {
            this.setState({ isLoader: true })
            RestAPIService.getAll(Serviceurls.FO_DOC_QUEUE_GET + `?appointment_date=${DateTime.fromJSDate(new Date(this.props.date))?.toFormat('yyyy-MM-dd')}`)
                .then((response) => {
                    if (response?.data?.status === "success") {
                        this.setState({
                            drQueueData: response.data.data ? response.data.data : [],
                            isLoader: false,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.setState({ isLoader: false })
                })
        } catch (e) {
            this.errorMessage(e?.message)
            this.setState({ isLoader: false })
        }
    }

    callPatient = (item, isFor) => {
        try {
            let data = {
                appointment_id: item?.id ? item?.id : null,
                call_status: isFor,
            }
            RestAPIService.create(data, Serviceurls.FO_DOC_QUEUE_GET)
                .then((response) => {
                    if (response?.data?.status === "success") {
                        this.successMsgFunc(response.data?.message)
                        this.props.callPatient(response?.data?.data?.id)
                        this.getDoctorQueueData()
                        this.setState({ disableBtn: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (e) {
            this.errorMessage(e?.message)
            this.setState({ disableBtn: false })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMsgFunc = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        const { t } = this.props
        return (
            <Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography fontWeight={'600'}>Dr Queue</Typography>
                    <IconButton emed_tid='drQueue_popUp_close_btn' onClick={() => { this.props.onClose() }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                </Box>
                <hr />
                {this.state.drQueueData?.map((item) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1vw', borderBottom: '1px solid black', padding: '0.5vw', justifyContent: 'space-around' }}>
                            <Box sx={{ fontWeight: '600', width: '3.5vw', height: '3.5vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{item?.token_number ? item?.token_number : '-'}</Box>
                            <Box sx={{ width: '13vw' }}>
                                {item?.patient_names?.length > 17 ? <Tooltip title={item?.patient_names} placement={'top'} arrow><Typography fontWeight={'600'}>{item?.patient_names ? item?.patient_names?.slice(0, 14) + '...' : ''} - {item?.patient_age ? item?.patient_age : ''}</Typography></Tooltip> : <Typography fontWeight={'600'}>{item?.patient_names ? item?.patient_names : ''} - {item?.patient_age ? item?.patient_age : ''}</Typography>}
                                <Typography >{item?.doctor_name ? item?.doctor_name : ''}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '0.5vw' }}>
                                <Tooltip title='Arrived' placement='top' arrow><div><IconButton emed_tid='drQueue_popUp_tick_btn' onClick={() => { this.setState({ disableBtn: true }, () => { this.callPatient(item, "Approved") }) }} disabled={this.state.disableBtn}><img src={ImagePaths.Tick.default} alt='tick' style={{ width: '2vw', height: '2vw' }} /></IconButton></div></Tooltip>
                                <Tooltip title='Not Available' placement='top' arrow><div><IconButton emed_tid='drQueue_popUp_cancel_btn' onClick={() => { this.setState({ disableBtn: true }, () => { this.callPatient(item, "Cancelled") }) }} disabled={this.state.disableBtn}><img src={ImagePaths.CancelIcon.default} alt='cancel' style={{ width: '2vw', height: '2vw' }} /></IconButton></div></Tooltip>
                            </Box>
                        </Box>
                    )
                })}
                {this.state.isLoader ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='eMed_App_DataView'><CircularProgress /></div> :
                    this.state.drQueueData?.length === 0 ? <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '45%' }}>{t("No records found")}</Typography> : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        );
    }
}
export default withTranslation()(DrQueuePopup)
