import {
    Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel,
    ListItemText,
    MenuItem, Modal, Select, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, Tabs, Tab, Stack
} from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { ImagePaths } from '../../../../../Utility/ImagePaths'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import { PhotoCamera } from '@material-ui/icons'
import CancelIcon from '@mui/icons-material/Cancel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Webcam from 'react-webcam'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import CommonValidation from '../../../../../Components/CommonFunctions/CommonValidation'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { DeletePopup, ReasonPopup } from '../../../../../Components/Common Components/CommonComponents'
import { SUBLOCATION_LIST_DATA } from '../../../../../Utility/Constants'
import { Colors } from '../../../../../Styles/Colors'
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import Loader from '../../../../../Components/Loader';
import { Sub_Permission_Access } from '../../../../../Utility/Constants'
import BlockIcon from '@mui/icons-material/Block';
import HowToRegIcon from '@mui/icons-material/HowToReg';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
            width: 100,
        },
    },
};

let ModuleLayout = JSON.stringify(SUBLOCATION_LIST_DATA)
let Permission = JSON.stringify(Sub_Permission_Access)
var BranchName = null;
var BranchId = null;
class PharmaUserSettings extends Component {

    constructor(props) {
        super(props)
        this.webcamRef = React.createRef();
        let userList = this.props.history?.location?.state?.userData
        let isEdit = this.props.history?.location?.state?.isEdit
        let showEdit = this.props.history?.location?.state?.showEdit
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            passwords: "",
            mobileNumber: "",
            userName: "",
            clinicName: "",
            role: "User",
            module: [],
            moduleAndRoleList: [],
            profile: "",
            temp_patient_img: null,
            userRoleData: [],
            userModuleData: [],
            selectedModule: "",
            showPassword: false,
            deletePopUp: false,
            moduleList: JSON.parse(ModuleLayout),
            onClickEdit: showEdit,
            loginId: userList?.login_id,
            isEdit: isEdit,
            isUserLock: false,
            isUserInactive: false,
            tabDetail: "one",
            per_Access: JSON.parse(Permission),
            onSelectModules: false,

        }
    }

    componentDidMount() {
        if (this.state.loginId) {
            this.LoaderFunction(true)
            this.getUserAccessData()
        }
        this.getUserAccessModuleData()
        BranchName = localGetItem("BranchName")
        BranchId = localGetItem("BranchId")
    }

    errorMessgae = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessgae = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        let alpha = CommonValidation.alphabetOnly(value)
        let num = CommonValidation.numberOnly(value)
        let PasswordInput = CommonValidation.PasswordInput(value)
        let OnlyAlphaNum = CommonValidation.gstnumberValidation(value)
        switch (name) {
            case 'firstName':
                if ((alpha && value.length < 30) || value === "") {
                    this.setState({
                        firstName: value
                    })
                }
                break;
            case 'lastName':
                if ((alpha && value.length < 30) || value === "") {
                    this.setState({
                        lastName: value
                    })
                }
                break;
            case 'mobileNumber':
                if ((num && value.length <= 10) || value === "") {
                    this.setState({
                        mobileNumber: value
                    })
                }
                break;
            case 'userName':
                if ((OnlyAlphaNum && value.length <= 30) || value === "") {
                    this.setState({
                        userName: value
                    })
                }
                break;
            case 'passwords':
                if ((PasswordInput && value.length <= 14) || value === "") {
                    this.setState({
                        passwords: value
                    })
                }
                break;
            case 'selectedModule':
                this.setState({
                    selectedModule: value
                }, () => { this.setState({ onSelectModules: true }) })
                break;
            default:
                this.setState({
                    [name]: value
                })
                break;
        }
    }

    inputTextBox(label, value, name) {
        const { t } = this.props
        return (
            <TextField
                size='small'
                label={`${label} *`}
                name={name}
                value={value}
                className='eMed_input_text_box'
                onChange={(e) => this.onChangeHandler(e)}
                autoComplete='off'
            />
        )
    }

    UploadImage = (e, key) => {
        let states = this.state
        var files = e.target.files[0]
        var url = ""
        this.getBase64(files, (result) => {
            states[key] = result
            this.setState({ states }, () => {
                this.setState({
                    open: false
                })
            })
        });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    onClearHandler = () => {
        this.setState({
            module: [],
        })
    }

    onAddHandler = () => {
        try {
            let module = [];
            let module_id = null;
            let SameModule = {}
            this.state.module.forEach(element => {
                let moduleName = ""
                if (element?.id) {
                    moduleName = element?.sublocation_name
                    module_id = element?.id
                }
                SameModule = this.state.moduleAndRoleList.find((item) => item.sublocation_name == moduleName)
                if (SameModule === undefined) {
                    module.push({
                        label: moduleName,
                        moduels: 'sub_location',
                        id: module_id
                    })
                } else {
                    this.errorMessgae("Module Already Selected")
                }

            })
            let data = []
            var defaultData = JSON.parse(ModuleLayout)
            this.state.module.forEach(element => {
                this.state.moduleList.forEach(ele => {
                    if (!ele.sub_name) {
                        data.push({
                            sub_name: element.sublocation_name,
                            data: ele.data,
                            sub_title: ele.sub_title,
                        })
                    } else {
                        let newObj = {}
                        defaultData.forEach(ele => {
                            newObj.sub_name = element.sublocation_name
                            newObj.data = ele.data
                            newObj.sub_title = ele.sub_title
                        })
                        data = [...this.state.moduleList, newObj]

                    }
                })

            })
            this.state.moduleAndRoleList = this.state.moduleAndRoleList.concat(module)
            this.setState({ moduleAndRoleList: this.state.moduleAndRoleList, moduleList: data }, () => {
                this.onClearHandler()
            })
        } catch (error) {
            this.errorMessgae(error.message)
        }
    }

    OpenWebCamPopUP = () => {
        try {
            return (
                <Box>
                    <Modal open={true}>
                        <Box className="eMed_WebCamPop_Main">
                            <Box component={'div'} className="eMed_WebCam_Header">
                                <Typography variant='h6'>{"Patient Profile "}</Typography>
                                <Button onClick={() => { this.setState({ temp_patient_img: this.state.patient_img, webCamOpen: false }) }} >
                                    <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                                </Button>
                            </Box>
                            <Box component={'div'}>
                                {this.state.temp_patient_img === null ?
                                    <Webcam
                                        className='eMed_WebCam_Div'
                                        audio={false}
                                        mirrored={true}
                                        ref={this.webcamRef}
                                        screenshotFormat="image/jpeg"
                                    /> :
                                    <img className='eMed_WebCam_Img_Div' src={this.state.temp_patient_img} />}
                            </Box>
                            <Box component={'div'} className="eMed_WebCam_Btn_div">
                                <Button disabled={this.state.temp_patient_img !== null} variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ temp_patient_img: this.webcamRef.current.getScreenshot() }) }}>Capture</Button>
                                <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ temp_patient_img: null }) }}>Retake</Button>
                                <Button disabled={this.state.temp_patient_img === null} variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ profile: this.state.temp_patient_img, webCamOpen: false }) }}>Set Profile</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            );
        } catch (error) {
            this.errorMessgae(error.message)
        }
    };

    getUserAccessData = () => {
        try {
            RestAPIService.getAll(Serviceurls.PHARMA_USER_ACCESS_POST + "?login_id=" + this.state.loginId).
                then((response) => {
                    if (response.data.status === "success") {
                        this.AssignUserData(response.data.data[0])
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessgae(error.response.data.message)
                    } else {
                        this.errorMessgae(error.message)
                    }
                })
        } catch (error) {
            this.errorMessgae(error.message)
        }
    }

    AssignUserData(Data) {
        this.setState({
            firstName: Data?.login_fname,
            lastName: Data?.login_lname,
            email: Data?.login_email_address,
            passwords: "",
            mobileNumber: Data?.login_phone_number,
            userName: Data?.login_name,
            clinicName: Data?.clinic_name,
            role: "User",
            moduleAndRoleList: Data?.userlinked_modules,
            profile: Data?.user_image,
            moduleList: Data?.clinic_access,
            isUserInactive: Data?.is_deactivated,
            isUserLock: Data?.login_count >= 6 ? true : false,
            per_Access: Data?.permission_access != "" ? Data?.permission_access : JSON.parse(Permission)
        }, () => { this.LoaderFunction(false) })
    }

    getUserAccessModuleData = () => {
        try {
            RestAPIService.getAll(Serviceurls.PHARMA_USER_ACCESS_SUB_LOC).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            userModuleData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessgae(error.response.data.message)
                    } else {
                        this.errorMessgae(error.message)
                    }
                })
        } catch (error) {
            this.errorMessgae(error.message)
        }
    }

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    sendDocuments(key) {
        let states = this.state;
        if (states[key] === "" || states[key] === null) {
            return ""
        }
        else {
            if (states[key]?.length > 500) {
                return this.dataURItoBlob(states[key])
            }
            else {
                return ""
            }
        }
    }

    onClearLeftHandler = () => {
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            password: "",
            role: "",
            module: [],
            userName: "",
            moduleAndRoleList: [],
            clinicName: "",
            profile: null
        })
    }

    popupClose = () => {
        this.setState({ deletePopUp: false })
    }

    PostUserStatus = () => {
        const { t } = this.props
        try {
            let data = {
                "login_id": this.state.loginId,
                "active_status": !this.state.isUserLock
            }
            RestAPIService.create(data, Serviceurls.FO_CONFIG_USER_CONFIGURATION).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessgae(response.data.message)
                        this.getUserAccessData()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessgae(error.response.data.message)
                    } else {
                        this.errorMessgae(error.message)
                    }
                })
        } catch (error) {
            this.errorMessgae(error.message)
        }
    }

    UserDeleteFunc = () => {
        try {
            let data = {
                "login_id": this.state.loginId,
                "active_status": !this.state.isUserInactive
            }
            RestAPIService.create(data, Serviceurls.USER_ACCESS_GET_LIST)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({ deletePopUp: false })
                        this.getUserAccessData()
                        this.successMessgae(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessgae(error.response.data.message)
                    } else {
                        this.errorMessgae(error.message)
                    }
                })
        } catch (error) {
            this.errorMessgae(error.message)
        }

    }

    postNewUserAccessData = () => {
        try {
            let moduleJson = JSON.stringify(this.state.moduleList)
            let permission = JSON.stringify(this.state.per_Access)
            const dataForm = new FormData();
            dataForm.append("fname", CommonValidation.removeSpace(this.state.firstName))
            dataForm.append("lname", CommonValidation.removeSpace(this.state.lastName))
            dataForm.append("mail_id", this.state.email)
            dataForm.append("mobile_number", this.state.mobileNumber)
            dataForm.append("user_name", this.state.userName)
            dataForm.append("password", this.state.passwords === "" ? "None" : this.state.passwords)
            dataForm.append("user_image", (this.state.profile === null || this.state.profile === "" || this.state.profile.includes("https://s3-ap-south-1.amazonaws.com")) ? "None" : this.sendDocuments("profile"))
            dataForm.append("userlinked_module", JSON.stringify(this.state.moduleAndRoleList))
            dataForm.append("clinic_access", moduleJson)
            dataForm.append("branch_id", BranchId)
            dataForm.append("permission_access", permission)
            if (this.state.loginId) {
                dataForm.append("login_id", this.state.loginId)
            }

            if (this.state.firstName === "" || this.state.lastName === "" || this.state.email === ""
                || this.state.mobileNumber === "" || this.state.userName === "") {
                this.errorMessgae("Enter All Mandatory Fields")
            } else if (this.state.moduleAndRoleList.length === 0) {
                this.errorMessgae("Select Modules for the User")
            } else if (!CommonValidation.emailValidation(this.state.email)) {
                this.errorMessgae("Invalid E-Mail")
            } else if (this.state.isEdit === true) {
                if (this.state.passwords === "") {
                    this.postAPICall(dataForm)
                }
                else if (!CommonValidation.checkPassword(this.state.passwords)) {
                    this.errorMessgae("Password should be 6 characters, one UpperCase,Special Characters,LowerCase")
                } else if (CommonValidation.checkPassword(this.state.passwords)) {
                    this.postAPICall(dataForm)
                }
            } else if (this.state.isEdit === undefined) {
                if (this.state.passwords !== "" && CommonValidation.checkPassword(this.state.passwords)) {
                    this.postAPICall(dataForm)
                }
                else {
                    this.errorMessgae("Password should be 6 characters, one UpperCase,Special Characters,LowerCase")
                }
            }
        } catch (error) {
            this.errorMessgae(error.message)
        }
    }

    postAPICall(dataForm) {
        try {
            this.LoaderFunction(true)
            this.setState({disableBtn: true})
            RestAPIService.create(dataForm, Serviceurls.PHARMA_USER_ACCESS_POST).
                then((response) => {
                    if (response.data.status === "success") {
                        if (this.state.loginId === undefined) {
                            this.setState({
                                isEdit: true,
                                onClickEdit: false,
                                loginId: response.data?.user_id
                            }, () => { this.getUserAccessData() })
                        }
                        this.LoaderFunction(false)
                        this.successMessgae(response.data.message)
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.setState({disableBtn: false})
                        this.errorMessgae(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.setState({disableBtn: false})
                        this.errorMessgae(error.message)
                    }
                })
        }
        catch (e) {
            this.errorMessgae(e.message)
            this.setState({disableBtn: false})
        }
    }


    onChangeHandleCheck = (e) => {
        const { name, checked } = e.target;
        this.state.moduleList.map((user => (
            (user.sub_name === this.state.selectedModule) ?
                user.data.map((main) => {
                    if (main.title === name) {
                        main.viewAccess = checked
                        if (main.viewAccess === false) { main.editAccess = false }
                    }
                    main.main_tabs.map((mainTab) => {
                        if (main.title === name) {
                            mainTab.viewAccess = checked
                            if (mainTab.viewAccess === false) { mainTab.editAccess = false }
                            if (mainTab.sub_tabs?.length > 0) {
                                mainTab.sub_tabs.map((subTabs) => {
                                    if (mainTab.viewAccess) {
                                        subTabs.viewAccess = checked
                                    }
                                    else {
                                        subTabs.viewAccess = false
                                        subTabs.editAccess = false
                                    }
                                })
                            }
                        }
                    })
                }) : ""
        )))
        this.setState({ moduleList: this.state.moduleList })
    }

    onChangeHandleCheckMain = (e) => {
        const { name, checked } = e.target;
        this.state.moduleList.map((user => (
            (user.sub_name === this.state.selectedModule) ?
                user.data.map((main) => {
                    main.main_tabs.map((mainTab) => {
                        if (mainTab.title === name && main.viewAccess) {
                            mainTab.viewAccess = checked
                            if (checked === false) { mainTab.editAccess = false }
                            if (mainTab.sub_tabs?.length > 0) {
                                mainTab.sub_tabs.map((subTabs) => {
                                    if (mainTab.viewAccess) {
                                        subTabs.viewAccess = checked
                                    }
                                    else {
                                        subTabs.viewAccess = false
                                        subTabs.editAccess = false
                                    }
                                })
                            }
                            if (main.main_tabs.every(item => item['viewAccess'] === false)) {
                                main.viewAccess = false
                                main.editAccess = false
                            }
                        }
                    })

                }) : ""
        )))
        this.setState({ moduleList: this.state.moduleList })
    }

    ValidateAccess(){
        let flag = true
        for(let i = 0; i <= this.state.moduleList?.length; i++){
            if(this.state.moduleList[i]?.data?.every((list)=>(list?.viewAccess === false))){
                this.errorMessgae(`No Access given for ${this.state.moduleList[i]?.sub_name}. Give access to atleast one menu. `)
                flag = false
            }
        }
        return flag
    }

    renderModuleList = () => {
        return (
            <><Box component={'div'} className='eMed_mod_card'>
                {this.state.moduleList.map((item) => (
                    item.sub_name === this.state.selectedModule ?
                        <Box>
                            {item.data?.length > 0 ? item.data.map((i, indMain) => (
                                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }} key={indMain}>
                                    <Box display={'flex'} className="eMed_userAccessRow">
                                        <Box flex={0.7} display={'flex'} alignItems={'center'}>
                                            <Box mr={1} position={'relative'} top='0.2vw'>
                                                <img src={i.image ? i.image : ""} className='eMed_list_image' alt='' />
                                            </Box>
                                            <FormControlLabel
                                                label={<Typography fontWeight={600} fontSize={"1vw"}>{i?.title}</Typography>}
                                                value={i?.viewAccess}
                                                name={i?.title}
                                                onChange={(e) => this.onChangeHandleCheck(e)}
                                                control={<Checkbox checked={i?.viewAccess} size='small' />}
                                            />
                                        </Box>
                                        <Box flex={0.3} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} pr={'3vw'}>
                                            <IconButton disabled={!(i?.viewAccess)} onClick={() => {
                                                i.editAccess = !i.editAccess
                                                i.main_tabs.map((mainTab) => {
                                                    // if (i.title === name) {
                                                    if (mainTab.viewAccess === false) { mainTab.editAccess = false }
                                                    if (mainTab.viewAccess === true) { mainTab.editAccess = i.editAccess }

                                                    if (mainTab.sub_tabs?.length > 0) {
                                                        mainTab.sub_tabs.map((subTabs) => {
                                                            if (subTabs.viewAccess === true) { subTabs.editAccess = i.editAccess }
                                                        })
                                                    }
                                                    // }
                                                })
                                                this.setState({ moduleList: this.state.moduleList })
                                            }}>
                                                <img src={i?.editAccess ? ImagePaths.UserEditOn.default : ImagePaths.UserEditOff.default} className='eMed_list_image' />
                                            </IconButton>

                                        </Box>
                                    </Box>
                                    {i?.main_tabs?.length > 0 ? i.main_tabs.map((tabs, ind) => (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 5 }} key={ind}>
                                            <Box display={'flex'} className="eMed_userAccessRow">
                                                <Box flex={0.7}>
                                                    <FormControlLabel
                                                        label={tabs?.title}
                                                        value={tabs?.viewAccess}
                                                        name={tabs?.title}
                                                        onChange={(e) => this.onChangeHandleCheckMain(e)}
                                                        control={<Checkbox checked={tabs?.viewAccess} size='small' />}
                                                    />
                                                </Box>
                                                <Box flex={0.3} display={'flex'} alignItems={'center'} pr={'3vw'} justifyContent={'flex-end'}>
                                                    <IconButton disabled={!(tabs?.viewAccess)} onClick={() => {
                                                        tabs.editAccess = !tabs.editAccess
                                                        if (tabs.sub_tabs?.length > 0) {
                                                            tabs.sub_tabs.map((subTabs) => {
                                                                if (subTabs.viewAccess === true) { subTabs.editAccess = tabs.editAccess }
                                                            })
                                                        }
                                                        this.setState({ moduleList: this.state.moduleList })
                                                    }}>
                                                        <img src={tabs?.editAccess ? ImagePaths.UserEditOn.default : ImagePaths.UserEditOff.default} className='eMed_list_image' />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )) : null}
                                </Box>
                            )) : null}
                        </Box>
                        : ""
                ))}

            </Box >
                <Box display={'flex'}
                    justifyContent={'center'} alignItems={'center'}
                    height={"3.1vw"} sx={{ backgroundColor: Colors.Background }}>
                    <Box>
                        <Button
                            size='small'
                            variant='outlined'
                            sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                            onClick={() => {
                                let list = JSON.parse(ModuleLayout)
                                let array = []
                                this.state.moduleAndRoleList.forEach((ele) => {
                                    array.push({
                                        ...list[0], sub_name: ele.label
                                    })
                                })
                                 this.setState({ moduleList: array }) 
                                }}
                        >Reset</Button>
                        <Button
                            size='small'
                            variant='contained'
                            emed_tid='UserAcess_ModuleAccess_Save_Btn'
                            disabled={this.state.disableBtn}
                            sx={{ textTransform: "capitalize" }}
                            onClick={() => {
                                if(this.ValidateAccess()){
                                    this.postNewUserAccessData()
                                }
                            }}
                        >Save</Button>
                    </Box>
                </Box></>
        )
    }

    handleCheckBox = (e, billtype) => {
        let states = this.state
        states.per_Access[billtype] = e.target.checked
        this.setState({ states })
    }

    renderPermissionList = (billtype, label, value, helpertext) => {
        const { t } = this.props;
        return (
            <Stack direction="row" justifyContent="space-Between" alignItems="center" sx={{ height: "4.5vw" }}>
                <Stack direction="column" justifyContent={"center"}>
                    <FormControlLabel control={<Checkbox size="small" checked={value} onChange={(e) => { this.handleCheckBox(e, billtype) }} />}
                        label={<Typography id="eMed_permission_checkTxt"> {label} </Typography>} />
                    <Typography id="eMed_permission_Txt">{helpertext}</Typography>
                </Stack>
            </Stack>
        )
    }

    moduleAccessTab = () => {
        const { t } = this.props;
        return (
            <Box component={'div'}>
                <Box component={'div'} className='eMed_left_header'>
                    <FormControl size='small' className='eMed_select_text_box'>
                        <InputLabel>{t('Module')}</InputLabel>
                        <Select
                            disabled={this.state.moduleAndRoleList.length === 0 || this.state.isUserInactive === true}
                            label={t('Module')}
                            value={this.state.selectedModule}
                            name='selectedModule'
                            onChange={(e) => { this.onChangeHandler(e) }}
                        >
                            {this.state.moduleAndRoleList?.length > 0 ? this.state.moduleAndRoleList?.map((item) => {
                                return <MenuItem value={item.label}>{item.label}</MenuItem>
                            }) : null}
                        </Select>
                    </FormControl>
                </Box>
                {this.state.onSelectModules ?
                    <Box component={'div'}>
                        {this.renderModuleList()}
                    </Box> :
                    <Box component={'div'} className='eMed_left_content'>
                        <Box component={'div'} className='eMed_new_user_img'>
                            <img src={ImagePaths.NewUserImg.default} alt='new User' />
                        </Box>
                        <Box component={'div'} className='eMed_new_user'>
                            <Typography component={'div'} fontWeight={600}>Select any module to continue</Typography>
                            <Typography component={'div'}
                                fontWeight={400}
                                fontSize={'0.8vw'}
                                color={"#616161"}>Kindly Add any module to edit user access</Typography>
                        </Box>
                    </Box>}
            </Box>
        )
    }

    permissionTab = () => {
        const { t } = this.props;
        let states = this.state;
        return (
            <Box>
                <Box component={'div'} className='eMed_permission_box'>
                    <Box component={'div'} className='eMed_permission_card'>
                        {/* {this.renderPermissionList('discount_edit', 'Discount % Editable Access', states?.per_Access?.discount_edit, 'User can change the discount precentage')} */}
                        {/* {this.renderPermissionList('margin_profit', "User can View Margin Profit", states?.per_Access?.margin_profit, 'Approve for intent request')} */}
                        {this.renderPermissionList('indent_approve', "Indent Approver", states?.per_Access?.indent_approve, 'Approve for intent request')}
                        {/* {this.renderPermissionList('stock_correction', "Stock Correction", states?.per_Access?.stock_correction, 'Permission for stock corrections')} */}
                    </Box>
                </Box>
                <Box display={'flex'}
                    justifyContent={'center'} alignItems={'center'}
                    height={"3.1vw"} sx={{ backgroundColor: Colors.Background }}>
                    <Box>
                        <Button
                            size='small'
                            variant='outlined'
                            sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                            onClick={() => {
                                this.setState({
                                    per_Access: JSON.parse(Permission)
                                })
                            }}
                        >{t("Reset")}</Button>
                        <Button
                            size='small'
                            variant='contained'
                            emed_tid='UserAcess_Save_Btn'
                            disabled={this.state.disableBtn}
                            sx={{ textTransform: "capitalize" }}
                            onClick={() => this.postNewUserAccessData()}
                        >{t("Save")}</Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    render() {
        const { t } = this.props
        return (
            <Box component={'div'} className='eMed_new_container'>
                <Box component={'div'} className='eMed_new_left'>
                    <Box component={'div'} className='eMed_new_right_card'>
                        <Box component={'div'} className='eMed_new_middle'>
                            {this.state.isEdit ?
                                <Box component={'div'} className='eMed_top_edit_btn'>
                                    {!this.state.onClickEdit ?
                                        <Tooltip title={"Edit"} arrow placement='top'>
                                            <IconButton>
                                                <img src={ImagePaths.Edit.default} alt='edit'
                                                    className='eMed_edit_img_user'
                                                    onClick={() => { this.setState({ onClickEdit: true }) }}
                                                />
                                            </IconButton>
                                        </Tooltip> : null}
                                    <Tooltip title={this.state.isUserLock ? "Unlock the User" : "Lock the User"} arrow placement='top'>
                                        <IconButton onClick={() => { this.setState({ isUserLock: !this.state.isUserLock }, () => { this.PostUserStatus() }) }}>
                                            <img src={this.state.isUserLock ? ImagePaths.LockBtn.default : ImagePaths.LockBtnoutline.default} alt='lock' className='eMed_lock_img_user' />
                                        </IconButton>
                                    </Tooltip>
                                </Box> : null}

                            <Box component={'div'} className='eMed_new_right_top'>
                                <Box component={'div'} className='eMed_new_image_card'>
                                    <img src={this.state.profile ? this.state.profile : ImagePaths.Male.default} alt='profile'
                                        className={this.state.profile ? 'eMed_new_img_res' : 'eMed_new_profile'} />
                                    {this.state.open ?
                                        <Box component={'div'}>
                                            <IconButton color="primary" onClick={() => this.setState({ webCamOpen: true })}>
                                                <PhotoCamera />
                                            </IconButton>
                                            <IconButton color="primary" aria-label="upload picture" component="label">
                                                <input hidden accept="image/*" type="file" onChange={(e) => { this.UploadImage(e, "profile") }} />
                                                <UploadFileIcon />
                                            </IconButton>
                                            <IconButton color="primary"
                                                onClick={() => this.setState({ open: false })}>
                                                <CancelIcon />
                                            </IconButton>
                                        </Box>
                                        :
                                        <img
                                            src={ImagePaths.AddUserButton.default}
                                            alt='add'
                                            className='eMed_add_btn'
                                            onClick={() => this.setState({ open: true })}
                                        />}

                                    <Typography fontWeight={600}>New User</Typography>
                                </Box>
                            </Box>
                            <Box component={'div'} className='eMed_new_right_middle'>
                                {this.inputTextBox(t("FirstName"), this.state.firstName, 'firstName')}
                                {this.inputTextBox(t("LastName"), this.state.lastName, 'lastName')}
                                {this.inputTextBox(t("Username"), this.state.userName, 'userName')}
                                <form style={{ display: 'inline' }}>
                                    <TextField
                                        size='small'
                                        label={`${"Password"} *`}
                                        name={'passwords'}
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment>
                                                    <IconButton onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                                                        {this.state.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        value={this.state.passwords}
                                        className='eMed_input_text_box'
                                        onChange={(e) => this.onChangeHandler(e)}
                                        autoComplete='no'
                                    />
                                </form>
                                {this.inputTextBox(t("MobileNo"), this.state.mobileNumber, 'mobileNumber')}
                                {this.inputTextBox(t("MailID"), this.state.email, 'email')}
                            </Box>
                            <Box component={'div'} className='eMed_new_right_bottom'>
                                <Box>
                                    <FormControl size='small' className='eMed_select_text_box'>
                                        <InputLabel>{t('Role')}</InputLabel>
                                        <Select
                                            label={t('Role')}
                                            value={0}
                                            name='role'
                                            onChange={(e) => { this.onChangeHandler(e) }}
                                            disabled
                                        >
                                            <MenuItem value={0}>{"User"}</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl size='small' className='eMed_select_text_box'>
                                        <InputLabel>{t('SubLoc')}</InputLabel>
                                        <Select
                                            label={t('SubLoc')}
                                            value={this.state.module}
                                            name='module'
                                            multiple
                                            renderValue={(selected) => selected.map((item) => `${item?.sublocation_name}, `)}
                                            onChange={(e) => { this.onChangeHandler(e) }}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.userModuleData.map((name, index) => (
                                                <MenuItem key={index} value={name} disabled={this.state.moduleAndRoleList.some((list)=>(list?.id === name?.id))}>
                                                    <Checkbox size='small' checked={this.state.module.indexOf(name) > -1} disabled={this.state.moduleAndRoleList.indexOf(name) > -1} />
                                                    <ListItemText>{name.sublocation_name}</ListItemText>
                                                </MenuItem>

                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        variant='contained'
                                        className='eMed_new_btn_two'
                                        onClick={() => {
                                            if (this.state.role !== "") {
                                                this.onAddHandler()
                                            } else {
                                                this.errorMessgae("Select Role")
                                            }
                                        }}
                                    >{t("Add")}</Button>
                                </Box>
                                <Box component={'div'} className='eMed_new_table'>
                                    <TableContainer className='eMed_new_user_tbl'>
                                        <Table stickyHeader size='small'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className='eMed_tbl_head'>{t("Role")}</TableCell>
                                                    <TableCell className='eMed_tbl_head'>{t("SubLoc")}</TableCell>
                                                    <TableCell className='eMed_tbl_head'>{t("Action")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.moduleAndRoleList?.length > 0 ? this.state.moduleAndRoleList?.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{"User"}</TableCell>
                                                        <TableCell>{item?.label}</TableCell>
                                                        <TableCell>
                                                            <img
                                                                src={ImagePaths.Delete.default}
                                                                alt='delete'
                                                                className='eMed_new_delete'
                                                                onClick={() => {
                                                                    this.state.moduleAndRoleList.splice(index, 1)
                                                                    this.setState({ moduleAndRoleList: this.state.moduleAndRoleList })
                                                                }} />
                                                        </TableCell>
                                                    </TableRow>
                                                )) : <TableRow>
                                                    <TableCell />
                                                    <TableCell align='center'>{t("NoRecordsFound")}</TableCell>
                                                    <TableCell />
                                                </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                {this.state.isEdit ?
                                    <Box component={'div'} className='eMed_delete_user'>
                                        {
                                            this.state.isUserInactive === false ?
                                                <Button sx={{ textTransform: 'capitalize', fontWeight: '600' }} startIcon={<BlockIcon />} variant='outlined' color='error' onClick={() => { this.setState({ deletePopUp: true }) }}>
                                                    Inactive The User
                                                </Button> :
                                                <Button sx={{ textTransform: 'capitalize', fontWeight: '600' }} startIcon={<HowToRegIcon />} variant='outlined' onClick={() => { this.UserDeleteFunc() }}>
                                                    Active The User
                                                </Button>
                                        }
                                    </Box> : null}

                            </Box>
                        </Box>
                        {
                            (this.state.onClickEdit && this.state.selectedModule === "") ?

                                <Box component={'div'} className='eMed_new_btn'>
                                    <Box>
                                        {/* <Button
                                            size='small'
                                            variant='outlined'
                                            sx={    { marginRight: "1vw", textTransform: "capitalize" }}
                                        >Reset</Button> */}
                                        <Button
                                            size='small'
                                            variant='contained'
                                            emed_tid="UserAccess_LeftSave_Btn"
                                            disabled={this.state.disableBtn}
                                            sx={{ textTransform: "capitalize" }}
                                            onClick={() => this.postNewUserAccessData()}
                                        >Save</Button>
                                    </Box>
                                </Box> : null}

                    </Box>
                </Box>
                <Box component={'div'} className='eMed_new_right'>
                    <Box component={'div'} className='eMed_new_left_content'>
                        <Tabs
                            value={this.state.tabDetail}
                            onChange={(e, newValue) => this.setState({
                                tabDetail: newValue
                            })}
                        >
                            <Tab value="one" label={t("ModuleAccess")} className='eMed_tab_header' />
                            <Tab value="two" label={`${t("Permission")}s`} className='eMed_tab_header' />
                        </Tabs>
                        {this.state.tabDetail === "one" ? this.moduleAccessTab() : this.state.tabDetail === "two" ? this.permissionTab() : ""}
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletePopUp ? <DeletePopup
                    DeletTitle={"Are you sure ? Do you want to Inactive the User ?"}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.UserDeleteFunc.bind(this)}
                    ButtonText={'Yes'}
                /> : null}
                {this.state.webCamOpen ? this.OpenWebCamPopUP() : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}


export default withTranslation()(PharmaUserSettings)