import React, { Component } from 'react'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Popover from '@mui/material/Popover';
import { DateTime } from "luxon";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import './FutureDatePicker.css';
import { withTranslation } from 'react-i18next';

class FutureDatePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FrmDate: new Date(),
            ToDate: new Date()
        }
    }

    HandleFromDate = (newvalue) => {
        this.setState({
            FrmDate: newvalue,
            ToDate: newvalue
        });
    };


    HandleToDate = (newvalue) => {
        this.setState({
            ToDate: newvalue,
        });
    };

    HandleCustomDate = () => {
        this.props.CustomDates(
            DateTime.fromJSDate(this.state.FrmDate).toFormat('yyyy-MM-dd'),
            DateTime.fromJSDate(this.state.ToDate).toFormat('yyyy-MM-dd'))
    }

    PopClose = () => {
        this.props.PopClose()
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Popover
                    id="emed_calender_popover"
                    open={true}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 190, left: 800 }}>
                    <div className='emed_Calender_Pop'>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginLeft: "20%" }}> <h5 id="emed_CalenderHead">{t("FromDate")}</h5></div>
                            <div style={{ marginLeft: "35%" }}>  <h5 id="emed_CalenderHead">{t("ToDate")}</h5></div>
                            <IconButton id="emed_CalenderClose_Icon" onClick={this.PopClose}>
                                <CloseIcon color='primary' fontSize='large' />
                            </IconButton>
                        </div>
                        <div style={{ display: 'flex' }} >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <StaticDatePicker
                                    id="emed_calender_"
                                    displayStaticWrapperAs="desktop"
                                    value={this.state.FrmDate}
                                    minDate={this.props.RestrictPast ? new Date() : null}
                                    onChange={this.HandleFromDate}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <StaticDatePicker
                                    displayStaticWrapperAs="desktop"
                                    value={this.state.ToDate}
                                    minDate={this.state.FrmDate}
                                    onChange={this.HandleToDate}
                                />
                            </LocalizationProvider>
                        </div>
                        <Button id="emed_Calender_TodayButton" variant="text"
                            onClick={() => {
                                this.setState({
                                    FrmDate: new Date(),
                                    ToDate: new Date()
                                })
                            }}>{t("Today")}</Button>
                        <Button id="emed_Calender_SearchIcon" variant="contained" onClick={this.HandleCustomDate}>{t("Search")}</Button>
                    </div>
                </Popover>
            </div>
        )
    }
}

export default withTranslation()(FutureDatePicker);