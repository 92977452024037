import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import DescriptionIcon from '@mui/icons-material/Description';
import ClearIcon from '@mui/icons-material/Clear';
import { AdmissionTypeList } from './RemedinetConstants';
import { AmountFormat } from '../../Components/CommonFunctions/CommonFunctions';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import RestAPIService from '../../Utility/Services/RestAPIService';
import Loader from '../../Components/Loader'
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ShowBase64Document } from '../../Components/Common Components/CommonComponents';
import { Colors } from '../../Styles/Colors';


class PayerDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      CheckValidation: false,
      PayerDetails: {},
      HomeData: this.props?.HomeData ? this.props?.HomeData : {},
      ShowDocument: null
    }
    this.uploadAttachment = React.createRef();
  }


  componentDidMount() {
    this.setState({
      HomeData: this.props?.HomeData ? this.props?.HomeData : {}
    }, () => {
      this.getHistoryDetails()

    })
  }

  getHistoryDetails() {
    this.LoaderFunction(true)
    try {
      RestAPIService.getAll(Serviceurls.REMEDINET_PAYER_DETAILS + '?id=' + (this.state.HomeData?.id ? this.state.HomeData?.id : ""))
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              PayerDetails: response.data?.data ? response.data?.data : {}
            }, () => {
              this.LoaderFunction(false)
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
            this.LoaderFunction(false)
          } else {
            this.errorMessage(error.message)
            this.LoaderFunction(false)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.LoaderFunction(false)
    }
  }

  // componentDidUpdate(prevProps) {
  //     if (prevProps !== this.props) {
  //         this.setState({
  //             ClaimDatas: this.props?.ClaimDatas
  //         })
  //     }
  // }
  handleChangeTextBox = (e, key) => {
    let FormData = this.state?.PayerDetails
    switch (key) {
      case 'ip_number':
        FormData["ip_number"] = e.target.value
        break
      default:
        FormData[key] = e.target.value
        break;
    }
    this.setState({
      PayerDetails: FormData,
      CheckValidation: false
    })
  }

  //   Proceedpayment = () => {
  //     let Company_Name = this.state?.CreditDetails?.credit_type === "Insurance credit" ? this.state?.CreditDetails?.insurance_name : this.state?.CreditDetails?.credit_type === "Corprate credit" ? this.state?.CreditDetails?.employer_name : null;
  //     let ID_No = this.state?.CreditDetails?.credit_type === "Insurance credit" ? this.state?.CreditDetails?.policy_no : this.state?.CreditDetails?.credit_type === "Corprate credit" ? this.state?.CreditDetails?.id_no : null;
  //     this.setState({isDisabled : true})
  //     try {
  //         let states = this.state;
  //         var data = {
  //             "ip_admission_id": states?.ipPatientData?.id,
  //             "claim_amount": +states?.TotalClaimAmt,
  //             "primary_payment_type": this.state?.CreditDetails?.credit_type === "Corprate credit" ? 'Corprate credit' : this.state?.CreditDetails?.credit_type === "Insurance credit" ? 'Insurance credit' : null,
  //             "policy_company_name": Company_Name,
  //             "policy_number": ID_No,
  //             "approved_amount": +states?.ApprovedAmt,
  //             "disallowance_amount": +states?.DisallowanceAmt,
  //             "tds_amount": +states?.TDSAmount
  //         }
  //         RestAPIService.create(data, Serviceurls.PRE_AUTHORIZATION_POST)
  //             .then((response) => {
  //                 if (response.data.status === 'success') {
  //                     this.setState({
  //                         isDisabled: false
  //                     },()=> {this.getPatientData()})
  //                     this.successMessage(response.data.message)
  //                 }
  //             }).catch((error) => {
  //                 if (error?.response?.data?.message) {
  //                     this.errorMessage(error.response.data.message)
  //                 } else {
  //                     this.errorMessage(error.message)
  //                 }
  //             })
  //     } catch (error) {
  //         this.errorMessage(error.message)
  //     }
  // }


  ViewPayerDetail = (label, Count) => {
    try {
      return (
        <Box className='eMed_payer_detail_items' >
          <Typography>{label}</Typography>
          <TextField
            label={`Amount`}
            variant="standard"
            value={AmountFormat(Count ? Count : 0)} />
        </Box>
      )
    }
    catch (e) {

    }
  }
  FormTextBox = (label, key, WidthinVW = 18, isMandatory = false, isdisabled = false) => {
    try {
      return (
        <Box className='eMed_Form_textBoxes' sx={{ width: `${WidthinVW}vw` }}>
          <TextField
            label={`${label}  ${isMandatory ? "*" : ""}`}
            disabled={isdisabled}
            variant="standard"
            sx={{ width: `${WidthinVW - 2}vw` }}
            error={isMandatory && this.state?.CheckValidation && !this.state?.PayerDetails[key]}
            value={this.state?.PayerDetails[key] ? this.state?.PayerDetails[key] : ""}
            onChange={(e) => { this.handleChangeTextBox(e, key) }} />
        </Box>
      )
    }
    catch (e) {
      console.log(e.message);
    }
  }

  handleDocViewClose() {
    this.setState({
      ShowDocument: null
    })
  }


  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  render() {
    console.log(this.state);
    let { PayerDetails } = this.state
    return (
      <Box>
        <Box className="eMEd_Form_body_div" height={"75vh"} sx={{ display: 'flex', justifyContent: 'flex-start', overflow: 'auto' }}>
          <Box className='eMed_Form_Div_body'>
            <Typography className='eMed_Form_Div_Title' sx={{ paddingLeft: '2vw', textDecoration: 'underline' }}>Payer Details</Typography>
            <Box>
              {this.ViewPayerDetail("Bill Amount", PayerDetails?.bill_amount ? PayerDetails?.bill_amount : 0)}
              {this.ViewPayerDetail("Gross Payment", PayerDetails?.Gross_Payment ? PayerDetails?.Gross_Payment : 0)}
              {this.ViewPayerDetail("Transaction Amount", PayerDetails?.transaction_amt ? PayerDetails?.transaction_amt : 0)}
              {this.ViewPayerDetail("Total Payable Amount", PayerDetails?.net_payable_amount ? PayerDetails?.net_payable_amount : 0)}
              {this.ViewPayerDetail("Disallowed Amount", PayerDetails?.disallowedAmount ? PayerDetails?.disallowedAmount : 0)}
              {this.ViewPayerDetail("Deduction", PayerDetails?.deduction_amount ? PayerDetails?.deduction_amount : 0)}
              {this.ViewPayerDetail("Approved Amount", PayerDetails?.approvedAmount ? PayerDetails?.approvedAmount : 0)}
              {this.ViewPayerDetail("Co-Payment", PayerDetails?.co_payment ? PayerDetails?.co_payment : 0)}
              {/* {this.ViewPayerDetail("Eligible Amount",  "-")} */}
              {this.ViewPayerDetail("TDS", PayerDetails?.TDS ? PayerDetails?.TDS : 0)}
              {this.ViewPayerDetail("Payer Discount", PayerDetails?.PayerDiscountAmount ? PayerDetails?.PayerDiscountAmount : 0)}
            </Box>
          </Box>
          <Divider orientation='vertical' sx={{ paddingX: '1vw' }} />
          <Box>
            <Box className='eMed_Form_Div_body'>
              <Box sx={{ display: 'flex', width: "100%", marginLeft: "2vw" }}>
                <Typography fontWeight={500}>{`Payer Status : `}</Typography>
                <Typography className='eMed_Form_Div_Title' >{`${PayerDetails?.status ? PayerDetails?.status : "-"}`}</Typography>
              </Box>
              {/* <Box className='eMed_Form_body_inputs_div'>
                {this.FormTextBox("ICD Code", "insured_first_name", 20, false)}
                {this.FormTextBox("ICD Name", "insured_first_name", 20, false)}
                {this.FormTextBox("Pre-Auth Letter No", "insured_first_name", 20, false)}
                {this.FormTextBox("Co Pay Type", "insured_first_name", 20, false)}
                {this.FormTextBox("Special Claim No", "insured_first_name", 20, false)}
                {this.FormTextBox("Status", "insured_first_name", 20, true, true)}
              </Box> */}
            </Box>
            <Box className='eMed_Form_Div_body' sx={{ marginLeft: '1vw' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ marginLeft: '1vw', textDecoration: 'underline' }}>Payer Attachments :</Typography>
              </Box>
              <Box sx={{ maxHeight: '63vh', overflow: 'scroll', paddingRight: "1vw" }}>
                {
                  this.state.PayerDetails?.payer_attachment?.length > 0 ?
                    this.state.PayerDetails?.payer_attachment.map((list, index) => {
                      let Remarks = `Remarks : ${list?.attachment_remarks ? list?.attachment_remarks : "N/A"}`
                      return (
                        <Box className='eMed_document_div' sx={{ width: "48vw", marginLeft: '1vw', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '11vh' }}>
                          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Typography paddingX={"0.5vw"} fontWeight={600}>{`${index < 10 ? `0${index + 1}` : index + 1}. `}</Typography>
                            <DescriptionIcon color='primary' />
                            <Typography width={"20vw"} paddingX={"1vw"}>{list?.approval_file_name?.length > 40 ? <Tooltip placement="top" title={list?.approval_file_name} arrow><div>{list?.approval_file_name.slice(0, 40) + "..."}</div></Tooltip> : list?.approval_file_name ? list?.approval_file_name : "-"}</Typography>
                            <Typography width={"20vw"} paddingX={"1vw"} fontSize={'0.9vw'}>{Remarks?.length > 40 ? <Tooltip placement="top" title={Remarks} arrow><div>{Remarks.slice(0, 40) + "..."}</div></Tooltip> : Remarks ? Remarks : "-"}</Typography>
                            <Tooltip title="View & Download" placement='top' arrow>
                              <IconButton
                                sx={{ visibility: typeof (list?.approval_file_Content) === "string" ? 'visible' : 'hidden' }}
                                onClick={() => { this.setState({ ShowDocument: list }) }}
                              >
                                <VisibilityIcon color='primary' />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: "48vw", marginTop: '0.5vw' }}>
                            <Typography visibility={'hidden'} fontSize={'0.8vw'} color={Colors.grayShade} paddingX={"1vw"}>{`Payer Status : ${list?.status ? list?.status : "-"}`}</Typography>
                            <Typography fontSize={'0.8vw'} color={Colors.grayShade} paddingX={"1vw"}>{`${list?.created_date ? list?.created_date : "-"}`}</Typography>
                          </Box>
                        </Box>
                      )
                    })
                    : <Typography sx={{ color: Colors.grayShade, width: '100%', textAlign: 'center', marginTop: "3vh", paddingLeft: '10vw' }}>No Records Found</Typography>
                }
              </Box>
            </Box>
          </Box>



        </Box>
        {/* <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%', justifyContent: 'center' }}>
          <Typography>Payable Amount</Typography>
          {this.FormTextBox("Amount", "insured_first_name", 15, true)}
          {this.FormTextBox("Remarks", "insured_first_name", 40, true)}
        </Box> */}
        <Box className="eMEd_Form_Foot_Btn_div" sx={{ paddingTop: '2vw', justifyContent: "center" }}>
          {/* <Button size='small' sx={{ textTransform: 'capitalize' }} variant='outlined'>Save Draft</Button> */}
          {/* <Box>
            <Button size='small' sx={{ textTransform: 'capitalize' }} variant='outlined' onClick={() => {
              // this.props.MovetoNext(this.state.FormsDatas, 1)
            }}>Cancel
            </Button>
            <Button size='small' sx={{ textTransform: 'capitalize', marginLeft: '1vw' }} variant='contained' onClick={() => {
              // this.props.MovetoPayerTab(this.state.ClaimDatas)
            }}>Save
            </Button>
          </Box> */}
          {/* <Button disabled size='small' onClick={() => { this.Proceedpayment() }} variant='contained'>Proceed to Pay</Button> */}
        </Box>
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.ShowDocument ? <ShowBase64Document Data={this.state.ShowDocument} handleClose={this.handleDocViewClose.bind(this)} /> : null}

      </Box>
    )
  }
}
export default withTranslation()(PayerDetail)
