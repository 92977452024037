import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Colors } from '../../../Styles/Colors'
import { Autocomplete, Box, Button, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Paper, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import Loader from '../../../Components/Loader'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { ImagePaths } from '../../../Utility/ImagePaths'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { localSetItem } from '../../../Utility/Services/CacheProviderService'

class QMSConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateLimit: '',
            dateLimitType: 'Days',
            appointmentList: [],
            priorityOne: '',
            priorityTwo: '',
            priorityThree: '',
            isLoader: false,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            tokenScreen: '',
            linkPopup: false,
            doctorList: [],
            doctorName: [],
            generateLinkData: [],
            finalLinkData: [],
            showNotiFo: false,
            orgGenerateLinkData: [
                {
                    doctor_details: [],
                }
            ]
        }
    }


    componentDidMount = () => {
        this.getQmsConfigData()
        this.getAppointmentList()
        this.getDoctorList()
    }

    getQmsConfigData = () => {
        try {
            this.setState({ isLoader: true })
            RestAPIService.getAll(Serviceurls.QMS_CONFIG)
                .then(response => {
                    if (response.data.status === "success") {
                        let data = response?.data?.data ? response.data.data : {}
                        this.setState({
                            dateLimit: data?.appointment_date_limit ? data?.appointment_date_limit : '',
                            dateLimitType: data?.duration_type ? data?.duration_type : 'Days',
                            priorityOne: data?.appointment_type_priority?.Priority_Level_1 ? data?.appointment_type_priority?.Priority_Level_1 : '',
                            priorityTwo: data?.appointment_type_priority?.Priority_Level_2 ? data?.appointment_type_priority?.Priority_Level_2 : '',
                            priorityThree: data?.appointment_type_priority?.Priority_Level_3 ? data?.appointment_type_priority?.Priority_Level_3 : '',
                            orgGenerateLinkData: data?.token_screen_link?.length > 0 ? data?.token_screen_link : [{ doctor_details: [] }],
                            showNotiFo: data?.show_notification_banner_fo ? data?.show_notification_banner_fo : false,
                            isLoader: false
                        })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message);
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.setState({ isLoader: false })
                });
        } catch (e) {
            this.setState({ isLoader: false })
            this.errorMessage(e.message);
        }
    }

    getAppointmentList = () => {
        try {
            this.setState({ isLoader: true })
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPAPPOINTMENT)
                .then(response => {
                    if (response?.data?.status === "success") {
                        this.setState({
                            appointmentList: response?.data?.data ? response.data.data : [],
                            isLoader: false
                        })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data?.message);
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.setState({ isLoader: false })
                });
        } catch (e) {
            this.setState({ isLoader: false })
            this.errorMessage(e.message);
        }
    }

    getDoctorList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            doctorList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    handleSave = () => {
        let data = {
            "appointment_date_limit": this.state.dateLimit ? +this.state.dateLimit : null,
            "duration_type": this.state.dateLimitType,
            "appointment_type_priority": {
                "Priority_Level_1": this.state.priorityOne ? this.state.priorityOne : '',
                "Priority_Level_2": this.state.priorityTwo ? this.state.priorityTwo : '',
                "Priority_Level_3": this.state.priorityThree ? this.state.priorityThree : '',
            },
            "token_screen_link": this.state.finalLinkData?.length > 0 ? this.state.finalLinkData : this.state.orgGenerateLinkData?.length > 0 ? this.state.orgGenerateLinkData : [],
            "show_notification_banner_fo": this.state.showNotiFo ? this.state.showNotiFo : false,
        }
        try {
            this.setState({ isLoader: true })
            RestAPIService.create(data, Serviceurls.QMS_CONFIG)
                .then(response => {
                    if (response.data.status === "successs") {
                        localSetItem("show_notification_banner_fo",this.state.showNotiFo)
                        this.getAppointmentList();
                        this.setState({ disableBtn: false, isLoader: false })
                        this.successMessage(response.data?.message);
                        this.handleClear();
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message);
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.setState({ disableBtn: false, isLoader: false })
                });
        } catch (e) {
            this.setState({ disableBtn: false, isLoader: false })
            this.errorMessage(e?.message);
        }
    }

    handleChange = (key, value) => {
        let states = this.state
        states[key] = value
        this.setState({ states })
    }

    handleClear = () => {
        this.setState({
            dateLimit: '',
            dateLimitType: 'Days',
            priorityOne: '',
            priorityTwo: '',
            priorityThree: '',
            tokenScreen: '',
            finalLinkData: [],
            generateLinkData: [],
            orgGenerateLinkData: [
                {
                    doctor_details: [],
                }
            ],
            showNotiFo: false,
        }, () => { this.getQmsConfigData() })
    }

    renderSelect = (label, option1, stateName1, stateName2, width1, width2) => {
        let states = this.state
        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', m: '1vw', ml: '2vw' }}>
                <Typography>{label}</Typography>
                <Box sx={{ gap: '1vw', mr: '1vw', display: 'flex' }}>
                    <Select
                        sx={{ width: width1, fontSize: '0.95vw' }}
                        inputProps={{ emed_tid: `${stateName1}_testID` }}
                        size='small'
                        value={states[stateName1]}
                        onChange={(e) => {
                            this.handleChange(stateName1, e.target.value)
                            states[stateName2] = ''
                            this.setState({ states })
                        }}
                    >
                        {option1?.map((item, index) => (
                            <MenuItem sx={{ fontSize: '0.95vw' }} key={index} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                    <OutlinedInput
                        size='small'
                        sx={{ width: width2 }}
                        endAdornment={<InputAdornment position="end">{this.state.dateLimitType}</InputAdornment>}
                        inputProps={{ emed_tid: `${stateName2}_testID` }}
                        value={states[stateName2]}
                        onChange={(e) => {
                            if ((CommonValidation.numberOnly(e.target.value) || e.target.value === "") && (this.state.dateLimitType === "Days" ? +e.target.value <= 31 : +e.target.value <= 12)) {
                                this.handleChange(stateName2, e.target.value)
                            }
                        }}

                    />

                </Box>
            </Box>
        )
    }

    renderPriorityType = (label, stateName, color = "") => {
        let states = this.state
        return (
            <Box sx={{ gap: '1vw', mr: '1vw', display: 'flex', alignItems: 'center' }}>
                <Box sx={{ backgroundColor: color, padding: '0.2vw', borderRadius: '0.4vw', alignContent: 'center', height: '3vh' }}><Typography color={'white'} fontWeight={'600'} fontSize={'0.7vw'}>{label}</Typography></Box>
                <FormControl size='small'>
                    <InputLabel>Select Appointment Type</InputLabel>
                    <Select
                        sx={{ width: '17vw' }}
                        size='small'
                        value={states[stateName]}
                        label="Select Appointment Type"
                        inputProps={{ emed_tid: `${stateName}_testID` }}
                        onChange={(e) => {
                            if (stateName === "priorityOne") {
                                if ((e.target.value !== this.state.priorityTwo && e.target.value !== this.state.priorityThree) || e.target.value === "") {
                                    this.handleChange(stateName, e.target.value)
                                } else {
                                    this.errorMessage("Already appointment type selected")
                                }
                            }
                            else if (stateName === "priorityTwo") {
                                if ((e.target.value !== this.state.priorityOne && e.target.value !== this.state.priorityThree) || e.target.value === "") {
                                    this.handleChange(stateName, e.target.value)
                                } else {
                                    this.errorMessage("Already appointment type selected")
                                }
                            }
                            else if (stateName === "priorityThree") {
                                if ((e.target.value !== this.state.priorityOne && e.target.value !== this.state.priorityTwo) || e.target.value === "") {
                                    this.handleChange(stateName, e.target.value)
                                } else {
                                    this.errorMessage("Already appointment type selected")
                                }
                            }
                        }}
                    >
                        <MenuItem value=''>None</MenuItem>
                        {this.state.appointmentList?.map((item, index) => (
                            <MenuItem key={index} value={item?.appoinment_type}>{item?.appoinment_type}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        )
    }

    removeLink = (i) => {
        this.state.generateLinkData?.splice(i, 1)
        this.setState({ generateLinkData: this.state.generateLinkData })
    }

    renderLinkRow = (data, index) => {
        const { t } = this.props
        let states = this.state
        return (
            <Box display={'flex'} justifyContent={'space-evenly'} padding={'1vw'}>
                <Typography alignContent={'center'}>{`Link ${index + 1}`}</Typography>

                <Autocomplete
                    multiple
                    limitTags={1}
                    size='small'
                    sx={{ width: '20vw' }}
                    options={this.state.doctorList}
                    getOptionLabel={(option) => (option?.doctor_name)}
                    value={data["doctor_details"]}
                    onChange={(event, newValue) => {
                        data["doctor_details"] = newValue
                        this.setState({
                            states
                        })
                    }}
                    renderOption={(props, option) => (
                        <li {...props} key={option?.doctor_id}>
                            {option?.doctor_name}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('selectDoctor')}
                            inputProps={{ ...params.inputProps, 'emed_tid': 'generate_link_selectDoctor_feild' }}
                        />
                    )}
                />
                <IconButton sx={{ marginLeft: "0.5vw" }} size='small' emed_tid='generate_link_delete_btn' disabled={index === 0 ? true : false} onClick={() => this.removeLink(index)}>
                    <img src={index === 0 ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete' />
                </IconButton>

            </Box>
        )
    }

    addNewLink = () => {
        let { generateLinkData } = this.state
        let dupData = {
            doctor_details: []
        }
        generateLinkData?.push(dupData)
        this.setState({
            generateLinkData
        })
    }

    validateLink = () => {
        let ArrayList = []
        let isDuplicated = false
        let noValue = false

        this.state.generateLinkData?.forEach((list) => {
            if(list?.doctor_details?.length === 0){
                noValue = true
            }
            ArrayList = ArrayList.concat(list.doctor_details)
        })

        for (let i = 0; i < ArrayList.length; i++) {
            let isSame = ArrayList.find((list, Findex) => (list?.doctor_id === ArrayList[i]?.doctor_id && Findex !== i))
            if (isSame?.doctor_id) {
                this.errorMessage(`${isSame?.doctor_name} repeated in multiple times`)
                isDuplicated = true
                break
            } else {
                isDuplicated = false
            }
        }

        if(noValue){
            this.errorMessage("Select Doctor Name to Continue")
        } else if (!isDuplicated && !noValue) {
            let finalData = this.state.generateLinkData?.map((item, i) => ({
                link: `link ${i + 1}`,
                doctor_details: item?.doctor_details?.map((i) => ({
                    doctor_id: i?.doctor_id,
                    doctor_name: i?.doctor_name
                }))
            }));
            this.setState({
                linkPopup: false,
                finalLinkData: finalData,
                orgGenerateLinkData: finalData,
                generateLinkData: [],
            })
        }
    }

    closeDoctorSelect = () => {
        this.setState({
            linkPopup: false,
            generateLinkData: [],
        })
    }

    renderDoctorSelectPopup = () => {
        let length = this.state.generateLinkData?.length
        let disableAdd = this.state.generateLinkData?.length > 0 ? this.state.generateLinkData[length - 1]?.doctor_details?.length === 0 : false      
        return (
            <Modal open={true} onClose={() => this.closeDoctorSelect()} sx={{ height: "50vh", width: "40vw", marginTop: "25vh", marginLeft: '35vw' }}>
                <Paper sx={{ height: '100%', padding: '1vw', overflow: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography fontWeight={'600'}>Generate Link</Typography>
                            <IconButton emed_tid='generate_link_close_btn' onClick={() => { this.closeDoctorSelect() }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                        </Box>
                        <hr />

                        {this.state.generateLinkData?.map((item, i) => (
                            this.renderLinkRow(item, i)
                        ))}
                        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                            <Button
                                emed_tid='generate_link_addLink_btn'
                                startIcon={<AddCircleIcon />}
                                className='eMed_Lab_act_btn'
                                variant='outlined'
                                sx={{ mb: '0.5vw', width: '8vw', padding: '5px', fontSize: '0.7rem', height: '5vh' }}
                                disabled={disableAdd}
                                onClick={() => { this.addNewLink() }}
                            > Add Link</Button>
                        </Box>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                        <Button emed_tid='generate_link_save_btn' variant='contained' size='small' onClick={() => this.validateLink()}>Save</Button>
                    </Box>
                </Paper>
            </Modal>
        )
    }

    validation = () => {
        if (+this.state.dateLimit) {
            this.handleSave()
        } else {
            this.errorMessage("Please enter date limit for appointment creation")
            this.setState({ disableBtn: false })
        }
    }

    handleRadioChange = (key, value) => {
        let states = this.state
        states[key] = JSON.parse(value)
        this.setState({ states })
    }

    renderRadio = (label, stateValue, key) => {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', m: '0.5vw', ml: '2vw' }}>
                <Typography>{label}</Typography>
                <RadioGroup row sx={{ gap: '2vw', mr: '1vw' }} value={stateValue} onChange={(e) => { this.handleRadioChange(key, e.target.value) }}>
                    <FormControlLabel value={true} control={<Radio inputProps={{ 'emed_tid': `${key}_yesBtn_testID` }} disabled={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "QMS Configuration", "SubTab")?.editAccess} />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio inputProps={{ 'emed_tid': `${key}_noBtn_testID` }} disabled={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "QMS Configuration", "SubTab")?.editAccess} />} label="No" />
                </RadioGroup>
            </Box>
        )
    }

    render() {
        const { t } = this.props
        const dateLimitOption = ["Days", "Months"]
        return (
            <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Paper className="eMed_srvConfig_Div">
                    <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                        <p className='eMed_srvConfig_rightHead_txt'>{t('QMS Configuration')}</p>
                    </div>
                    <div className="eMed_srvConfig_subDiv">
                        {this.renderSelect("Date limitation for appointment creation *", dateLimitOption, "dateLimitType", "dateLimit", "8vw", "12vw")}
                        <Box sx={{ m: '1vw', ml: '2vw' }}>
                            <Typography>Priority types of appointment</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: "1vw", alignItems: 'flex-end' }}>
                                {this.renderPriorityType("Priority Level 1", "priorityOne", "#1E972A")}
                                {this.renderPriorityType("Priority Level 2", "priorityTwo", "#0461B7")}
                                {this.renderPriorityType("Priority Level 3", "priorityThree", "#DE7D24")}
                            </Box>
                        </Box >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: '1vw', ml: '2vw' }}>
                            <Typography>Generate link for token screen</Typography>
                            <Button sx={{ marginRight: '1vw' }} emed_tid='generate_link_popup' variant='contained' onClick={() => this.setState({ generateLinkData: JSON.parse(JSON.stringify(this.state.orgGenerateLinkData)), linkPopup: true })}>Generate Link</Button>
                        </Box>     
                        <Box>{this.renderRadio("Show doctor calling message in FO", this.state.showNotiFo, "showNotiFo")}</Box>               
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: "flex-end", gap: "2vw", mr: '2vw' }}>
                        <Button variant='outlined' size="small" id="eMed_Config_btn" emed_tid='qmsConfig_resetBtn_testId' disabled={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "QMS Configuration", "SubTab")?.editAccess} onClick={() => { this.handleClear() }}>{t("Reset")}</Button>
                        <Button variant='contained' emed_tid='qmsConfig_saveBtn_testId' disabled={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "QMS Configuration", "SubTab")?.editAccess || this.state.disableBtn} size="small" id="eMed_Config_btn" onClick={() => { this.setState({ disableBtn: true }, () => { this.validation() }) }}>{t("Save")}</Button>
                    </Box>
                    <Loader loaderOpen={this.state.isLoader} />
                </Paper>
                {this.state.linkPopup ? this.renderDoctorSelectPopup() : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(QMSConfig)
