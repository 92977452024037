import React, { Component } from 'react';
import { Typography, Button, TextField, OutlinedInput, InputAdornment, Tooltip, TablePagination } from '@mui/material';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import './../../../Laboratory/Home/home.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { Trans, withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import CircularProgress from '@mui/material/CircularProgress';
class Appointments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchkey: "",
            appointmentData: [],
            admissionData: [],
            filterDate: new Date(),
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            rowsPerPage: 5,
            page: 0,
            total_count: 0,
            disableBtn: false,
        }
    }
    componentDidMount() {
        if(this.props.isFromIpAdmission){
            this.getIpAdmission()
        }else if(this.props.isFromLabHome){
            this.getOpappointments()
        }
        else{
            this.getAppointment()
        }
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    getAppointment() {
        try {
            let date = DateTime.fromJSDate(this.state.filterDate).toFormat('yyyy-MM-dd')
            if(date !== "Invalid DateTime"){
            this.LoaderFunction(true)
            this.setState({disableBtn: true})
            RestAPIService.getAll(Serviceurls.APPOINTMENT_HOME + "?search_key=" + this.state.searchkey + "&from_date=" + date + "&to_date=" + date + "&page_size=" + this.state.rowsPerPage + "&page=" + (this.state.page+1) + "&clinic_appointment=true")
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            appointmentData: response.data.data,
                            total_count: response.data.total_count,
                        }, () => {
                            this.LoaderFunction(false)
                            this.setState({disableBtn: false})
                        });
                    }
                })
                .catch(e => {
                    if (e?.reponse?.data?.status === 'fail') {
                        this.LoaderFunction(false)
                        this.errorMsgFunc(e.reponse.data.status)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMsgFunc(e.message)
                    }
                    this.setState({disableBtn: false})
                });}
        }
        catch (e) {
            this.errorMsgFunc(e.message)
            this.setState({disableBtn: false})
        }
    }

    getIpAdmission() {
        try {
            let date = DateTime.fromJSDate(this.state.filterDate).toFormat('yyyy-MM-dd')
            if (date !== "Invalid DateTime") {
                this.LoaderFunction(true)
                this.setState({disableBtn: true})
                RestAPIService.getAll(Serviceurls.IP_ADMISSION_LIST_HOME + "?search_key=" + this.state.searchkey + "&page_size=" + this.state.rowsPerPage + "&page=" + (this.state.page+1))
                    .then(response => {
                        if (response.data.status == "success") {
                            this.setState({
                                admissionData: response.data.data,
                                total_count: response.data.total_count,
                            }, () => {
                                this.LoaderFunction(false)
                                this.setState({disableBtn: false})
                            });
                        }
                    })
                    .catch(e => {
                        if (e?.reponse?.data?.status === 'fail') {
                            this.LoaderFunction(false)
                            this.errorMsgFunc(e.reponse.data.status)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMsgFunc(e.message)
                        }
                        this.setState({disableBtn: false})
                    });
            }
        }
        catch (e) {
            this.errorMsgFunc(e.message)
            this.setState({disableBtn: false})
        }
    }

    getOpappointments() {
        try {
            let date = DateTime.fromJSDate(this.state.filterDate).toFormat('yyyy-MM-dd')
            if (date !== "Invalid DateTime") {
                this.LoaderFunction(true)
                this.setState({disableBtn: true})
                RestAPIService.getAll(Serviceurls.RAD_APPOINTMENT_GET + "?search_date=" + date + "&search_key=" + this.state.searchkey  + "&page_size=" + this.state.rowsPerPage + "&page=" + (this.state.page+1))
                    .then(response => {
                        if (response.data.status == "success") {
                            this.setState({
                                admissionData: response.data.data,
                                total_count: response.data.total_count,
                            }, () => {
                                this.LoaderFunction(false)
                                this.setState({disableBtn: false})
                            });
                        }
                    })
                    .catch(e => {
                        if (e?.reponse?.data?.status === 'fail') {
                            this.LoaderFunction(false)
                            this.errorMsgFunc(e.reponse.data.status)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMsgFunc(e.message)
                        }
                        this.setState({disableBtn: false})
                    });
            }
        }
        catch (e) {
            this.errorMsgFunc(e.message)
            this.setState({disableBtn: false})
        }
    }

    errorMsgFunc = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMsgFunc = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    // getAppointmentPrint = (patient_id) => {
    //     RestAPIService.getAll(Serviceurls.APPOINTMENT_HOME + `?export_type=pdf&patient_id=${patient_id}`).
    //         then((response) => {
    //             const file = new Blob(
    //                 [response.data],
    //                 { type: 'application/pdf' });
    //             const fileURL = URL.createObjectURL(file);
    //             window.open(fileURL);
    //         }).catch((error) => {
    //             this.setState({
    //                 isErrorMsg: true,
    //                 isErrorMsgText: error.reponse.data.message
    //             }, () => {
    //                 setTimeout(() => {
    //                     this.setState({ isErrorMsg: false })
    //                 }, 2000);
    //             })
    //         })
    // }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        const { t, isFromIpAdmission , isFromLabHome } = this.props
        const { history } = this.props.properties
        let renderList = (isFromIpAdmission || isFromLabHome) ? this.state.admissionData : this.state.appointmentData;
        return (
            <div>
                <div style={{ display: "flex", margin: "0.5vw" }}>
                    <label style={{ flex: "0.5" }}><b>{isFromIpAdmission ? t("IPAdmissionList"): t("Appointments")}</b></label>
                    <div style={{ flex: "0.5", display: "flex", justifyContent: "end" }}
                        onClick={() => { this.props.onClickAppointClose(false) }}>
                        <img
                            src={ImagePaths.LabCloseButton.default}
                            style={{ cursor: "pointer", height: "1vw", width: "1vw" }}
                            alt=""
                        />
                    </div>
                </div>

                <div className='eMed_Pres_Header'>
                    <OutlinedInput
                        placeholder="Search Patient..."
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                if (isFromIpAdmission) {
                                    this.getIpAdmission()
                                } else if (isFromLabHome) {
                                    this.getOpappointments()
                                } else {
                                    this.getAppointment()
                                }
                            }
                        }}
                        onChange={(e) => {
                            this.setState({
                                searchkey: e.target.value
                            })
                        }}
                        value={this.state.searchkey}
                        size="small"
                        sx={{ width: "13vw", marginRight: "0.5vw" }}
                        endAdornment={
                            <InputAdornment disablePointerEvents={this.state.disableBtn} emed_tid= {isFromIpAdmission ? "IpAdmission_patient_search_btn": "Appoint_patient_search_btn"} position="end" sx={{ cursor: 'pointer' }} onClick={() => { if (this.state.searchkey) { (isFromIpAdmission || isFromLabHome) ? this.getIpAdmission() : this.getAppointment() } }}>
                                <i class="fas fa-search"></i>
                            </InputAdornment>
                        }
                        inputProps={{ emed_tid:isFromIpAdmission ? "IpAdmission_patient_search": "Appoint_patient_search"}}
                    />
                   { isFromIpAdmission ? null : <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            emed_tid="Appoint_date_search"
                            className="Lab_SavedBill_date"
                            id="Lab_SavedBill_date"
                            label={("")}
                            value={this.state.filterDate}
                            maxDate={new Date()}
                            inputFormat={"dd-MM-yyyy"}
                            autoComplete="off"
                            onChange={(newValue) => {
                                this.setState({
                                    filterDate: newValue > new Date() ? new Date() : newValue
                                }, () => {
                                    if (isFromLabHome) {
                                        this.getOpappointments()
                                    } else {
                                        this.getAppointment()
                                    }
                                })
                            }}
                            renderInput={(params) => <TextField size="small" sx={{ width: "10vw" }} {...params}
                                error={this.state.expDateerror} helperText={this.state.expDatehelpertext} />}
                        />
                    </LocalizationProvider>}
                </div>
                {renderList && renderList.length > 0 ?
                    <div>
                        <div className='eMed_App_DataView' style={{width: (isFromIpAdmission || isFromLabHome) ? '36vw' : '28.5vw', height: this.props.isFromPharmaApp ? '48vh': "58vh"}}>
                            {renderList.map((item, index) => {
                                var sno = (this.state.page * this.state.rowsPerPage) + (index + 1)
                                let RoomDetails = `${item?.bed_number ? item?.bed_number : '-'}/${item?.room_number ? item?.room_number : '-'}/${item?.ward_name ? item?.ward_name : '-'}/${item?.block_name ? item?.block_name : '-'}`
                                return (
                                    <div className="Lab_Home_Saved_Bill">
                                        <div style={{ flex: 0.10 }}>{sno}</div>
                                        <div style={{ flex: 0.15 }}><img src={ImagePaths.Male.default} alt='Avatar' /></div>
                                        <div style={{ flex: 0.33 }}>
                                            {item?.patient_name.length > 12 ?
                                                <Tooltip title={item?.patient_name} placement='top'>
                                                    <Typography className='savedBillText'>{item?.patient_name ? item?.patient_name.slice(0, 12) + '...' : '-'}</Typography>
                                                </Tooltip> : <Typography className='savedBillText'>{item?.patient_name ? item?.patient_name : '-'}</Typography>}
                                            <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC' }}>{item?.patient_mobile_number ? item?.patient_mobile_number : '-'}</Typography>
                                        </div>
                                        <div style={{ flex: 0.32 }}>
                                            {item?.doctor_name.length > 12 ?
                                                <Tooltip title={item?.doctor_name} placement='top'>
                                                    <Typography className='savedBillText'>{item?.doctor_name ? item?.doctor_name.slice(0, 12) + '...' : '-'}</Typography>
                                                </Tooltip> : <Typography className='savedBillText'>{item?.doctor_name ? item?.doctor_name : '-'}</Typography>}
                                                {isFromIpAdmission ? RoomDetails.length > 15 ?
                                                <Tooltip title={RoomDetails} placement='top'>
                                                    <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC' }}>{RoomDetails ? RoomDetails.slice(0, 15) + '...' : '-'}</Typography>
                                                </Tooltip> : <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC'}}>{RoomDetails ? RoomDetails : '-'}</Typography> : null}
                                        </div>
                                        <div style={{ flex: 0.10 }}>
                                            <Button emed_tid="Appoint_to_bill" size='small' variant='contained' sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                                                onClick={() => {
                                                    this.props.isFromLabHome ?
                                                        this.props.properties.history.push({ pathname: "/MainHome", state: { Patient_ID: item?.patient_id } })
                                                        : this.props.properties.history.push({ pathname: "/PharmacyBilling", state: { Patient_ID: item?.patient_id, showHistory: true, RefreshTable: true, is_AppDetail: true } })
                                                    this.props.onClickAppointClose(false)
                                                }}
                                            >{(t('CreateBill'))}</Button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {this.props?.isFromPharmaApp ? <TablePagination
                            component='div'
                            id="eMed_tbl_pagination"
                            rowsPerPageOptions={[5, 10, 15]}
                            count={this.state.total_count}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={(event, newPage) => { this.setState({ page: newPage }, () => { isFromIpAdmission ? this.getIpAdmission() : this.getAppointment() }); }}
                            onRowsPerPageChange={(event) => { this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => { isFromIpAdmission ? this.getIpAdmission() : this.getAppointment() }); }}
                        /> : null}
                        </div> :
                        this.state.isLoader ? <div style={{display: 'flex', alignItems:'center', justifyContent:'center', width: isFromIpAdmission ? '36vw' : '28.5vw'}} className='eMed_App_DataView'><CircularProgress/></div>:<div><Typography id='eMed_Pres_pop_NORec'>{t("No records found")}</Typography></div>}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                    
            </div>
        );
    }
}
export default withTranslation()(Appointments)