import React, { Component } from 'react';
import { CommonNavigationHeader } from '../../../Components/Common Components/CommonComponents';
import BillReport from './BillReport';
import DoctorReport from './DoctorReport';
import OutSourceReport from './OutSourceReport';
import PatientReport from './PatientReport';
import { Box, Typography } from '@mui/material'
import ReceiptBills from './ReceiptBills';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import GroupwiseReport from './GroupwiseReport';
import GroupwiseSummaryReport from './GroupwiseSummaryReport';
import TestwiseReport from './TestwiseReport';
import TestwiseSummaryReport from './TestwiseSummaryReport';
import LabCensusReport from './LabCensusReport';
import IpAdmissionReport from '../../FrontOffice/FOReports/IpServiceReport/IpAdmissionReport';
import LabResultCensusReport from './LabResultCensusReport';

const navigationHeaderList = [
    { id: 0, TabName: "Patient Report", value: "patientReport", content: <PatientReport /> },
    { id: 1, TabName: "Referred Doctor Report", value: "doctorReport", content: <DoctorReport /> },
    { id: 2, TabName: "Bill Report", value: "billReport", content: <BillReport /> },
    { id: 3, TabName: "Out Source Report", value: "outSourceReport", content: <OutSourceReport /> },
    { id: 4, TabName: "Receipt Bills", value: "receiptbills", content: <ReceiptBills /> },
    { id: 5, TabName: "GroupWise", value: "groupwise", content: <GroupwiseReport /> },
    { id: 6, TabName: "GroupWise Summary", value: "groupwiseSummary", content: <GroupwiseSummaryReport /> },
    { id: 7, TabName: "TestWise", value: "testwise", content: <TestwiseReport /> },
    { id: 8, TabName: "TestWise Summary", value: "testwiseSummary", content: <TestwiseSummaryReport /> },
    { id: 9, TabName: "Census Report", value: "censusReport", content: <LabCensusReport /> },
    { id: 10, TabName: "IP Admission List", value: "IPAdmissionList", content: <IpAdmissionReport /> },
    { id: 11, TabName: "Diagnostic Census Report", value: "LabResultCensusReport", content: <LabResultCensusReport /> },
]



export default class Reports extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedScreen: "patientReport",
            patient_search : '',
            doctor_search : '',
            bill_search : '',
            outsource_search : '',
            common_search : '',
            receipt_search: '',
        }
    }

    componentDidMount(){
        this.props.changeScreen('2')
    }

    componentWillReceiveProps(props){
        let {selectedScreen} = this.state
        if(props.reportSearch !== this.state.common_search){
            this.setState({
                common_search : props.reportSearch
            })
            if(selectedScreen === "patientReport"){
                this.setState({
                    patient_search : props.reportSearch
                })
            }else if(selectedScreen === "doctorReport"){
                this.setState({
                    doctor_search : props.reportSearch
                })
            }else if(selectedScreen === "billReport"){
                this.setState({
                    bill_search : props.reportSearch
                })
            }else if(selectedScreen === "outSourceReport"){
                this.setState({
                    outsource_search : props.reportSearch
                })
            }else if(selectedScreen === "receiptbills"){
                this.setState({
                    receipt_search : props.reportSearch
                })
            }
        }
    }

    NavigateScreen(id) {
        this.setState({
            selectedScreen: navigationHeaderList[id].value
        })
        this.props.clrSearch()
        this.props.subScreenChange(navigationHeaderList[id].value)
    }

    render() {
        return (
            <>
                <Box>
                    <CommonNavigationHeader
                        menu={navigationHeaderList}
                        NavigateScreen={this.NavigateScreen.bind(this)}
                    />
                    <div>
                        {
                            (this.state.selectedScreen === 'patientReport' &&  CheckAccessFunc("Laboratory", "Reports", "Patient Report", null, "Tab")?.viewAccess) ? <PatientReport search_key={this.state.patient_search} /> :
                                (this.state.selectedScreen === 'doctorReport' &&  CheckAccessFunc("Laboratory", "Reports", "Referred Doctor Report", null, "Tab")?.viewAccess) ? <DoctorReport search_key={this.state.doctor_search} /> :
                                    (this.state.selectedScreen === 'billReport'&&  CheckAccessFunc("Laboratory", "Reports", "Bill Report", null, "Tab")?.viewAccess) ? <BillReport search_key={this.state.bill_search} /> :
                                        (this.state.selectedScreen === 'outSourceReport' && CheckAccessFunc("Laboratory", "Reports", "Out Source Report", null, "Tab")?.viewAccess) ? <OutSourceReport search_key={this.state.outsource_search} /> :
                                            (this.state.selectedScreen === 'receiptbills' && CheckAccessFunc("Laboratory", "Reports", "Receipt Bills", null, "Tab")?.viewAccess) ? <ReceiptBills search_key={this.state.receipt_search} /> :
                                                (this.state.selectedScreen === 'groupwise' && CheckAccessFunc("Laboratory", "Reports", "GroupWise", null, "Tab")?.viewAccess) ? <GroupwiseReport search_key={this.state.receipt_search} /> :
                                                    (this.state.selectedScreen === 'groupwiseSummary' && CheckAccessFunc("Laboratory", "Reports", "GroupWise Summary", null, "Tab")?.viewAccess) ? <GroupwiseSummaryReport search_key={this.state.receipt_search} /> :
                                                        (this.state.selectedScreen === 'testwise' && CheckAccessFunc("Laboratory", "Reports", "TestWise", null, "Tab")?.viewAccess) ? <TestwiseReport search_key={this.state.receipt_search} /> :
                                                            (this.state.selectedScreen === 'testwiseSummary' && CheckAccessFunc("Laboratory", "Reports", "TestWise Summary", null, "Tab")?.viewAccess) ? <TestwiseSummaryReport search_key={this.state.receipt_search} /> :
                                                                (this.state.selectedScreen === 'censusReport' && CheckAccessFunc("Laboratory", "Reports", "Census Report", null, "Tab")?.viewAccess) ? <LabCensusReport /> :
                                                                    (this.state.selectedScreen === 'IPAdmissionList' && CheckAccessFunc("Laboratory", "Reports", "IP Admission List", null, "Tab")?.viewAccess) ? <IpAdmissionReport /> :
                                                                        (this.state.selectedScreen === 'LabResultCensusReport' && CheckAccessFunc("Laboratory", "Reports", "Diagnostic Census Report", null, "Tab")?.viewAccess) ? <LabResultCensusReport /> :
                                                                <Typography className='eMed_NoAccess_Text' sx={{ paddingTop: "13vw" }}>No Access For This Screen</Typography>
                        }
                    </div>
                </Box>
            </>
        )
    }
}
